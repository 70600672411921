/* eslint-disable jsx-a11y/anchor-is-valid */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatCurrency } from "../../../../helpers";
import secondaryComponents from "../../../secondaryComponents";
import useOrderflows from "../Orderflows/useOrderflows";
import styles from "./TransactionDetailsV2.module.scss";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import primaryComponents from "../../../primaryComponents";
import useTransactionDetails from "./useTransactionDetails";
import Icon from "../../../../assets/icons";
import { useNavigate } from "react-router-dom";
import PrintSelect from "../PrintSelect";
import { EditIcon } from "../../../../assets/svgIcons";
import UpdateOrderDate from "../UpdateOrderDate";
import CustomerUpdate from "../../CustomerUpdate";

const TransactionDetailsV2 = ({
  orderId,
  isOrderFlow = true,
  closeModal = () => {},
  resetView,
  setViaPos = () => {},
  viaSellMode = false,
  billWasUpdated = false,
  viaSaleRecord = false,
  // setBillWasUpdated = () => {},
}) => {
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const { isLoading, orderDetails } = useOrderflows({
    orderId,
  });
  const {
    isLoading: isPrinting,
    isGeneratingInvoice,
    isCancellingOrder,
    isPrintModal,
    isEditDate,
    isOrderCustomer,
    customerOrderDetails,
    setCustomerOrderDetails,
    setIsOrderCustomer,
    setIsEditDate,
    setIsPrintModal,
    cancelCurrentOrder,
    printReceipt,
    handlegenerateInvoice,
  } = useTransactionDetails({
    orderId,
  });

  const navigate = useNavigate();

  return (
    <>
      <motion.div
        initial={{ y: 100 }}
        animate={{ y: 0 }}
        className={styles.transactionDetails}
      >
        {!isOrderFlow ? (
          <FontAwesomeIcon
            onClick={closeModal}
            className="d-block ml-auto"
            icon="times"
            style={{ fontSize: "25px" }}
          />
        ) : null}

        {isOrderFlow ? (
          <div className={styles.buttonsContainer}>
            <>
              {(orderDetails?.payment_method === "bill" &&
                !orderDetails?.is_paid) ||
              orderDetails?.status === "PLACED" ? (
                <>
                  {!viaSellMode ? (
                    <primaryComponents.Button
                      classNames={"btn btn--primary smallBtn"}
                      onClick={() => {
                        closeModal();
                        navigate(
                          `/sell-mode/${orderDetails?.store?.id}?order_id=${orderDetails?.id}`,
                        );
                      }}
                    >
                      Collect Payment
                    </primaryComponents.Button>
                  ) : null}

                  {orderDetails?.status !== "PLACED" ? (
                    <secondaryComponents.NavigateWithPermission
                      permissionParentKey={"Inventory Management"}
                      permissionChildKey="can_edit_bill"
                    >
                      <primaryComponents.Button
                        classNames={"btn btn--outline smallBtn"}
                        onClick={() => resetView("isOrderUpdate")}
                      >
                        <img
                          width={18}
                          src={Icon.editWrite}
                          className="mr-2"
                          alt=""
                        />
                        Update Order
                      </primaryComponents.Button>
                    </secondaryComponents.NavigateWithPermission>
                  ) : null}

                  <primaryComponents.Button
                    classNames={"btn btn--outline smallBtn"}
                    isDisabled={isCancellingOrder}
                    isLoading={isCancellingOrder}
                    loadingText={"Cancelling..."}
                    onClick={() => cancelCurrentOrder()}
                  >
                    <img
                      width={18}
                      src={Icon.xSquareCloseDelete}
                      className="mr-2"
                      alt=""
                    />
                    Cancel Order
                  </primaryComponents.Button>
                </>
              ) : null}
            </>
            {orderDetails?.store?.allow_waybill &&
            orderDetails?.status !== "CANCELED" ? (
              <secondaryComponents.NavigateWithPermission
                permissionParentKey={"Inventory Management"}
                permissionChildKey="can_generate_waybill"
              >
                <primaryComponents.Button
                  onClick={() => resetView("isGenerateWaybill")}
                  classNames={"btn btn--primary smallBtn"}
                >
                  <img width={18} src={Icon.note} alt="" className="mr-1" />
                  <span>Generate Waybill</span>
                </primaryComponents.Button>
              </secondaryComponents.NavigateWithPermission>
            ) : null}
            {orderDetails?.store?.allow_prescription ? (
              <secondaryComponents.NavigateWithPermission
                permissionParentKey={"Inventory Management"}
                permissionChildKey="can_generate_prescription"
              >
                <primaryComponents.Button
                  onClick={() => resetView("isGeneratePescription")}
                  classNames={"btn btn--outline smallBtn"}
                >
                  <img
                    width={18}
                    src={Icon.printIcon}
                    alt=""
                    className="mr-1"
                  />
                  <span>Generate Prescription</span>
                </primaryComponents.Button>
              </secondaryComponents.NavigateWithPermission>
            ) : null}
            <primaryComponents.Button
              onClick={() => handlegenerateInvoice({ id: orderDetails?.id })}
              isDisabled={isGeneratingInvoice}
              isLoading={isGeneratingInvoice}
              loadingText={"Generating..."}
              classNames={"btn btn--primary smallBtn"}
            >
              <img width={18} src={Icon.note} alt="" className="mr-2" />
              Generate Invoice
            </primaryComponents.Button>
          </div>
        ) : null}

        <secondaryComponents.LoaderHelper
          classNames={"mt-5"}
          isLoading={isLoading}
        >
          <div>
            <h5 className="mb-3">Transaction Details</h5>
            {!viaSaleRecord ? (
              <>
                <div className="d-flex justify-content-between mb-2">
                  <p>Order Date:</p>
                  <p className="text-right dark--text">
                    {new Date(orderDetails?.created_at).toLocaleString("en-us")}
                  </p>
                </div>
                {orderDetails?.store?.allow_waybill ? (
                  <div className="d-flex justify-content-between mb-2">
                    <p>Waybill Number:</p>
                    <p className="text-right dark--text">
                      {orderDetails?.waybill_number || "N/A"}
                    </p>
                  </div>
                ) : null}
                <div className="d-flex justify-content-between mb-2">
                  <p>Transaction Reference:</p>
                  <p className="text-right dark--text">
                    {orderDetails?.trx_reference || "N/A"}
                  </p>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <p>Queue Number:</p>
                  <p className="text-right dark--text">
                    {orderDetails?.queue_number || "N/A"}
                  </p>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <p>Payment Mode:</p>
                  <p className="text-right dark--text">
                    {orderDetails?.payment_method || "N/A"}
                  </p>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <p>Cashier:</p>
                  <p className="text-right dark--text">
                    {orderDetails?.cashier?.first_name || "N/A"}{" "}
                    {orderDetails?.cashier?.last_name || "N/A"}
                  </p>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <p>Customer:</p>
                  <p className="text-right dark--text">
                    {orderDetails?.customer?.full_name || "N/A"}
                  </p>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <p>Store Name:</p>
                  <p className="text-right dark--text">
                    {orderDetails?.store?.name || "N/A"}
                  </p>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <p>Table:</p>
                  <p className="text-right dark--text">
                    {orderDetails?.table?.name || "N/A"}
                  </p>
                </div>
                {orderDetails?.delivery_address && (
                  <div>
                    <p className="small-text-size">Delivery Address:</p>
                    <p className="dark--text">
                      {`${orderDetails?.delivery_address?.address} ${
                        orderDetails?.delivery_address?.zipcode || ""
                      } ${orderDetails?.delivery_address?.city || ""} ${
                        orderDetails?.delivery_address?.state
                      }, ${orderDetails?.delivery_address?.country}` || "N/A"}
                    </p>
                  </div>
                )}
              </>
            ) : (
              <div className={styles.transactionDetails__column}>
                <div>
                  <p className="small-text-size">Order ID:</p>
                  <p className="dark--text">{orderDetails?.id}</p>
                </div>
                <div>
                  <p className="d-flex">
                    <secondaryComponents.NavigateWithPermission
                      permissionParentKey={"Inventory Management"}
                      permissionChildKey="can_update_order_date"
                    >
                      <secondaryComponents.ToolTipV2 tipText="Edit Date">
                        <span
                          className="mr-1"
                          style={{ cursor: "pointer" }}
                          onClick={() => setIsEditDate(true)}
                        >
                          <EditIcon />
                        </span>
                      </secondaryComponents.ToolTipV2>
                    </secondaryComponents.NavigateWithPermission>
                    <p className="small-text-size">Order Date:</p>
                  </p>
                  <p className="dark--text">
                    {orderDetails?.order_dates?.length > 0
                      ? new Date(
                          orderDetails?.order_dates?.[
                            orderDetails?.order_dates?.length - 1
                          ],
                        ).toLocaleString("en-us")
                      : new Date(orderDetails?.created_at).toLocaleString(
                          "en-us",
                        )}
                  </p>
                </div>
                <div>
                  <p className="small-text-size">Transaction Ref:</p>
                  <p className="dark--text">
                    {orderDetails?.trx_reference || "N/A"}
                  </p>
                </div>
                <div>
                  <p className="small-text-size">Payment Mode:</p>
                  <p className="dark--text">
                    {orderDetails?.payment_method || "N/A"}
                  </p>
                </div>
                <div>
                  <p className="small-text-size">Cashier:</p>
                  <p className="dark--text">
                    {orderDetails?.cashier?.first_name || "N/A"}
                  </p>
                </div>
                <div>
                  <p className="d-flex">
                    <secondaryComponents.NavigateWithPermission
                      permissionParentKey={"Inventory Management"}
                    >
                      <secondaryComponents.ToolTipV2 tipText="Update Customer Details">
                        <span
                          className="mr-1"
                          style={{ cursor: "pointer" }}
                          onClick={() => setIsOrderCustomer(true)}
                        >
                          <EditIcon />
                        </span>
                      </secondaryComponents.ToolTipV2>
                    </secondaryComponents.NavigateWithPermission>
                    <p className="small-text-size">Customer:</p>
                  </p>
                  <p className="dark--text">
                    {orderDetails?.customer?.full_name || "N/A"}
                  </p>
                </div>
                <div>
                  <p className="small-text-size">Store Name:</p>
                  <p className="dark--text">
                    {orderDetails?.store?.name || "N/A"}
                  </p>
                </div>
                <div>
                  <p className="small-text-size">Table:</p>
                  <p className="dark--text">
                    {orderDetails?.table?.name || "N/A"}
                  </p>
                </div>
                {orderDetails?.delivery_address && (
                  <div>
                    <p className="small-text-size">Delivery Address:</p>
                    <p className="dark--text">
                      {`${orderDetails?.delivery_address?.address} ${
                        orderDetails?.delivery_address?.zipcode || ""
                      } ${orderDetails?.delivery_address?.city || ""} ${
                        orderDetails?.delivery_address?.state
                      }, ${orderDetails?.delivery_address?.country}` || "N/A"}
                    </p>
                  </div>
                )}
                {orderDetails?.notes && (
                  <div>
                    <p className="small-text-size">Order Note:</p>
                    <p className="dark--text">{orderDetails?.notes}</p>
                  </div>
                )}
                {orderDetails?.waybills?.length > 0 ? (
                  <div>
                    <p className="small-text-size">Waybills</p>
                    <a
                      onClick={() => {
                        resetView("isWaybillDetails");
                      }}
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      className="small-text-size purple--text font-weight-semibold"
                    >
                      View Waybills
                    </a>
                  </div>
                ) : null}
                {orderDetails?.prescriptions?.length > 0 ? (
                  <div>
                    <p className="small-text-size">Prescriptions</p>
                    <a
                      onClick={() => {
                        resetView("isPrescriptionDetails");
                      }}
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      className="small-text-size purple--text font-weight-semibold"
                    >
                      View Prescriptions
                    </a>
                  </div>
                ) : null}
              </div>
            )}

            <h5 className="mt-4 mb-3">Ordered Items</h5>
            <div className={styles.orderDetails}>
              <p>ITEM</p>
              <p>QUANTITY</p>
              <p>UNIT(S)</p>
              <p>UNIT AMOUNT</p>
              <p>TOTAL AMOUNT</p>
            </div>
            {orderDetails?.order_items?.map((item) => (
              <div className={styles.orderDetails} key={item?.id}>
                <p>{item?.cart_item_name}</p>
                <p className="text-left">{item?.quantity}</p>
                <p className="text-left">
                  {item?.product?.unit?.unit ||
                    item?.variant?.unit?.unit ||
                    "N/A"}
                </p>
                <p className="text-left">
                  {formatCurrency({
                    value:
                      Number(item?.unit_price) - Number(item?.discount_amount),
                    currencyCode: currency_code,
                  })}
                </p>
                <p className="text-left">
                  {formatCurrency({
                    value: item?.total_price,
                    currencyCode: currency_code,
                  })}
                </p>
              </div>
            ))}
            <div className="mt-2 d-flex justify-content-between">
              <p>Subtotal:</p>
              <h6>
                {" "}
                {formatCurrency({
                  value: orderDetails?.total_item_amount,
                  currencyCode: currency_code,
                })}
              </h6>
            </div>
            <h5 className="mt-4 mb-3">Price Summary</h5>
            <div className="mt-3 d-flex justify-content-between">
              <p>Subtotal:</p>
              <h6>
                {" "}
                {formatCurrency({
                  value: orderDetails?.total_item_amount,
                  currencyCode: currency_code,
                })}
              </h6>
            </div>
            <div className="d-flex justify-content-between">
              <p>Gift Card:</p>
              <h6>
                {formatCurrency({
                  value: orderDetails?.giftcard_debit_amount || 0,
                  currencyCode: currency_code,
                })}
              </h6>
            </div>
            <div className="d-flex justify-content-between">
              <p>Loyalty Point:</p>
              <h6>
                {formatCurrency({
                  value: orderDetails?.loyalty_debit_amount || 0,
                  currencyCode: currency_code,
                })}
              </h6>
            </div>
            <div className="d-flex justify-content-between">
              <p>Delivery Fee:</p>
              <h6>
                {formatCurrency({
                  value: orderDetails?.delivery_fee_amount || 0,
                  currencyCode: currency_code,
                })}
              </h6>
            </div>
            <div className="mt-1 d-flex justify-content-between">
              <p>Discount:</p>
              <h6>
                {formatCurrency({
                  value: orderDetails?.discount_amount || 0,
                  currencyCode: currency_code,
                })}
              </h6>
            </div>
            <div className="mt-1 d-flex justify-content-between">
              <p>VAT:</p>
              <h6>
                {formatCurrency({
                  value: orderDetails?.vat || 0,
                  currencyCode: currency_code,
                })}
              </h6>
            </div>
            <div className="mt-1 d-flex justify-content-between">
              <p>Total:</p>
              <h6 className="purple--text">
                {formatCurrency({
                  value: orderDetails?.total_amount,
                  currencyCode: currency_code,
                })}
              </h6>
            </div>
          </div>
        </secondaryComponents.LoaderHelper>

        {isOrderFlow ? (
          <div
            className="mt-auto d-flex"
            style={{
              gap: "10px",
              position: "sticky",
              bottom: "-10px",
              background: "#fff",
              padding: "10px 0",
            }}
          >
            <primaryComponents.Button
              classNames={`btn btn--outline cover ${styles.btnMobile}`}
              isLoading={isPrinting}
              isDisabled={isPrinting}
              loadingText="Loading..."
              onClick={() => {
                if (orderDetails?.section_items_status === "NOT_SENT") {
                  if (!billWasUpdated) printReceipt();
                  else setIsPrintModal(true);
                } else {
                  setViaPos(false);
                  resetView("isPrintOnDevice");
                }
              }}
            >
              Print
            </primaryComponents.Button>
            <secondaryComponents.CountryItemPermission>
              <primaryComponents.Button
                classNames={`btn btn--primary cover ${styles.btnMobile}`}
                onClick={() => {
                  if (orderDetails?.section_items_status === "NOT_SENT")
                    resetView("isTerminal");
                  else {
                    setViaPos(true);
                    resetView("isPrintOnDevice");
                  }
                }}
              >
                Print on POS
              </primaryComponents.Button>
            </secondaryComponents.CountryItemPermission>
            <primaryComponents.Button
              classNames={`btn btn--outline cover ${styles.btnMobile}`}
              onClick={() => {
                resetView("isSendReceipt");
              }}
            >
              Send
            </primaryComponents.Button>
          </div>
        ) : null}
      </motion.div>

      {/* print select */}
      <secondaryComponents.Modal
        isActive={isPrintModal}
        closeModal={() => setIsPrintModal(false)}
        width={500}
      >
        <PrintSelect
          setIsPrintModal={setIsPrintModal}
          printReceipt={printReceipt}
        />
      </secondaryComponents.Modal>

      {/* edit order date */}
      <secondaryComponents.Modal
        isActive={isEditDate}
        closeModal={() => setIsEditDate(false)}
        width={400}
      >
        <UpdateOrderDate
          orderId={orderDetails?.id}
          closeModal={() => setIsEditDate(false)}
        />
      </secondaryComponents.Modal>

      {/* update customer details */}
      <secondaryComponents.Modal
        isActive={isOrderCustomer}
        closeModal={() => setIsOrderCustomer(false)}
        width={580}
      >
        <CustomerUpdate
          closeWidget={() => setIsOrderCustomer(false)}
          customerOrderDetails={customerOrderDetails}
          setCustomerOrderDetails={setCustomerOrderDetails}
          orderId={orderDetails?.id}
        />
      </secondaryComponents.Modal>
    </>
  );
};

export default TransactionDetailsV2;
