import React from "react";
import "./styles.scss";
import primaryComponents from "../../../../../../../components/primaryComponents";
import useLogistics from "./useLogistics";
import { useNavigate } from "react-router-dom";

const LogisticsSettings = ({ foodMenus }) => {
  const navigate = useNavigate();
  const { instance } = useLogistics({ foodMenus });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className={"table-container"}>
      <>
        {/* shows on desktop */}
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => (
                  <th
                    key={i}
                    className="font-weight-semibold"
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                  </th>
                ))}
                <th className="font-weight-semibold">Actions</th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps({ key: i })}>
                  {row.cells.map((cell, i) => {
                    return (
                      <td key={i} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                  <td>
                    {/* edit icon */}
                    <primaryComponents.Button
                      classNames={"btn btn--primary smallBtn px-4"}
                      onClick={() =>
                        navigate(
                          `${row.original.id}?store_id=${row.original.store.id}&logistics=true`,
                        )
                      }
                    >
                      Edit
                    </primaryComponents.Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* display on mobile */}
        <div className="mobile-table">
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <div
                key={i}
                className="mobile-table__row"
                {...row.getRowProps({ key: i })}
              >
                {row.cells.map((cell, j) => (
                  <div
                    key={j}
                    className="row-item"
                    {...cell.getCellProps({ key: j })}
                  >
                    <h6 className="row-item__title">{cell.column.Header}</h6>
                    <p className="row-item__value">{cell.render("Cell")}</p>
                  </div>
                ))}
                {/*  action */}
                <div className="row-item">
                  <primaryComponents.Button
                    classNames={"btn btn--primary smallBtn"}
                    onClick={() =>
                      navigate(
                        `${row.original.id}?store_id=${row.original.store.id}&logistics=true`,
                      )
                    }
                  >
                    Edit
                  </primaryComponents.Button>
                </div>
              </div>
            );
          })}
        </div>
      </>
    </div>
  );
};

export default LogisticsSettings;
