import { useState } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { getUrlQuerysection } from "../../../../../helpers";
import { getReconciliations } from "../../../../../services/inventory";

const useAllReconciliations = () => {
  const queries = getUrlQuerysection();
  const [isOpenReconciliation, setIsOpenReconciliation] = useState(false);
  const [search, setSearch] = useSearchParams();

  const {
    isLoading: isFetchingReconciliations,
    data: reconciliations,
    refetch: handleFetchReconciliations,
  } = useQuery(["getReconciliations", queries], () =>
    getReconciliations(queries).then((res) => res.data),
  );

  const gotoPage = (pageNumber) => {
    search.set("page", pageNumber);
    setSearch(search, { replace: true });
  };

  return {
    isFetchingReconciliations,
    reconciliations: reconciliations?.data || [],
    metaDetails: reconciliations?.meta,
    isOpenReconciliation,
    handleFetchReconciliations,
    setIsOpenReconciliation,
    gotoPage,
  };
};
export default useAllReconciliations;
