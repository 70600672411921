import httpClient from "../axiosBase/axiosHandler";

export const getNotifications = () => {
  return httpClient.get("/notifications/");
};

export const markNotificationAsRead = (id) => {
  return httpClient.post(`/notifications/${id}/mark-as-read/`);
};

export const markAllNotificationAsRead = () => {
  return httpClient.post("/notifications/mark-all-as-read/");
};

export const getDevice = (id) => {
  return httpClient.get(`/devices/${id}`);
};

export const createDevice = (data) => {
  return httpClient.post("/devices/", data);
};

export const updateDevice = ({ id, data }) => {
  return httpClient.put(`/devices/${id}/`, data);
};
