import { useNavigate } from "react-router-dom";
import Icon from "../../../assets/icons";
import styles from "./PushNotifications.module.scss";
import { usePushNotifications } from "./usePushNotifications";
import { pathConstants } from "../../../routes/pathContants";

const PushNotifications = ({ children }) => {
  const navigate = useNavigate();
  const { showNotificationToast, setShowNotificationToast } =
    usePushNotifications();

  return (
    <>
      {children}

      {showNotificationToast ? (
        <div className={styles.notificationContainer}>
          <div className={styles.notificationIcon}>
            <img src={Icon.unReadNotify} alt="" />
          </div>
          <div className={styles.notificationContent}>
            <div className={styles.content}>
              <div className={styles.textContent}>
                <p className={styles.mainText}>
                  {showNotificationToast?.data?.title?.split(": ")?.[0]}{" "}
                  <span>
                    {showNotificationToast?.data?.title?.split(": ")?.[1]}:
                  </span>
                </p>
                <p className={styles.subText}>
                  {showNotificationToast?.data?.body}
                </p>
              </div>
            </div>
            <div>
              <div
                className={styles.button}
                onClick={() => {
                  // dataNotifications &&
                  //   mutateMarkAsRead(dataNotifications[0].id);
                  navigate(
                    pathConstants.INVENTORY_SALES_TRANSACTIONS +
                      `?orderId=${showNotificationToast.data.data}`,
                  );
                  setShowNotificationToast(null);
                }}
              >
                View order
              </div>
            </div>
          </div>
          <div
            onClick={() => setShowNotificationToast(null)}
            className={styles.closeContainer}
          >
            <img src={Icon.closeNotification} alt="" />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default PushNotifications;
