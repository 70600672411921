import { useState, useContext, useEffect } from "react";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getRandomIntInclusive } from "../../../helpers";
import { loginUserVerification } from "../../../services/auth";
import useGrantAccessToApp from "../../../hooks/useGrantAccessToApp";
import { pathConstants } from "../../../routes/pathContants";
import { useGetProfileDetails } from "../../../hooks/api/queries/useSettings";

const LoginVerificationLogic = () => {
  const [isVerifing, setIsVerifing] = useState(false);
  const [otp, setOtp] = useState("");
  const triggerToast = useContext(ToastContext);
  const navigate = useNavigate();
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const params = useSearchParams()[0];
  const email = params.get("email");
  const sessionId = params.get("session_id");
  const { setAuthTokensAndGrantAccessToApp } = useGrantAccessToApp({
    message: "Login Successful",
  });
  const [tokens, setTokens] = useState({
    refreshToken: null,
    accessToken: null,
  });
  const { isLoading, data: profileDetails } = useGetProfileDetails({
    isProfileEnabled: tokens?.accessToken ? true : false,
    accessToken: tokens?.accessToken,
  });

  useEffect(() => {
    if (!sessionId && !email) return navigate(pathConstants.LOGIN);
  }, []);

  useEffect(() => {
    if (!isLoading && tokens?.refreshToken) getUserProfileDetails();
  }, [isLoading, tokens]);

  const handleOTPInputs = (value) => {
    setOtp(value);
    if (value?.length !== 4) return;
    setIsVerifing(true);
    setRequestLoaderProgress(getRandomIntInclusive(3, 30));
    loginUserVerification({ otp: value, sessionId: sessionId })
      .then((response) => {
        const { access, refresh } = response?.data?.data;
        setTokens({
          refreshToken: refresh,
          accessToken: access,
        });
      })
      .finally(() => {
        setIsVerifing(false);
        setRequestLoaderProgress(100);
      });
  };

  const getUserProfileDetails = () => {
    if (!profileDetails.email_verified) {
      triggerToast("Please verify your email", "warning");
      return navigate(
        `${pathConstants.EMAIL_VERIFICATION}?email=${email}&token=${tokens?.accessToken}`,
      );
    }
    setAuthTokensAndGrantAccessToApp({
      refreshToken: tokens?.refreshToken,
      accessToken: tokens?.accessToken,
      profileDetails: profileDetails,
    });
  };

  return {
    isVerifing,
    otp,
    email,
    handleOTPInputs,
  };
};

export default LoginVerificationLogic;
