import { useTable } from "react-table";
import { useMemo, useState } from "react";
import { formatCurrency, formatDate } from "../../../helpers";
import { useSelector } from "react-redux";

const useCustomerCreditLogTable = ({ credits }) => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  const columns = useMemo(
    () => [
      {
        Header: "Date/Time",
        accessor: "created_at",
        Cell: (cell) => new Date(cell.value).toLocaleString("en-us"),
      },
      {
        Header: "Order Number",
        accessor: "order_id",
        Cell: (cell) => (
          <span
            style={{
              color: "#7647EE",
              height: "28px",
              cursor: "pointer",
            }}
            role="button"
            onClick={() => {
              setSelectedOrder(cell?.value);
            }}
          >
            {cell?.value}
          </span>
        ),
      },
      {
        Header: "Payment method",
        accessor: "repayment_method",
        Cell: (cell) => (cell.value ? cell.value : "____"),
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ cell }) => {
          let val = formatCurrency({
            value: cell.value,
            currencyCode: currency_code,
          });

          if (cell.row.original.type === "credit") {
            return (
              <span
                style={{
                  color: "#E02020",
                }}
              >
                -{val}
              </span>
            );
          }

          return (
            <span
              style={{
                color: "#19AE57",
              }}
            >
              {val}
            </span>
          );
        },
      },
      {
        Header: "Outstanding Balance",
        accessor: "closing_outstanding_balance",
        Cell: (cell) =>
          formatCurrency({ value: cell.value, currencyCode: currency_code }),
      },
      {
        Header: "Due Date",
        accessor: "expected_repayment_date",
        Cell: (cell) => (cell.value ? formatDate(cell.value) : "___"),
      },
      {
        Header: "Cashier",
        accessor: "cashier_name",
      },
      {
        Header: "Store",
        accessor: "store_name",
      },
    ],
    [],
  );

  const instance = useTable({ columns, data: credits });

  return {
    instance,
    selectedOrder,
    setSelectedOrder,
  };
};

export default useCustomerCreditLogTable;
