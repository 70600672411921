import React, { useState } from "react";
import style from "./TreeFormWidget.module.scss";
import primaryComponents from "../../primaryComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatCurrency } from "../../../helpers";
import { useSelector } from "react-redux";
import { removeSpecialCharacter } from "../../../helpers";
import secondaryComponents from "..";
import { ToolTipIcon } from "../../../assets/svgIcons";

const TreeNode = ({ node, nodeName, isLast, tipText }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const isObject = (value) => {
    return value && typeof value === "object" && !Array.isArray(value);
  };

  const isArray = (value) => {
    return Array.isArray(value);
  };

  return (
    <>
      <p>
        {isObject(node) || isArray(node) ? (
          <>
            <div className="d-flex justify-content-between align-items-center flex-wrap ">
              <div onClick={handleToggle} className={style.container}>
                <primaryComponents.Button
                  classNames={`btn btn--outline p-1 ${style.icon}`}
                >
                  <FontAwesomeIcon
                    className={`${isExpanded ? "purple--text" : "dark--text"}`}
                    icon={`${isExpanded ? "angle-up" : "angle-down"}`}
                    fontSize={"14px"}
                  />
                </primaryComponents.Button>
                <p className=" small-text-size">
                  {removeSpecialCharacter(nodeName)}
                </p>
              </div>
              <p className=" small-text-size pr-3">
                {formatCurrency({
                  value: node || 0,
                  currencyCode: currency_code,
                })}
              </p>
            </div>

            {isExpanded && (
              <p>
                {isObject(node) &&
                  Object.keys(node).map((key, index, array) => (
                    <TreeNode
                      key={key}
                      node={node[key]}
                      nodeName={key}
                      isLast={index === array.length - 1}
                    />
                  ))}
                {isArray(node) &&
                  node.map((item, index) => (
                    <TreeNode
                      key={index}
                      node={item}
                      nodeName={`${nodeName} ${index + 1}`}
                      isLast={index === node.length - 1}
                    />
                  ))}
              </p>
            )}
          </>
        ) : (
          <>
            <div
              className={`d-flex justify-content-between align-items-center flex-wrap ${
                tipText ? "pt-1 pr-2 pl-2" : "p-2"
              }`}
              style={{ marginBottom: tipText ? "-7px" : "0" }}
            >
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <p className="small-text-size">
                  {removeSpecialCharacter(nodeName)}
                </p>
                {tipText && (
                  <div className="mt-2 p-2">
                    <secondaryComponents.ToolTipV2
                      tipText={tipText}
                      position="right"
                    >
                      <ToolTipIcon />
                    </secondaryComponents.ToolTipV2>
                  </div>
                )}
              </div>
              <p className="small-text-size">
                {formatCurrency({
                  value: node || 0,
                  currencyCode: currency_code,
                })}
              </p>
            </div>
            {!isLast && <hr />}
          </>
        )}
      </p>
    </>
  );
};

const Tree = ({ data, color, title, tipText }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const isArray = (arr) => Array.isArray(arr);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleToggleOff = () => {};

  return (
    <>
      <div className="p-1">
        <div
          className="d-flex justify-content-between align-items-center flex-wrap"
          style={{ backgroundColor: `${color}` }}
        >
          <div
            className={`${style.containerTree} align-items-center pl-5`}
            onClick={
              title === "Cost of Sales" || title === "Expenses"
                ? handleToggle
                : handleToggleOff
            }
          >
            {title === "Cost of Sales" || title === "Expenses" ? (
              <primaryComponents.Button
                classNames={`btn btn--outline p-1 ${style.icon}`}
              >
                <FontAwesomeIcon
                  className={`${isExpanded ? "purple--text" : "dark--text"}`}
                  icon={`${isExpanded ? "angle-up" : "angle-down"}`}
                  fontSize={"14px"}
                />
              </primaryComponents.Button>
            ) : (
              ""
            )}
            <h5 className="m-1 default-font-size"> {title} </h5>
            {title === "Expenses" ? (
              ""
            ) : (
              <div className="p-2">
                <secondaryComponents.ToolTipV2
                  tipText={tipText}
                  position="right"
                >
                  <ToolTipIcon />
                </secondaryComponents.ToolTipV2>
              </div>
            )}
          </div>
          <h5 className="m-2 default-font-size">
            {(() => {
              switch (title) {
                case "Cost of Sales":
                  return formatCurrency({
                    value: data?.cost_of_sales || 0,
                    currencyCode: currency_code,
                  });
                case "Gross Profit":
                  return formatCurrency({
                    value: data?.gross_profit || 0,
                    currencyCode: currency_code,
                  });
                case "Net Earnings Before Tax":
                  return formatCurrency({
                    value: data?.net_earnings_before_tax || 0,
                    currencyCode: currency_code,
                  });
                case "Expenses":
                  return formatCurrency({
                    value: data?.expenses || 0,
                    currencyCode: currency_code,
                  });
                default:
                  return null;
              }
            })()}
          </h5>
        </div>
        {isExpanded && (
          <p style={{ paddingLeft: "60px" }}>
            {title === "Cost of Sales" &&
              Object.keys(data).map((key) => {
                if (
                  [
                    "cost_of_goods_available",
                    "opening_stock",
                    "purchases",
                    "closing_stock",
                  ].includes(key)
                ) {
                  const isCostOfGoodsAvailable =
                    key === "cost_of_goods_available";
                  return (
                    <TreeNode
                      key={key}
                      node={data[key]}
                      nodeName={key}
                      isLast={key === Object.keys(data).pop()}
                      tipText={
                        isCostOfGoodsAvailable
                          ? "Opening Stock + Purchases"
                          : null
                      }
                    />
                  );
                }
                return null;
              })}
            {title === "Expenses" &&
              Object.keys(data).map((key) => {
                if (key === "expense_category" && isArray(data[key])) {
                  return data[key].map((item, index) => (
                    <TreeNode
                      key={`${key}-${index}`}
                      node={item.amount}
                      nodeName={item.category}
                      isLast={index === data[key].length - 1}
                    />
                  ));
                }
                return null;
              })}
          </p>
        )}
      </div>
    </>
  );
};

export default Tree;
