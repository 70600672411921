import primaryComponents from "../../primaryComponents";
import Icon from "../../../assets/icons";
import { useTable } from "react-table";
import { useEffect, useMemo, useState, useContext } from "react";
import { ToastContext } from "../../../hooks/context";
import secondaryComponents from "..";
import { cleanObject, truncateString } from "../../../helpers";
import { bulkTransferstockDelete } from "../../../services/inventory";
import { useParams } from "react-router-dom";

export const useBulkTransferTable = ({
  transferData,
  setTransferData,
  validation,
  isReviewMode,
}) => {
  const {
    control,
    formState: { errors },
    trigger,
  } = validation;
  const [checkError, setCheckError] = useState(false);
  const params = useParams();
  const triggerToast = useContext(ToastContext);

  useEffect(() => {
    const newData = [...transferData];
    for (const key in errors?.transferData) {
      newData[key].errors = {};
      for (const key2 in errors?.transferData[key]) {
        newData[key].errors[key2] =
          errors?.transferData?.[key]?.[key2]?.message;
      }
    }
    newData?.forEach((item, index) => {
      if (!errors?.transferData?.[index]) item.errors = undefined;
    });
    setTransferData(newData);
  }, [errors?.transferData, checkError]);

  const isObjectEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const deleteRow = (index) => {
    const newData = [...instance?.data];
    const deleteStockTransferItem = cleanObject({
      stock_transfer_item_id: newData[index]?.stock_transfer_item_id,
    });
    if (!isObjectEmpty(deleteStockTransferItem)) {
      bulkTransferstockDelete({
        data: deleteStockTransferItem,
        id: params?.id,
      })
        .then(() => {
          newData.splice(index, 1);
          setTransferData(newData);
          triggerToast("Stock Transfer Removed", "success");
        })
        .catch((error) => {
          console.error("Error deleting stock transfer items:", error);
        });
    } else {
      triggerToast("Stock Transfer Removed", "success");
      newData.splice(index, 1);
      setTransferData(newData);
    }
  };

  const columns = useMemo(() => {
    const headers = [
      {
        Header: "Product Name",
        accessor: "name",
        Cell: ({ cell: { value }, row }) => (
          <span className="d-flex align-items-center ml-3">
            <secondaryComponents.ConcatenateWithTooltip
              value={value}
              noOfCharacters={200}
            />
          </span>
        ),
      },
      {
        Header: "Variant",
        accessor: "variant_name",
        Cell: ({ cell: { value } }) =>
          truncateString(value, 200) ? value : "N/A",
      },
      {
        Header: "Quantity",
        accessor: "stock_quantity",
        Cell: ({ cell: { value } }) => (
          <span
            style={{
              color: "#19AE57",
              backgroundColor: "#E6FFF0",
            }}
          >
            {value}
          </span>
        ),
      },
      {
        Header: "Quantity to Transfer",
        accessor: "quantity",
        Cell: ({ cell: { value }, row }) => (
          <primaryComponents.InputFieldV2
            name={`transferData.${row?.index}.quantity`}
            control={control}
            classNames="purple--border white"
            isRequired
            type="number"
            style={{ height: "45px", width: "180px" }}
            step={0.01}
            min={0.01}
            placeholderContent="Enter value"
            value={value}
            errorMessage={
              row?.original?.errors?.quantity
                ? {
                    message: row?.original?.errors?.quantity,
                  }
                : undefined
            }
            onKeyUp={() => {
              errors?.transferData?.[row?.index]?.quantity &&
                trigger(`transferData.${row?.index}.quantity`);
              setCheckError((prev) => !prev);
            }}
            onChange={({ target: { value } }) => {
              const newData = [...instance?.data];
              newData[row?.index].quantity = value;
              setTransferData(newData);
            }}
            isDisabled={isReviewMode}
          />
        ),
      },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ cell: { value }, row }) => (
          <span className="d-flex align-items-center mb-4">
            {!isReviewMode ? (
              <button
                type="button"
                onClick={() => deleteRow(row?.index)}
                className="mr-2"
                style={{
                  border: "0.5px solid #E02020",
                  height: "24px",
                  width: "28px",
                  backgroundColor: "white",
                  borderRadius: "3px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  outline: "none",
                }}
              >
                <img src={Icon.deleteIcon} alt="" />
              </button>
            ) : null}
          </span>
        ),
      },
    ];

    return headers;
  }, []);

  const instance = useTable({
    columns,
    data: transferData,
    autoResetPage: false,
  });
  return { instance };
};
