import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import Icon from "../../../../../assets/icons";
import secondaryComponents from "../../../../../components/secondaryComponents";
import { useDispatch } from "react-redux";
import { clearAllMenuCartItems } from "../../../../../store/modules/menuCart";

const PaymentSuccessModal = ({
  orderId,
  setShowPaymentSuccessModal,
  setShowOrderStatusModal,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (orderId) {
      dispatch(clearAllMenuCartItems());
      setTimeout(() => {
        setShowPaymentSuccessModal(false);
        setShowOrderStatusModal(true);
      }, 1500);
    }
  }, [orderId]);

  return (
    <div className={styles.content_container}>
      <div className={styles.success_container}>
        <img src={Icon.successPayment} alt="" />
        <h4>Payment Successful</h4>
        <p>Creating your order status ...</p>
        <secondaryComponents.LoaderHelper isLoading={true} classNames="mt-4">
          <></>
        </secondaryComponents.LoaderHelper>
      </div>
    </div>
  );
};

export default PaymentSuccessModal;
