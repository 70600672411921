import { useStep } from "react-hooks-helper";
import { useState, useContext } from "react";
import { createUser } from "../../../services/auth";
import { validateData } from "../../../services/auth";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { useNavigate } from "react-router-dom";
import { getRandomIntInclusive } from "../../../helpers";
import { pathConstants } from "../../../routes/pathContants";
import countriesList from "../../../localdata/worldCountries.json";

const SignupLogic = () => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState("");
  const [currency, setCurrency] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessSize, setBusinessSize] = useState("");
  const [serviceType, setServiceType] = useState([]);
  const [referral, setReferral] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [isValidatingData, setisValidatingData] = useState(false);
  const triggerToast = useContext(ToastContext);
  const navigate = useNavigate();
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const { index, navigation } = useStep({
    steps: 3,
    initialStep: 0,
  });

  const handleNextStep = () => {
    if (index === 0) validatEmailAndPhoneNumber();
    else navigation.next();
  };

  const handlePreviousStep = () => {
    navigation.previous();
  };

  const validatEmailAndPhoneNumber = () => {
    setisValidatingData(true);
    setRequestLoaderProgress(getRandomIntInclusive(10, 40));
    validateData({
      email: email,
      phone_number: `${phoneNumber}`,
      country_code: countryCode,
    })
      .then((response) => {
        if (response?.data?.data?.email?.available === false) {
          window.setError(
            "email",
            {
              type: "validate",
              message: "Email already exist",
            },
            { shouldFocus: true },
          );
        }
        if (response?.data?.data?.phone_number.available === false) {
          window.setError(
            "phoneNumber",
            {
              type: "validate",
              message: "Phone number already exist",
            },
            { shouldFocus: true },
          );
        }
        if (
          response?.data?.data?.email?.available === false ||
          response?.data?.data?.phone_number?.available === false
        )
          return;
        navigation.next();
      })
      .finally(() => {
        setRequestLoaderProgress(100);
        setisValidatingData(false);
      });
  };

  const createAccount = () => {
    setRequestLoaderProgress(getRandomIntInclusive(5, 30));
    setIsCreatingAccount(true);
    createUser({
      email: email,
      first_name: firstName,
      last_name: lastName,
      country,
      currency_code: currency,
      country_code: countryCode,
      phone_number: `${phoneNumber}`,
      business_name: businessName,
      job_title: jobTitle,
      size: businessSize,
      hear_about_us: referral,
      services_of_interest: serviceType,
      password: password,
      confirm_password: confirmPassword,
      agree_terms: agreeToTerms,
      is_web: true,
    })
      .then((response) => {
        navigate(
          `${pathConstants.EMAIL_VERIFICATION}?email=${email}&token=${response?.data?.data?.access}`,
        );
        triggerToast("Registration successful", "success");
      })
      .finally(() => {
        setRequestLoaderProgress(100);
        setIsCreatingAccount(false);
      });
  };

  return {
    handleNextStep,
    handlePreviousStep,
    setCountry,
    country,
    setCurrency,
    currency,
    setCountryCode,
    countryCode,
    setEmail,
    email,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    phoneNumber,
    setPhoneNumber,
    businessName,
    setBusinessName,
    businessSize,
    setBusinessSize,
    jobTitle,
    setJobTitle,
    serviceType,
    setServiceType,
    referral,
    setReferral,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    countriesList,
    index,
    agreeToTerms,
    setAgreeToTerms,
    createAccount,
    isCreatingAccount,
    isValidatingData,
  };
};

export default SignupLogic;
