import { Controller } from "react-hook-form";
import StoreCheckoutValidation from "./Validation";
import StoreCheckoutLogic from "./StoreCheckoutLogic";
import styles from "./StoreCheckout.module.scss";
import secondaryComponents from "..";
import CardPayment from "./CardPayment";
import CashPayment from "./CashPayment";
import CreditPayment from "./CreditPayment";
import TransferPayment from "./TransferPayment";
import SplitPayment from "./SplitPayment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatCurrency } from "../../../helpers";
import WarehousePayment from "./WarehousePayment";
import Icon from "../../../assets/icons";
import primaryComponents from "../../primaryComponents";
import { useSelector } from "react-redux";
import Orderflows from "./Orderflows";

const StoreCheckout = ({
  closeStoreCheckout,
  cancelCurrentOrder,
  refetchPendingSessions = () => {},
}) => {
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const {
    checkoutType,
    isLoadingOrder,
    orderDetails,
    isPendingSessions,
    // screenViews,
    showWareHouse,
    pendCheckout,
    closePaymentSession,
    openTransactionsession,
    setCheckoutType,
  } = StoreCheckoutLogic({
    closeStoreCheckout,
    refetchPendingSessions,
  });
  const { control } = StoreCheckoutValidation(checkoutType);

  return (
    <secondaryComponents.LoaderHelper
      isLoading={isLoadingOrder}
      classNames="mt-5"
    >
      {orderDetails?.status !== "COMPLETED" ? (
        <div className={styles.storeCheckout}>
          <div
            className="d-flex justify-content-between"
            style={{ gap: "10px" }}
          >
            <primaryComponents.Button
              classNames="btn btn--outline"
              onClick={pendCheckout}
              isDisabled={isPendingSessions}
              isLoading={isPendingSessions}
              loadingText="Pending..."
            >
              <img className="mr-2" src={Icon.pauseIcon} alt="" />
              Pend Order
            </primaryComponents.Button>
            <FontAwesomeIcon
              onClick={() => cancelCurrentOrder()}
              className="d-block"
              icon="times"
              style={{ fontSize: "25px" }}
            />
          </div>

          <div className="mt-4 mb-4">
            <h5 className="mb-3 font-weight-normal">Select Payment Method</h5>
            <div className={styles.storeCheckout__purchaseOptions}>
              {/* Cash payment */}
              <secondaryComponents.NavigateWithPermission
                permissionParentKey={"Inventory Management"}
                permissionChildKey="can_checkout_with_cash_payment"
              >
                <div className={styles.storeCheckout__purchaseOption}>
                  <Controller
                    defaultValue={checkoutType}
                    name="checkout_type"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <secondaryComponents.WalletOption
                        title={"Cash"}
                        name="purchase-option"
                        value={"cash"}
                        checked={checkoutType === "cash"}
                        onChange={(option) => {
                          onChange(option);
                          setCheckoutType(option);
                        }}
                      />
                    )}
                  />
                </div>
              </secondaryComponents.NavigateWithPermission>
              {/* Card payment */}
              <secondaryComponents.NavigateWithPermission
                permissionParentKey={"Inventory Management"}
                permissionChildKey="can_checkout_with_card_payment"
              >
                <div className={styles.storeCheckout__purchaseOption}>
                  <Controller
                    defaultValue={checkoutType}
                    name="checkout_type"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <secondaryComponents.WalletOption
                        title={"Card"}
                        name="purchase-option"
                        value={"card"}
                        checked={checkoutType === "card"}
                        onChange={(option) => {
                          onChange(option);
                          setCheckoutType(option);
                        }}
                      />
                    )}
                  />
                </div>
              </secondaryComponents.NavigateWithPermission>
              {/* Transfer payment */}
              <secondaryComponents.NavigateWithPermission
                permissionParentKey={"Inventory Management"}
                permissionChildKey="can_checkout_with_transfer"
              >
                <div className={styles.storeCheckout__purchaseOption}>
                  <Controller
                    defaultValue={checkoutType}
                    name="checkout_type"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <secondaryComponents.WalletOption
                        title={"Transfer"}
                        name="purchase-option"
                        value={"transfer"}
                        checked={checkoutType === "transfer"}
                        onChange={(option) => {
                          onChange(option);
                          setCheckoutType(option);
                        }}
                      />
                    )}
                  />
                </div>
              </secondaryComponents.NavigateWithPermission>
              {/* Credit payment */}
              <div className={styles.storeCheckout__purchaseOption}>
                <Controller
                  defaultValue={checkoutType}
                  name="checkout_type"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <secondaryComponents.WalletOption
                      title={"Credit"}
                      name="purchase-option"
                      value={"credit"}
                      checked={checkoutType === "credit"}
                      onChange={(option) => {
                        onChange(option);
                        setCheckoutType(option);
                      }}
                    />
                  )}
                />
              </div>
              {/* Split payment */}
              <secondaryComponents.NavigateWithPermission
                permissionParentKey={"Inventory Management"}
                permissionChildKey="can_checkout_with_split_payment"
              >
                <div className={styles.storeCheckout__purchaseOption}>
                  <Controller
                    defaultValue={checkoutType}
                    name="checkout_type"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <secondaryComponents.WalletOption
                        title={"Split"}
                        name="purchase-option"
                        value={"split"}
                        checked={checkoutType === "split"}
                        onChange={(option) => {
                          onChange(option);
                          setCheckoutType(option);
                        }}
                      />
                    )}
                  />
                </div>
              </secondaryComponents.NavigateWithPermission>
              {/* Warehouse_checkout payment */}
              {showWareHouse ? (
                <secondaryComponents.NavigateWithPermission
                  permissionParentKey={"Inventory Management"}
                  permissionChildKey="can_checkout_with_warehouse"
                >
                  <div className={styles.storeCheckout__purchaseOption}>
                    <Controller
                      defaultValue={checkoutType}
                      name="checkout_type"
                      control={control}
                      render={({ field: { onChange } }) => (
                        <secondaryComponents.WalletOption
                          title={"WHS"}
                          name="purchase-option"
                          value={"warehouse_checkout"}
                          checked={checkoutType === "warehouse_checkout"}
                          onChange={(option) => {
                            onChange(option);
                            setCheckoutType(option);
                          }}
                        />
                      )}
                    />
                  </div>
                </secondaryComponents.NavigateWithPermission>
              ) : null}
            </div>
            {checkoutType === "warehouse_checkout" ? (
              <p className="dark--text">
                Order Value:{" "}
                <span className="purple--text">
                  {formatCurrency({
                    value: orderDetails?.total_amount,
                    currencyCode: currency_code,
                  })}
                </span>
              </p>
            ) : (
              <p className="dark--text">
                Total Payable Amount:{" "}
                <span className="purple--text">
                  {formatCurrency({
                    value: orderDetails?.total_amount,
                    currencyCode: currency_code,
                  })}
                </span>
              </p>
            )}
          </div>

          <>
            {/* cash payment section */}
            {checkoutType === "cash" && (
              <CashPayment
                orderDetails={orderDetails}
                closePaymentSession={closePaymentSession}
              />
            )}
            {/* card payment section */}
            {checkoutType === "card" && (
              <CardPayment
                orderDetails={orderDetails}
                closePaymentSession={closePaymentSession}
              />
            )}
            {/* transfer payment section */}
            {checkoutType === "transfer" && (
              <TransferPayment
                orderDetails={orderDetails}
                closePaymentSession={closePaymentSession}
              />
            )}
            {/* credit payment section */}
            {checkoutType === "credit" && (
              <CreditPayment
                orderDetails={orderDetails}
                closePaymentSession={closePaymentSession}
                openTransactionsession={openTransactionsession}
              />
            )}
            {/* split payment section */}
            {checkoutType === "split" && (
              <SplitPayment
                orderDetails={orderDetails}
                closePaymentSession={closePaymentSession}
                openTransactionsession={openTransactionsession}
              />
            )}
            {/* warehouse_checkout payment section */}
            {checkoutType === "warehouse_checkout" && (
              <WarehousePayment
                orderDetails={orderDetails}
                closePaymentSession={closePaymentSession}
              />
            )}
          </>
        </div>
      ) : (
        <>
          {/* cusstomer form */}
          <Orderflows
            orderId={orderDetails?.id}
            closeModal={closeStoreCheckout}
            viaSellMode={true}
            isViaBill={orderDetails?.bill_url ? true : false}
          />
        </>
      )}
    </secondaryComponents.LoaderHelper>
  );
};
export default StoreCheckout;
