import styles from "./ExpenseAnalyticsChart.module.scss";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import useExpenseAnalyticsChart from "./useExpenseAnalyticsChart";
import { formatCurrency } from "../../../helpers";
import ChartCustomToolTip from "../ChartCustomToolTip";
import { useSelector } from "react-redux";

const ExpenseAnalyticsChart = ({ data }) => {
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const { COLORS, sumOfTags } = useExpenseAnalyticsChart({ data });

  return (
    <div className={styles.expenseAnalytics}>
      {/* legend section */}
      <div className={styles.expenseAnalytics__legend}>
        {data?.map(({ expense_value, category }, index) => (
          <div className={styles.expenseAnalytics__legendItem} key={index}>
            <span
              style={{ background: COLORS[index % COLORS.length] }}
              className={`${styles.indicator} mr-2`}
            ></span>
            <span className={styles.title}>{category}</span>
            <span className={`${styles.value} font-weight-semibold`}>
              {((expense_value / sumOfTags) * 100).toFixed(2)}%
            </span>
          </div>
        ))}
      </div>
      <div className={styles.chartSection}>
        <div className={styles.expenseAnalytics__total}>
          <p className="mb-1" style={{ fontSize: "14px" }}>
            Total
          </p>
          <h5>
            {formatCurrency({ value: sumOfTags, currencyCode: currency_code })}
          </h5>
        </div>
        <ResponsiveContainer
          height={"380px"}
          width={"100%"}
          aspect={1}
          className={styles.expenseAnalytics__chart}
        >
          <PieChart>
            <Pie
              data={data}
              outerRadius={130}
              innerRadius={100}
              dataKey="expense_value"
              nameKey="category"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip
              wrapperStyle={{ border: "none", outline: "none" }}
              content={<ChartCustomToolTip sumOfTags={sumOfTags} />}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ExpenseAnalyticsChart;
