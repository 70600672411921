import "./CustomerDetails.scss";
import styles from "../../Inventory/FoodMenus/AllFoodMenus/AllFoodMenus.module.scss";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import primaryComponents from "../../../../components/primaryComponents";
import secondaryComponents from "../../../../components/secondaryComponents";
import CustomerDetailsLogic from "./CustomerDetailsLogic";
import { formatCurrency, convertToTilteCase } from "../../../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Icon from "../../../../assets/icons";
import { pathConstants } from "../../../../routes/pathContants";
import { setToDateMonthYearInWords } from "../../../../helpers";
import SendCreditReminderWidget from "../../../../components/secondaryComponents/SendCreditReminderWidget";
import { COLORS } from "../../../../helpers/constants";

const CustomerDetails = () => {
  const { customerId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    toggleCustomerLoyalty,
    formatDate,
    gotoPage,
    sendMessage,
    setSendMessage,
    showEditModal,
    metaDetails,
    showUpdateCreditLimit,
    setShowUpdateCreditLimit,
    setShowEditModal,
    customerDetails,
    isFetchingCustomerDetails,
    customerLoyaltyActivities,
  } = CustomerDetailsLogic(customerId);
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  const tableTab = ["Sales Transactions", "Loyalty Activity"];

  const [selectedTableTab, setSelectedTableTab] =
    useState("Sales Transactions");

  useEffect(() => {
    if (location.state?.tableTab === "Sales Transactions") {
      setSelectedTableTab("Sales Transactions");
    }
    if (location.state?.tableTab === "Loyalty Activity") {
      setSelectedTableTab("Loyalty Activity");
    }
  }, [location]);

  return (
    <div className="customer">
      <Link to={pathConstants.CUSTOMERS} style={{ textDecoration: "none" }}>
        <primaryComponents.Button classNames="btn btn--outline">
          <FontAwesomeIcon
            icon="angle-left"
            className="mr-3"
            style={{ fontSize: "18px" }}
          />
          <span>Back</span>
        </primaryComponents.Button>
      </Link>
      <div className="customer__content">
        <secondaryComponents.LoaderHelper
          isLoading={isFetchingCustomerDetails}
          classNames="mt-5"
        >
          <>
            <div className="customer__content__header">
              <div className="customer__content__header__title">
                <h4 className="font-weight-semibold">
                  {customerDetails?.full_name
                    ? convertToTilteCase(customerDetails?.full_name)
                    : ""}
                </h4>
                <span
                  style={{ fontSize: "10px" }}
                  className="success--squared ml-3"
                >
                  Active
                </span>
              </div>

              <div className="d-flex flex-wrap" style={{ gap: "5px" }}>
                <primaryComponents.Button
                  onClick={() => {
                    setSendMessage(true);
                  }}
                  classNames="btn btn--outline p-3"
                >
                  <img src={Icon.email} alt="" />
                </primaryComponents.Button>
                <primaryComponents.Button classNames="btn btn--outline">
                  <span className="mr-3">Enable Loyalty</span>
                  <primaryComponents.Switch
                    name={"loyalty"}
                    id={customerId}
                    isChecked={customerDetails?.is_loyalty_eligible}
                    onChange={() => toggleCustomerLoyalty(customerId)}
                  />
                </primaryComponents.Button>
                <primaryComponents.Button
                  onClick={() => {
                    setShowEditModal(true);
                  }}
                  classNames="btn btn--primary"
                >
                  Update Customer
                </primaryComponents.Button>
              </div>
            </div>
            <p>
              Joined {setToDateMonthYearInWords(customerDetails?.created_at)}
            </p>
            <div className="customer__content__details">
              <p>Customer&apos;s Interaction</p>
              <div className="d-flex justify-content-between">
                <div></div>
                <div className="d-flex" style={{ gap: "10px" }}>
                  <Link
                    to={pathConstants.CREDIT_LOG_DETAILS({
                      id: customerDetails?.id,
                    })}
                    className="purple--text"
                    style={{
                      fontSize: "14px",
                      textDecoration: "none",
                    }}
                  >
                    View Credit Log Record{" "}
                    <img src={Icon.arrow_top_right} alt="creditlog" />
                  </Link>
                </div>
              </div>
              <div className="customer__content__analytics">
                <secondaryComponents.MainDashboardAnalyticsCard
                  isFetchingAnalytics={isFetchingCustomerDetails}
                  value={formatCurrency({
                    value: customerDetails?.total_purchases || 0,
                    currencyCode: currency_code,
                  })}
                  title="Total Amount Spent"
                  color="2"
                />

                <secondaryComponents.MainDashboardAnalyticsCard
                  isFetchingAnalytics={isFetchingCustomerDetails}
                  value={formatCurrency({
                    value: customerDetails?.average_spent || 0,
                    currencyCode: currency_code,
                  })}
                  title="Average Spend"
                  color="3"
                />

                <secondaryComponents.MainDashboardAnalyticsCard
                  isFetchingAnalytics={isFetchingCustomerDetails}
                  value={formatCurrency({
                    value: customerDetails?.outstanding_balance || 0,
                    currencyCode: currency_code,
                  })}
                  title="Customer Debts"
                  color="1"
                />

                <div className="analyticsCard">
                  <div
                    className="analyticsCard__left"
                    style={{
                      borderLeft: `4px solid ${COLORS[8 % COLORS.length]}`,
                    }}
                  >
                    <div
                      className="d-flex items-center"
                      style={{ gap: "10px", alignItems: "center" }}
                    >
                      <p className="mb-0 small-text-size">Credit Limit</p>
                      <primaryComponents.Button
                        classNames={"btn btn--outline p-2"}
                        onClick={() => {
                          setShowUpdateCreditLimit(true);
                        }}
                        style={{ height: "25px" }}
                      >
                        <img
                          src={Icon.editWrite}
                          alt=""
                          width="16px"
                          height="16px"
                        />{" "}
                        Edit
                      </primaryComponents.Button>
                    </div>
                  </div>
                  <secondaryComponents.LoaderHelper
                    isLoading={isFetchingCustomerDetails}
                  >
                    <h6 className="mb-0">
                      {formatCurrency({
                        value: customerDetails?.credit_limit,
                        currencyCode: currency_code,
                      })}
                    </h6>
                  </secondaryComponents.LoaderHelper>
                </div>

                <secondaryComponents.MainDashboardAnalyticsCard
                  isFetchingAnalytics={isFetchingCustomerDetails}
                  value={formatCurrency({
                    value:
                      customerDetails?.wallet
                        ?.total_points_awarded_naira_equiv || 0,
                    currencyCode: currency_code,
                  })}
                  title="Cashback Balance"
                  color="6"
                />

                <secondaryComponents.MainDashboardAnalyticsCard
                  isFetchingAnalytics={isFetchingCustomerDetails}
                  value={customerDetails?.total_completed_transactions}
                  title="Total Number of Transactions"
                  color="5"
                />
              </div>
              <hr />
              <p>Customer’s Personal Info</p>
              <div className="customer__content__analytics">
                <secondaryComponents.MainDashboardAnalyticsCard
                  isFetchingAnalytics={isFetchingCustomerDetails}
                  value={
                    customerDetails?.phone
                      ? "(+" +
                        customerDetails?.phone_code +
                        ") " +
                        customerDetails?.phone
                      : "N/A"
                  }
                  title="Phone Number"
                  color="8"
                />

                <secondaryComponents.MainDashboardAnalyticsCard
                  isFetchingAnalytics={isFetchingCustomerDetails}
                  value={
                    customerDetails?.date_of_birth
                      ? formatDate(customerDetails?.date_of_birth)
                      : "N/A"
                  }
                  title="Birthday"
                  color="4"
                />

                <secondaryComponents.MainDashboardAnalyticsCard
                  isFetchingAnalytics={isFetchingCustomerDetails}
                  value={
                    customerDetails?.country ? customerDetails?.country : "N/A"
                  }
                  text={customerDetails?.city ? customerDetails?.city : ""}
                  title="Location"
                  color="3"
                />
              </div>
            </div>
          </>

          <div className="customer__content__transactions mt-4">
            <div className={`${styles.allFoodMenu}`}>
              <div className={styles.menuHeading}>
                <div className={styles.menuItemScrollContainer}>
                  {tableTab?.map((item, index) => (
                    <div
                      key={index}
                      className={`
                      ${styles.menuItemScroll}
                      ${
                        item === selectedTableTab
                          ? styles.active
                          : styles.inactive
                      }
                    `}
                      onClick={() => {
                        setSelectedTableTab(item);
                        navigate(".", {
                          state: { tableTab: item },
                        });
                      }}
                    >
                      {item.split(" ").map((item, index) => (
                        <span key={index}>{item}&nbsp;</span>
                      ))}
                    </div>
                  ))}
                </div>

                {selectedTableTab === "Sales Transactions" ? (
                  <secondaryComponents.CustomerTransactionsTable
                    customerId={customerId}
                  />
                ) : (
                  <secondaryComponents.CustomerLoyaltyTable
                    customers={customerLoyaltyActivities}
                    gotoPage={gotoPage}
                    metaDetails={metaDetails}
                  />
                )}
              </div>
            </div>
          </div>
        </secondaryComponents.LoaderHelper>
      </div>

      {/* edit customer modal section */}
      <secondaryComponents.Modal
        isActive={showEditModal}
        closeModal={() => {
          setShowEditModal(false);
        }}
        width={600}
      >
        <secondaryComponents.CustomerForm
          customerInfo={customerDetails}
          closeWidget={() => {
            setShowEditModal(false);
          }}
          isEditMode={true}
        />
      </secondaryComponents.Modal>

      {/* send message */}
      <secondaryComponents.SideModal
        isActive={sendMessage}
        closeModal={() => setSendMessage(false)}
        width={650}
      >
        <SendCreditReminderWidget
          closeWidget={() => setSendMessage(false)}
          customer={customerDetails}
          type="message"
        />
      </secondaryComponents.SideModal>
      <secondaryComponents.NavigateWithPermission
        permissionParentKey={"Customers"}
        permissionChildKey={"can_update_customer_credit_limit"}
      >
        <secondaryComponents.Modal
          isActive={showUpdateCreditLimit}
          closeModal={() => setShowUpdateCreditLimit(false)}
          width={500}
        >
          <secondaryComponents.UpdateCreditLogModal
            closeModal={() => setShowUpdateCreditLimit(false)}
            customer={customerDetails}
          />
        </secondaryComponents.Modal>
      </secondaryComponents.NavigateWithPermission>
    </div>
  );
};

export default CustomerDetails;
