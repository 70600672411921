import { useTable } from "react-table";
import { useMemo, useState, useContext } from "react";
import { deleteCategory } from "../../../services/inventory";
import { RequestLoader } from "../../../hooks/context";
import { truncateString } from "../../../helpers";
import secondaryComponents from "..";
import { useQueryClient } from "react-query";

const ProductCategoryTableLogic = ({ categories }) => {
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isDeletingCategory, setIsDeletingCategory] = useState(false);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const queryClient = useQueryClient();
  const columns = useMemo(
    () => [
      {
        Header: "Category Name",
        accessor: "name",
        Cell: ({ cell: { value } }) => truncateString(value, 12),
      },
      {
        Header: "Created on",
        accessor: "created_at",
        Cell: ({ cell: { value } }) =>
          new Date(value).toLocaleDateString("en-GB"),
      },
      {
        Header: "Last Updated on",
        accessor: "updated_at",
        Cell: ({ cell: { value } }) =>
          new Date(value).toLocaleDateString("en-GB"),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => (
          <span
            style={{
              color: "#19AE57",
              backgroundColor: "#E6FFF0",
              borderRadius: "8px",
              padding: "10px 16px",
              height: "28px",
              fontSize: "12px",
            }}
          >
            {value}
          </span>
        ),
      },
      {
        Header: "Actions",
        accessor: "action",
        Cell: ({ cell: { row } }) => (
          <span>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_edit_product_category"
            >
              <span
                onClick={() => {
                  setIsOpenEditDialog(true);
                  setSelectedCategory(row.original);
                }}
                style={{
                  backgroundColor: "#7647EE",
                  display: "inline-block",
                  padding: "10px 16px",
                  fontSize: "12px",
                  borderRadius: "8px",
                  color: "white",
                  cursor: "pointer",
                  userSelect: "none",
                }}
              >
                Edit
              </span>
            </secondaryComponents.NavigateWithPermission>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_delete_product_category"
            >
              <span
                className="ml-3"
                onClick={() => {
                  setIsOpenDeleteDialog(true);
                  setSelectedCategory(row.original);
                }}
                style={{
                  backgroundColor: "#E02020",
                  display: "inline-block",
                  padding: "10px 16px",
                  fontSize: "12px",
                  borderRadius: "8px",
                  color: "white",
                  cursor: "pointer",
                  userSelect: "none",
                }}
              >
                Delete
              </span>
            </secondaryComponents.NavigateWithPermission>
          </span>
        ),
      },
    ],
    [],
  );

  const handleDeleteProductCategory = () => {
    if (selectedCategory === null) return;
    const { id } = selectedCategory;

    setIsDeletingCategory(true);
    setRequestLoaderProgress(30);

    deleteCategory(id)
      .then(() => {
        queryClient.invalidateQueries(["getProductCategoryList"]);
        setIsDeletingCategory(false);
        setRequestLoaderProgress(100);
        setIsOpenDeleteDialog(false);
      })
      .catch((error) => {
        setIsDeletingCategory(false);
        setRequestLoaderProgress(100);
      });
  };

  const instance = useTable({ columns, data: categories });

  return {
    instance,
    categories,
    selectedCategory,
    isOpenDeleteDialog,
    isOpenEditDialog,
    isDeletingCategory,
    setSelectedCategory,
    setIsOpenDeleteDialog,
    setIsOpenEditDialog,
    handleDeleteProductCategory,
  };
};

export default ProductCategoryTableLogic;
