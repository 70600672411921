import { useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBusiness,
  updateBusinessInfo as updateInfo,
} from "../../../services/settings";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { setBusinessDetails } from "../../../store/modules/profile";

const ProfileBusinessInfoFormLogic = () => {
  const business = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const [businessName, setBusinessName] = useState(business?.business_name);
  const [businessSize, setBusinessSize] = useState(business?.size);
  const [businessEmail, setBusinessEmail] = useState(
    business?.business_email || "",
  );
  const [supportEmail, setSupportEmail] = useState(
    business?.support_email || "",
  );
  const [businessPhoneNumber, setBusinessPhoneNumber] = useState(
    business?.business_phone_number !== null
      ? business.business_phone_number
      : "",
  );
  const [supportPhoneNumber, setSupportPhoneNumber] = useState(
    business?.support_phone_number !== null
      ? business?.support_phone_number
      : "",
  );
  const [businessAddress, setbusinessAddress] = useState(
    business?.address || "",
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const dispatch = useDispatch();
  const updateBusinessInfo = () => {
    if (!isEdited) return;
    setIsSubmitting(true);
    setRequestLoaderProgress(20);

    updateInfo({
      name: businessName,
      size: businessSize,
      business_email: businessEmail,
      support_email: supportEmail !== "" ? supportEmail : null,
      business_phone_number: businessPhoneNumber,
      support_phone_number:
        supportPhoneNumber !== "" ? supportPhoneNumber : null,
      business_address: businessAddress !== "" ? businessAddress : null,
    })
      .then(() => {
        triggerToast("Business information update successful", "success");
        setIsEdited(false);
        getBusiness().then((response) => {
          dispatch(setBusinessDetails(response.data.data));
        });
      })
      .finally(() => {
        setRequestLoaderProgress(100);
        setIsSubmitting(false);
      });
  };

  return {
    businessName,
    setBusinessName,
    businessSize,
    setBusinessSize,
    businessEmail,
    setBusinessEmail,
    supportEmail,
    setSupportEmail,
    businessPhoneNumber,
    setBusinessPhoneNumber,
    supportPhoneNumber,
    setSupportPhoneNumber,
    businessAddress,
    setbusinessAddress,
    updateBusinessInfo,
    isSubmitting,
    setIsEdited,
    isEdited,
  };
};
export default ProfileBusinessInfoFormLogic;
