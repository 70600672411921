import { useTable } from "react-table";
import { useContext, useMemo } from "react";
import { formatCurrency, getPlatform, truncateString } from "../../../helpers";
import primaryComponents from "../../primaryComponents";
import { useNavigate } from "react-router-dom";
import secondaryComponents from "..";
import { platformHandler } from "../../../hooks/context";
import { useSelector } from "react-redux";

const useReconciliationsTable = ({ reconciliations }) => {
  const statusMap = {
    APPROVED: "success--squared",
    DRAFT: "pending--squared",
    REVIEW: "pending--squared",
    REJECTED: "failed--text",
  };
  const navigate = useNavigate();
  const { platform } = useContext(platformHandler);
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  const columns = useMemo(
    () => [
      {
        Header: "Date/Time",
        accessor: "created_at",
        Cell: ({ cell: { value } }) =>
          value ? new Date(value).toLocaleString("en-us") : "N/A",
      },
      {
        Header: "Created By",
        accessor: "created_by",
        Cell: ({ cell: { value } }) =>
          value ? truncateString(value?.first_name, 50) : "N/A",
      },
      {
        Header: "Approved By",
        accessor: "reviewed_by",
        Cell: ({ cell: { value } }) =>
          value ? truncateString(value?.first_name, 50) : "N/A",
      },
      {
        Header: "Store",
        accessor: "store",
        Cell: ({ cell: { value } }) => value?.name,
      },
      {
        Header: "Discrepancies",
        accessor: "discrepancy",
      },
      {
        Header: "Estimated Impact",
        accessor: "discrepancy_impact",
        Cell: ({ cell: { value } }) =>
          formatCurrency({ value: value, currencyCode: currency_code }),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => (
          <span className={`${statusMap[value]} text-capitalize`}>{value}</span>
        ),
      },

      {
        Header: "Action",
        Cell: ({ row }) => (
          <span className="d-flex">
            {row?.original.status !== "DRAFT" ? (
              <secondaryComponents.NavigateWithPermission>
                <primaryComponents.Button
                  onClick={() =>
                    navigate(
                      `${getPlatform(platform)}/inventory/reconciliations/${
                        row?.original?.id
                      }/review`,
                    )
                  }
                  classNames={"btn btn--primary smallBtn mr-2"}
                >
                  Review
                </primaryComponents.Button>
              </secondaryComponents.NavigateWithPermission>
            ) : (
              <primaryComponents.Button
                onClick={() => {
                  navigate(
                    `${getPlatform(platform)}/inventory/reconciliations/${
                      row?.original?.id
                    }/edit?store_id=${row?.original?.store?.id}`,
                  );
                }}
                classNames={"btn btn--outline smallBtn"}
              >
                Edit
              </primaryComponents.Button>
            )}
          </span>
        ),
      },
    ],
    [],
  );

  const instance = useTable({ columns, data: reconciliations });
  return {
    instance,
  };
};

export default useReconciliationsTable;
