import Images from "../../../assets/images";
import styles from "./InvoicesTable.module.scss";
import useInvoicesTable from "./useInvoicesTable";
import secondaryComponents from "..";

const InvoiceTable = ({ invoices: invoicesList, refetchSupplierInvoices }) => {
  const { instance, isUpdateCredit, selectedInvoice, setIsUpdateCredit } =
    useInvoicesTable({ invoicesList });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;
  return (
    <div className={styles.invoiceTable}>
      <>
        <secondaryComponents.TableHelper
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
        >
          <div className="text-center mt-5 mb-5">
            <img src={Images.supplierEmptyState} width="250px" alt="" />
            <h4 className="font-weight-semibold text-center mt-4">
              You have no invoices
            </h4>
          </div>
        </secondaryComponents.TableHelper>

        {/* <secondaryComponents.Pagination
          totalPageNumber={metaDetails?.last_page}
          handlePageClick={(value) => gotoPage(value?.selected + 1)}
          currentPage={metaDetails?.current_page}
        /> */}
        <secondaryComponents.SideModal
          isActive={isUpdateCredit}
          closeModal={() => setIsUpdateCredit(false)}
        >
          <secondaryComponents.CreditForm
            invoiceDetails={selectedInvoice}
            handleFetchInvoice={refetchSupplierInvoices}
            closeWidget={() => setIsUpdateCredit(false)}
          />
        </secondaryComponents.SideModal>
      </>
    </div>
  );
};
export default InvoiceTable;
