import { useState, useContext } from "react";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { addInvoiceCredit } from "../../../services/invoices";
import { useMutation } from "react-query";
import { getRandomIntInclusive } from "../../../helpers";

const useCreditForm = ({ invoiceDetails, handleFetchInvoice, closeWidget }) => {
  const [creditDetails, setCreditDetails] = useState({
    amount_paid: "",
    payment_method: "",
  });
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const triggerToast = useContext(ToastContext);

  const { mutate: addCredit, isLoading: isAddingCredit } = useMutation((data) =>
    addInvoiceCredit(data, invoiceDetails?.id),
  );
  const handleAddCredit = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));
    const data = { ...creditDetails };
    addCredit(data, {
      onSuccess: () => {
        handleFetchInvoice();
        triggerToast("Payment added successfully", "success");
        closeWidget();
      },

      onSettled: () => setRequestLoaderProgress(100),
    });
  };

  const submitCreditInfo = () => {
    handleAddCredit();
  };

  return {
    isAddingCredit,
    creditDetails,
    setCreditDetails,
    submitCreditInfo,
  };
};

export default useCreditForm;
