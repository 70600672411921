import React, { useContext } from "react";
import "./styles.scss";
import primaryComponents from "../../../../../../../components/primaryComponents";
import secondaryComponents from "../../../../../../../components/secondaryComponents";
import Icon from "../../../../../../../assets/icons";
import usePaymentMethod from "./usePaymentMethod";
import { getPlatform } from "../../../../../../../helpers";
import { platformHandler } from "../../../../../../../hooks/context";
import { useNavigate } from "react-router-dom";

const PaymentMethod = ({ setDeletePaymentMethod }) => {
  const navigate = useNavigate();
  const { platform } = useContext(platformHandler);
  const {
    instance,
    isLoadingPaymentMethods,
    dataPaymentMethods,
    mutatePaymentGateway,
  } = usePaymentMethod();

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className={"table-container"}>
      <secondaryComponents.LoaderHelper
        isLoading={isLoadingPaymentMethods}
        classNames="mt-4"
      >
        {dataPaymentMethods && dataPaymentMethods.length > 0 ? (
          <>
            {/* shows on desktop */}
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup, i) => (
                  <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, i) => (
                      <th
                        key={i}
                        className="font-weight-semibold"
                        {...column.getHeaderProps()}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                    <th className="font-weight-semibold">Actions</th>
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr key={i} {...row.getRowProps({ key: i })}>
                      {row.cells.map((cell, i) => {
                        return (
                          <td key={i} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                      <td>
                        <div className="last-section">
                          {/* edit icon */}
                          <secondaryComponents.NavigateWithPermission
                            permissionParentKey={"Payments"}
                            permissionChildKey="can_update_payment_gateway"
                          >
                            <primaryComponents.Button
                              classNames={"btn btn--primary smallBtn"}
                              onClick={() =>
                                navigate(
                                  getPlatform(platform) +
                                    `/inventory/storefront/payment-method/${row.original.id}`,
                                )
                              }
                            >
                              Edit
                            </primaryComponents.Button>
                          </secondaryComponents.NavigateWithPermission>
                          <secondaryComponents.NavigateWithPermission
                            permissionParentKey={"Payments"}
                            permissionChildKey="can_delete_payment_gateway"
                          >
                            <primaryComponents.Button
                              classNames={
                                "btn btn--outline-red smallBtn ml-auto"
                              }
                              onClick={() => {
                                setDeletePaymentMethod(row.original.id);
                              }}
                            >
                              <img src={Icon.deleteIcon} alt="" />
                            </primaryComponents.Button>
                          </secondaryComponents.NavigateWithPermission>

                          {/* switch icon */}
                          <secondaryComponents.NavigateWithPermission
                            permissionParentKey={"Payments"}
                            permissionChildKey="can_activate_deactivate_payment_gateway"
                          >
                            <div className="switch-btn">
                              <primaryComponents.Switch
                                isChecked={row.original.active}
                                name="active"
                                id={row.original.id}
                                onChange={() =>
                                  mutatePaymentGateway(row.original.id)
                                }
                              />
                            </div>
                          </secondaryComponents.NavigateWithPermission>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {/* display on mobile */}
            <div className="mobile-table">
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <div
                    key={i}
                    className="mobile-table__row"
                    {...row.getRowProps({ key: i })}
                  >
                    {row.cells.map((cell, j) => (
                      <div
                        key={j}
                        className="row-item"
                        {...cell.getCellProps({ key: j })}
                      >
                        <h6 className="row-item__title">
                          {cell.column.Header}
                        </h6>
                        <p className="row-item__value">{cell.render("Cell")}</p>
                      </div>
                    ))}
                    {/*  action */}
                    <div className="row-item">
                      <h6 className="row-item__title">Action</h6>

                      <div className="row-item__value">
                        <secondaryComponents.NavigateWithPermission
                          permissionParentKey={"Payments"}
                          permissionChildKey="can_update_payment_gateway"
                        >
                          <primaryComponents.Button
                            classNames={"btn btn--primary smallBtn mr-4"}
                            onClick={() =>
                              navigate(
                                getPlatform(platform) +
                                  `/inventory/storefront/payment-method/${row.original.id}`,
                              )
                            }
                          >
                            Edit
                          </primaryComponents.Button>
                        </secondaryComponents.NavigateWithPermission>
                        <secondaryComponents.NavigateWithPermission
                          permissionParentKey={"Payments"}
                          permissionChildKey="can_delete_payment_gateway"
                        >
                          <primaryComponents.Button
                            classNames={"btn btn--outline-red smallBtn mr-4"}
                            onClick={() => {
                              setDeletePaymentMethod(row.original.id);
                            }}
                          >
                            <img src={Icon.deleteIcon} alt="" />
                          </primaryComponents.Button>
                        </secondaryComponents.NavigateWithPermission>
                        {/* switch icon */}
                        <secondaryComponents.NavigateWithPermission
                          permissionParentKey={"Payments"}
                          permissionChildKey="can_activate_deactivate_payment_gateway"
                        >
                          <primaryComponents.Switch
                            isChecked={row.original.active}
                            name="activeMobile"
                            id={"activeMobile" + row.original.id}
                            onChange={() =>
                              mutatePaymentGateway(row.original.id)
                            }
                          />
                        </secondaryComponents.NavigateWithPermission>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <div className="payment-empty-container">
            <div className="payment-empty">
              <img src={Icon.paymentEmpty} alt="" />
              <div className="empty-textContainer">
                <h3>
                  Looks like you haven’t set up any payment method for your
                  online store
                </h3>
                <p>Use the button above to set up a payment method </p>
              </div>
            </div>
          </div>
        )}
      </secondaryComponents.LoaderHelper>
    </div>
  );
};

export default PaymentMethod;
