import React, { useState } from "react";
import styles from "./styles.module.scss";
// import { Validation } from "./Validation";
import Icon from "../../../../../../../assets/icons";
import primaryComponents from "../../../../../../../components/primaryComponents";

const Logistics = () => {
  const [turnDeliveryOn, setTurnDeliveryOn] = useState(true);
  // const [newDeoliveryLocation, setNewDeoliveryLocation] = useState([1]);

  // const {
  //   control,
  //   formState: { errors },
  //   handleSubmit,
  //   setValue,
  // } = Validation();

  return (
    <div className={styles.deliveryMainContainer}>
      <div>
        <h5 className="mb-1">Set up Your Online Menu Logistics Settings</h5>
        {/* <p>Customize your delivery process.</p> */}
      </div>
      <div className={styles.deliveryContainer}>
        <div className={styles.deliveryHeadingContainer}>
          <h4 className={styles.toggleTitle}>Turn Delivery On/Off</h4>
          <div className={styles.toggleContainer}>
            <p>Click the toggle to turn delivery on/off</p>
            <primaryComponents.Switch
              isChecked={turnDeliveryOn}
              name="delivery"
              id="delivery"
              onChange={(value) => setTurnDeliveryOn(value)}
            />
          </div>
        </div>
        {turnDeliveryOn && (
          <>
            <hr />
            <div className={styles.deliverySelectionContainer}>
              <div className={styles.deliveryBox}>
                <div className={styles.radioButtonContainer}>
                  <div className={styles.radioButton}>
                    <div className={styles.radio} />
                  </div>
                </div>
                <div>
                  <h4>Handle Deliveries Yourself</h4>
                  <p className={styles.subText}>
                    Use your trusted dispatchers and set your own delivery
                    prices & location
                  </p>
                </div>
                <div className={styles.comingSoonContainer}>
                  <div className={styles.comingSoon}>
                    <p>Coming soon</p>
                    <img src={Icon.comingSoonStar} alt="" />
                  </div>
                </div>
              </div>
              <div className={styles.deliveryBoxLumi}>
                <div className={styles.radioButtonContainer}>
                  <div className={styles.radioButton}>
                    <div className={styles.radio} />
                  </div>
                </div>
                <div>
                  <h4>Use Lumi’s Shipping partners</h4>
                  <p className={styles.subText}>
                    We will handle everything for you from pricings and regions
                    to insuring the safe delivery of your items.
                  </p>
                </div>
                <div className={styles.comingSoonContainer}>
                  <div className={styles.comingSoon}>
                    <p>Coming soon</p>
                    <img src={Icon.comingSoonStar} alt="" />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className={styles.deliveyLocationContainer}>
              <h5>Delivery Location & Price</h5>
              {newDeoliveryLocation.map((_, index) => (
                <div key={index} className={styles.deliveryContainerDelete}>
                  <div className={styles.deliveyLocationInputsContainer}>
                    <primaryComponents.InputFieldV2
                      control={control}
                      name="country"
                      value={""}
                      classNames="white"
                      label={"Country"}
                      placeholder={"Enter country name"}
                      onChange={({ target: { value } }) => {
                        console.log(value);
                      }}
                      isRequired
                      errorMessage={errors.country}
                    />
                    <primaryComponents.InputFieldV2
                      control={control}
                      name="state"
                      value={""}
                      classNames="white"
                      label={"State"}
                      placeholder={"Enter state name"}
                      onChange={({ target: { value } }) => {
                        console.log(value);
                      }}
                      isRequired
                      errorMessage={errors.state}
                    />
                    <primaryComponents.InputFieldV2
                      control={control}
                      name="city"
                      value={""}
                      classNames="white"
                      label={"City"}
                      placeholder={"Enter city name"}
                      onChange={({ target: { value } }) => {
                        console.log(value);
                      }}
                      isRequired
                      errorMessage={errors.city}
                    />
                    <primaryComponents.InputFieldV2
                      control={control}
                      name="price"
                      value={""}
                      classNames="white"
                      label={"Price"}
                      placeholder={"Enter price name"}
                      onChange={({ target: { value } }) => {
                        console.log(value);
                      }}
                      isRequired
                      errorMessage={errors.price}
                    />
                    {index === 0 ? (
                      <div style={{ width: "100%" }}>
                        <div
                          style={{
                            borderWidth: 0,
                            cursor: "auto",
                            display: "none",
                          }}
                          className={styles.deleteContainerMobile}
                        ></div>
                        <hr />
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className={styles.deleteContainerMobile}
                          onClick={() =>
                            setNewDeoliveryLocation((prev) =>
                              prev.filter(
                                (_, prevIndex) => prevIndex + 1 !== index + 1,
                              ),
                            )
                          }
                        >
                          <img src={Icon.deleteIcon} alt="" />
                        </div>
                        <hr />
                      </div>
                    )}
                  </div>
                  {index === 0 ? (
                    <div
                      style={{ borderWidth: 0, cursor: "auto" }}
                      className={styles.deleteContainer}
                    ></div>
                  ) : (
                    <div
                      className={styles.deleteContainer}
                      onClick={() =>
                        setNewDeoliveryLocation((prev) =>
                          prev.filter(
                            (_, prevIndex) => prevIndex + 1 !== index + 1,
                          ),
                        )
                      }
                    >
                      <img src={Icon.deleteIcon} alt="" />
                    </div>
                  )}
                </div>
              ))}
              <div className={styles.addDeliveryContainer}>
                <div
                  className={styles.addDelivery}
                  onClick={() =>
                    setNewDeoliveryLocation([
                      ...newDeoliveryLocation,
                      newDeoliveryLocation.length + 1,
                    ])
                  }
                >
                  <img src={Icon.addCriclePlus} alt="" />
                  <p>Add new delivery location & price</p>
                </div>
              </div>
            </div> */}
          </>
        )}
      </div>
    </div>
  );
};

export default Logistics;
