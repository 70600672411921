import secondaryComponents from "../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import style from "./AllMarkets.module.scss";
import styles from "../../Inventory/FoodMenus/AllFoodMenus/AllFoodMenus.module.scss";
import { useNavigate, useLocation } from "react-router-dom";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import { useEffect, useState } from "react";
import { pathConstants } from "../../../../routes/pathContants";
import {
  Connected,
  MarketPlaceComingSoonIcon,
} from "../../../../assets/svgIcons";
import appsData from "../../../../localdata/marketApps";

const AllMarkets = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const tableTab = ["All Apps", "Payments", "Accounting", "Socials"];

  const [selectedTableTab, setSelectedTableTab] = useState("All Apps");
  useEffect(() => {
    if (location.state?.tableTab === "All Apps") {
      setSelectedTableTab("All Apps");
    }
    if (location.state?.tableTab === "Payments") {
      setSelectedTableTab("Payments");
    }
  }, [location]);
  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Markets"
      description="View all apps"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          className={` ${globalStyles.pagePaddings}`}
        >
          <div>
            <h4 className="mb-1">Connect Apps to Lumi Business</h4>
            <p className="default-text-size">
              {" "}
              Connect apps to Lumi Business to streamline your workflow and
              boost efficiency.{" "}
            </p>
          </div>
          <div className="customer__content__transactions mt-5">
            <div className={`${styles.allFoodMenu}`}>
              <div className={styles.menuHeading}>
                <div className={styles.menuItemScrollContainer}>
                  {tableTab?.map((item, index) => (
                    <div
                      key={index}
                      className={`
                      ${styles.menuItemScroll}
                      ${
                        item === selectedTableTab
                          ? styles.active
                          : styles.inactive
                      }
                    `}
                      onClick={() => {
                        setSelectedTableTab(item);
                        navigate(".", {
                          state: { tableTab: item },
                        });
                      }}
                    >
                      {item.split(" ").map((item, index) => (
                        <span key={index}>{item}&nbsp;</span>
                      ))}
                    </div>
                  ))}
                </div>

                {selectedTableTab === "All Apps" ? (
                  <div className={`${style.markets__apps}`}>
                    {appsData.map((app, index) => (
                      <motion.div
                        className={`d-flex ${style.card}`}
                        style={{
                          gap: "5px",
                          maxHeight: "200px",
                          height: "150px",
                          cursor: "pointer",
                        }}
                        key={index}
                        onClick={() => {
                          navigate(pathConstants.APPS_DETAILS({ id: app.id }));
                        }}
                        whileHover={{ scale: 1.05 }}
                      >
                        {" "}
                        {app.id === "vfd" ? (
                          app.imageSrc
                        ) : app.id === "paystackPOS" ? (
                          app.imageSrc
                        ) : (
                          <img
                            src={app.imageSrc}
                            alt={app.altText}
                            width={100}
                            height={100}
                            className="rounded mr-2"
                          />
                        )}
                        <div className="card-content mt-2">
                          <h6>{app.appName}</h6>
                          <p className="small-text-size">
                            {app.short_description}
                          </p>
                          <span
                            className={`smallest-text-size p-1 ${
                              app.status === "Connect"
                                ? "comingSoon--connect"
                                : "comingSoon--rounded"
                            }`}
                          >
                            {app.status === "Connect" ? (
                              <div className="d-flex" style={{ gap: "4px" }}>
                                <Connected /> <span>Connected</span>
                              </div>
                            ) : (
                              "Coming Soon"
                            )}
                          </span>
                        </div>
                      </motion.div>
                    ))}
                  </div>
                ) : selectedTableTab === "Payments" ? (
                  <div className={style.markets__apps}>
                    {appsData.map((app, index) => (
                      <motion.div
                        className={`d-flex ${style.card}`}
                        style={{
                          gap: "5px",
                          maxHeight: "200px",
                          height: "150px",
                          cursor: "pointer",
                        }}
                        key={index}
                        onClick={() => {
                          navigate(pathConstants.APPS_DETAILS({ id: app.id }));
                        }}
                        whileHover={{ scale: 1.05 }}
                      >
                        {app.id === "vfd" ? (
                          app.imageSrc
                        ) : app.id === "paystackPOS" ? (
                          app.imageSrc
                        ) : (
                          <img
                            src={app.imageSrc}
                            alt={app.altText}
                            width={100}
                            height={100}
                            className="rounded mr-2"
                          />
                        )}

                        <div className="card-content mt-2">
                          <h6>{app.appName}</h6>
                          <p className="small-text-size">
                            {app.short_description}
                          </p>

                          <span
                            className={`smallest-text-size p-1 ${
                              app.status === "Connect"
                                ? "comingSoon--connect"
                                : "comingSoon--rounded"
                            }`}
                          >
                            {app.status === "Connect" ? (
                              <div className="d-flex" style={{ gap: "4px" }}>
                                <Connected /> <span>Connected</span>
                              </div>
                            ) : (
                              "Coming Soon"
                            )}
                          </span>
                        </div>
                      </motion.div>
                    ))}
                  </div>
                ) : selectedTableTab === "Accounting" ? (
                  <div
                    className="d-flex"
                    style={{
                      flexDirection: "column",
                      alignItems: "center",
                      marginTop: "100px",
                    }}
                  >
                    <MarketPlaceComingSoonIcon />
                    <h5>Apps in this category are Coming Soon</h5>
                    <p className="smallest-text-size">
                      Explore other categories
                    </p>
                  </div>
                ) : selectedTableTab === "Socials" ? (
                  <div
                    className="d-flex"
                    style={{
                      flexDirection: "column",
                      alignItems: "center",
                      marginTop: "100px",
                    }}
                  >
                    <MarketPlaceComingSoonIcon />
                    <h5>Apps in this category are Coming Soon</h5>
                    <p className="smallest-text-size">
                      Explore other categories
                    </p>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default AllMarkets;
