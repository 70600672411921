import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { useContext } from "react";
import { RequestLoader, ToastContext } from "../../../../hooks/context";
import { uploadProductImage } from "../../../../services/inventory";

export const useMultipleImageUpload = () => {
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const queryClient = useQueryClient();
  const params = useParams();

  // Image upload function
  const { mutate: uploadImage, isLoading: isUploadingImage } = useMutation(
    (data) => uploadProductImage(data),
  );

  const handleUploadMultipleMediaFiles = ({
    item_type,
    productId,
    media_files,
  }) => {
    if (media_files.length > 0) {
      media_files
        .filter((item) => item.file)
        .forEach((media) => {
          const formData = new FormData();
          formData.append("file", media.file);
          formData.append("item_id", productId ? productId : `${params?.id}`);
          formData.append("item_type", item_type);

          uploadImage(formData, {
            onSuccess: () => {
              setRequestLoaderProgress(100);
              if (productId) {
                queryClient.invalidateQueries(["getProductList"]);
              } else {
                queryClient.invalidateQueries([
                  "getProductDetails",
                  params?.id,
                ]);
                queryClient.invalidateQueries([
                  "getServiceDetails",
                  params?.id,
                ]);
              }
              triggerToast("Images uploaded successfully", "success");
            },
            onError: () => {
              setRequestLoaderProgress(100);
            },
          });
        });
    }
  };

  return {
    handleUploadMultipleMediaFiles,
    isUploadingImage,
  };
};
