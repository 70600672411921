// import all icons and export them as an object
import getlumiIcon from "./getlumi-icon.svg"; //sample code
import arrow_right from "./arrow-right.svg";
import arrow_left from "./arrow-left.svg";
import nigerian_flag from "./nigerian-flag.svg";
import eye_icon from "./eye.svg";
import home from "./home.svg";
import settings from "./settings-account-more.svg";
import note from "./paper-note.svg";
import message from "./send-message-dm.svg";
import user_group from "./user-group-accounts.svg";
import like from "./thumbs-up.svg";
import add from "./add-plus.svg";
import arrow_down_alt from "./arrow-down-alt.svg";
import option_alt from "./option-alt.svg";
import copy from "./copy-duplicate.svg";
import shop from "./shop-store.svg";
import user from "./user-account-profile.svg";
import search from "./search.svg";
import chevron_left_arrow from "./chevron-left-arrow.svg";
import chevron_up_arrow from "./chevron-up-arrow.svg";
import chevron_down_arrow from "./chevron-down-arrow.svg";
import chevron_right_arrow from "./chevron-right-arrow.svg";
import chevron_left_arrow_black from "./chevron-left-arrow-black.svg";
import clock_time from "./clock-time.svg";
import add_blue from "./add-blue.svg";
import filter from "./Filter.svg";
import close from "./x-close.svg";
import check from "./Check.svg";
import logoutIcon from "./logout-icon.svg";
import money from "./money-alt-cash-currency.svg";
import message_alt from "./send-message-dm-alt.svg";
import transferIcon from "./transfer-icon.svg";
import user_alt from "./user-account-profile-alt.svg";
import paymentIcon from "./payment-icon.svg";
import walletIcon from "./wallet-icon.svg";
import terminal from "./launch-link-open.svg";
import tag from "./tag-price-discount.svg";
import pie_chart from "./pie-chart.svg";
import package_icon from "./package 1.svg";
import product_box_1 from "./product-box-1.svg";
import product_box_2 from "./product-box-2.svg";
import product_box_3 from "./product-box-3.svg";
import upload from "./upload-share.svg";
import alert_icon from "./alert-cirlcle.svg";
import deleteIcon from "./delete.svg";
import switch_off from "./switch-off.svg";
import switch_on from "./switch-on.svg";
import productIcon1 from "./productIcon1.svg";
import productIcon2 from "./productIcon2.svg";
import productIcon3 from "./productIcon3.svg";
import productIcon4 from "./productIcon4.svg";
import productIcon5 from "./productIcon5.svg";
import shoppingCart from "./shopping-cart.svg";
import storeIcon1 from "./storeIcon1.svg";
import storeIcon2 from "./storeIcon2.svg";
import storeIcon3 from "./storeIcon3.svg";
import variantCollapse from "./VariantCollapsed.svg";
import editWrite from "./edit-write-alt.svg";
import storeMenuIcon from "./store-menu-icon.svg";
import sellModeBill from "./sell-mode-bill-icon.svg";
import sellModeSale from "./sell-mode-sales-icon.svg";
import sellModePayment from "./sell-mode-payment-history.svg";
import sellModeEndofDay from "./sell-mode-end-of-day.svg";
import gridViewMenuIcon from "./gridViewMenuIcon.svg";
import rowViewMenuIcon from "./rowViewMenuIcon.svg";
import productAvatar1 from "./productAvatar1.svg";
import productAvatar2 from "./productAvatar2.svg";
import productAvatar3 from "./productAvatar3.svg";
import productAvatar4 from "./productAvatar4.svg";
import productAvatar5 from "./productAvatar5.svg";
import expenseIcon from "./expenseIcon.svg";
import mobileHomeIcon from "./mobileHomeIcon.svg";
import mobileHomePurpleIcon from "./mobileHomePurpleIcon.svg";
import mobileExpenseIcon from "./mobileExpenseIcon.svg";
import mobileExpensePurpleIcon from "./mobileExpensePurpleIcon.svg";
import mobileInventoryIcon from "./mobileInventoryIcon.svg";
import mobileInventoryPurpleIcon from "./mobileInventoryPurpleIcon.svg";
import mobilePaymentPurpleIcon from "./mobilePaymentPurpleIcon.svg";
import mobilePaymentIcon from "./mobilePaymentIcon.svg";
import mobileMoreIcon from "./mobileMoreIcon.svg";
import mobileMorePurpleIcon from "./mobileMorePurpleIcon.svg";
import mobileTagIcon from "./mobileTagIcon.svg";
import mobileTransactionIcon from "./mobileTransactionIcon.svg";
import mobileTransferIcon from "./mobileTransferIcon.svg";
import mobilePosIcon from "./mobilePosIcon.svg";
import mobileSubaccountIcon from "./mobileSubaccountIcon.svg";
import mobileSellIcon from "./mobileSellIcon.svg";
import mobileManageIcon from "./mobileManageIcon.svg";
import mobileSettingsIcon from "./mobileSettingsIcon.svg";
import mobileLogoutIcon from "./mobileLogoutIcon.svg";
import mobileDocumentationIcon from "./mobileDocumentationIcon.svg";
import mobileCustomerIcon from "./mobileCustomerIcon.svg";
import mobileLoyaltyIcon from "./mobileLoyaltyIcon.svg";
import mobileManagePinkIcon from "./mobileManagePinkIcon.svg";
import mobileCustomerPinkIcon from "./mobileCustomerPinkIcon.svg";
import reconsileIcon from "./reconsileIcon.svg";
import saveIcon from "./saveIcon.svg";
import accountingPaperNoteIcon from "./accounting-paper-note.svg";
import alertCircleLumiPurple from "./alert-cirlcle-lumi-purple.svg";
import addCriclePlus from "./addCriclePlus.svg";
import xSquareCloseDelete from "./xSquareCloseDelete.svg";
import printIcon from "./printIcon.svg";
import posPhone from "./posPhone.svg";
import sendMessage from "./sendMessage.svg";
import nextArrowRight from "./nextArrowRight.svg";
import paperIcon from "./paperIcon.svg";
import pauseIcon from "./pauseIcon.svg";
import cartIcon from "./cartIcon.svg";
import barCodeIcon from "./barCodeIcon.svg";
import barCodeCreateIcon from "./barCodeCreateIcon.svg";
import printWhiteIcon from "./printWhiteIcon.svg";
import padlockIcon from "./padlockIcon.svg";
import bankFinancial from "./bank-financial.svg";
import userProfile from "./userProfile.svg";
import productBox from "./product-box.svg";
import qrCodeIcon from "./qr-code.svg";
import download from "./download.svg";
import saveIconAlt from "./save-alt.svg";
import product_box_6 from "./product_box_6.svg";
import product_box_7 from "./product_box_7.svg";
import tableIcon from "./table-icon.svg";
import refresh from "./refresh.svg";
import closeIcon from "./close.svg";
import tableMenuIcon from "./table-menu-icon.svg";
import dinersIcon from "./diners-icon.svg";
import dinersIconGreen from "./diners-icon-green.svg";
import errorAlarm from "./errorAlarm.svg";
import restaurantTableIcon from "./restaurant-table-icon.svg";
import logisticsIcon from "./logistics.svg";
import orderNoteIcon from "./order-note.svg";
import tableCloseIcon from "./table-close.svg";
import noTableIcon from "./no-table.svg";
import plusIcon from "./plus.svg";
import noticeIcon from "./noticeIcon.svg";
import star from "./star.svg";
import setup from "./setup.svg";
import progressBar from "./progress.svg";
import activity from "./activity.svg";
import setting from "./setting.svg";
import shopBag from "./shopBag.svg";
import exclamationCirlceIcon from "./exclamationCirlceIcon.svg";
import inviteUser from "./invitie-user.svg";
import manageRole from "./manage-role.svg";
import authorizedIcon from "./authorized.svg";
import unauthorizedIcon from "./unauthorized.svg";
import lecture from "./lecture.svg";
import calender from "./calender.svg";
import pieChart from "./pie-chart.png";
import pieChartMobile from "./pie-chart-mobile.png";
import location from "./location.svg";
import menuFilter from "./menu-filter.svg";
import hamburger from "./hamburger.svg";
import comingSoonStar from "./coming-soon-star.svg";
import newDashboardIcon from "./newDashboardIcon.svg";
import notes from "./notes.svg";
import kitchen from "./kitchen-order.svg";
import bankFinan from "./bank-finacial.svg";
import cart from "./cart.svg";
import biker from "./biker.svg";
import successPayment from "./success-payment-check.svg";
import keysetupone from "./keysetupone.svg";
import keysetuptwo from "./keysetuptwo.svg";
import keysetupthree from "./keysetupthree.svg";
import keysetupfour from "./keysetupfour.svg";
import keysetupfive from "./keysetupfive.svg";
import link from "./link.svg";
import embedlink from "./embedlink.svg";
import paymentEmpty from "./payment-empty.svg";
import notificationBell from "./notification-bell.svg";
import unReadNotification from "./notification-unread-bell.svg";
import readNotification from "./notificcation-read-bell.svg";
import unReadNotify from "./unread-notify.svg";
import closeNotification from "./close-notification.svg";
import orderStatus from "./order-status-img.svg";
import arrow_top_right from "./arrow-top-right.svg";
import redo from "./redo.svg";
import email from "./email.svg";
import storefront_settings from "./settings.svg";
import brand from "./brand.svg";
import sort from "./sort.svg";
import uploadLogo from "./upload-logo.svg";
import campaign from "./campaign.svg";

const Icon = {
  sellModeBill,
  sellModeEndofDay,
  sellModeSale,
  sellModePayment,
  storeMenuIcon,
  getlumiIcon: getlumiIcon,
  arrow_right: arrow_right,
  arrow_left: arrow_left,
  nigerian_flag: nigerian_flag,
  eye_icon: eye_icon,
  home,
  settings,
  note,
  message,
  user_group,
  like,
  add,
  arrow_down_alt,
  option_alt,
  copy,
  shop,
  user,
  search,
  chevron_left_arrow,
  chevron_down_arrow,
  chevron_up_arrow,
  chevron_right_arrow,
  chevron_left_arrow_black,
  clock_time,
  add_blue,
  filter,
  close,
  check,
  logoutIcon,
  money,
  message_alt,
  transferIcon,
  user_alt,
  paymentIcon,
  walletIcon,
  terminal,
  tag,
  pie_chart,
  package_icon,
  product_box_1,
  product_box_2,
  product_box_3,
  upload,
  alert: alert_icon,
  deleteIcon,
  switch_off,
  switch_on,
  productIcon1,
  productIcon2,
  productIcon3,
  productIcon4,
  productIcon5,
  shoppingCart,
  storeIcon1,
  storeIcon2,
  storeIcon3,
  variantCollapse,
  editWrite,
  gridViewMenuIcon,
  rowViewMenuIcon,
  productAvatar1,
  productAvatar2,
  productAvatar3,
  productAvatar4,
  productAvatar5,
  expenseIcon,
  mobileHomeIcon,
  mobileHomePurpleIcon,
  mobileExpenseIcon,
  mobileExpensePurpleIcon,
  mobileInventoryIcon,
  mobileInventoryPurpleIcon,
  mobilePaymentPurpleIcon,
  mobilePaymentIcon,
  mobileMoreIcon,
  mobileMorePurpleIcon,
  mobileTagIcon,
  mobileTransactionIcon,
  mobileTransferIcon,
  mobilePosIcon,
  mobileSubaccountIcon,
  mobileSellIcon,
  mobileManageIcon,
  mobileSettingsIcon,
  mobileLogoutIcon,
  mobileDocumentationIcon,
  mobileCustomerIcon,
  mobileLoyaltyIcon,
  mobileCustomerPinkIcon,
  mobileManagePinkIcon,
  reconsileIcon,
  saveIcon,
  accountingPaperNoteIcon,
  alertCircleLumiPurple,
  addCriclePlus,
  xSquareCloseDelete,
  posPhone,
  sendMessage,
  printIcon,
  nextArrowRight,
  paperIcon,
  pauseIcon,
  cartIcon,
  barCodeIcon,
  barCodeCreateIcon,
  printWhiteIcon,
  padlockIcon,
  bankFinancial,
  userProfile,
  productBox,
  errorAlarm,
  qrCodeIcon,
  download,
  saveIconAlt,
  product_box_7,
  product_box_6,
  tableIcon,
  refresh,
  closeIcon,
  tableMenuIcon,
  dinersIcon,
  dinersIconGreen,
  restaurantTableIcon,
  logisticsIcon,
  orderNoteIcon,
  tableCloseIcon,
  noTableIcon,
  plusIcon,
  noticeIcon,
  star,
  setup,
  progressBar,
  activity,
  setting,
  shopBag,
  exclamationCirlceIcon,
  inviteUser,
  manageRole,
  authorizedIcon,
  unauthorizedIcon,
  lecture,
  calender,
  pieChart,
  pieChartMobile,
  location,
  menuFilter,
  hamburger,
  comingSoonStar,
  newDashboardIcon,
  notes,
  kitchen,
  bankFinan,
  cart,
  biker,
  successPayment,
  keysetupone,
  keysetuptwo,
  keysetupthree,
  keysetupfour,
  keysetupfive,
  link,
  embedlink,
  paymentEmpty,
  notificationBell,
  unReadNotification,
  readNotification,
  unReadNotify,
  closeNotification,
  orderStatus,
  arrow_top_right,
  redo,
  email,
  storefront_settings,
  brand,
  sort,
  uploadLogo,
  campaign,
};

export default Icon;
