import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useOutletContext, useParams, useSearchParams } from "react-router-dom";
import { getViewMenuList } from "../../../services/inventory";

const useMenuPage = () => {
  const [search] = useSearchParams();
  const { id } = useParams();
  const { menuSearchText, showCartItems, setShowCartItems } =
    useOutletContext();

  const orderId = search.get("orderId");

  const [menuCatagories, setMenuCatagories] = useState([]);

  const { isLoading: isFetchingMenus, data: menuObject } = useQuery(
    ["getViewMenuList", [id]],
    () => getViewMenuList({ id: id }).then((res) => res?.data?.data),
  );

  useEffect(() => {
    setMenuCatagories(
      menuObject?.categories?.filter((obj) => {
        return obj.menu_items.some((item) =>
          item?.name.toLowerCase().includes(menuSearchText.toLowerCase()),
        );
      }),
    );
  }, [menuObject, menuSearchText]);

  return {
    orderId,
    menuId: id,
    isFetchingMenus,
    menuCategories: menuCatagories,
    menuObject: menuObject || {},
    menuSearchText,
    showCartItems,
    setShowCartItems,
  };
};

export default useMenuPage;
