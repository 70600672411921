import styles from "./ReconciliationReview.module.scss";
import secondaryComponents from "../../../../../components/secondaryComponents";
import primaryComponents from "../../../../../components/primaryComponents";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icon from "../../../../../assets/icons";
import useReconciliationReview from "./useReconciliationReview";
import { formatCurrency } from "../../../../../helpers";
import { useSelector } from "react-redux";

const ReconciliationReview = () => {
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const {
    isFetchingReconciliationDetails,
    reconciliationDetails,
    isRejecting,
    isApproving,
    products,
    isLoading,
    isFetchingNextPage,
    reconciliedData,
    isReconciliationPrompt,
    reconciliationType,
    autoScrollRef,
    setReconciliedData,
    setIsReconciliationPrompt,
    proceedReconciliation,
    setReconciliationType,
  } = useReconciliationReview();
  const navigate = useNavigate();
  const statusMap = {
    APPROVED: "success--squared",
    DRAFT: "pending--squared",
    REVIEW: "pending--squared",
    REJECTED: "failed--text",
  };

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Inventory - Reconciliations"
      description="Review reconciled stocks"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <div className={styles.reconciliationReview}>
          <h3>Inventory</h3>
          <div className={styles.reconciliationReview__header}>
            <div
              className="d-flex align-items-center flex-wrap"
              style={{ gap: "20px" }}
            >
              <primaryComponents.Button
                classNames="btn btn--outline "
                onClick={() => navigate(-1)}
                type="button"
                style={{ width: "max-content" }}
              >
                <FontAwesomeIcon
                  icon="angle-left"
                  className="mr-3"
                  style={{ fontSize: "18px" }}
                />
                <span>Back</span>
              </primaryComponents.Button>
              <h4 className="mb-0">Reconciliation Review</h4>
            </div>

            {reconciliationDetails?.status === "APPROVED" ||
            reconciliationDetails?.status === "REJECTED" ? null : (
              <secondaryComponents.NavigateWithPermission
                permissionParentKey={"Inventory Management"}
                permissionChildKey="can_approve_reconciliation"
              >
                <div className="d-flex ml-auto">
                  <primaryComponents.Button
                    classNames="btn btn--primary mr-3"
                    onClick={() => {
                      setReconciliationType("approve");
                      setIsReconciliationPrompt(true);
                    }}
                    isDisabled={isApproving}
                    isLoading={isApproving}
                    loadingText="Approving..."
                  >
                    <img src={Icon.like} alt="approve" className="mr-2" />
                    Approve
                  </primaryComponents.Button>
                  <primaryComponents.Button
                    classNames="btn btn--outline-red"
                    onClick={() => {
                      setReconciliationType("reject");
                      setIsReconciliationPrompt(true);
                    }}
                    isDisabled={isRejecting}
                    isLoading={isRejecting}
                    loadingText="Rejecting..."
                  >
                    <FontAwesomeIcon icon="times" className="mr-2" />
                    Reject
                  </primaryComponents.Button>
                </div>
              </secondaryComponents.NavigateWithPermission>
            )}
          </div>

          <div>
            <h5>Reconciliation Details</h5>
            <secondaryComponents.LoaderHelper
              isLoading={isFetchingReconciliationDetails}
              classNames="mt-4"
            >
              <div className={styles.reconciliationReview__card}>
                <div>
                  <h6>Location</h6>
                  <p>{reconciliationDetails?.store?.name || "N/A"}</p>
                </div>
                <div>
                  <h6>Created By</h6>
                  <p>
                    {reconciliationDetails?.created_by?.first_name || "N/A"}
                  </p>
                </div>
                <div>
                  <h6>Approved By</h6>
                  <p>{reconciliationDetails?.reviewed_by?.first_name || "-"}</p>
                </div>
                <div>
                  <h6> Date Submitted</h6>
                  <p>
                    {new Date(
                      reconciliationDetails?.submitted_at,
                    ).toLocaleString("en-us")}
                  </p>
                </div>
                <div>
                  <h6>Approval Date</h6>
                  <p>
                    {new Date(
                      reconciliationDetails?.reviewed_at,
                    ).toLocaleString("en-us")}
                  </p>
                </div>
                <div>
                  <h6>Discrepancy Impact</h6>
                  <p>
                    {formatCurrency({
                      value: reconciliationDetails?.discrepancy_impact || 0,
                      currencyCode: currency_code,
                    })}
                  </p>
                </div>
                <div>
                  <h6>Status</h6>
                  <p className={`${statusMap[reconciliationDetails?.status]}`}>
                    {reconciliationDetails?.status}
                  </p>
                </div>
              </div>
            </secondaryComponents.LoaderHelper>
          </div>

          <div>
            <secondaryComponents.LoaderHelper
              classNames="mt-4"
              isLoading={isLoading}
            >
              <secondaryComponents.ReconcileStockEditView
                products={products}
                autoScrollRef={autoScrollRef}
                setReconciliedData={setReconciliedData}
                reconciliedData={reconciliedData}
                discrepancyReasons={[]}
                isReviewMode={true}
              />
            </secondaryComponents.LoaderHelper>

            <secondaryComponents.LoaderHelper
              classNames="mt-4"
              isLoading={isFetchingNextPage && !isLoading}
            ></secondaryComponents.LoaderHelper>
          </div>
        </div>

        <secondaryComponents.Modal
          isActive={isReconciliationPrompt}
          closeModal={() => setIsReconciliationPrompt(false)}
          width="500px"
        >
          <secondaryComponents.ReconciliationReviewPrompt
            closeModal={() => setIsReconciliationPrompt(false)}
            reconciliationType={reconciliationType}
            proceed={proceedReconciliation}
            discrepancy={reconciliationDetails?.discrepancy_impact}
          />
        </secondaryComponents.Modal>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default ReconciliationReview;
