import { useMemo, useState } from "react";
import { useTable } from "react-table";
import { convertToTilteCase, formatCurrency } from "../../../helpers";
import { Link } from "react-router-dom";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "../../secondaryComponents";
import { useSelector } from "react-redux";

export const useCreditLogTable = ({ creditLog }) => {
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const [selectedCredit, setSelectedCredit] = useState({});
  const [isUpdateCreditWidget, setIsUpdateCreditWidget] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "Full Name",
        accessor: "full_name",
        Cell: ({ cell }) => (
          <Link
            className="purple--text"
            to={`${cell.row.original["id"]}`}
            style={{ fontSize: "12px" }}
          >
            {cell.value ? convertToTilteCase(cell.value) : "N/A"}
          </Link>
        ),
      },
      {
        Header: "Phone Number",
        accessor: "phone",
        Cell: ({ cell }) => (cell.value ? cell.value : "N/A"),
      },
      {
        Header: "Credit Limit",
        accessor: "credit_limit",
        Cell: ({ cell }) =>
          formatCurrency({ value: cell.value, currencyCode: currency_code }),
      },
      {
        Header: "Credit Till Date",
        accessor: "total_credit",
        Cell: ({ cell }) =>
          formatCurrency({ value: cell.value, currencyCode: currency_code }),
      },
      {
        Header: "Repayment",
        accessor: "total_repayment",
        Cell: ({ cell }) =>
          formatCurrency({ value: cell.value, currencyCode: currency_code }),
      },
      {
        Header: "Outstanding Balance",
        accessor: "outstanding_balance",
        Cell: ({ cell }) => {
          let val = formatCurrency({
            value: cell.value,
            currencyCode: currency_code,
          });

          if (cell.value > 0) {
            return (
              <span
                style={{
                  color: "#FD561F",
                }}
              >
                -{val}
              </span>
            );
          }

          return val;
        },
      },
      {
        Header: "Actions",
        accessor: "action",
        Cell: ({ cell: { row } }) =>
          row?.depth === 1 ? null : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                position: "relative",
              }}
            >
              <secondaryComponents.NavigateWithPermission
                permissionParentKey={"Inventory Management"}
                permissionChildKey="can_view_and_update_credits"
              >
                <primaryComponents.Button
                  classNames="btn btn--primary smallBtn"
                  onClick={() => {
                    setSelectedCredit(row?.original);
                    setIsUpdateCreditWidget(true);
                  }}
                >
                  Update
                </primaryComponents.Button>
              </secondaryComponents.NavigateWithPermission>
            </div>
          ),
      },
    ],
    [],
  );

  const instance = useTable({ columns, data: creditLog });

  return {
    selectedCredit,
    instance,
    isUpdateCreditWidget,
    setIsUpdateCreditWidget,
    setSelectedCredit,
  };
};
