const allSubAccounts = [
  {
    branch: "Ajah Branch",
    accountNumber: 1234567890,
    accountName: "Tobi Ilori1",
    bank: "Access Bank",
    phoneNumber: "08123456789",
    backgroundColor: "#E8F7EE",
    color: "#19AE57",
  },
  {
    branch: "Ikoyi Branch",
    accountNumber: 1234567890,
    accountName: "Tobi Ilori2",
    bank: "Access Bank",
    phoneNumber: "08123456789",
    backgroundColor: "#FFEEE9",
    color: "#FD561F",
  },
  {
    branch: "Lekki Branch",
    accountNumber: 1234567890,
    accountName: "Tobi Ilori3",
    bank: "Access Bank",
    phoneNumber: "08123456789",
    backgroundColor: "#F2EDFE",
    color: "#7647EE",
  },
  {
    branch: "Surulere Branch",
    accountNumber: 1234567890,
    accountName: "Tobi Ilori4",
    bank: "Access Bank",
    phoneNumber: "08123456789",
    backgroundColor: "#FEF7EB",
    color: "#F7AD3C",
  },
];

export default allSubAccounts;
