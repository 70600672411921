import React from "react";
import styles from "../../MenuPage.module.scss";
import { useRef } from "react";
import MainsCard from "../MainsCard";

const CategorySection = ({
  index,
  categoryItem,
  menuObject,
  menuSearchText,
}) => {
  const categoryRef = useRef(null);
  const categoryName =
    categoryItem.category.name.toLowerCase().charAt(0).toUpperCase() +
    categoryItem.category.name.toLowerCase().slice(1);

  const secondaryColor = menuObject?.secondary_colour
    ? menuObject?.secondary_colour
    : index % 2
      ? "#f3f1fc"
      : "#fef7eb";

  return (
    <div
      ref={categoryRef}
      style={{
        // background: index % 2 ? "#f3f1fc" : "#fef7eb",
        background: secondaryColor,
        marginTop: index > 0 ? 24 : 32,
      }}
      className={styles.mainsContainer}
    >
      <h4>{categoryName}</h4>
      <div className={styles.mainsCard}>
        {categoryItem.menu_items
          .filter((prod) =>
            prod?.name.toLowerCase().includes(menuSearchText.toLowerCase()),
          )
          .map((item, index) => (
            <MainsCard
              key={item.id}
              productIndex={index}
              productItem={item}
              store={menuObject?.store}
              menuObject={menuObject}
            />
          ))}
      </div>
    </div>
  );
};

export default CategorySection;
