import styles from "./AccountingLayout.module.scss";
import { Outlet } from "react-router-dom";

const AccountingLayout = () => {
  return (
    <div className={styles.accountingLayout}>
      <h3 className="font-weight-semibold">Reports</h3>
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default AccountingLayout;
