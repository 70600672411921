import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object({
  store_id: yup.string().required("store is required"),
  order_id: yup.string().required("order is required"),
  remarks: yup.string().optional(),
  items: yup.array().of(
    yup.object().shape({
      store_id: yup.string().required("store id is required"),
      product_id: yup.string().optional(),
      variant_id: yup.string().optional(),
      cost_price: yup.string().required("cost price is requird"),
      selling_price: yup.string().required("selling price is required"),
      quantity: yup
        .number()
        .positive()
        .typeError("invalid quantity")
        .required("quantity is required"),
      expiry_date: yup.string().optional(),
    }),
  ),
});

export const useReturnStockValidation = (formData) => {
  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: formData,
  });
};
