import "../../../Customers/CustomerDetails/CustomerDetails.scss";
import { Link, useNavigate } from "react-router-dom";
import {
  formatCurrency,
  convertToTilteCase,
  getPlatform,
} from "../../../../../helpers";
import { platformHandler } from "../../../../../hooks/context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import primaryComponents from "../../../../../components/primaryComponents";
import secondaryComponents from "../../../../../components/secondaryComponents";
import useCreditLogDetails from "./useCreditLogDetails";
import { useSelector } from "react-redux";
import { useContext } from "react";
import Icon from "../../../../../assets/icons";

const CreditLogDetails = () => {
  const navigate = useNavigate();
  const {
    showUpdateCreditReminder,
    setShowUpdateCreditReminder,
    showUpdateCreditLimit,
    setShowUpdateCreditLimit,
    showUpdateCredit,
    isFetchingCustomer,
    customer,
    metaDetails,
    isFetchingCredits,
    credits,
    refetchCredits,
    fetchCustomer,
    setShowUpdateCredit,
    gotoPage,
  } = useCreditLogDetails();
  const { platform } = useContext(platformHandler);
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  return (
    <div className="pt-5">
      <div className="customer">
        <primaryComponents.Button
          classNames="btn btn--outline"
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon
            icon="angle-left"
            className="mr-3"
            style={{ fontSize: "18px" }}
          />
          <span>Back</span>
        </primaryComponents.Button>
        <div className="customer__content">
          <secondaryComponents.LoaderHelper
            isLoading={isFetchingCustomer}
            classNames="mt-5"
          >
            {customer !== null && (
              <>
                <div className="customer__content__header">
                  <div className="customer__content__header__title">
                    <Link
                      className="purple--text"
                      to={getPlatform(platform) + `/customers/${customer?.id}`}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <h4 className="font-weight-semibold purple--text">
                        {customer?.full_name
                          ? convertToTilteCase(customer?.full_name)
                          : "N/A"}
                      </h4>
                    </Link>
                    <span
                      style={{ fontSize: "10px" }}
                      className="success--squared ml-3"
                    >
                      Active
                    </span>
                  </div>
                  <div className="d-flex flex-wrap" style={{ gap: "5px" }}>
                    <primaryComponents.Button
                      classNames={"btn btn--outline"}
                      onClick={() => {
                        setShowUpdateCreditReminder(true);
                      }}
                    >
                      Remind Customer
                    </primaryComponents.Button>
                    <primaryComponents.Button
                      type="button"
                      classNames={"btn btn--primary"}
                      onClick={() => {
                        setShowUpdateCredit(true);
                      }}
                    >
                      Update Credit
                    </primaryComponents.Button>
                  </div>
                </div>
                <div className="customer__content__customer mt-4">
                  <div>
                    <h6>Phone Number</h6>
                    <span className="gray--text">
                      {customer?.phone
                        ? "(+" + customer?.phone_code + ") " + customer?.phone
                        : "N/A"}
                    </span>
                  </div>
                  <div>
                    <h6>Credit till date</h6>
                    <span className="gray--text">
                      {formatCurrency({
                        value: customer?.total_credit,
                        currencyCode: currency_code,
                      })}
                    </span>
                  </div>
                  <div>
                    <h6>Total Repayment</h6>
                    <span className="gray--text">
                      {formatCurrency({
                        value: customer?.total_repayment,
                        currencyCode: currency_code,
                      })}
                    </span>
                  </div>
                  <div>
                    <h6>Outstanding Balance</h6>
                    <span className="gray--text">
                      {formatCurrency({
                        value: customer?.outstanding_balance,
                        currencyCode: currency_code,
                      })}
                    </span>
                  </div>
                  <div>
                    <h6>Average Spent</h6>
                    <span className="gray--text">
                      {formatCurrency({
                        value: customer?.average_spent,
                        currencyCode: currency_code,
                      })}
                    </span>
                  </div>

                  {/* <div>
                    <h6>Total Earning</h6>
                    <span className="gray--text">
                      {formatCurrency({
                        value: customer?.total_cash_earned,
                        currencyCode: currency_code,
                      })}
                    </span>
                  </div> */}
                  <div>
                    <h6>Total Purchase</h6>
                    <span className="gray--text">
                      {formatCurrency({
                        value: customer?.total_purchases,
                        currencyCode: currency_code,
                      })}
                    </span>
                  </div>
                  <div>
                    <h6>Credit Limit</h6>
                    <div
                      className="d-flex items-center"
                      style={{ gap: "5px", alignItems: "center" }}
                    >
                      <span className="gray--text">
                        {formatCurrency({
                          value: customer?.credit_limit,
                          currencyCode: currency_code,
                        })}
                      </span>
                      <primaryComponents.Button
                        classNames={"btn btn--outline p-2"}
                        onClick={() => {
                          setShowUpdateCreditLimit(true);
                        }}
                        style={{ height: "25px" }}
                      >
                        <img
                          src={Icon.editWrite}
                          alt=""
                          width="16px"
                          height="16px"
                        />{" "}
                        Edit
                      </primaryComponents.Button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </secondaryComponents.LoaderHelper>

          <div className="mt-4">
            <h5 className="font-weight-semibold">
              {" "}
              ({metaDetails?.total || 0}) Transactions
            </h5>
            <secondaryComponents.LoaderHelper
              classNames="mt-4"
              isLoading={isFetchingCredits}
            >
              <secondaryComponents.CustomerCreditLogTable
                credits={credits}
                gotoPage={gotoPage}
                metaDetails={metaDetails}
              />
            </secondaryComponents.LoaderHelper>
          </div>
        </div>
      </div>
      <secondaryComponents.SideModal
        isActive={showUpdateCredit}
        closeModal={() => setShowUpdateCredit(false)}
      >
        <secondaryComponents.UpdateCreditWidget
          closeWidget={() => setShowUpdateCredit(false)}
          onSuccess={() => {
            fetchCustomer();
            refetchCredits();
          }}
          customer={customer}
        />
      </secondaryComponents.SideModal>

      <secondaryComponents.SideModal
        isActive={showUpdateCreditReminder}
        closeModal={() => setShowUpdateCreditReminder(false)}
        width={650}
      >
        <secondaryComponents.SendCreditReminderWidget
          closeWidget={() => setShowUpdateCreditReminder(false)}
          customer={customer}
        />
      </secondaryComponents.SideModal>
      <secondaryComponents.NavigateWithPermission
        permissionParentKey={"Customers"}
        permissionChildKey={"can_update_customer_credit_limit"}
      >
        <secondaryComponents.Modal
          isActive={showUpdateCreditLimit}
          closeModal={() => setShowUpdateCreditLimit(false)}
          width={500}
        >
          <secondaryComponents.UpdateCreditLogModal
            closeModal={() => setShowUpdateCreditLimit(false)}
            customer={customer}
            type="credit"
          />
        </secondaryComponents.Modal>
      </secondaryComponents.NavigateWithPermission>
    </div>
  );
};

export default CreditLogDetails;
