import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import { getUrlQuerysection } from "../../../../helpers";
import { useGetCustomers } from "../../../../hooks/api/queries";
import { useSelector } from "react-redux";

const AllCustomersLogic = () => {
  const queries = getUrlQuerysection();
  const queryParams = new URLSearchParams(window.location.search);
  const [search, setSearch] = useSearchParams();
  const [currentStore, setCurrentStore] = useState(queryParams.get("store_id"));
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const [isCreateCustomer, setIsCreateCustomer] = useState(false);

  const {
    isLoading: isFetchingCustomers,
    data: customers,
    refetch: handleFetchCustomers,
  } = useGetCustomers({ queries });

  const gotoPage = (pageNumber) => {
    search.set("page", pageNumber);
    setSearch(search, { replace: true });
  };

  const searchCustomers = (value) => {
    if (value.length === 0) search.delete("query");
    else search.set("query", value);
    search.delete("page");
    setSearch(search, { replace: true });
  };

  const filterByStoreId = (storeId) => {
    if (storeId === "ALL_STORES") search.delete("store_id");
    else search.set("store_id", storeId);
    setSearch(search, { replace: true });
  };

  return {
    isFetchingCustomers,
    customers: customers?.data || [],
    metaDetails: customers?.meta,
    search,
    handleFetchCustomers,
    gotoPage,
    searchCustomers,
    filterByStoreId,
    storeList,
    currentStore,
    setCurrentStore,
    isCreateCustomer,
    setIsCreateCustomer,
  };
};
export default AllCustomersLogic;
