import React from "react";
import styles from "./styles.module.scss";
import Icon from "../../../../../assets/icons";
import { useNavigate, useParams } from "react-router-dom";
import { clearAllMenuCartItems } from "../../../../../store/modules/menuCart";
import { useDispatch } from "react-redux";

const OrderStatusModal = ({
  orderId,
  setShowCartItems,
  setShowOrderStatusModal,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  return (
    <div className={styles.mainContainer}>
      <div
        onClick={() => {
          if (window.innerWidth > 850) {
            setShowCartItems(false);
          }
          dispatch(clearAllMenuCartItems());
          setShowOrderStatusModal(false);
          navigate(`/storefront/${id}`, { replace: true });
        }}
        className={styles.icon_container}
      >
        <img src={Icon.close} alt="" />
      </div>
      <div className={styles.mainContentContainer}>
        <div className={styles.contentContainer}>
          <div className={styles.mainContent}>
            <div className={styles.headingContainer}>
              <p>Order Status:</p>
              {/* <h4>Your Order Has Been Received</h4> */}
            </div>
            <div className={styles.bodyContainer}>
              <img className={styles.orderImg} src={Icon.orderStatus} alt="" />
              <h4>Your order has been placed successfully!</h4>
              <div className="d-flex align-items-center">
                <p>We&apos;ll send you a confirmation email shortly.</p>
                <img src={Icon.email} alt="" />
              </div>
            </div>
            <div className={styles.orderContainer}>
              {/* <div>
                <p>Preparation Time:</p>
                <span>40 Mins</span>
              </div> */}
              <div>
                <p>Order No:</p>
                <span>{orderId}</span>
              </div>
            </div>
          </div>
          <div className={styles.footerContent}>
            <p>Powered By:</p>
            <h2>Lumi Business</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderStatusModal;
