import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape(
  {
    storeName: yup.string().required("Store Name is required"),
    storeAddress: yup.string().required("Store address is required"),
    storeCategory: yup.string().required("Please, select your store category"),
    storePhone: yup
      .string()
      .notRequired()
      .when("storePhone", {
        is: (val) => val?.length,
        then: yup
          .string()
          .matches(/^[0-9]+$/, "Phone number must contain only digits")
          .test(
            "num",
            "Invalid phone number format",
            (val) => !val.startsWith("0"),
          )
          .min(10, "Invalid phone number format")
          .max(10, "Invalid phone number format"),
      }),
  },
  ["storePhone", "storePhone"],
);

const Validation = ({ storeDetails }) => {
  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      storeName: storeDetails?.name,
      storeAddress: storeDetails?.address,
      storeCategory: storeDetails?.store_category,
      storePhone: storeDetails?.phone_number,
    },
  });
};

export default Validation;
