import { useState, useContext } from "react";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import {
  createStore,
  getStoreCategoryList,
  updateStoreDetails,
} from "../../../services/inventory";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { cleanObject, getRandomIntInclusive } from "../../../helpers";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { pathConstants } from "../../../routes/pathContants";

const useStoreForm = ({
  storeInfo,
  handleFetchStores,
  isEditMode,
  closeWidget,
}) => {
  const { business_phone_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const [storeDetails, setStoreDetails] = useState({
    name: storeInfo?.name || "",
    address: storeInfo?.address || "",
    subaccount_label: storeInfo?.subaccount || "",
    store_category: storeInfo?.store_category?.id || "",
    store_category_label: storeInfo?.store_category?.name || "",
    is_warehouse: storeInfo?.is_warehouse || false,
    vat: storeInfo?.vat,
    allow_generate_bill: storeInfo?.allow_generate_bill || false,
    allow_table: storeInfo?.allow_table || false,
    allow_waybill: storeInfo?.allow_waybill || false,
    allow_prescription: storeInfo?.allow_prescription || false,
    allow_custom_price: storeInfo?.allow_custom_price || false,
    allow_section: storeInfo?.allow_section || false,
    phone_number: storeInfo?.phone_number?.substring(3) || "",
    phone_code: business_phone_code,
    credit_limit: storeInfo?.credit_limit || "",
  });
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const triggerToast = useContext(ToastContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: category_list } = useQuery(["storeCategoryList"], () =>
    getStoreCategoryList().then((res) => {
      return res?.data?.data;
    }),
  );

  // create a new store
  const { mutate: addStore, isLoading: isAddingStore } = useMutation((data) =>
    createStore(data),
  );
  const handleAddStore = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));
    const data = cleanObject(
      {
        ...storeDetails,
        phone_number:
          storeDetails?.phone_number !== ""
            ? `${storeDetails.phone_code}${storeDetails.phone_number}`
            : null,
      },
      ["store_category_label", "subaccount_label"],
    );

    addStore(data, {
      onSuccess: () => {
        handleFetchStores();
        triggerToast("Store creation successful", "success");
        closeWidget();
      },

      onSettled: () => setRequestLoaderProgress(100),
    });
  };

  // Edit store properties
  const { mutate: updateStore, isLoading: isEditingStore } = useMutation(
    (data) => updateStoreDetails(data),
  );
  const handleEditStore = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));
    const data = cleanObject(
      {
        ...storeDetails,
        phone_number:
          storeDetails?.phone_number !== ""
            ? `${storeDetails.phone_code}${storeDetails.phone_number}`
            : null,
      },
      ["store_category_label", "subaccount_label"],
    );
    updateStore(
      { data, id: storeInfo?.id },
      {
        onSuccess: () => {
          handleFetchStores();
          queryClient.invalidateQueries(["getStoreDetails", storeInfo?.id]);
          navigate(pathConstants.INVENTORY_STORES);
          triggerToast("Store update successful", "success");
          closeWidget();
        },
        onSettled: () => setRequestLoaderProgress(100),
      },
    );
  };

  const submitStoreInfo = () => {
    if (isEditMode) handleEditStore();
    else handleAddStore();
  };

  return {
    isAddingStore,
    isEditingStore,
    storeDetails,
    category_list: category_list || [],
    setStoreDetails,
    submitStoreInfo,
  };
};

export default useStoreForm;
