import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const terminalRequestSchema = yup
  .object({
    terminal_type: yup.string().required("Terminal type is required"),
    payment_option: yup.string().required("Payment option is required"),
    number_of_terminals: yup
      .number()
      .typeError("Number of terminals must be a number")
      .positive("Number must be greater than 0")
      .max(50, "The maximum number of terminals you can request is 50")
      .required("Number of terminals is required"),
    lumi_account: yup.string().required("Lumi account is required"),
    delivery_address: yup.string().required("Delivery address is required"),
    contact_phone_number: yup
      .string()
      .matches(/^[0-9]+$/, "Phone number must contain only digits")
      .test("num", "Invalid phone number format", (val) => !val.startsWith("0"))
      .min(10, "Invalid phone number format")
      .max(10, "Invalid phone number format"),
    subAccount: yup.array().required("Account is required").nullable(),
  })
  .required("Please fill in all fields");

const TerminalRequestFormValidation = ({
  terminal_type,
  payment_option,
  number_of_terminals,
  lumi_account,
  delivery_address,
  contact_phone_number,
  subAccount,
}) => {
  return useForm({
    resolver: yupResolver(terminalRequestSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      terminal_type: terminal_type,
      payment_option: payment_option,
      number_of_terminals: number_of_terminals,
      lumi_account: lumi_account,
      delivery_address: delivery_address,
      contact_phone_number: contact_phone_number,
      subAccount: subAccount,
    },
  });
};

export default TerminalRequestFormValidation;
