import { useContext, useState, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { ToastContext } from "../../../../../hooks/context";
import { bulkTransferstockUpdate } from "../../../../../services/inventory";
import { cleanObject, deepCopyFunction } from "../../../../../helpers";
import { useNavigate } from "react-router-dom";
import { pathConstants } from "../../../../../routes/pathContants";
import { useParams } from "react-router-dom";
import {
  useGetStockTransferDetails,
  useGetStockTransferItems,
} from "../../../../../hooks/api/queries";

export const useEditStockTransfer = () => {
  const triggerToast = useContext(ToastContext);
  const [transferData, setTransferData] = useState([]);
  const [otherTransferInfo, setOtherTransferInfo] = useState({
    transfer_from_store_id: null,
    transfer_to_store_id: null,
  });
  const navigate = useNavigate();
  const params = useParams();
  const queryClient = useQueryClient();

  const {
    data: stockTransferDetails,
    isLoading: isFetchingStockTransferDetails,
  } = useGetStockTransferDetails({ id: params.id });

  const {
    isLoading: isFetchingStockTransferItems,
    data: stockTransferItems,
    isFetchingNextPage,
    autoScrollRef,
  } = useGetStockTransferItems({ id: params.id });

  const { mutate: updateTranferStockMutation, isLoading: isTransferUpdating } =
    useMutation((data) => bulkTransferstockUpdate({ data, id: params?.id }));

  useEffect(() => {
    setOtherTransferInfo({
      transfer_from_store_id: stockTransferDetails?.transfer_from_store?.id,
      transfer_to_store_id: stockTransferDetails?.transfer_to_store?.id,
    });
  }, [stockTransferDetails]);

  useEffect(() => {
    setTransferData((prev) => [...prev, ...stockTransferItems]);
    const newTransferData = [...transferData];
    for (
      let index = newTransferData?.length;
      index < stockTransferItems?.length;
      index++
    )
      newTransferData.push(stockTransferItems[index]);
    setTransferData(newTransferData);
  }, [stockTransferItems?.length]);

  const handleTransferEditProducts = () => {
    if (transferData?.length === 0)
      return triggerToast(
        "You must transfer at least one item with some quantity",
        "warning",
      );

    const newTransferData = deepCopyFunction(transferData);
    const rearrangedData = newTransferData?.map((dataItem) => {
      return cleanObject({
        product_id: dataItem?.product_id,
        variant_id: dataItem?.variant_id,
        quantity: dataItem?.quantity,
        stock_transfer_item_id: dataItem?.stock_transfer_item_id,
      });
    });

    updateTranferStockMutation(
      {
        items: rearrangedData,
      },
      {
        onSuccess: () => {
          triggerToast("Request Updated Successfully", "success");
          queryClient.invalidateQueries(["getStockTransferItems", params.id]);
          navigate(pathConstants.INVENTORY_PRODUCT_BULK_TRANSFER_HISTORY);
        },
      },
    );
  };

  const updateTransferProducts = () => handleTransferEditProducts();

  return {
    transferData,
    isLoading:
      isFetchingStockTransferItems ||
      isFetchingStockTransferDetails ||
      otherTransferInfo?.transfer_from_store_id === null ||
      otherTransferInfo?.transfer_from_store_id === undefined,
    isTransferUpdating,
    stockTransferDetails,
    autoScrollRef,
    isFetchingNextPage,
    otherTransferInfo,
    setOtherTransferInfo,
    updateTransferProducts,
    setTransferData,
  };
};
