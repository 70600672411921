import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Icon from "../../../assets/icons";
import styles from "./SellModeMenu.module.scss";
import secondaryComponents from "..";
import { pathConstants } from "../../../routes/pathContants";

const SellModeMenu = ({
  closeWidget,
  setOpenBillsWidget,
  setOpenSalesWidget,
  setIsExportWidget,
  setIsOpenKitchensWidget,
  setIsOpenReturnWidget,
  setShowPendingSessions,
  setIsTable,
  setTableCardId,
}) => {
  const { store_id } = useParams();
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const showGeneratBills = (() => {
    return storeList?.find((item) => `${item?.id}` === store_id)
      ?.allow_generate_bill;
  })();
  const showTables = (() => {
    return storeList?.find((item) => `${item?.id}` === store_id)?.allow_table;
  })();

  return (
    <div className={styles.menuList}>
      <div
        onClick={() => {
          closeWidget();
          setShowPendingSessions(true);
        }}
        className={styles.menuItem}
      >
        <div className={styles.menuItemImage}>
          <img src={Icon.cartIcon} alt="" />
        </div>
        <p className="dark--text mt-1">Pending Checkout</p>
      </div>
      <div
        onClick={() => {
          window.open(pathConstants.SECTIONS, "_blank");
        }}
        className={styles.menuItem}
      >
        <div className={styles.menuItemImage}>
          <img src={Icon.kitchen} alt="" width={"48px"} />
        </div>
        <p className="dark--text mt-1 lh-1">Kitchen & Bar Orders</p>
      </div>

      {showGeneratBills ? (
        <div
          onClick={() => {
            setTableCardId(null);
            closeWidget();
            setOpenBillsWidget(true);
          }}
          className={styles.menuItem}
        >
          <div className={styles.menuItemImage}>
            <img src={Icon.sellModeBill} alt="" width={"48px"} />
          </div>
          <p className="dark--text mt-1">Bills</p>
        </div>
      ) : null}
      {showTables && (
        <div
          onClick={() => {
            closeWidget();
            setIsTable(true);
          }}
          className={styles.menuItem}
        >
          <div className={styles.menuItemImage}>
            <img src={Icon.tableIcon} alt="" />
          </div>
          <p className="dark--text mt-1">Tables</p>
        </div>
      )}
      <div
        onClick={() => {
          closeWidget();
          setOpenSalesWidget(true);
        }}
        className={styles.menuItem}
      >
        <div className={styles.menuItemImage}>
          <img src={Icon.sellModeSale} alt="" />
        </div>
        <p className="dark--text mt-1">Sales</p>
      </div>
      <secondaryComponents.NavigateWithPermission
        permissionParentKey={"Inventory Management"}
        permissionChildKey="can_generate_end_of_day"
      >
        <div
          onClick={() => {
            closeWidget();
            setIsExportWidget(true);
          }}
          className={styles.menuItem}
        >
          <div className={styles.menuItemImage}>
            <img src={Icon.sellModeEndofDay} alt="" width={"48px"} />
          </div>
          <p className="dark--text mt-1">End of Day</p>
        </div>
      </secondaryComponents.NavigateWithPermission>
      <div
        onClick={() => {
          window.open(
            pathConstants.PAYMENT_TRANSACTIONS_ALL + "?all_business=true",
            "_blank",
          );
        }}
        className={styles.menuItem}
      >
        <div className={styles.menuItemImage}>
          <img src={Icon.sellModePayment} alt="" width={"48px"} />
        </div>
        <p className="dark--text mt-1 lh-1">Payments</p>
      </div>

      <secondaryComponents.NavigateWithPermission
        permissionParentKey={"Inventory Management"}
        permissionChildKey="can_return_products"
      >
        <div
          onClick={() => {
            closeWidget();
            setIsOpenReturnWidget(true);
          }}
          className={styles.menuItem}
        >
          <div className={styles.menuItemImage}>
            <img src={Icon.redo} alt="" />
          </div>
          <p className="dark--text mt-1 lh-1">Returns</p>
        </div>
      </secondaryComponents.NavigateWithPermission>
    </div>
  );
};

export default SellModeMenu;
