import { useMemo } from "react";
import { useTable } from "react-table";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "../../secondaryComponents";
import { useState } from "react";
import { useGetStoreDetails } from "../../../hooks/api/queries";

const useStoresTable = ({ storeList }) => {
  const [isEditModal, setIsEditModal] = useState(null);
  const columns = useMemo(
    () => [
      {
        Header: "Store ID",
        accessor: "id",
      },
      {
        Header: "Store Name",
        accessor: "name",
      },
      {
        Header: "Business Name",
        accessor: "subaccount",
      },
      {
        Header: "Business Category",
        accessor: "store_category",
        Cell: ({ cell: { value } }) => value?.name,
      },
      {
        Header: "Last updated on",
        accessor: "created_at",
        Cell: ({ cell: { value } }) => new Date(value).toLocaleDateString(),
      },
      {
        Header: "Type",
        accessor: "is_warehouse",
        Cell: ({ cell: { value } }) => (
          <span
            style={{
              color: "#7647EE",
              backgroundColor: "#F2EDFE",
              borderRadius: "8px",
              padding: "10px 16px",
              height: "28px",
            }}
          >
            {value ? "Warehouse" : "Store"}
          </span>
        ),
      },
      {
        Header: "",
        accessor: "a",
        Cell: ({ row }) => (
          <span>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_edit_stores"
            >
              <primaryComponents.Button
                classNames={"btn btn--primary smallBtn"}
                onClick={() => {
                  setIsEditModal(row?.original?.id);
                }}
              >
                Edit details
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>
          </span>
        ),
      },
    ],
    [],
  );
  const { data: storeDetails, isLoading: isFetchingStoreDetails } =
    useGetStoreDetails(isEditModal);
  const instance = useTable({ columns, data: storeList || [] });
  return {
    instance,
    isEditModal,
    setIsEditModal,
    isFetchingStoreDetails,
    storeDetails,
  };
};

export default useStoresTable;
