import { useContext, useEffect, useState, useCallback } from "react";
import { ToastContext } from "../../../hooks/context";
import { useSendCreditLimitReminderMutation } from "../../../hooks/api/mutations/useInventory";
import { useQueryClient } from "react-query";
import { cleanObject } from "../../../helpers";
import { useSelector } from "react-redux";
import { formatCurrency } from "../../../helpers";

const SendCreditReminderWidget = ({ closeWidget, customer, type }) => {
  const triggerToast = useContext(ToastContext);
  const [messageType, setMessageType] = useState("email");
  const sendCreditLimitReminderMutation = useSendCreditLimitReminderMutation();
  const queryClient = useQueryClient();
  const { business_phone_code, currency_code, business_phone_number } =
    useSelector((state) => state.profileDetailsReducer.businessDetails);
  const sendThroughOptions = {
    EMAIL: "email",
    SMS: "sms",
  };
  const MAX_BODY_LENGTH = 200;

  const generateDefaultBody = () => {
    if (type === "reminder") {
      if (messageType === sendThroughOptions.SMS) {
        return `Dear ${customer?.first_name},<p><br/>Be reminded that your debt of ${customer?.outstanding_balance} from a purchase you made from our store ${customer?.store?.name} is due in 3 days. Kindly make the necessary arrangements to repay.<p><br/>Thank you,<p>${customer?.business}`;
      } else if (messageType === sendThroughOptions.EMAIL) {
        return `Dear ${customer?.first_name},<p><br/>This is a friendly reminder that your credit repayment of ${customer?.outstanding_balance} for your purchase(s) at ${customer?.store?.name} will be due in 3 days. Please make your payment on time to avoid any penalties. For assistance, you can contact ${customer?.store?.name} at ${business_phone_number}. <p><br/>Thank you for your prompt attention to this matter.<p>Best regards`;
      }
    }
    if (type === "message" && messageType === sendThroughOptions.EMAIL) {
      return `Dear ${customer?.full_name}, <p><br/>We noticed it’s been a while since we last saw you at ${customer?.store?.name}, and we wanted to let you know that we’ve missed you! As a thank you, we’re offering you 5% off your next purchase. We hope to see you again soon! <p><br/>Best regards, </p> <p>${customer?.business}</p>`;
    }
    if (type === "message" && messageType === sendThroughOptions.SMS) {
      return `Hi ${customer?.full_name}, <p><br/>We miss you at ${customer?.store?.name}! Enjoy 5% off your next purchase with code discount Code. Come back soon! <p><br/> Regards,</p> <p>${customer?.store?.name}</p>`;
    }
  };

  const generateDefaultSubject = () => {
    if (type === "reminder" && messageType === sendThroughOptions.EMAIL) {
      return `${formatCurrency({
        value: customer?.outstanding_balance || 0,
        currencyCode: currency_code,
      })} Payment Due in 3 Days for Your Purchase at ${customer?.store?.name}`;
    } else if (type === "message" && messageType === sendThroughOptions.EMAIL) {
      return `We Miss You, ${customer?.first_name} - Enjoy 5% Off Your Next Purchase`;
    }
    return "";
  };

  const [reminderDetails, setReminderDetails] = useState({
    send_through: sendThroughOptions.EMAIL,
    email: customer?.email || "",
    phone_code: customer?.phone_code || business_phone_code,
    phone: customer?.phone || "",
    subject: generateDefaultSubject(),
    body: generateDefaultBody(),
  });

  useEffect(() => {
    setReminderDetails((prev) => ({
      ...prev,
      send_through: messageType,
      email:
        messageType === sendThroughOptions.EMAIL
          ? customer?.email || ""
          : prev.email,
      phone_code:
        messageType === sendThroughOptions.SMS
          ? customer?.phone_code || business_phone_code
          : prev.phone_code,
      phone:
        messageType === sendThroughOptions.SMS
          ? customer?.phone || ""
          : prev.phone,
      subject:
        prev.subject === generateDefaultSubject() && !prev.subject
          ? generateDefaultSubject()
          : prev.subject,
      body:
        prev.body === generateDefaultBody() && !prev.body
          ? generateDefaultBody()
          : prev.body,
    }));
  }, [messageType, customer, business_phone_code]);

  const handleSendThroughChange = (value) => {
    setMessageType(value);
  };

  const handleBodyChange = useCallback(
    (content, delta, source, editor) => {
      const text = editor.getText();
      if (text.length <= MAX_BODY_LENGTH) {
        setReminderDetails((prev) => ({
          ...prev,
          body: content,
        }));
      } else {
      }
    },
    [MAX_BODY_LENGTH],
  );

  const handleSubjectChange = (e) => {
    setReminderDetails((prev) => ({
      ...prev,
      subject: e.target.value,
    }));
  };

  const sendCreditLimitReminder = () => {
    sendCreditLimitReminderMutation.mutate(
      { id: customer?.id, data: cleanObject(reminderDetails) },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["getCustomerDetails"]);
          triggerToast("Credit Limit Reminder Sent Successfully", "success");
          closeWidget();
        },
      },
    );
  };

  return {
    sendThroughOptions,
    currency_code,
    business_phone_number,
    reminderDetails,
    setReminderDetails,
    handleSendThroughChange,
    generateDefaultSubject,
    messageType,
    generateDefaultBody,
    setMessageType,
    sendCreditLimitReminder,
    handleBodyChange,
    handleSubjectChange,
    isSending: sendCreditLimitReminderMutation.isLoading,
  };
};

export default SendCreditReminderWidget;
