import { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getUrlQuerysection } from "../../../../helpers";
import { getOrderList } from "../../../../services/inventory";
import { useGetUserList } from "../../../../hooks/api/queries/useSettings";

export const useSalesTransactions = () => {
  const [search, setSearch] = useSearchParams();
  const [isModal, setIsModal] = useState({
    isEndOfDay: search.get("endOfDay") ? true : false,
    isReturn: false,
  });
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const [currentStore, setCurrentStore] = useState(
    (() => {
      if (search.get("store_id"))
        return storeList?.find(
          (item) => `${item.id}` === search.get("store_id"),
        )?.name;
      else return null;
    })(),
  );
  const [initiator, setInitiator] = useState(
    search.get("initiator_id") || null,
  );
  const [storeType, setStoreType] = useState(
    search.get("order_source") || null,
  );
  const queries = getUrlQuerysection();

  const { data: userList, isLoading: isLoadingUsers } = useGetUserList();

  const {
    isLoading: isFetchingSalesTransactions,
    data: salesTransactions,
    refetch: handleFetchSalesTransactions,
  } = useQuery(["getOrderList", queries], () =>
    getOrderList(queries).then((res) => res?.data),
  );

  const gotoPage = (pageNumber) => {
    search.set("page", pageNumber);
    setSearch(search, { replace: true });
  };

  const filterHistoryByStore = (storeId) => {
    search.delete("page");
    if (storeId === "ALL_STORES") search.delete("store_id");
    else search.set("store_id", storeId);
    setSearch(search, { replace: true });
  };

  const searchSalesTransactions = (value) => {
    if (value?.length === 0) search.delete("query");
    else search.set("query", value);
    search.delete("page");
    setSearch(search, { replace: true });
  };

  const filterByInitiator = (value) => {
    search.delete("page");
    if (value === "ALL_INITIATORS") search.delete("initiator_id");
    else search.set("initiator_id", value);
    setSearch(search, { replace: true });
  };

  const filterByStoreType = (value) => {
    search.delete("page");
    if (value === "ALL_STORES") search.delete("order_source");
    else search.set("order_source", value);
    setSearch(search, { replace: true });
  };

  return {
    isFetchingSalesTransactions,
    storeList,
    currentStore,
    metaDetails: salesTransactions?.meta || {},
    salesTransactions: salesTransactions?.data || [],
    search,
    initiator,
    storeType,
    userList: userList || [],
    isLoadingUsers,
    isModal,
    setIsModal,
    setInitiator,
    setStoreType,
    filterByInitiator,
    handleFetchSalesTransactions,
    filterHistoryByStore,
    filterByStoreType,
    setCurrentStore,
    gotoPage,
    searchSalesTransactions,
  };
};
