import { useMemo, useState } from "react";
import { useTable } from "react-table";
import { formatCurrency } from "../../../helpers";
import { useSelector } from "react-redux";

export const usePerformingProductsLogic = ({ products }) => {
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const [openWidget, setOpenWidget] = useState(false);

  const columns = useMemo(() => {
    return [
      {
        Header: "No.",
        accessor: (_, index) => {
          return index + 1;
        },
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Revenue",
        accessor: "revenue",
        Cell: ({ cell: { value } }) =>
          formatCurrency({ value: value, currencyCode: currency_code }) ||
          "N/A",
      },
      {
        Header: "Sales Count",
        accessor: "sales_count",
      },
    ];
  }, []);

  const instance = useTable({ columns, data: products });

  return { instance, openWidget, setOpenWidget };
};
