import { createSlice } from "@reduxjs/toolkit";

const storedCartItems = localStorage.getItem("menuCartItems")
  ? JSON.parse(localStorage.getItem("menuCartItems"))
  : [];

const initialState = {
  menuCartItems: storedCartItems,
};

export const menuCartSlice = createSlice({
  name: "menuCart",
  initialState,
  reducers: {
    addToMenuCart: (state, action) => {
      const productIndex = state.menuCartItems.findIndex(
        (item) => item.id === action.payload.id,
      );

      if (productIndex !== -1) {
        state.menuCartItems.splice(productIndex, 1);
      }

      state.menuCartItems.unshift(action.payload);

      localStorage.setItem(
        "menuCartItems",
        JSON.stringify(state.menuCartItems),
      );
    },
    removeFromMenuCart: (state, action) => {
      state.menuCartItems = state.menuCartItems.filter(
        (item) => item.id !== action.payload.id,
      );

      localStorage.setItem(
        "menuCartItems",
        JSON.stringify(state.menuCartItems),
      );
    },
    setMenuItemQty: (state, action) => {
      const indexOfProduct = state.menuCartItems.findIndex(
        (item) => item.id === action.payload.id,
      );

      if (indexOfProduct !== -1) {
        state.menuCartItems[indexOfProduct] = action.payload;

        localStorage.setItem(
          "menuCartItems",
          JSON.stringify(state.menuCartItems),
        );
      }
    },
    clearAllMenuCartItems: (state) => {
      state.menuCartItems = [];

      localStorage.setItem(
        "menuCartItems",
        JSON.stringify(state.menuCartItems),
      );
    },
  },
});

export const {
  addToMenuCart,
  removeFromMenuCart,
  setMenuItemQty,
  clearAllMenuCartItems,
} = menuCartSlice.actions;
