import { useState, useContext } from "react";
import {
  useGetCustomerDetails,
  useGetCustomerLoyaltyActivites,
} from "../../../../hooks/api/queries";
import { useCustomerLoyaltyEligibilityToggleMutation } from "../../../../hooks/api/mutations/useCustomer";
import { useSearchParams } from "react-router-dom";
import { ToastContext } from "../../../../hooks/context";
import { useQueryClient } from "react-query";

const CustomerDetailsLogic = (customerId) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [sendMessage, setSendMessage] = useState(false);
  const [showUpdateCreditLimit, setShowUpdateCreditLimit] = useState(false);
  const [search, setSearch] = useSearchParams();
  const triggerToast = useContext(ToastContext);
  const queryClient = useQueryClient();
  const customerLoyaltyEligibilityToggleMutation =
    useCustomerLoyaltyEligibilityToggleMutation();

  const gotoPage = (pageNumber) => {
    search.set("page", pageNumber);
    setSearch(search, { replace: true });
  };

  const { isLoading: isFetchingCustomerDetails, data: customerDetails } =
    useGetCustomerDetails(customerId);

  const formatDate = (string) => {
    var options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(string).toLocaleDateString([], options);
  };

  const toggleCustomerLoyalty = (id) => {
    customerLoyaltyEligibilityToggleMutation.mutate(id, {
      onSuccess: () => {
        queryClient.invalidateQueries(["getCustomerOrderDetails"]);
        triggerToast("Customer Loyalty Status Updated Successfully", "success");
      },
    });
  };

  const {
    isLoading: isFetchingCustomerLoyaltyActivities,
    data: customerLoyaltyActivities,
    refetch: isRefetchingCustomerLoyaltyActivites,
  } = useGetCustomerLoyaltyActivites(customerId);
  return {
    toggleCustomerLoyalty,
    gotoPage,
    sendMessage,
    setSendMessage,
    customerDetails,
    isFetchingCustomerDetails,
    showUpdateCreditLimit,
    setShowUpdateCreditLimit,
    isFetchingCustomerLoyaltyActivities,
    customerLoyaltyActivities: customerLoyaltyActivities?.data || [],
    metaDetails: customerLoyaltyActivities,
    isRefetchingCustomerLoyaltyActivites,
    formatDate,
    showEditModal,
    setShowEditModal,
  };
};
export default CustomerDetailsLogic;
