import React from "react";
import styles from "./styles.module.scss";
import primaryComponents from "../../../../../components/primaryComponents";
import secondaryComponents from "../../../../../components/secondaryComponents";
// import Icon from "../../../../../assets/icons";
import {
  formatCurrency,
  getPriceWithDiscout,
  getVariantPriceWithDiscout,
} from "../../../../../helpers";
import usePayForOrder from "./usePayForOrder";
import { ChevronLeftArrow } from "../../../../../assets/svgIcons";

const PayForOrderModal = ({ setShowPayForOrder, setShowOrderSummaryModal }) => {
  const {
    isLoadingProcessPayment,
    isLoadingPaymentGateways,
    dataPaymentGateways,
    menuCartItems,
    paymentMethod,
    store,
    menuObject,
    setPaymentMethod,
    handleProcessPayment,
  } = usePayForOrder();

  const primaryColor = menuObject?.primary_colour
    ? menuObject?.primary_colour
    : "#7647ee";

  return (
    <div className={styles.storeCustomerForm}>
      <div
        onClick={() => {
          setShowPayForOrder(false);
          setShowOrderSummaryModal(true);
        }}
        className={styles.back_icon_container}
        style={{ borderColor: primaryColor }}
      >
        <ChevronLeftArrow color={primaryColor} />
        {/* <img src={Icon.chevron_left_arrow} alt="" /> */}
      </div>
      <div className={styles.checkout_heading_container}>
        <h4>Pay For Your Order</h4>
        <p>Choose your preferred payment method</p>
      </div>
      <secondaryComponents.LoaderHelper
        isLoading={isLoadingPaymentGateways || !dataPaymentGateways}
        classNames="mt-4"
      >
        <div className={styles.payment_type_container}>
          {dataPaymentGateways &&
            dataPaymentGateways.data.data.map((gateway, index) => (
              <div
                key={index}
                onClick={() => setPaymentMethod(gateway)}
                className={styles.payment_type}
              >
                <p>{gateway.type}</p>
                <div className={styles.radio}>
                  {paymentMethod === gateway && (
                    <div
                      style={{ background: primaryColor }}
                      className={styles.radio_inside}
                    ></div>
                  )}
                </div>
              </div>
            ))}
        </div>
      </secondaryComponents.LoaderHelper>

      <div className={styles.storeCustomerForm__bottom}>
        <primaryComponents.Button
          classNames="btn btn--primary cover"
          isDisabled={
            dataPaymentGateways?.data.data.length === 0 ||
            isLoadingPaymentGateways ||
            isLoadingProcessPayment
          }
          isLoading={isLoadingPaymentGateways || isLoadingProcessPayment}
          loadingText={isLoadingPaymentGateways ? "..." : "Submitting..."}
          onClick={handleProcessPayment}
          style={{ background: primaryColor }}
        >
          Pay{" "}
          {formatCurrency({
            value: menuCartItems.reduce(
              (sum, product) =>
                sum +
                (product?.is_variant
                  ? getVariantPriceWithDiscout(product) * product.quantity
                  : getPriceWithDiscout(product) * product.quantity),
              0,
            ),
            currencyCode: store?.currency_code,
          })}
        </primaryComponents.Button>
      </div>
    </div>
  );
};

export default PayForOrderModal;
