import { useGetOrderDetails } from "../../../../hooks/api/queries/useOrder";

const useOrderflows = ({ orderId }) => {
  const { isLoading, data: orderDetails } = useGetOrderDetails({ id: orderId });

  return {
    isLoading,
    orderDetails,
  };
};
export default useOrderflows;
