import primaryComponents from "../../primaryComponents";
import Pagination from "../Pagination/Pagination";
import LoaderHelper from "../LoaderHelper/LoaderHelper";
import styles from "./SalesWidget.module.scss";
import useSalesWidget from "./useSalesWidget";
import secondaryComponents from "..";
import Orderflows from "../StoreCheckout/Orderflows";

const SalesWidget = () => {
  const {
    instance,
    orderPageDetails,
    orders,
    isFetchingOrders,
    selectedOrder,
    setSelectedOrder,
    gotoPage,
    handleFilterOrderByStatus,
    handleSearchOrders,
  } = useSalesWidget({});

  const { getTableBodyProps, getTableProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <>
      <div className={styles.salesWidget}>
        <div className={styles.salesWidgetContent}>
          <div className={styles.salesWidgetTableContainer}>
            <div className={styles.salesWidgetTableContainerHeader}>
              <div className={styles.searchSection}>
                <primaryComponents.SearchField
                  placeholder="Search for a product"
                  trigger={(value) => handleSearchOrders(value)}
                  triggerOnInput={true}
                />
              </div>
              <div style={{ maxWidth: "200px", width: "100%" }}>
                <primaryComponents.SelectField
                  value="Status"
                  options={[
                    { label: "All", value: "" },
                    { label: "Placed", value: "PLACED" },
                    { label: "In Progress", value: "IN_PROGRESS" },
                    { label: "Completed", value: "COMPLETED" },
                    { label: "Canceled", value: "CANCELED" },
                    { label: "Pending", value: "PENDING" },
                  ]}
                  onChange={(value) => {
                    if (value === "Status") return;
                    handleFilterOrderByStatus(value);
                  }}
                  nameKey="label"
                  idKey="value"
                  floatError={false}
                />
              </div>
            </div>
            <LoaderHelper isLoading={isFetchingOrders} classNames={"mt-5"}>
              <secondaryComponents.TableHelper
                getTableBodyProps={getTableBodyProps}
                getTableProps={getTableProps}
                headerGroups={headerGroups}
                rows={rows}
                prepareRow={prepareRow}
                handleScroll={true}
              >
                <h4 className="mt-5 text-center">No Data Available</h4>
              </secondaryComponents.TableHelper>
            </LoaderHelper>
          </div>
          {orders?.length > 0 && (
            <Pagination
              totalPageNumber={orderPageDetails?.last_page}
              handlePageClick={(value) => gotoPage(value?.selected + 1)}
              currentPage={orderPageDetails?.current_page}
            />
          )}
        </div>
      </div>

      <secondaryComponents.SideModal
        isActive={selectedOrder !== null}
        closeModal={() => setSelectedOrder(null)}
        width={1100}
      >
        <Orderflows
          closeModal={() => setSelectedOrder(null)}
          orderId={selectedOrder}
          defaultLanding={"tnxReceipt"}
          viaSaleRecord={true}
        />
      </secondaryComponents.SideModal>
    </>
  );
};

export default SalesWidget;
