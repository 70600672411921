import React from "react";
import "./styles.scss";
import primaryComponents from "../../../../../../../components/primaryComponents";
import secondaryComponents from "../../../../../../../components/secondaryComponents";
import useMenuDetails from "./useMenuDetails";
import { useNavigate } from "react-router-dom";
import Icon from "../../../../../../../assets/icons";

const MenuDetails = ({
  selectedMenuTab,
  foodMenus,
  onCopy,
  setIsDeleteFoodMenu,
  setIsFoodMenuWidget,
}) => {
  const { instance } = useMenuDetails({ foodMenus, onCopy });
  const navigate = useNavigate();

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className={"table-container"}>
      <>
        {/* shows on desktop */}
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => (
                  <th
                    key={i}
                    className="font-weight-semibold"
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                  </th>
                ))}
                <th className={"font-weight-semibold action-column"}>
                  <div>Actions</div>
                </th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps({ key: i })}>
                  {row.cells.map((cell, i) => {
                    return (
                      <td key={i} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                  <td>
                    {selectedMenuTab === "Storefront Details" ? (
                      <secondaryComponents.NavigateWithPermission
                        permissionParentKey={"Inventory Management"}
                        permissionChildKey="can_view_edit_and_create_menu"
                      >
                        <div className={"actions"}>
                          <primaryComponents.Button
                            classNames={"btn btn--primary smallBtn"}
                            onClick={() =>
                              navigate(
                                `${row.original.id}?store_id=${row.original.store.id}&edit=true`,
                              )
                            }
                          >
                            Edit
                          </primaryComponents.Button>
                          <primaryComponents.Button
                            classNames={
                              "btn btn--outline selfCheckoutBtn ml-auto"
                            }
                            onClick={() => setIsFoodMenuWidget(row.original.id)}
                          >
                            QR Code
                          </primaryComponents.Button>
                          <primaryComponents.Button
                            onClick={() => {
                              setIsDeleteFoodMenu(row.original.id);
                            }}
                            classNames={"btn btn--outline-red deletBtn"}
                            // style={{ cursor: "pointer" }}
                          >
                            <img src={Icon.deleteIcon} alt="" />
                          </primaryComponents.Button>
                        </div>
                      </secondaryComponents.NavigateWithPermission>
                    ) : (
                      <div>
                        <primaryComponents.Button
                          classNames={"btn btn--primary smallBtn"}
                          onClick={() =>
                            navigate(
                              `${row.original.id}?store_id=${row.original.store.id}&sort=true`,
                            )
                          }
                        >
                          Customize
                        </primaryComponents.Button>
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* display on mobile */}
        <div className="mobile-table">
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <div
                key={i}
                className="mobile-table__row"
                {...row.getRowProps({ key: i })}
              >
                {row.cells.map((cell, j) => (
                  <div
                    key={j}
                    className="row-item"
                    {...cell.getCellProps({ key: j })}
                  >
                    <h6 className="row-item__title">{cell.column.Header}</h6>
                    <p className="row-item__value">{cell.render("Cell")}</p>
                  </div>
                ))}
                {/*  action */}
                {selectedMenuTab === "Storefront Details" ? (
                  <secondaryComponents.NavigateWithPermission
                    permissionParentKey={"Inventory Management"}
                    permissionChildKey="can_view_edit_and_create_menu"
                  >
                    <div className={"actions"}>
                      <primaryComponents.Button
                        classNames={"btn btn--primary smallBtn mr-3"}
                        onClick={() =>
                          navigate(
                            `${row.original.id}?store_id=${row.original.store.id}&edit=true`,
                          )
                        }
                      >
                        Edit
                      </primaryComponents.Button>
                      <primaryComponents.Button
                        classNames={"btn btn--outline smallBtn mr-3"}
                        onClick={() => setIsFoodMenuWidget(row.original.id)}
                      >
                        Self checkout
                      </primaryComponents.Button>
                      <primaryComponents.Button
                        classNames={"btn btn--outline-red smallBtn"}
                        onClick={() => {
                          setIsDeleteFoodMenu(row.original.id);
                        }}
                      >
                        <img src={Icon.deleteIcon} alt="" />
                      </primaryComponents.Button>
                    </div>
                  </secondaryComponents.NavigateWithPermission>
                ) : (
                  <div className={"actions"}>
                    <primaryComponents.Button
                      classNames={"btn btn--primary smallBtn"}
                      onClick={() =>
                        navigate(
                          `${row.original.id}?store_id=${row.original.store.id}&sort=true`,
                        )
                      }
                    >
                      Customize
                    </primaryComponents.Button>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </>
    </div>
  );
};

export default MenuDetails;
