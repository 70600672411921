import { createSlice } from "@reduxjs/toolkit";

// Define the initial state using that type
const initialState = {
  accessToken: null,
  requestingTokens: false,
  profileDetails: {
    business_list: [],
    email: null,
    role: null,
    business_id: null,
    permissions: {},
  },
  businessDetails: {
    business_name: null,
    is_subscribed: true,
    has_wallet: false,
  },
  storeList: [],
};

export const profileDetailsSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfileDetails: (state, action) => {
      state.profileDetails = action.payload;
    },
    setBusinessDetails: (state, action) => {
      state.businessDetails = action.payload;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setRequestingTokens: (state, action) => {
      state.requestingTokens = action.payload;
    },
    setStoreList: (state, action) => {
      state.storeList = action.payload;
    },
  },
});

export const {
  setProfileDetails,
  setBusinessDetails,
  setAccessToken,
  setRequestingTokens,
  setStoreList,
} = profileDetailsSlice.actions;
