import { useContext, useState } from "react";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";

import { ToastContext } from "../../../hooks/context";
import { returnStock } from "../../../services/inventory";

export const useReturnStock = ({ closeWidget }) => {
  const triggerToast = useContext(ToastContext);
  const { storeList } = useSelector((state) => state.profileDetailsReducer);

  const [formData, setFormData] = useState({
    order_id: "",
    store_id: "",
    remarks: "",
    items: [],
  });

  const isOrderItemsSelected =
    formData.items
      .map((item) => item.isReturning === true)
      .filter((item) => item === true).length >= 1;

  const returnStockMutation = useMutation({
    mutationFn: (data) => returnStock(data),
    onSuccess: () => {
      triggerToast("Product returned successfully", "success");
      closeWidget();
    },
  });

  const handleReturnStock = () => {
    returnStockMutation.mutate({
      ...formData,
      items: formData.items.filter((item) => item?.isReturning !== false),
      remarks: formData.remarks ? formData.remarks : undefined,
    });
  };

  return {
    formData,
    isReturningStock: returnStockMutation.isLoading,
    stores: storeList,
    isOrderItemsSelected,
    handleReturnStock,
    setFormData,
  };
};
