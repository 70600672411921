import { useQuery } from "react-query";
import {
  getCustomer,
  getCustomers,
  getCustomerOrder,
  getCustomerLoyaltyActivities,
} from "../../../services/customer";

export const useGetCustomerDetails = (customerId) => {
  console.log(customerId);
  return useQuery(
    ["getCustomerDetails", customerId],
    () => getCustomer(customerId).then((res) => res?.data?.data),
    {
      enabled: !!customerId,
    },
  );
};

export const useGetCustomers = ({ queries = "" }) => {
  return useQuery(
    ["getCustomers", queries],
    () => getCustomers(queries).then((res) => res?.data),
    {
      staleTime: Infinity,
    },
  );
};

export const useGetCustomerOrderDetails = (customerId) => {
  return useQuery(
    ["getCustomerOrderDetails", customerId],
    () => getCustomerOrder(customerId).then((res) => res?.data?.data),
    {
      staleTime: Infinity,
    },
  );
};

export const useGetCustomerLoyaltyActivites = (customerId) => {
  return useQuery(
    ["getCustomerLoyaltyActivities", customerId],
    () => getCustomerLoyaltyActivities(customerId).then((res) => res?.data),
    {
      enabled: customerId !== null,
      staleTime: Infinity,
    },
  );
};
