import { useTable } from "react-table";
import { useMemo, useContext, useState } from "react";
import { ToastContext } from "../../../hooks/context";
import primaryComponents from "../../primaryComponents";
import { useUpdateSectionToggleMutation } from "../../../hooks/api/mutations/useSection";
import { useQueryClient } from "react-query";
import secondaryComponents from "..";

const useSectionsTableLogic = ({ sections }) => {
  const [isSectionProperties, setIsSectionProperties] = useState(null);
  const [isSectionDeleteProperties, setIsSectionDeleteProperties] =
    useState(null);
  const [showSectionModal, setShowSectionModal] = useState(false);
  const [showEditSectionModal, setShowEditSectionModal] = useState(false);
  const [showDeleteSectionModal, setShowDeleteSectionModal] = useState(false);
  const triggerToast = useContext(ToastContext);
  const updateSectionToggleMutation = useUpdateSectionToggleMutation();
  const queryClient = useQueryClient();

  const toggleSection = (id) => {
    updateSectionToggleMutation.mutate(id, {
      onSuccess: () => {
        queryClient.invalidateQueries(["sections"]);
        triggerToast("Section Updated Successfully", "success");
      },
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Kitchen Section",
        accessor: "name",
      },
      {
        Header: "Action",
        Cell: ({ row }) => (
          <div
            className="d-flex align-items-center justify-content-end"
            style={{ gap: "10px" }}
          >
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Inventory Management"}
              permissionChildKey={"can_edit_kitchen_sections"}
            >
              <primaryComponents.Button
                onClick={() => {
                  setIsSectionProperties(row?.original);
                }}
                classNames={"btn btn--primary smallBtn"}
              >
                Edit
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Inventory Management"}
              permissionChildKey={"can_delete_kitchen_sections"}
            >
              <primaryComponents.Button
                onClick={() => {
                  setIsSectionDeleteProperties(row?.original);
                }}
                classNames={"btn btn--outline-red smallBtn"}
              >
                Delete
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Inventory Management"}
              permissionChildKey={"can_on_or_off_kitchen_sections"}
            >
              <secondaryComponents.ToolTipV2
                tipText={row?.original?.is_active ? "Turn Off" : "Turn On"}
              >
                <primaryComponents.Switch
                  name={row?.original?.id}
                  id={row?.original?.id}
                  isChecked={row?.original?.is_active}
                  onChange={() => toggleSection(row?.original?.id)}
                />
              </secondaryComponents.ToolTipV2>
            </secondaryComponents.NavigateWithPermission>
          </div>
        ),
      },
    ],
    [],
  );
  const instance = useTable({ columns, data: sections });
  return {
    instance,
    showSectionModal,
    setShowSectionModal,
    setIsSectionProperties,
    isSectionProperties,
    showDeleteSectionModal,
    setShowDeleteSectionModal,
    columns,
    toggleSection,
    showEditSectionModal,
    setShowEditSectionModal,
    isSectionDeleteProperties,
    setIsSectionDeleteProperties,
  };
};

export default useSectionsTableLogic;
