import secondaryComponents from "../../../../../components/secondaryComponents";
import globalStyles from "../../../../../assets/styles/base/globalPage.module.scss";
import { motion } from "framer-motion";
import primaryComponents from "../../../../../components/primaryComponents";
import useStockTransfer from "./useAllStockTransferHistory";
import { useNavigate } from "react-router-dom";
import { pathConstants } from "../../../../../routes/pathContants";
import { TransferArrowIcon } from "../../../../../assets/svgIcons";

const StockTransfer = () => {
  const {
    isFetchingStocksTransfer,
    stocksTransfer,
    metaDetails,
    search,
    gotoPage,
    searchStockTransfer,
  } = useStockTransfer();

  const navigate = useNavigate();
  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Stocks"
      description="View of all Stocks Transfer"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className={`${globalStyles.pageColumnGaps}`}
        >
          <div>
            <h4 className="mb-0">Transfer Stock</h4>
            <p className="default-font-size">
              Move products and stocks between your stores
            </p>
          </div>

          <div className={globalStyles.pageHeaders}>
            <h4> {stocksTransfer?.length} Stock Transfers</h4>

            <primaryComponents.Button
              classNames={"btn btn--primary"}
              onClick={() => {
                navigate(pathConstants.INVENTORY_PRODUCT_BULK_TRANSFER);
              }}
            >
              <TransferArrowIcon />
              <span className="ml-2 default-font-size">Transfer Stock </span>
            </primaryComponents.Button>
          </div>

          <div className={globalStyles.tableWrapper}>
            <div className={globalStyles.tableHeader}>
              <primaryComponents.SearchField
                classNames={globalStyles.searchField}
                placeholder="Search stock transfer ID"
                trigger={(value) => searchStockTransfer(value)}
                value={search.get("query") !== null ? search.get("query") : ""}
                triggerOnInput={true}
                showsearchBtn={true}
                isScan={true}
              />
            </div>

            <secondaryComponents.LoaderHelper
              isLoading={isFetchingStocksTransfer}
              classNames="mt-5"
            >
              <secondaryComponents.AllStockTransferTable
                stockHistory={stocksTransfer}
                metaDetails={metaDetails}
                gotoPage={gotoPage}
              />
            </secondaryComponents.LoaderHelper>
          </div>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default StockTransfer;
