import { useMutation, useQueryClient } from "react-query";
import {
  sendOrderReceiptToPrint,
  sendReceipt,
  updateOrderDate,
  updateOrderCustomer,
} from "../../../services/sales-point";
import { reverseOrder } from "../../../services/inventory";
import { useContext } from "react";
import { ToastContext } from "../../context";

export const useSendOrderReceiptMutation = () => {
  return useMutation((data) => sendReceipt(data));
};

export const useSendOrderReceiptToPrint = () => {
  return useMutation((data) => sendOrderReceiptToPrint(data));
};

export const useCancelOrderMutation = () => {
  return useMutation((data) => reverseOrder(data));
};

export const useUpdateOrderDate = ({ closeModal }) => {
  const updateOrder = useMutation((data) => updateOrderDate(data));
  const triggerToast = useContext(ToastContext);
  const queryClient = useQueryClient();

  const handleUpdateOrderDate = (data) => {
    updateOrder.mutate(data, {
      onSuccess: () => {
        triggerToast("Order date update successfully", "success");
        queryClient.invalidateQueries(["orderDetails"]);
        queryClient.invalidateQueries(["getOrderList"]);
        closeModal();
      },
    });
  };
  return {
    handleUpdateOrderDate,
    isLoading: updateOrder.isLoading,
  };
};

export const useUpdateOrderCustomer = ({ closeWidget }) => {
  const updateOrder = useMutation((data) => updateOrderCustomer(data));
  const triggerToast = useContext(ToastContext);
  const queryClient = useQueryClient();

  const handleUpdateOrderCustomer = (data) => {
    updateOrder.mutate(data, {
      onSuccess: () => {
        triggerToast("Order customer update successfully", "success");
        queryClient.invalidateQueries(["orderDetails"]);
        queryClient.invalidateQueries(["getOrderList"]);
        closeWidget();
      },
    });
  };
  return {
    handleUpdateOrderCustomer,
    isLoading: updateOrder.isLoading,
  };
};
