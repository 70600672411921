import { useContext, useState } from "react";
import { useMutation } from "react-query";
import { cleanObject, getRandomIntInclusive } from "../../../../helpers";
import { RequestLoader, ToastContext } from "../../../../hooks/context";
import { downloadSalesEndOfDay } from "../../../../services/sales-point";

const useEndOfDayDetails = ({ endOfDayFormDetails }) => {
  const [isDownloadSummary, setIsDownloadSummary] = useState();
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const { isLoading: isDownloading, mutate: exportEndOfDay } = useMutation(
    (data) => downloadSalesEndOfDay(data),
  );
  const downloadEndOfDay = (fileFormat) => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 40));
    exportEndOfDay(
      cleanObject({
        store_id: endOfDayFormDetails?.stores.map((item) => item?.value),
        initiator_ids: endOfDayFormDetails?.initiators.map(
          (item) => item?.value,
        ),
        download_format: fileFormat,
        start_date: endOfDayFormDetails?.startDate,
        end_date: endOfDayFormDetails?.endDate,
        start_time: endOfDayFormDetails?.startTime,
        end_time: endOfDayFormDetails?.endTime,
        order_source: endOfDayFormDetails?.order_source.value
          ? [endOfDayFormDetails?.order_source.value]
          : [],
      }),
      {
        onSuccess: (res) => {
          if (res?.data?.data?.url !== null)
            window.open(res?.data?.data?.url, "_blank");
          triggerToast(res?.data?.detail, "success");
        },

        onSettled: () => setRequestLoaderProgress(100),
      },
    );
  };

  return {
    isDownloading,
    isDownloadSummary,
    setIsDownloadSummary,
    downloadEndOfDay,
  };
};
export default useEndOfDayDetails;
