import TableCard from "../../primaryComponents/TableCard";
import primaryComponents from "../../../components/primaryComponents";
import secondaryComponents from "../../secondaryComponents";
import styles from "./TableView.module.scss";
import Icon from "../../../assets/icons";
import useTableView from "./useTableView";
import { pathConstants } from "../../../routes/pathContants";

const TableView = ({ setIsTable, setTableCardId, setIsOpenBillsWidget }) => {
  const {
    isLoadingOrderTable,
    orderTables,
    isLoadingRefresh,
    handleRefresh,
    refetchOrderTables,
  } = useTableView();

  return (
    <div className={styles.table_container}>
      <div className={styles.table_header}>
        <primaryComponents.Button
          type="button"
          classNames={"btn btn--outline"}
          onClick={() => setIsTable(false)}
        >
          <img className="mr-2" src={Icon.closeIcon} alt="" />
          Close
        </primaryComponents.Button>
        <primaryComponents.Button
          type="button"
          classNames={"btn btn--primary"}
          onClick={handleRefresh}
          loadingText={"Refreshing..."}
          isLoading={isLoadingRefresh}
          isDisabled={isLoadingRefresh}
        >
          <img className="mr-2" src={Icon.refresh} alt="" />
          Refresh
        </primaryComponents.Button>
      </div>
      <secondaryComponents.NavigateWithPermission
        permissionParentKey={"Inventory Management"}
        permissionChildKey="can_view_edit_and_create_tables"
      >
        <secondaryComponents.LoaderHelper
          isLoading={isLoadingOrderTable}
          classNames="mt-3"
        >
          {orderTables && orderTables.length > 0 ? (
            <div className={styles.card_container}>
              {orderTables.map((table, index) => (
                <TableCard
                  key={index}
                  table={table}
                  refetchOrderTables={refetchOrderTables}
                  setIsTable={setIsTable}
                  setTableCardId={setTableCardId}
                  setIsOpenBillsWidget={setIsOpenBillsWidget}
                />
              ))}
            </div>
          ) : (
            <div className={styles.no_table_container}>
              <img className="mr-2" src={Icon.noTableIcon} alt="" />
              <div className={styles.no_table_text}>
                <h4>You have no table</h4>
                <p>Use the button below to add a table.</p>
                <p>When you do, they would appear here.</p>
              </div>
              <primaryComponents.Button
                type="button"
                classNames={"btn btn--primary"}
                onClick={() =>
                  window.open(pathConstants.INVENTORY_TABLES, "_blank")
                }
              >
                <img className="mr-2" src={Icon.plusIcon} alt="" />
                Add new table
              </primaryComponents.Button>
            </div>
          )}
        </secondaryComponents.LoaderHelper>
      </secondaryComponents.NavigateWithPermission>
    </div>
  );
};

export default TableView;
