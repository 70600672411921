import React, { useContext, useEffect, useRef, useState } from "react";
import secondaryComponents from "../../../../../components/secondaryComponents";
import primaryComponents from "../../../../../components/primaryComponents";
// import Icon from "../../../../../assets/icons";
import { GRID_PRODUCT_DEFAULT_ICONS } from "../../../../../helpers/constants";
import styles from "./styles.module.scss";
import { ToastContext } from "../../../../../hooks/context";
import {
  getPriceWithDiscout,
  getPriceWithoutDiscout,
  getVariantPriceWithDiscout,
  getVariantPriceWithoutDiscout,
  truncateString,
} from "../../../../../helpers";
import { useDispatch } from "react-redux";
import { addToMenuCart } from "../../../../../store/modules/menuCart";
import {
  ChevronLeftArrow,
  ChevronRightArrow,
} from "../../../../../assets/svgIcons";

const ITEM_WIDTH = 84;

const VariantItem = ({
  productIndex,
  variantItem,
  variantQuantity,
  setVariantQuantity,
  showAddToCartModal,
  primaryColor,
}) => {
  const triggerToast = useContext(ToastContext);

  return (
    <div className={styles.variantItemContainer}>
      <div className={styles.variantImgContainer}>
        <img
          src={
            showAddToCartModal?.image_url
              ? showAddToCartModal?.image_url
              : GRID_PRODUCT_DEFAULT_ICONS[productIndex % 5]
          }
          className={styles.variantImg}
          alt=""
        />
      </div>
      <div className={styles.variantContent}>
        <h5>
          {variantItem.option} -- {variantItem.value}
        </h5>
        <p className={styles.description}>
          {showAddToCartModal.store.currency_symbol}{" "}
          {(
            getVariantPriceWithDiscout(variantItem) *
            (variantQuantity[productIndex].quantity > 0
              ? variantQuantity[productIndex].quantity
              : 1)
          ).toLocaleString()}{" "}
          {variantItem.store_variant_properties[0]?.pricings?.find(
            (item) => item.pricing_type.id === 1,
          )?.discount_rate > 0 && (
            <span className={`error--text ${styles.productPrice}`}>
              <span style={{ textDecoration: "line-through" }}>
                {showAddToCartModal.store.currency_symbol}{" "}
                {(
                  getVariantPriceWithoutDiscout(variantItem) *
                  (variantQuantity[productIndex].quantity > 0
                    ? variantQuantity[productIndex].quantity
                    : 1)
                ).toLocaleString()}
              </span>{" "}
              {
                variantItem.store_variant_properties[0]?.pricings?.find(
                  (item) => item.pricing_type.id === 1,
                )?.discount_rate
              }
              % OFF
            </span>
          )}
        </p>
      </div>
      <div className={styles.selectButtonsContainer}>
        <div
          onClick={() =>
            setVariantQuantity((prev) =>
              prev.map((item) =>
                item.index === productIndex
                  ? {
                      index: productIndex,
                      quantity:
                        prev[productIndex].quantity > 0
                          ? prev[productIndex].quantity - 1
                          : prev[productIndex].quantity,
                    }
                  : item,
              ),
            )
          }
          className={styles.decrement}
          style={{ borderColor: primaryColor }}
        >
          -
        </div>
        <primaryComponents.CartInputField
          value={variantQuantity[productIndex].quantity}
          style={{ height: 32, margin: "0 8px" }}
          updateCart={() => {}}
          onChange={(e) => {
            if (
              variantItem.store_variant_properties[0]?.stock_quantity === 0 &&
              variantItem?.composite_product_type !== "ON_DEMAND"
            )
              return triggerToast("Product is out of stock", "warning");
            if (
              e.target.value >
              variantItem.store_variant_properties[0]?.stock_quantity
            )
              return triggerToast(
                `Quantity can not be more than available quantity of ${variantItem.store_variant_properties[0]?.stock_quantity} for item ( ${variantItem.option} -- ${variantItem.value} )`,
                "warning",
              );

            setVariantQuantity((prev) =>
              prev.map((item) =>
                item.index === productIndex
                  ? {
                      index: productIndex,
                      quantity: e.target.value,
                    }
                  : item,
              ),
            );
          }}
        />
        <div
          onClick={() => {
            if (
              variantItem.store_variant_properties[0]?.stock_quantity === 0 &&
              variantItem?.composite_product_type !== "ON_DEMAND"
            )
              return triggerToast("Product is out of stock", "warning");

            setVariantQuantity((prev) =>
              prev.map((item) =>
                item.index === productIndex
                  ? {
                      index: productIndex,
                      quantity: prev[productIndex].quantity + 1,
                    }
                  : item,
              ),
            );
          }}
          className={styles.increment}
          style={{ background: primaryColor }}
        >
          +
        </div>
      </div>
    </div>
  );
};

const AddToCartModal = ({
  productIndex,
  showAddToCartModal,
  setShowAddToCartModal,
  menuObject,
}) => {
  const containerRef = useRef();
  const dispatch = useDispatch();
  const triggerToast = useContext(ToastContext);

  const [currentImage, setCurrentImage] = useState(
    showAddToCartModal?.media_files[0],
  );
  const [scrollPosition, setScrollPosition] = useState(0);

  const [showMoreDescription, setShowMoreDescription] = useState(false);

  const [quantity, setQuantity] = useState(1);
  const [variantQuantity, setVariantQuantity] = useState(
    new Array(showAddToCartModal?.variants?.length)
      .fill("")
      .map((_, index) => ({ index, quantity: 0 })),
  );

  const primaryColor = menuObject?.primary_colour
    ? menuObject?.primary_colour
    : "#7647ee";

  const handleAddVariantToCart = () => {
    if (
      (showAddToCartModal?.is_service
        ? showAddToCartModal?.store_service_properties[0]?.stock_quantity === 0
        : showAddToCartModal?.store_product_properties[0]?.stock_quantity ===
          0) &&
      showAddToCartModal?.composite_product_type !== "ON_DEMAND"
    )
      return triggerToast("Product is out of stock", "warning");

    variantQuantity.forEach((variant) => {
      if (variant.quantity > 0) {
        dispatch(
          addToMenuCart({
            id: showAddToCartModal.variants[variant.index].id,
            is_variant: true,
            is_service: showAddToCartModal.is_service,
            variant: showAddToCartModal.variants[variant.index],
            productId: showAddToCartModal.id,
            image_url: showAddToCartModal?.image_url,
            name: `${showAddToCartModal.variants[variant.index].option} -- ${
              showAddToCartModal.variants[variant.index].value
            }`,
            quantity: variant.quantity,
            pricing_type:
              showAddToCartModal.variants[variant.index].pricing_type,
            store: showAddToCartModal.store,
            store_variant_properties:
              showAddToCartModal.variants[variant.index]
                .store_variant_properties,
          }),
        );
      }
    });
    triggerToast("Item(s) added to cart", "success");
    setShowAddToCartModal(null);
  };

  const handleHorizontalScroll = (scrollAmount) => {
    const newScrollPosition = scrollPosition + scrollAmount;

    setScrollPosition(newScrollPosition);

    containerRef.current.scrollLeft = newScrollPosition;
  };

  useEffect(() => {
    if (scrollPosition < 0) {
      setScrollPosition(0);
    }
  }, [scrollPosition]);

  return (
    <secondaryComponents.Modal
      width={showAddToCartModal?.variants?.length > 0 ? "729px" : "600px"}
      isActive={showAddToCartModal ? true : false}
      closeModal={() => setShowAddToCartModal(null)}
    >
      {showAddToCartModal?.variants?.length > 0 ? (
        <div className={styles.variantModalContainer}>
          {/* <img
            onClick={() => setShowAddToCartModal(null)}
            src={Icon.close}
            className={styles.closeIcon}
            alt=""
          /> */}
          <div className={styles.imgContainer}>
            <img
              src={
                showAddToCartModal?.media_files.length > 0
                  ? currentImage.url
                  : showAddToCartModal?.image_url
                    ? showAddToCartModal?.image_url
                    : GRID_PRODUCT_DEFAULT_ICONS[productIndex % 5]
              }
              className={styles.productImg}
              alt=""
            />
          </div>
          {showAddToCartModal &&
            showAddToCartModal.media_files &&
            showAddToCartModal.media_files.length > 0 && (
              <div className={styles.baseImagesContainer}>
                <div
                  onClick={() => handleHorizontalScroll(-ITEM_WIDTH)}
                  style={{
                    borderColor: primaryColor,
                    opacity: scrollPosition === 0 ? 0.5 : 1,
                    cursor: scrollPosition === 0 ? "default" : "pointer",
                  }}
                  className={styles.arrowContainer}
                >
                  <ChevronLeftArrow color={primaryColor} />
                </div>
                <div ref={containerRef} className={styles.imagesContainer}>
                  {showAddToCartModal.media_files.map((image) => (
                    <div key={image.id}>
                      <div
                        onClick={() => setCurrentImage(image)}
                        className={styles.imageItem}
                        style={{
                          borderColor:
                            image === currentImage
                              ? "#7647ee"
                              : "rgba(197, 197, 197, 1)",
                        }}
                      >
                        <img src={image.url} alt="" />
                        {image !== currentImage && (
                          <div className={styles.imageCover} />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  onClick={() => handleHorizontalScroll(ITEM_WIDTH)}
                  style={{
                    borderColor: primaryColor,
                  }}
                  className={styles.arrowContainer}
                >
                  <ChevronRightArrow color={primaryColor} />
                </div>
              </div>
            )}

          <div className={styles.contentContainer}>
            <h4>{showAddToCartModal.name}</h4>
            {showAddToCartModal.description && (
              <div className={styles.descriptionContainer}>
                <div className={styles.detailsContainer}>
                  <p>Description</p>
                </div>
                {showMoreDescription ? (
                  <p>
                    {showAddToCartModal.description}
                    <span
                      onClick={() => setShowMoreDescription(false)}
                      style={{ color: primaryColor }}
                    >
                      {"  "}Read Less
                    </span>
                  </p>
                ) : showAddToCartModal.description.length > 120 ? (
                  <p>
                    {truncateString(showAddToCartModal.description, 120)}{" "}
                    <span
                      onClick={() => setShowMoreDescription(true)}
                      style={{ color: primaryColor }}
                    >
                      Read More
                    </span>
                  </p>
                ) : (
                  <p>{showAddToCartModal.description}</p>
                )}
              </div>
            )}
            <div className={styles.variantContainer}>
              <p>Variants</p>
              <div className={styles.variants}>
                {showAddToCartModal.variants.map((variantItem, index) => (
                  <VariantItem
                    key={index}
                    productIndex={index}
                    variantItem={variantItem}
                    variantQuantity={variantQuantity}
                    setVariantQuantity={setVariantQuantity}
                    showAddToCartModal={showAddToCartModal}
                    primaryColor={primaryColor}
                  />
                ))}
              </div>
            </div>
            <primaryComponents.Button
              classNames={`btn btn--primary cover ${styles.button}`}
              isDisabled={
                variantQuantity.find((item) => item.quantity > 0) ? false : true
              }
              onClick={handleAddVariantToCart}
              style={{ background: primaryColor }}
            >
              Add{" "}
              {variantQuantity.length > 1
                ? variantQuantity.reduce(
                    (acc, item) => Number(acc.quantity) + Number(item.quantity),
                  )
                : variantQuantity[0].quantity}{" "}
              item(s) to cart
            </primaryComponents.Button>
          </div>
        </div>
      ) : (
        <div className={styles.modalContainer}>
          {/* <img
            onClick={() => setShowAddToCartModal(null)}
            src={Icon.close}
            className={styles.closeIcon}
            alt=""
          /> */}
          <div className={styles.imgContainer}>
            <img
              src={
                showAddToCartModal?.media_files.length > 0
                  ? currentImage.url
                  : showAddToCartModal?.image_url
                    ? showAddToCartModal?.image_url
                    : GRID_PRODUCT_DEFAULT_ICONS[productIndex % 5]
              }
              className={styles.productImg}
              alt=""
            />
          </div>
          {showAddToCartModal &&
            showAddToCartModal.media_files &&
            showAddToCartModal.media_files.length > 0 && (
              <div className={styles.baseImagesContainer}>
                <div
                  onClick={() => handleHorizontalScroll(-ITEM_WIDTH)}
                  style={{
                    borderColor: primaryColor,
                    opacity: scrollPosition === 0 ? 0.5 : 1,
                    cursor: scrollPosition === 0 ? "default" : "pointer",
                  }}
                  className={styles.arrowContainer}
                >
                  <ChevronLeftArrow color={primaryColor} />
                </div>
                <div ref={containerRef} className={styles.imagesContainer}>
                  {showAddToCartModal.media_files.map((image) => (
                    <div key={image.id}>
                      <div
                        onClick={() => setCurrentImage(image)}
                        className={styles.imageItem}
                        style={{
                          borderColor:
                            image === currentImage
                              ? "#7647ee"
                              : "rgba(197, 197, 197, 1)",
                        }}
                      >
                        <img src={image.url} alt="" />
                        {image !== currentImage && (
                          <div className={styles.imageCover} />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  onClick={() => handleHorizontalScroll(ITEM_WIDTH)}
                  style={{
                    borderColor: primaryColor,
                  }}
                  className={styles.arrowContainer}
                >
                  <ChevronRightArrow color={primaryColor} />
                </div>
              </div>
            )}
          <div className={styles.contentContainer}>
            <h4>{showAddToCartModal.name}</h4>
            <p style={{ color: primaryColor }} className={styles.description}>
              {showAddToCartModal.store.currency_symbol}{" "}
              {(
                getPriceWithDiscout(showAddToCartModal) * quantity
              ).toLocaleString()}{" "}
              {(showAddToCartModal.is_service
                ? showAddToCartModal.store_service_properties[0]?.pricings?.find(
                    (item) => item.pricing_type.id === 1,
                  )?.discount_rate > 0
                : showAddToCartModal.store_product_properties[0]?.pricings?.find(
                    (item) => item.pricing_type.id === 1,
                  )?.discount_rate > 0) && (
                <span className={`error--text ${styles.productPrice}`}>
                  <span style={{ textDecoration: "line-through" }}>
                    {showAddToCartModal.store.currency_symbol}{" "}
                    {(
                      getPriceWithoutDiscout(showAddToCartModal) * quantity
                    ).toLocaleString()}
                  </span>{" "}
                  {showAddToCartModal.is_service
                    ? showAddToCartModal.store_service_properties[0].pricings?.find(
                        (item) => item.pricing_type.id === 1,
                      )?.discount_rate
                    : showAddToCartModal.store_product_properties[0].pricings?.find(
                        (item) => item.pricing_type.id === 1,
                      )?.discount_rate}
                  % OFF
                </span>
              )}
            </p>
            {showAddToCartModal.description && (
              <>
                <div className={styles.detailsContainer}>
                  <p>Description</p>
                </div>
                {showMoreDescription ? (
                  <p>
                    {showAddToCartModal.description}
                    <span
                      onClick={() => setShowMoreDescription(false)}
                      style={{ color: primaryColor }}
                    >
                      {"  "}Read Less
                    </span>
                  </p>
                ) : showAddToCartModal.description.length > 120 ? (
                  <p>
                    {truncateString(showAddToCartModal.description, 120)}{" "}
                    <span
                      onClick={() => setShowMoreDescription(true)}
                      style={{ color: primaryColor }}
                    >
                      Read More
                    </span>
                  </p>
                ) : (
                  <p>{showAddToCartModal.description}</p>
                )}
              </>
            )}
            <div className={styles.separator} />
            <div className={styles.baseContainer}>
              <div className={styles.selectQuantityContainer}>
                <p>Select quantity</p>
                <div className={styles.selectButtonsContainer}>
                  <div
                    onClick={() =>
                      setQuantity((prev) => (prev > 1 ? prev - 1 : prev))
                    }
                    className={styles.decrement}
                    style={{ borderColor: primaryColor }}
                  >
                    -
                  </div>
                  <primaryComponents.CartInputField
                    value={quantity}
                    style={{ height: 32, margin: "0 8px" }}
                    updateCart={() => {}}
                    onChange={(e) => {
                      if (
                        (showAddToCartModal.is_service
                          ? showAddToCartModal?.store_service_properties[0]
                              ?.stock_quantity === 0
                          : showAddToCartModal?.store_product_properties[0]
                              ?.stock_quantity === 0) &&
                        showAddToCartModal?.composite_product_type !==
                          "ON_DEMAND"
                      )
                        return triggerToast(
                          "Product is out of stock",
                          "warning",
                        );
                      if (
                        e.target.value >
                        (showAddToCartModal.is_service
                          ? showAddToCartModal?.store_service_properties[0]
                              ?.stock_quantity === 0
                          : showAddToCartModal?.store_product_properties[0]
                              ?.stock_quantity === 0)
                      )
                        return triggerToast(
                          `Quantity can not be more than available quantity of ${showAddToCartModal.store_properties.stock_quantity} for item ( ${showAddToCartModal.product.name} )`,
                          "warning",
                        );

                      setQuantity(e.target.value);
                    }}
                  />
                  <div
                    onClick={() => {
                      if (
                        (showAddToCartModal.is_service
                          ? showAddToCartModal?.store_service_properties[0]
                              ?.stock_quantity === 0
                          : showAddToCartModal?.store_product_properties[0]
                              ?.stock_quantity === 0) &&
                        showAddToCartModal?.composite_product_type !==
                          "ON_DEMAND"
                      )
                        return triggerToast(
                          "Product is out of stock",
                          "warning",
                        );

                      setQuantity((prev) => prev + 1);
                    }}
                    className={styles.increment}
                    style={{ background: primaryColor }}
                  >
                    +
                  </div>
                </div>
              </div>
              <div className={styles.button}>
                <primaryComponents.Button
                  style={{ background: primaryColor }}
                  classNames={"btn btn--primary cover"}
                  isDisabled={quantity < 1}
                  onClick={() => {
                    if (
                      (showAddToCartModal.is_service
                        ? showAddToCartModal?.store_service_properties[0]
                            ?.stock_quantity === 0
                        : showAddToCartModal?.store_product_properties[0]
                            ?.stock_quantity === 0) &&
                      showAddToCartModal?.composite_product_type !== "ON_DEMAND"
                    )
                      return triggerToast("Product is out of stock", "warning");

                    dispatch(
                      addToMenuCart({
                        id: showAddToCartModal?.id,
                        is_variant: false,
                        is_service: showAddToCartModal.is_service,
                        productId: showAddToCartModal?.id,
                        image_url: showAddToCartModal?.image_url,
                        name: showAddToCartModal?.name,
                        quantity,
                        pricing_type: showAddToCartModal?.pricing_type,
                        store: showAddToCartModal?.store,
                        store_product_properties:
                          showAddToCartModal?.store_product_properties,
                        store_service_properties:
                          showAddToCartModal?.store_service_properties,
                      }),
                    );
                    triggerToast("Item added to cart", "success");
                    setShowAddToCartModal(null);
                  }}
                >
                  {`Add ${quantity} item${quantity > 1 ? "(s)" : ""} to cart (${
                    showAddToCartModal?.store.currency_code
                  } ${getPriceWithDiscout(showAddToCartModal) * quantity})`}
                </primaryComponents.Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </secondaryComponents.Modal>
  );
};

export default AddToCartModal;
