import { useState, useId } from "react";
import { useCombobox } from "downshift";
import { useSpinDelay } from "spin-delay";
import { getOrderList } from "../../../services/inventory";

export const useOrderComboBox = ({ onChange }) => {
  const id = useId();
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const combobox = useCombobox({
    id,
    onSelectedItemChange: ({ selectedItem }) => {
      onChange(selectedItem);
      setSelectedOrder(selectedItem);
    },
    items: orders,
    itemToString: (item) => (item ? item?.id : ""),
    onInputValueChange: (changes) => {
      if (changes.inputValue) {
        handleFetchOrders(changes.inputValue);
      }
    },
  });

  const displayMenu = combobox.isOpen && orders.length > 0;
  const showSpinner = useSpinDelay(isLoading, {
    delay: 150,
    minDuration: 500,
  });

  const handleFetchOrders = (query) => {
    setIsLoading(true);
    getOrderList(`?query=${query}`)
      .then((response) => {
        setOrders(response.data?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  return {
    combobox,
    displayMenu,
    orders,
    selectedOrder,
    isLoading,
    showSpinner,
  };
};

export const formatOrderDate = (dateString) => {
  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  const formattedMonth = months[month];

  return `${day}-${formattedMonth}-${year}`;
};
