import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getUrlQuerysection } from "../../../../helpers";
import { useGetExpenses } from "../../../../hooks/api/queries/useExpenses";

const useExpenses = () => {
  const queries = getUrlQuerysection();
  const [search, setSearch] = useSearchParams();
  const [isCreateExpense, setIsCreateExpense] = useState(
    search.get("isCreateExpense") === "true" ? true : false,
  );

  const {
    isLoading: isFetchingExpenses,
    data: expenses,
    refetch: handleFetchExpenses,
  } = useGetExpenses(queries);

  const gotoPage = (pageNumber) => {
    search.set("page", pageNumber);
    setSearch(search, { replace: true });
  };

  const searchExpenses = (value) => {
    if (value.length === 0) search.delete("query");
    else search.set("query", value);
    search.delete("page");
    setSearch(search, { replace: true });
  };

  return {
    isFetchingExpenses,
    metaDetails: expenses?.meta || {},
    search,
    isCreateExpense,
    expenses: expenses?.data || [],
    setIsCreateExpense,
    handleFetchExpenses,
    gotoPage,
    searchExpenses,
  };
};

export default useExpenses;
