export const PlusCircle = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00016 15.1663C11.6821 15.1663 14.6668 12.1816 14.6668 8.49967C14.6668 4.81778 11.6821 1.83301 8.00016 1.83301C4.31826 1.83301 1.3335 4.81778 1.3335 8.49967C1.3335 12.1816 4.31826 15.1663 8.00016 15.1663Z"
        fill="white"
        stroke="#0B0C0E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.6665 8.50033H11.3332M7.99984 5.16699V11.8337V5.16699Z"
        stroke="#0B0C0E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
