import styles from "./ProductTableActions.module.scss";
import Icon from "../../../../assets/icons";
import { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getPlatform } from "../../../../helpers";
import { platformHandler } from "../../../../hooks/context";
import secondaryComponents from "../../../secondaryComponents";
import { pathConstants } from "../../../../routes/pathContants";

const ProductTableActions = ({
  setIsOpenDeleteDialog,
  setSelectedProduct,
  product,
  isMobile = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef();
  const { platform } = useContext(platformHandler);
  const [search] = useSearchParams();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <span
      className={`${styles.productTableOption} ${
        isMobile ? styles.isMobile : ""
      }`}
      ref={menuRef}
    >
      <span
        style={{ cursor: "pointer" }}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <img src={Icon.option_alt} alt="" />
      </span>

      {isOpen ? (
        <span className={styles.dropdrown}>
          <secondaryComponents.NavigateWithPermission
            permissionParentKey={"Inventory Management"}
            permissionChildKey="can_delete_products"
          >
            <p
              onClick={() => {
                setIsOpen(false);
                setIsOpenDeleteDialog(true);
                setSelectedProduct(product);
              }}
              className="error--text"
            >
              Delete
            </p>
          </secondaryComponents.NavigateWithPermission>
          <secondaryComponents.NavigateWithPermission
            permissionParentKey={"Inventory Management"}
            permissionChildKey="can_view_stock_history"
          >
            <p
              onClick={() => {
                setIsOpen(false);
                navigate(
                  `${getPlatform(platform)}/inventory/products/${
                    product?.id
                  }/stock-history?product_id=${product?.id}`,
                );
              }}
            >
              Stock History
            </p>
          </secondaryComponents.NavigateWithPermission>
          <p
            onClick={() => {
              setIsOpen(false);
              navigate(
                pathConstants.INVENTORY_PRODUCT_ITEM_TRACKING({
                  id: product?.id,
                }) + `?store_id=${search.get("store_id")}&status=available`,
              );
            }}
          >
            Track Items
          </p>
        </span>
      ) : null}
    </span>
  );
};

export default ProductTableActions;
