/* eslint-disable no-useless-escape */
import "./SplitPayment.scss";
import SplitPaymentLogic from "./useSplitPaymentLogic";
import SplitPaymentValidation from "./Validation";
import primaryComponents from "../../../primaryComponents";
import { Controller } from "react-hook-form";
import { formatCurrency } from "../../../../helpers";
import { useSelector } from "react-redux";
import AddCustomerSection from "../AddCustomerSection";

const SplitPayment = ({
  closePaymentSession,
  openTransactionsession,
  orderDetails,
}) => {
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  const {
    isConfirmingPayment,
    cashAmount,
    transferAmount,
    cardAmount,
    creditAmount,
    customerDetails,
    setCustomerDetails,
    setCashAmount,
    setTransferAmount,
    setCardAmount,
    setCreditAmount,
    setRepaymentDate,
    payViaSplit,
  } = SplitPaymentLogic({
    closePaymentSession,
    openTransactionsession,
    orderDetails,
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = SplitPaymentValidation(
    cashAmount,
    cardAmount,
    creditAmount,
    transferAmount,
  );

  return (
    <>
      <div className="cash-payment mt-4 mb-3">
        <div>
          <p className="split-payment-confirm">
            <span className="text-danger">*</span>Please confirm the amounts
            that are being split across these methods of payment
          </p>
          <div className="split-payment-group mt-2">
            <div className="split-payment-input">
              <label
                className="split-payment-input__label"
                htmlFor="cashAmount"
              >
                Cash Amount
              </label>
              <div className="split-payment-input__field">
                <Controller
                  control={control}
                  name="cashAmount"
                  render={({ field: { onChange } }) => (
                    <primaryComponents.AmountField
                      name="cashAmount"
                      placeholder="Enter Amount"
                      value={cashAmount}
                      onChange={({ target: { value } }) => {
                        onChange(value);
                        setCashAmount(value);
                      }}
                      errorMessage={errors.cashAmount}
                    />
                  )}
                />
              </div>
            </div>

            <div className="split-payment-input">
              <label
                className="split-payment-input__label"
                htmlFor="transferAmount"
              >
                Transfer Amount
              </label>
              <div className="split-payment-input__field">
                <Controller
                  control={control}
                  name="transferAmount"
                  render={({ field: { onChange } }) => (
                    <primaryComponents.AmountField
                      name="transferAmount"
                      placeholder="Enter Amount"
                      value={transferAmount}
                      onChange={({ target: { value } }) => {
                        onChange(value);
                        setTransferAmount(value);
                      }}
                      errorMessage={errors.transferAmount}
                    />
                  )}
                />
              </div>
            </div>

            <div className="split-payment-input">
              <label
                className="split-payment-input__label"
                htmlFor="cardAmount"
              >
                Card Amount
              </label>
              <div className="split-payment-input__field">
                <Controller
                  control={control}
                  name="cardAmount"
                  render={({ field: { onChange } }) => (
                    <primaryComponents.AmountField
                      name="cardAmount"
                      placeholder="Enter Amount"
                      value={cardAmount}
                      onChange={({ target: { value } }) => {
                        onChange(value);
                        setCardAmount(value);
                      }}
                      errorMessage={errors.cardAmount}
                    />
                  )}
                />
              </div>
            </div>

            <div className="split-payment-input">
              <label
                className="split-payment-input__label"
                htmlFor="creditAmount"
              >
                Credit Amount
              </label>

              <div className="split-payment-input__field">
                <Controller
                  control={control}
                  name="creditAmount"
                  render={({ field: { onChange } }) => (
                    <primaryComponents.AmountField
                      name="creditAmount"
                      placeholder="Enter Amount"
                      value={creditAmount}
                      onChange={({ target: { value } }) => {
                        onChange(value);
                        setCreditAmount(value);
                      }}
                      errorMessage={errors.creditAmount}
                    />
                  )}
                />
              </div>
            </div>

            <div className="d-flex justify-content-between w-100">
              <h6 className="mb-0 purple--text">Balance</h6>
              <h6 className="mb-0 purple--text">
                {formatCurrency({
                  value:
                    Number(orderDetails?.total_amount || 0) -
                    (Number(cardAmount) +
                      Number(transferAmount) +
                      Number(creditAmount) +
                      Number(cashAmount)),
                  currencyCode: currency_code,
                })}
              </h6>
            </div>

            {Number(orderDetails?.total_amount || 0) <
            Number(cardAmount) +
              Number(transferAmount) +
              Number(creditAmount) +
              Number(cashAmount) ? (
              <p className="small-text-size error--text mb-0">
                The entered amount exceeds the total amount payable.
              </p>
            ) : null}

            {Number(orderDetails?.total_amount || 0) >
            Number(cardAmount) +
              Number(transferAmount) +
              Number(creditAmount) +
              Number(cashAmount) ? (
              <p className="small-text-size error--text mb-0">
                The entered amount is lower than the total amount payable.
              </p>
            ) : null}
          </div>
        </div>
        {creditAmount > 0 && (
          <div>
            <div className="my-4">
              <AddCustomerSection
                setCustomerDetails={setCustomerDetails}
                customerDetails={customerDetails}
              />
            </div>

            <primaryComponents.InputField
              type="date"
              label="Repayment date"
              isRequired={false}
              name="repayment_date"
              onChange={({ target: { value } }) => {
                setRepaymentDate(value);
              }}
            />
          </div>
        )}
      </div>
      <div className="card-payment__bottom">
        <primaryComponents.Button
          classNames="btn btn--primary cover"
          isLoading={isConfirmingPayment}
          loadingText="Confirming..."
          isDisabled={isConfirmingPayment}
          onClick={handleSubmit(payViaSplit)}
        >
          Confirm
        </primaryComponents.Button>
      </div>
    </>
  );
};
export default SplitPayment;
