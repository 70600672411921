import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import { useExpanded, useTable } from "react-table";
import Icon from "../../../assets/icons";
import { formatCurrency } from "../../../helpers";
import primaryComponents from "../../primaryComponents";
import styles from "./InvoicesTable.module.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { pathConstants } from "../../../routes/pathContants";

const useInvoicesTable = ({ invoicesList }) => {
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  const [invoices, setInvoices] = useState([]);
  const [isUpdateCredit, setIsUpdateCredit] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState({});

  useEffect(() => {
    const result = invoicesList?.map((invoice) => {
      const subResult = {
        ...invoice,
        subRows:
          invoice?.invoice_payments?.length > 0
            ? invoice?.invoice_payments?.map((invoice_payment, index) => ({
                ...invoice_payment,
                invoice_number: `Repayment ${
                  invoice?.invoice_payments.length - index
                }`,
                invoice_date: invoice_payment?.created_at,
                invoice_due_date: null,
                total: null,
                payment_date: invoice_payment?.created_at,
                payment_method: invoice_payment?.payment_method,
                amount_paid: invoice_payment?.amount,
                outstanding_balance: invoice_payment?.outstanding_balance_after,
              }))
            : null,
      };
      return subResult;
    });

    setInvoices(result);
  }, [invoicesList]);

  const columns = useMemo(
    () => [
      {
        Header: "Invoice Number",
        accessor: "id",
        Cell: ({ row, cell: { value } }) => {
          return (
            <span
              style={{ display: "flex", alignItems: "center", gap: "15px" }}
            >
              {row?.depth !== 1 ? (
                <Link
                  style={{ fontSize: "12px" }}
                  className="purple--text"
                  to={pathConstants.INVENTORY_INVOICE_DETAILS({ id: value })}
                >
                  <span>{value}</span>
                </Link>
              ) : (
                <span className={row?.depth !== 1 ? null : styles.repaymentTag}>
                  {row?.original?.invoice_number}
                </span>
              )}
              {row?.canExpand && (
                <span
                  {...row?.getToggleRowExpandedProps({
                    style: {
                      // We can even use the row.depth property
                      // and paddingLeft to indicate the depth
                      // of the row
                      // paddingRight: `${row.depth * 2}rem`,
                    },
                  })}
                >
                  <span
                    style={{
                      width: "14px",
                      height: "14px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background: "#E4DAFC",
                      borderRadius: "2px",
                      border: "1px solid #7647ee",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={row?.isExpanded ? "angle-up" : "angle-down"}
                      color="#7647ee"
                    />
                  </span>
                </span>
              )}
            </span>
          );
        },
      },
      {
        Header: "Date",
        accessor: "invoice_date",
        Cell: ({ cell: { value } }) => {
          return value ? new Date(value).toLocaleString("en-us") : null;
        },
      },
      {
        Header: "Amount Paid",
        accessor: "amount_paid",
        Cell: ({ cell: { value } }) =>
          formatCurrency({
            value: value ? value : 0,
            currencyCode: currency_code,
          }),
      },
      {
        Header: "Outstanding Balance",
        accessor: "outstanding_balance",
        Cell: ({ cell: { value, row } }) => {
          if (value !== null && value !== undefined)
            return (
              <span
                style={{
                  color: value <= 0 ? "green" : "red",
                }}
              >
                {value}
              </span>
            );

          if (row?.canExpand && !value)
            return <img src={Icon.variantCollapse} alt="" />;
        },
      },
      {
        Header: "Invoice Due Date",
        accessor: "invoice_due_date",
        Cell: ({ cell: { value } }) => {
          return value ? new Date(value).toLocaleString("en-us") : null;
        },
      },
      {
        Header: "Invoice Amount",
        accessor: "total",
        Cell: ({ cell: { value } }) =>
          value
            ? formatCurrency({ value: value, currencyCode: currency_code })
            : null,
      },
      {
        Header: "Actions",
        accessor: "action",
        Cell: ({ cell: { row } }) =>
          row?.depth === 1 ? null : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                position: "relative",
              }}
            >
              <primaryComponents.Button
                classNames="btn btn--primary smallBtn"
                isDisabled={row?.original?.outstanding_balance === 0}
                onClick={() => {
                  setSelectedInvoice(row?.original);
                  setIsUpdateCredit(true);
                }}
              >
                Update Credit
              </primaryComponents.Button>
            </div>
          ),
      },
    ],
    [],
  );
  const instance = useTable(
    {
      columns,
      data: invoices,
    },
    useExpanded,
  );

  return {
    instance,
    isUpdateCredit,
    selectedInvoice,
    setIsUpdateCredit,
    setSelectedInvoice,
  };
};
export default useInvoicesTable;
