import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    expenseName: yup.string().required("Expense name is required"),
    store: yup.string().required("Store is required"),
    amount: yup
      .number()
      .min(0, "The minimum amount allowed is 0")
      .nullable(true)
      .transform((_, val) => (val === "" ? null : Number(val)))
      .typeError("Amount must be a number")
      .required("Amount is required"),
    category: yup.string().required("Category is required"),
    paymentMethod: yup.string().required("Payment method is required"),
    expenseDate: yup.string().required("Expense date is required"),
  })
  .required("All fields are required");

const Validation = ({ expenseDetails }) => {
  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      expenseName: expenseDetails?.name,
      store: expenseDetails?.store,
      amount: expenseDetails?.amount,
      category: expenseDetails?.category,
      paymentMethod: expenseDetails?.payment_method,
      expenseDate: expenseDetails?.expense_date,
    },
  });
};

export default Validation;
