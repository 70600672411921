import httpClient from "../axiosBase/axiosHandler";

export const getCustomers = (queries) => {
  const response = httpClient.get(`/customers/${queries}`);
  return response;
};

export const createCustomer = (data) => {
  return httpClient.post("/customers/", { ...data });
};

export const updateCustomer = ({ id, data }) => {
  return httpClient.patch(`/customers/${id}/`, { ...data });
};

export const removeCustomer = ({ id }) => {
  return httpClient.delete(`/customers/${id}`);
};

export const getCustomer = (customerId) => {
  return httpClient.get(`/customers/${customerId}/`);
};

export const getCustomerOrder = (customerId) => {
  return httpClient.get(`/order/?customer_id=${customerId}`);
};

export const getCustomerTransactions = (customerId) => {
  return httpClient.get(`/order/?all_business=true&customer_id=${customerId}`);
};

export const addLoyaltyCustomer = (payload) => {
  return httpClient.post("/loyalty/customer-profile/", { ...payload });
};

export const getCustomersHistory = (queries) => {
  const response = httpClient.get(
    `/loyalty/customers/customer-history/${queries}`,
  );
  return response;
};

export const getLoyaltyCustomers = () => {
  return httpClient.get("/loyalty/customer-profile/");
};

export const getLoyaltyCustomerDetails = (id) => {
  return httpClient.get(`/loyalty/customers/${id}/`);
};

export const toggleCustomerLoyalty = (id) => {
  return httpClient.post(`/loyalty/customers/${id}/update-loyalty-status/`, {});
};

export const getCustomersNames = (customer) => {
  return httpClient.get(
    `/customers/?all_business=1&per_page=50&query=${customer}`,
  );
};

export const getCustomerLoyaltyActivities = (customer_id) => {
  return httpClient.get(
    `/loyalty/customers/customer-history/?customer_id=${customer_id}`,
  );
};

export const toggleCustomerLoyaltyEligibity = (id) => {
  return httpClient.patch(`/customers/${id}/toggle-loyalty-eligibility/`);
};
