import { useTable } from "react-table";
import { useMemo } from "react";
import { truncateString } from "../../../../../../../helpers";
import Icon from "../../../../../../../assets/icons";
import CopyToClipboard from "react-copy-to-clipboard";
import "./styles.scss";

const useMenuDetails = ({ foodMenus, onCopy }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Storefront",
        accessor: "name",
        Cell: ({ cell: { value } }) => <span>{truncateString(value, 50)}</span>,
      },
      {
        Header: "Store",
        accessor: "store",
        Cell: ({ cell: { value } }) => <span>{value.name}</span>,
      },
      {
        Header: "URL",
        accessor: "slug_url",
        Cell: ({ cell: { value } }) => (
          <div className={"d-flex align-items-center"} style={{ gap: "15px" }}>
            <a
              href={`${window.location.origin}/storefront/${
                value?.split("/menu/")[1]
              }`}
              className="gray--text"
              target="_blank"
              rel="noreferrer"
            >
              {truncateString(
                `${window.location.origin}/storefront/${
                  value?.split("/menu/")[1]
                }`,
                15,
              )}{" "}
            </a>
            <CopyToClipboard
              text={`${window.location.origin}/storefront/${
                value?.split("/menu/")[1]
              }`}
              onCopy={() => onCopy()}
            >
              <span className={"purple--text"} style={{ cursor: "pointer" }}>
                <img width="15px" className="mr-2" src={Icon.copy} alt="" />
                <span className="small-text-size">Copy Url</span>
              </span>
            </CopyToClipboard>
          </div>
        ),
      },
    ],
    [],
  );

  const instance = useTable({ columns, data: foodMenus || [] });

  return {
    instance,
  };
};

export default useMenuDetails;
