import { useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { getStoreList } from "../../../../services/inventory";
import { setStoreList } from "../../../../store/modules/profile";

const useStores = () => {
  const [isCreateStoreModal, setIsCreateStoreModal] = useState(false);
  const dispatch = useDispatch();

  const {
    isLoading: isFetchingStores,
    data: storeList,
    refetch: handleFetchStores,
  } = useQuery(
    ["storeList"],
    () => getStoreList().then((res) => res?.data?.data),
    {
      onSuccess: (data) => {
        dispatch(setStoreList(data));
      },
    },
  );

  return {
    isCreateStoreModal,
    storeList: storeList || [],
    isFetchingStores,
    handleFetchStores,
    setIsCreateStoreModal,
  };
};

export default useStores;
