import styles from "./StoreForm.module.scss";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "..";
import { useSelector } from "react-redux";
import useStoreForm from "./useStoreForm";
import Validation from "./Validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CautionIcon } from "../../../assets/svgIcons";

const StoreForm = ({
  storeInfo = null,
  isEditMode = false,
  handleFetchStores = () => {},
  closeWidget = () => {},
}) => {
  const {
    isAddingStore,
    isEditingStore,
    storeDetails,
    category_list,
    setStoreDetails,
    submitStoreInfo,
  } = useStoreForm({ storeInfo, isEditMode, handleFetchStores, closeWidget });
  const {
    handleSubmit,
    control,
    register,
    trigger,
    formState: { errors },
  } = Validation({ storeDetails });
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  return (
    <div className={styles.storeForm}>
      {isEditMode && (
        <primaryComponents.Button
          classNames="btn btn--outline"
          onClick={() => closeWidget()}
        >
          <FontAwesomeIcon
            icon="angle-left"
            className="mr-3"
            style={{ fontSize: "18px" }}
          />
          <span>Back</span>
        </primaryComponents.Button>
      )}
      <h4 className="font-weight-semibold text-center">
        {isEditMode ? "Update Store" : "Add New Store"}
      </h4>
      {/* form section */}
      <form
        onSubmit={handleSubmit(submitStoreInfo)}
        className={styles.formSection}
      >
        <div className={styles.inputSection}>
          <primaryComponents.InputFieldV2
            control={control}
            classNames="white"
            name="storeName"
            label="Store Name"
            placeholder="Enter your store name"
            isRequired={true}
            value={storeDetails.name}
            onChange={({ target: { value } }) => {
              setStoreDetails((prev) => ({
                ...prev,
                name: value,
              }));
            }}
            errorMessage={errors.storeName}
            onKeyUp={() =>
              errors.storeName !== undefined && trigger("storeName")
            }
            register={register}
          />

          <primaryComponents.InputFieldV2
            control={control}
            classNames="white"
            name="storeAddress"
            label="Store Address"
            placeholder="Enter your store address"
            isRequired={true}
            value={storeDetails.address}
            onChange={({ target: { value } }) => {
              setStoreDetails((prev) => ({
                ...prev,
                address: value,
              }));
            }}
            errorMessage={errors.storeAddress}
            onKeyUp={() =>
              errors.storeAddress !== undefined && trigger("storeAddress")
            }
            register={register}
          />
        </div>

        <div className={styles.inputSection}>
          <div className="w-100">
            <primaryComponents.SelectFieldV2
              control={control}
              name="storeCategory"
              label="Select store category"
              isRequired
              value={storeDetails?.store_category_label || "Select category"}
              onChange={(value) => {
                setStoreDetails((prev) => ({
                  ...prev,
                  store_category: value,
                }));
              }}
              options={category_list}
              nameKey="name"
              idKey="id"
              errorMessage={errors.storeCategory}
            />
          </div>

          <div className="w-100">
            <primaryComponents.PhoneNumberInputFieldV2
              control={control}
              classNames="white"
              name="storePhone"
              label="Phone Number"
              placeholder="Enter phone number"
              //defaultValue={storeDetails?.phone_number}
              value={storeDetails?.phone_number}
              onChange={({ target: { value } }) => {
                setStoreDetails((prev) => ({
                  ...prev,
                  phone_number: value,
                }));
              }}
              errorMessage={errors.storePhone}
              onKeyUp={() =>
                errors.storePhone !== undefined && trigger("storePhone")
              }
              register={register}
              phoneCodeEditable={true}
              onChangePhoneCode={(val) => {
                setStoreDetails((prev) => ({
                  ...prev,
                  phone_code: val,
                }));
              }}
              countryCode={storeDetails?.phone_code}
            />
          </div>
        </div>
        <div className={styles.inputSection}>
          <div className="w-100">
            <primaryComponents.SelectFieldV2
              control={control}
              name="isWarehouse"
              label="Store to serve as a warehouse?"
              nameKey="label"
              idKey="value"
              isRequired={true}
              value={storeDetails?.is_warehouse ? "Yes" : "No"}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
              onChange={(value) => {
                setStoreDetails((prev) => ({
                  ...prev,
                  is_warehouse: value,
                }));
              }}
              errorMessage={errors?.isWarehouse}
            />
            <p className="gray--text smallest-text-size">
              <span className="error--text">Please Note: </span>Set up your
              store’s Discount will be applied on every product available in
              this store
            </p>
          </div>
          <div className="w-100">
            <primaryComponents.InputFieldV2
              control={control}
              classNames="white"
              name="vat"
              label="VAT Rate(%)"
              placeholder="Enter vat rate"
              value={storeDetails?.vat}
              onChange={({ target: { value } }) => {
                setStoreDetails((prev) => ({
                  ...prev,
                  vat: value,
                }));
              }}
              type="number"
              min="0"
              max="100"
              step="0.01"
              errorMessage={errors.vat}
              onKeyUp={() => errors.vat !== undefined && trigger("vat")}
              register={register}
            />
          </div>
          <div className="w-100">
            <primaryComponents.InputFieldV2
              control={control}
              classNames="white"
              name="credit_limit"
              label={
                <span className="d-flex" style={{ gap: "4px" }}>
                  Set Credit Limit
                  <secondaryComponents.ToolTipV2
                    tipText={
                      "<span>Credit Limit <br><br> Set the max limit a customer can owe<br>for a purchase.</span>"
                    }
                    className="ml-2"
                  >
                    <CautionIcon />
                  </secondaryComponents.ToolTipV2>
                </span>
              }
              placeholder={currency_code + " 0"}
              value={storeDetails?.credit_limit}
              onChange={({ target: { value } }) => {
                setStoreDetails((prev) => ({
                  ...prev,
                  credit_limit: value,
                }));
              }}
              type="number"
              errorMessage={errors.credit_limit}
              onKeyUp={() =>
                errors.credit_limit !== undefined && trigger("credit_limit")
              }
              register={register}
            />
            <p className="gray--text smallest-text-size">
              <span className="error--text">Please Note: </span>This credit
              limit will be applied on all customers that purchase from this
              store
            </p>
          </div>
        </div>

        {/* bills  */}
        <h4 className="text-center mt-3 mb-3">
          Activate features You Need For This Store
        </h4>
        <div className={styles.cardWrap}>
          <div className={styles.customCheckCard}>
            <div className={styles.customCheckCard__top}>
              <div>
                <h5>Bills</h5>
                <p className="small-text-size">
                  Activate this to generate bills for your customer’s order
                  before payment
                </p>
              </div>
              <primaryComponents.Switch
                isChecked={storeDetails?.allow_generate_bill}
                name="bills"
                id="storeBill"
                onChange={(value) =>
                  setStoreDetails((prev) => ({
                    ...prev,
                    allow_generate_bill: value,
                  }))
                }
              />
            </div>
            <p className="smallest-text-size mt-3">
              <span className="font-weight-bold">NB: </span>Recommended for
              businesses in the hospitality industry e.g Restaurants, lounges,
              etc.
            </p>
          </div>

          {/* table */}
          <div className={styles.customCheckCard}>
            <div className={styles.customCheckCard__top}>
              <div>
                <h5>Tables</h5>
                <p className="small-text-size">
                  Activate this to create and assign tables on checkout for
                  orders
                </p>
              </div>
              <primaryComponents.Switch
                isChecked={storeDetails?.allow_table}
                name="tables"
                id="storeTable"
                onChange={(value) =>
                  setStoreDetails((prev) => ({
                    ...prev,
                    allow_table: value,
                  }))
                }
              />
            </div>
            <p className="smallest-text-size mt-3">
              <span className="font-weight-bold">NB: </span>Recommended for
              businesses in the hospitality industry e.g Restaurants, lounges,
              etc.
            </p>
          </div>
        </div>

        <div className={styles.cardWrap}>
          {/* section */}
          <div className={styles.customCheckCard}>
            <div className={styles.customCheckCard__top}>
              <div>
                <h5>Kitchen Display</h5>
                <p className="small-text-size">
                  Turn this on so orders can be directed to the right areas in
                  the kitchen department
                </p>
              </div>
              <primaryComponents.Switch
                isChecked={storeDetails?.allow_section}
                name="sections"
                id="storeSection"
                onChange={(value) =>
                  setStoreDetails((prev) => ({
                    ...prev,
                    allow_section: value,
                  }))
                }
              />
            </div>
            <p className="smallest-text-size mt-3">
              <span className="font-weight-bold">NB: </span>Recommended for
              businesses in the hospitality industry e.g Restaurants, lounges,
              etc.
            </p>
          </div>

          {/* waybills */}
          <div className={styles.customCheckCard}>
            <div className={styles.customCheckCard__top}>
              <div>
                <h5>Waybills</h5>
                <p className="small-text-size">
                  Activate this to generate and view waybills for orders on
                  checkout and later
                </p>
              </div>
              <primaryComponents.Switch
                isChecked={storeDetails?.allow_waybill}
                name="waybills"
                id="storeWaybill"
                onChange={(value) =>
                  setStoreDetails((prev) => ({
                    ...prev,
                    allow_waybill: value,
                  }))
                }
              />
            </div>
            <p className="smallest-text-size mt-3">
              <span className="font-weight-bold">NB: </span>Recommended for
              businesses with a warehouse
            </p>
          </div>
        </div>
        <div className={styles.cardWrap}>
          {/* prescriptions */}
          <div className={styles.customCheckCard}>
            <div className={styles.customCheckCard__top}>
              <div>
                <h5>Prescription</h5>
                <p className="small-text-size ">
                  Activate this to generate and view prescriptions for orders on
                  checkout
                </p>
              </div>
              <primaryComponents.Switch
                isChecked={storeDetails?.allow_prescription}
                name="prescriptions"
                id="storePrescription"
                onChange={(value) =>
                  setStoreDetails((prev) => ({
                    ...prev,
                    allow_prescription: value,
                  }))
                }
              />
            </div>
            <p className="smallest-text-size mt-3">
              <span className="font-weight-bold">NB: </span>Recommended for
              Pharmacies
            </p>
          </div>

          {/* custom pricing */}
          <div className={styles.customCheckCard}>
            <div className={styles.customCheckCard__top}>
              <div>
                <h5>Custom Pricing</h5>
                <p className="small-text-size">
                  Enable this feature to let your cashiers effortlessly update
                  product prices on the spot while serving customers
                </p>
              </div>
              <primaryComponents.Switch
                isChecked={storeDetails?.allow_custom_price}
                name="customPricing"
                id="storeCustomPricing"
                onChange={(value) =>
                  setStoreDetails((prev) => ({
                    ...prev,
                    allow_custom_price: value,
                  }))
                }
              />
            </div>
            <p className="smallest-text-size mt-3">
              <span className="font-weight-bold">NB: </span>Recommended for
              businesses with a warehouse
            </p>
          </div>
        </div>

        <div className="d-flex justify-content-between mt-3">
          {!isEditMode ? (
            <primaryComponents.Button
              type="button"
              classNames={"btn btn--outline"}
              onClick={closeWidget}
            >
              Cancel
            </primaryComponents.Button>
          ) : (
            <div></div>
          )}
          <primaryComponents.Button
            classNames={"btn btn--primary"}
            isLoading={isEditMode ? isEditingStore : isAddingStore}
            isDisabled={isEditMode ? isEditingStore : isAddingStore}
            loadingText={isEditMode ? "Updating..." : "Creating..."}
          >
            {isEditMode ? "Update" : "Create Store"}
          </primaryComponents.Button>
        </div>
      </form>
    </div>
  );
};

export default StoreForm;
