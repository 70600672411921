import { useContext, useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import {
  addNewPricingForStorePropertyHelper,
  cleanObject,
  createVariantPropertiesHelper,
  deepCopyFunction,
  getRandomIntInclusive,
  removePricingForStorePropertyHelper,
  updateStorePropertiesHelper,
  updateVariantStorePropertiesHelper,
  addNewVariantOptionHelper,
  removeVariantPropertyHelper,
  addNewPricingPropertyForVariantStorePropertyHelper,
  removePricingPropertyForVariantStorePropertyHelper,
} from "../../../helpers";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import {
  createCategory,
  deleteProductImage,
  uploadProductImage,
} from "../../../services/inventory";
import {
  useGetPricingTypes,
  useGetProductCategoryList,
  useGetStockUnits,
} from "../../../hooks/api/queries/useInventory";
import { v4 as uuidv4 } from "uuid";
import { useDeleteVariantFromBackend } from "../../../hooks/api/mutations/useInventory";
import { useGetSections } from "../../../hooks/api/queries/useSection";
import { useParams } from "react-router-dom";

const booleanLabel = {
  false: "No",
  true: "Yes",
};

const reLabelDefault = (stores, nameKey = "name", idKey = "id") => {
  let newArray = [];
  stores?.forEach((store) => {
    newArray.push({ value: store?.[idKey], label: store?.[nameKey] });
  });
  return newArray;
};

const reArrangeVariantStoreProperties = (variantStoreProperties) => {
  const newVariantStoreProperties = [];
  variantStoreProperties?.forEach((variantStoreProperty) => {
    const newStoreProperty = {
      ...variantStoreProperty,
      cost_price: `${variantStoreProperty?.cost_price || ""}`,
      pricings: variantStoreProperty?.pricings?.map((pricingProperty) => {
        return {
          price: pricingProperty?.price,
          discount_rate: pricingProperty?.discount_rate,
          pricing_type_id: pricingProperty?.pricing_type?.id,
          uuid: uuidv4(),
        };
      }),
      minimum_stock_quantity:
        variantStoreProperty?.minimum_stock_quantity === null ||
        variantStoreProperty?.minimum_stock_quantity === undefined
          ? undefined
          : `${variantStoreProperty?.minimum_stock_quantity}`,
      stock_quantity:
        variantStoreProperty?.stock_quantity === null ||
        variantStoreProperty?.stock_quantity === undefined
          ? undefined
          : `${variantStoreProperty?.stock_quantity}`,
      expiry_date: variantStoreProperty?.expiry_date || "",
      discount_rate: `${variantStoreProperty?.discount_rate || ""}`,
    };
    newVariantStoreProperties.push(newStoreProperty);
  });
  return newVariantStoreProperties;
};

const reArrangeVariant = (variants) => {
  const newVariants = [];

  variants?.forEach((variant) => {
    const newVariant = {
      ...variant,
      store_properties: reArrangeVariantStoreProperties(
        variant?.store_variant_properties,
      ),
    };
    delete newVariant.store_variant_properties;
    newVariants.push(newVariant);
  });
  return newVariants;
};

const getVariantsProperties = (productDetails) => {
  return productDetails?.variants?.length > 0
    ? reArrangeVariant(productDetails?.variants)
    : [];
};

const reArrangeStoreProperties = (storeProperties) => {
  const newStoreProperties = [];
  storeProperties?.forEach((storeProperty) => {
    const newStoreProperty = {
      ...storeProperty,
      pricings: storeProperty?.pricings?.map((pricingProperty) => {
        return {
          price: pricingProperty?.price,
          discount_rate: pricingProperty?.discount_rate,
          pricing_type_id: pricingProperty?.pricing_type?.id,
        };
      }),
      cost_price: `${storeProperty?.cost_price || ""}`,
      minimum_stock_quantity:
        storeProperty?.minimum_stock_quantity === null ||
        storeProperty?.minimum_stock_quantity === undefined
          ? undefined
          : `${storeProperty?.minimum_stock_quantity}`,
      rebate: storeProperty?.rebate || "",
      expiry_date: storeProperty?.expiry_date || "",
      discount_rate: `${storeProperty?.discount_rate || ""}`,
    };

    newStoreProperties.push(newStoreProperty);
  });
  return newStoreProperties;
};

const useSingleProductForm = ({
  isEditMode,
  productDetails,
  handleProductUpdate,
  storePropertiesKey = "store_product_properties",
  isServiceType = false,
}) => {
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const inputRef = useRef();
  const params = useParams();
  const queryClient = useQueryClient();
  const [rootFormDetails, setRootFormDetails] = useState({
    name: productDetails?.name || "",
    description: productDetails?.description || "",
    category: productDetails?.category
      ? {
          label: productDetails?.category?.name,
          value: productDetails?.category?.id,
        }
      : "",
    sku: productDetails?.sku || "",
    barcode: productDetails?.barcode || "",
    stores: productDetails?.stores
      ? reLabelDefault(productDetails?.stores)
      : [],
    has_variant: productDetails?.variants?.length > 0 ? true : false,
    use_same_pricing: false,
    unit: productDetails?.unit
      ? {
          label: productDetails?.unit?.unit,
          value: productDetails?.unit?.id,
        }
      : "",
    show_product_in_sell_mode:
      (productDetails?.show_product_in_sell_mode !== null) &
      (productDetails?.show_product_in_sell_mode !== undefined)
        ? productDetails?.show_product_in_sell_mode
        : true,
    section: productDetails?.section || "",
    image_url: productDetails?.image_url || null,
    media_files: productDetails?.media_files || [],
  });
  const [variantProperties, setVariantProperties] = useState(
    getVariantsProperties(productDetails),
  );
  const [storeProperties, setStoreProperties] = useState(
    reArrangeStoreProperties(productDetails?.[storePropertiesKey]),
  );
  const [isStorePropertiesUpdated, setIsStorePropertiesUpdated] =
    useState(false);
  const [isVariantPropertiesUpdated, setIsVariantPropertiesUpdated] =
    useState(false);
  const [isCreatePricing, setIsCreatePricing] = useState(false);
  const [productImages, setProductImages] = useState(
    productDetails?.media_files ? productDetails?.media_files : [],
  );

  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const { data: stockUnits, isLoading: isLoadingStockUnit } =
    useGetStockUnits();
  const { data: pricingTypes, isLoading: isLoadingPricingTypes } =
    useGetPricingTypes();
  const {
    data: categories,
    isLoading: isLoadingCategories,
    refetch: refetchCategories,
  } = useGetProductCategoryList();
  const { data: sections, isLoading: isLoadingSections } = useGetSections();

  useEffect(() => {
    if (productDetails) {
      setProductImages(productDetails?.media_files);
    }
  }, [productDetails]);

  // update store properties for product without variants
  const updateStoreProperties = (
    stores = rootFormDetails?.stores,
    useSamePricing = rootFormDetails?.use_same_pricing,
  ) => {
    const newStoreProperties = updateStorePropertiesHelper({
      stores,
      useSamePricing,
      storeProperties: storeProperties,
      storeList,
    });
    setStorePropertiesFunc(newStoreProperties);
  };

  // add a new pricing for a store property
  const addNewPricingForStoreProperty = (storeIndex) => {
    const newStoreProperties = addNewPricingForStorePropertyHelper({
      storeProperties,
      storeIndex,
    });
    setStorePropertiesFunc(newStoreProperties);
  };

  // remove pricing for a store property
  const removePricingForStoreProperty = (storeIndex, pricingIndex) => {
    const newStoreProperties = removePricingForStorePropertyHelper({
      storeProperties,
      storeIndex,
      pricingIndex,
    });
    setStorePropertiesFunc(newStoreProperties);
  };

  // help to set store property when changes are made
  const setStorePropertiesFunc = (newStoreProperties) => {
    setStoreProperties(newStoreProperties);
    setIsStorePropertiesUpdated(false);
    window.setProductFormValue("storeProperties", newStoreProperties);
  };

  const createVariantProperties = (stores = rootFormDetails?.stores) => {
    const newVariant = createVariantPropertiesHelper({ storeList, stores });
    setVariantPropertiesFunc([newVariant]);
  };

  const updateVariantStoreProperties = (stores = rootFormDetails?.stores) => {
    const updatedVariantProperties = updateVariantStorePropertiesHelper({
      stores,
      storeList,
      variantProperties,
    });
    setVariantPropertiesFunc(updatedVariantProperties);
  };

  const addNewVariantOption = (stores = rootFormDetails?.stores) => {
    const newVariant = addNewVariantOptionHelper({ stores, storeList });
    setVariantPropertiesFunc([...variantProperties, newVariant]);
  };

  const deleteVariantProperty = (variantIndex) => {
    const newVariantProperties = removeVariantPropertyHelper({
      variantProperties,
      variantIndex,
    });
    setVariantPropertiesFunc(newVariantProperties);
  };

  const addNewPricingPropertyForVariantStoreProperty = (
    storeIndex,
    variantIndex,
  ) => {
    const newVariantProperties =
      addNewPricingPropertyForVariantStorePropertyHelper({
        storeIndex,
        variantIndex,
        variantProperties,
      });
    setVariantPropertiesFunc(newVariantProperties);
  };

  const removePricingPropertyForVariantStoreProperty = (
    pricingIndex,
    storeIndex,
    variantIndex,
  ) => {
    const newVariantProperties =
      removePricingPropertyForVariantStorePropertyHelper({
        pricingIndex,
        storeIndex,
        variantIndex,
        variantProperties,
      });
    setVariantPropertiesFunc(newVariantProperties);
  };

  // help to set variant store property when changes are made
  const setVariantPropertiesFunc = (variants) => {
    setVariantProperties(variants);
    window.setProductFormValue("variantProperties", variants);
    setIsVariantPropertiesUpdated(false);
  };

  //   Image upload function
  const { mutate: uploadImage, isLoading: isUploadingImage } = useMutation(
    (data) => uploadProductImage(data),
  );
  const { mutate: deleteImage } = useMutation({
    mutationFn: (mediaId) => deleteProductImage(mediaId),
    onSuccess: (data) => {
      queryClient.invalidateQueries(["getProductDetails", params?.id]);
      queryClient.invalidateQueries(["getProductList"]);
      triggerToast(data.data.detail, "success");
    },
  });
  const handleProductImageUpload = (event) => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 40));
    if (event.target.files[0].size > 10000000)
      return new Error("File size should be less than 10mb");
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    uploadImage(formData, {
      onSuccess: (response) => {
        const url = response?.data?.data?.url;
        setRootFormDetails((prevState) => ({ ...prevState, image_url: url }));
        setRequestLoaderProgress(100);
        triggerToast("Image uploaded successfully", "success");
      },
      onError: () => {
        setRequestLoaderProgress(100);
      },
    });
  };
  const handleMultipleImageUpload = (event) => {
    if (event.target.files) {
      const file = event.target.files[0];
      const reader = new FileReader();

      if (file) {
        reader.onload = () => {
          setProductImages((prev) => [
            { file: file, preview: reader.result },
            ...prev,
          ]);
        };

        reader.readAsDataURL(file);
      }
    }
  };

  //   Add new category function
  const { mutate: addCategory } = useMutation((data) => createCategory(data));
  const addNewCategory = (value) => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 40));
    const createOption = (label, value) => ({
      label,
      value,
    });
    addCategory(
      { name: value },
      {
        onSuccess: (response) => {
          setRequestLoaderProgress(100);
          const newOption = createOption(
            response?.data?.data?.name,
            response?.data?.data?.id,
          );
          refetchCategories();
          setRootFormDetails((prevState) => ({
            ...prevState,
            category: newOption,
          }));
          triggerToast("Category Added Sucessfully", "success");
        },
        onSettled: () => setRequestLoaderProgress(100),
      },
    );
  };

  // delete variant from backend
  const deleteVariantFromBackend = useDeleteVariantFromBackend();
  const handleDeleteVariantFromBackend = (id) => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 40));
    deleteVariantFromBackend.mutate(
      { id },
      {
        onSuccess: () => {
          triggerToast(
            `Variant with id: ${id} deleted successfully`,
            "success",
          );
        },
        onSettled: () => setRequestLoaderProgress(100),
      },
    );
  };

  const createOrUpdateProduct = () => {
    const submissionData = { ...rootFormDetails, media_files: productImages };
    submissionData.category = rootFormDetails.category?.value;
    submissionData.barcode = rootFormDetails?.has_variant
      ? undefined
      : submissionData?.barcode;
    submissionData.stores = submissionData?.stores?.map(
      (storeItem) => storeItem?.value,
    );
    submissionData.unit = submissionData?.unit?.value;
    const cleanSubmissionData = cleanObject(submissionData, [
      "use_same_pricing",
      isServiceType ? "has_variant" : "",
    ]);

    if (!rootFormDetails.has_variant) {
      cleanSubmissionData.store_properties = [...storeProperties]?.map(
        (storeItem) => {
          const pricings = storeItem?.pricings?.map((pricingItem) => {
            return cleanObject(pricingItem, ["uuid"]);
          });

          return cleanObject(
            { ...storeItem, pricings },
            isServiceType ? ["minimum_stock_quantity", "stock_quantity"] : [],
          );
        },
      );
    } else {
      const oldVariants = deepCopyFunction(variantProperties);

      const cleanedOldVariants = oldVariants?.map((variantItem) => {
        const store_properties = variantItem?.store_properties?.map(
          (variantStoreItem) => {
            const pricings = variantStoreItem?.pricings?.map((pricingItem) => {
              return cleanObject(pricingItem, ["uuid"]);
            });

            return cleanObject({ ...variantStoreItem, pricings }, [
              "useSameVariantPricing",
            ]);
          },
        );

        return cleanObject({ ...variantItem, store_properties }, [
          "useSameVariantPricing",
          "unit",
          "uuid",
        ]);
      });

      if (isEditMode) {
        submissionData.new_variants = cleanedOldVariants.filter(
          (item) => item?.id === undefined,
        );
        submissionData.variants = cleanedOldVariants.filter((item) => item?.id);
      } else submissionData.variants = cleanedOldVariants;
    }

    handleProductUpdate(submissionData);
  };

  useEffect(() => {
    if (!rootFormDetails.has_variant && isStorePropertiesUpdated)
      updateStoreProperties();
  }, [isStorePropertiesUpdated]);

  useEffect(() => {
    if (rootFormDetails.has_variant && isVariantPropertiesUpdated) {
      if (variantProperties?.length === 0) createVariantProperties();
      else updateVariantStoreProperties();
    }
  }, [isVariantPropertiesUpdated]);

  return {
    productImages,
    booleanLabel,
    rootFormDetails,
    categories,
    storeList,
    inputRef,
    isUploadingImage,
    variantProperties,
    storeProperties,
    stockUnits,
    isLoadingStockUnit,
    pricingTypes,
    isLoadingPricingTypes,
    isLoadingCategories,
    isCreatePricing,
    sections,
    isLoadingSections,
    setProductImages,
    setIsCreatePricing,
    updateStoreProperties,
    createVariantProperties,
    setStoreProperties,
    setVariantProperties,
    setRootFormDetails,
    handleProductImageUpload,
    createOrUpdateProduct,
    addNewVariantOption,
    updateVariantStoreProperties,
    deleteVariantProperty,
    addNewCategory,
    setIsStorePropertiesUpdated,
    setIsVariantPropertiesUpdated,
    addNewPricingForStoreProperty,
    removePricingForStoreProperty,
    addNewPricingPropertyForVariantStoreProperty,
    removePricingPropertyForVariantStoreProperty,
    handleDeleteVariantFromBackend,
    handleMultipleImageUpload,
    deleteImage,
  };
};

export default useSingleProductForm;
