import styles from "./MenuPageLayout.module.scss";
import { Outlet } from "react-router-dom";
import Logo from "../../assets/logo";
import globalStyles from "../../assets/styles/base/globalPage.module.scss";
import primaryComponents from "../../components/primaryComponents";
import secondaryComponents from "../../components/secondaryComponents";
import Icon from "../../assets/icons";
import { useMenuPageLayout } from "./useMenuPageLayout";
import { ScanIcon } from "../../assets/svgIcons";

const MenuPageLayout = () => {
  const {
    isLoadingMenuObject,
    menuCartItems,
    menuSearchText,
    setMenuSearchText,
    showCartItems,
    setShowCartItems,
    menuObject,
  } = useMenuPageLayout();

  return (
    <secondaryComponents.LoaderHelper
      isLoading={isLoadingMenuObject}
      classNames={"mt-5"}
    >
      <div className={styles.menuLayout}>
        <div
          style={{
            background: menuObject?.primary_colour ? "white" : "#7647ee",
          }}
          className={styles.navbar}
        >
          <div className={styles.logoContainer}>
            {menuObject?.logo_url && (
              <img
                src={menuObject?.logo_url}
                className={styles.logo}
                alt="logo"
              />
            )}
          </div>
          {/* <img src={Logo.arrows} className={styles.arrows} alt="" />
        <div className={styles.lumiContainer}>
          <p className={styles.smallText}>Powered by</p>
          <p className={styles.mainText}>Lumi Business</p>
        </div> */}
          <div className={styles.search}>
            <primaryComponents.SearchField
              value={""}
              placeholder={"Search for a storefront"}
              triggerOnInput={true}
              showsearchBtn={true}
              trigger={(value) => setMenuSearchText(value)}
              classNames={`${globalStyles.searchField}`}
              borderColor={
                menuObject?.primary_colour
                  ? menuObject?.primary_colour
                  : "#7647ee"
              }
              scanIcon={
                <ScanIcon
                  color={
                    menuObject?.primary_colour
                      ? menuObject?.primary_colour
                      : "#7647ee"
                  }
                />
              }
            />
          </div>
          {menuObject && menuObject.allow_payment ? (
            <div
              onClick={() => setShowCartItems(true)}
              className={styles.cartContainer}
              style={{
                borderColor: menuObject?.primary_colour
                  ? menuObject?.primary_colour
                  : "#7647ee",
              }}
            >
              <img src={Icon.cart} alt="" />
              <span
                style={{
                  color: menuObject?.primary_colour
                    ? menuObject?.primary_colour
                    : "#7647ee",
                }}
              >
                {menuCartItems.length} in cart
              </span>
            </div>
          ) : null}
        </div>

        <div className={styles.menuOutlet}>
          <Outlet
            context={{ menuSearchText, showCartItems, setShowCartItems }}
          />
          <div className={styles.lumiPoweredBy}>
            <img src={Logo.powered_logo} alt="logo" />
            <div className={styles.poweredContainer}>
              <p className={styles.smallText}>Powered by</p>
              <p className={styles.mainText}>Lumi Business</p>
            </div>
          </div>
        </div>
      </div>
    </secondaryComponents.LoaderHelper>
  );
};

export default MenuPageLayout;
