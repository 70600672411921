import { motion } from "framer-motion";
import styles from "./BillUpdate.module.scss";
import primaryComponents from "../../../primaryComponents";
import { formatCurrency, truncateString } from "../../../../helpers";
import secondaryComponents from "../..";
import Icon from "../../../../assets/icons";
import useBillUpdate from "./useBillUpdate";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BillUpdate = ({
  orderId,
  setBillWasUpdated,
  resetView,
  isOrderFlow = true,
}) => {
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const {
    // tagList,
    orderDetails,
    productItems,
    isUpdating,
    otherFields,
    isLoading,
    setOtherFields,
    addProductItem,
    removeBillItem,
    updateBillItem,
    setProductItems,
    removeItemNotWithId,
  } = useBillUpdate({
    orderId,
    setBillWasUpdated,
    resetView,
  });

  return (
    <motion.div
      initial={{ y: 100 }}
      animate={{ y: 0 }}
      className={styles.billDetails}
    >
      {!isOrderFlow ? (
        <FontAwesomeIcon
          onClick={() => setBillWasUpdated(null)}
          className="d-block ml-auto"
          icon="times"
          style={{ fontSize: "25px" }}
        />
      ) : null}
      <h4 className="mt-4 mb-3">Update Bill Information</h4>

      {/* order details */}
      <secondaryComponents.LoaderHelper
        isLoading={isLoading}
        classNames={"mt-4"}
      >
        <div className={styles.orderCover}>
          <div className={styles.orderDetails}>
            <p>ITEM</p>
            <p>QUANTITY</p>
            <p>UNIT(S)</p>
            <p>UNIT AMOUNT</p>
            <p>TOTAL AMOUNT</p>
          </div>
          {productItems?.map((item, index) => (
            <div className={styles.orderDetails} key={item?.id}>
              <p>
                <img
                  style={{ cursor: "pointer" }}
                  src={Icon.deleteIcon}
                  className="mr-2"
                  alt=""
                  onClick={() => {
                    if (item?.cart_item_id)
                      removeBillItem({ cartId: item.cart_item_id, index });
                    else removeItemNotWithId(index);
                  }}
                />
                {truncateString(item?.cart_item_name, 100)}
              </p>
              <div className={"d-flex"} style={{ gap: "5px" }}>
                <primaryComponents.Button
                  classNames={"btn btn--outline"}
                  style={{ width: "40px", height: "40px" }}
                  onClick={() => {
                    const newProductItems = [...productItems];
                    if (newProductItems[index].quantity - 1 < 0) return;
                    newProductItems[index].quantity =
                      Number(newProductItems[index].quantity) - 1;
                    setProductItems(newProductItems);
                  }}
                >
                  -
                </primaryComponents.Button>
                <primaryComponents.CartInputField
                  classNames={`${styles.input}`}
                  defaultValue={item?.quantity}
                  updateCart={(val) => {
                    if (val <= 0) return;
                    const newProductItems = [...productItems];
                    newProductItems[index].quantity = val;
                    setProductItems(newProductItems);
                  }}
                />
                <primaryComponents.Button
                  classNames={"btn btn--primary"}
                  style={{ width: "40px", height: "40px" }}
                  onClick={() => {
                    const newProductItems = [...productItems];
                    newProductItems[index].quantity =
                      Number(newProductItems[index].quantity) + 1;
                    setProductItems(newProductItems);
                  }}
                >
                  +
                </primaryComponents.Button>
              </div>
              <p className={"text-left"}>{item?.unit?.unit || "N/A"}</p>

              <p className={"text-left"}>
                {formatCurrency({
                  value: Number(item?.unit_price),
                  currencyCode: currency_code,
                })}
              </p>
              <p className={"text-left"}>
                {formatCurrency({
                  value: Number(item?.quantity) * Number(item?.unit_price),
                  currencyCode: currency_code,
                })}
              </p>
            </div>
          ))}
        </div>

        {/* add new product section */}
        <div className="mt-3 mb-3">
          {/* search section */}
          <secondaryComponents.ProductSearch
            addProduct={addProductItem}
            orderDetails={orderDetails}
            filterByComposite={false}
            excludeManageModeOnlyProducts={true}
            isBillUpdate={true}
          />
        </div>

        <div className="d-flex justify-content-between">
          <p className="dark--text">Subtotal:</p>
          <h6 className="purple--text">
            {formatCurrency({
              value: productItems?.reduce(
                (accumulator, item) =>
                  Number(item?.quantity) * Number(item?.unit_price) +
                  Number(accumulator),
                0,
              ),
              currencyCode: currency_code,
            })}
          </h6>
        </div>

        <div
          className="mt-5"
          style={{
            padding: "15px",
            borderRadius: "8px",
            border: "1px solid #ddd",
          }}
        >
          <h5 className="mb-3">Price Summary</h5>
          <div className="mt-3 d-flex justify-content-between">
            <p>Subtotal:</p>
            <h6 className="mb-0">
              {formatCurrency({
                value: productItems?.reduce(
                  (accumulator, item) =>
                    Number(item?.quantity) * Number(item?.unit_price) +
                    Number(accumulator),
                  0,
                ),
                currencyCode: currency_code,
              })}
            </h6>
          </div>
          <div className="mt-2 d-flex justify-content-between">
            <p>Delivery Fee:</p>
            {/* <h6 className="mb-0">
            {formatCurrency({
              value: orderDetails?.delivery_fee_amount || 0,
              currencyCode: currency_code,
            })}
          </h6> */}
            <div style={{ width: "120px" }}>
              <primaryComponents.InputField
                classNames={"smallInput"}
                placeholder={"Delivery Fee"}
                value={otherFields?.delivery_fee_amount}
                onChange={({ target: { value } }) => {
                  setOtherFields((prev) => ({
                    ...prev,
                    delivery_fee_amount: value,
                  }));
                }}
              />
            </div>
          </div>
          <div className="mt-2 d-flex justify-content-between">
            <p>Discount:</p>
            {/* <h6 className="mb-0">
            {formatCurrency({
              value: orderDetails?.discount_amount || 0,
              currencyCode: currency_code,
            })}
          </h6> */}
            <div style={{ width: "120px" }}>
              <primaryComponents.InputField
                classNames={"smallInput"}
                placeholder={"Discount"}
                value={otherFields?.discount_amount}
                onChange={({ target: { value } }) => {
                  setOtherFields((prev) => ({
                    ...prev,
                    discount_amount: value,
                  }));
                }}
              />
            </div>
          </div>
          <div className="mt-2 d-flex justify-content-between">
            <p>VAT:</p>
            <h6 className="mb-0">
              {formatCurrency({
                value: orderDetails?.vat || 0,
                currencyCode: currency_code,
              })}
            </h6>
          </div>
          <div className="mt-2 d-flex justify-content-between">
            <p>Total:</p>
            <h6 className="purple--text mb-0">
              {formatCurrency({
                value:
                  productItems?.reduce(
                    (accumulator, item) =>
                      Number(item?.quantity) * Number(item?.unit_price) +
                      Number(accumulator),
                    0,
                  ) +
                  Number(otherFields?.delivery_fee_amount) +
                  -Number(otherFields?.discount_amount) +
                  Number(orderDetails?.vat),
                currencyCode: currency_code,
              })}
            </h6>
          </div>
        </div>

        <div className={styles.billDetails__bottom}>
          <div className="ml-auto" style={{ width: "300px" }}>
            <primaryComponents.Button
              classNames="btn btn--primary cover"
              onClick={() => updateBillItem()}
              isDisabled={isUpdating}
              isLoading={isUpdating}
              loadingText={"Updating..."}
            >
              Continue
            </primaryComponents.Button>
          </div>
        </div>
      </secondaryComponents.LoaderHelper>
    </motion.div>
  );
};
export default BillUpdate;
