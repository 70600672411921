import styles from "./ProductsPage.module.scss";
import "./ProductsPage.scss";
import secondaryComponents from "../../../components/secondaryComponents";
import { useOutletContext } from "react-router-dom";
import useProductsPage from "./useProductsPage";
import ProductsList from "./ProductsList";
import { motion } from "framer-motion";
import primaryComponents from "../../../components/primaryComponents";
import Icon from "../../../assets/icons";
import ProductsGridList from "./ProductsGridList";
import Orderflows from "../../../components/secondaryComponents/StoreCheckout/Orderflows";

const ProductsPage = () => {
  const {
    tableCardId,
    inventoryType,
    isOpenBillsWidget,
    isOpenEndOfDayWidget,
    isOpenSalesWidget,
    isOpenKitchensWidget,
    isOpenReturnWidget,
    showPendingSessions,
    setShowPendingSessions,
    setIsOpenSalesWidget,
    setIsOpenEndOfDayWidget,
    setIsOpenBillsWidget,
    setInventoryType,
    filterByInventory,
    setIsOpenKitchensWidget,
    setIsOpenReturnWidget,
  } = useOutletContext();

  const {
    tableDetails,
    products,
    isfetchingProducts,
    refetchCart,
    search,
    productPageDetails,
    cartItems,
    isActiveCart,
    viewType,
    showGeneratBills,
    isPendingSessions,
    pendCheckout,
    isRefreshCart,
    isLoadingPricingTypes,
    pricingType,
    pricingTypes,
    store_id,
    setPricingType,
    setIsRefreshCart,
    cancelCurrentOrder,
    setViewType,
    getCartItems,
    setRefetchCart,
    handleAddToCart,
    handleReduceCartItem,
    closeStoreCheckout,
    handleFetchProducts,
    gotoPage,
    setIsActiveCart,
    addCartItemToLocalStorage,
  } = useProductsPage({ inventoryType });

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Sell Mode"
      description="View your store products, services and sell to customers"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <div
          className={`${styles.storeProductsPage} storeProductsPage ${
            viewType !== "rowView" ? "white" : ""
          }`}
        >
          {/* product list section */}
          <div className={styles.productsList}>
            <secondaryComponents.LoaderHelper
              isLoading={isfetchingProducts}
              classNames="mt-3"
            >
              <div className="d-flex align-items-center justify-content-between">
                {tableDetails && (
                  <div>
                    <p className={styles.tableOrderText}>
                      Start adding new order to{" "}
                      <span>Table {tableDetails.name}</span>
                    </p>
                  </div>
                )}
                <div className={styles.viewMenuContainer}>
                  <div style={{ width: "150px" }}>
                    <primaryComponents.SelectField
                      options={["All", "Products", "Services"]}
                      value={inventoryType}
                      onChange={(value) => {
                        setInventoryType(value);
                        filterByInventory(value);
                      }}
                      floatError={false}
                    />
                  </div>
                  <div style={{ width: "150px" }}>
                    <secondaryComponents.LoaderHelper
                      isLoading={isLoadingPricingTypes}
                      classNames={"mt-2"}
                    >
                      <primaryComponents.SelectField
                        options={pricingTypes}
                        nameKey="label"
                        idKey="id"
                        isSearchable={false}
                        value={
                          pricingTypes?.find(
                            (pricingItem) => pricingItem?.id === pricingType,
                          )?.label
                        }
                        onChange={(val) => setPricingType(val)}
                        floatError={false}
                      />
                    </secondaryComponents.LoaderHelper>
                  </div>
                  <img
                    className={`${
                      viewType === "gridView" ? styles.active : ""
                    }`}
                    src={Icon.gridViewMenuIcon}
                    alt="grid-view"
                    onClick={() => setViewType("gridView")}
                  />
                  <img
                    className={`${viewType === "rowView" ? styles.active : ""}`}
                    src={Icon.rowViewMenuIcon}
                    alt="row-view"
                    onClick={() => setViewType("rowView")}
                  />
                </div>
              </div>

              {viewType === "rowView" ? (
                <ProductsList
                  products={products}
                  handleAddToCart={handleAddToCart}
                  cartItems={cartItems}
                  getCartItems={getCartItems}
                  handleReduceCartItem={handleReduceCartItem}
                  pricingType={pricingType}
                />
              ) : (
                <ProductsGridList
                  products={products}
                  handleAddToCart={handleAddToCart}
                  cartItems={cartItems}
                  getCartItems={getCartItems}
                  handleReduceCartItem={handleReduceCartItem}
                  pricingType={pricingType}
                />
              )}

              {/* pagination section */}
              {products?.length > 0 && (
                <div className="mt-auto">
                  <secondaryComponents.Pagination
                    totalPageNumber={productPageDetails.last_page}
                    handlePageClick={(value) => gotoPage(value.selected + 1)}
                    currentPage={productPageDetails.current_page}
                  />
                </div>
              )}
            </secondaryComponents.LoaderHelper>
          </div>

          {/* cart section */}
          <motion.div
            initial={{ x: 100 }}
            animate={{ x: 0 }}
            className={`${styles.cartSection} ${
              isActiveCart ? styles.active : ""
            }`}
          >
            <secondaryComponents.StoreCart
              refetchCart={refetchCart}
              setRefetchCart={setRefetchCart}
              handleAddToCart={handleAddToCart}
              handleReduceCartItem={handleReduceCartItem}
              setRefreshProducts={handleFetchProducts}
              setIsActiveCart={setIsActiveCart}
              isActiveCart={isActiveCart}
              showGeneratBills={showGeneratBills}
              pendCheckout={pendCheckout}
              isPendingSessions={isPendingSessions}
              addCartItemToLocalStorage={addCartItemToLocalStorage}
              isRefreshCart={isRefreshCart}
              setIsRefreshCart={setIsRefreshCart}
            />
          </motion.div>

          {/* store checkout */}
          <secondaryComponents.SideModal
            isActive={search.get("order_id") !== null}
            closeModal={closeStoreCheckout}
            closeViaOverlay={false}
            width={700}
          >
            <secondaryComponents.StoreCheckout
              closeStoreCheckout={closeStoreCheckout}
              cancelCurrentOrder={cancelCurrentOrder}
            />
          </secondaryComponents.SideModal>

          {/* Bill details checkout */}
          <secondaryComponents.SideModal
            isActive={search.get("bill_id") !== null}
            closeModal={closeStoreCheckout}
            closeViaOverlay={false}
            width={700}
          >
            <Orderflows
              orderId={search.get("bill_id")}
              closeModal={closeStoreCheckout}
            />
          </secondaryComponents.SideModal>

          {/* Bills table Widget */}
          <secondaryComponents.SellModeSideModal
            closeWidget={() => {
              setIsOpenBillsWidget(false);
            }}
            isActive={isOpenBillsWidget}
            title="Bills"
            width={1400}
          >
            <secondaryComponents.BillsWidget
              closeWidget={() => setIsOpenBillsWidget(false)}
              cancelCurrentOrder={cancelCurrentOrder}
              tableCardId={tableCardId}
            />
          </secondaryComponents.SellModeSideModal>

          {/* Kitchen Widget */}
          <secondaryComponents.SellModeSideModal
            closeWidget={() => {
              setIsOpenKitchensWidget(false);
            }}
            isActive={isOpenKitchensWidget}
            title="Kitchens"
            width={1200}
          >
            <secondaryComponents.SectionWidget store_id={store_id} />
          </secondaryComponents.SellModeSideModal>

          {/* Sales Widget for sell mode */}
          <secondaryComponents.SellModeSideModal
            closeWidget={() => setIsOpenSalesWidget(false)}
            isActive={isOpenSalesWidget}
            title="Sales"
            width={1100}
          >
            <secondaryComponents.SalesWidget />
          </secondaryComponents.SellModeSideModal>

          {/* Pending checkout session */}
          <secondaryComponents.SellModeSideModal
            closeWidget={() => setShowPendingSessions(false)}
            isActive={showPendingSessions}
            title="Pending Checkouts"
            width={1200}
          >
            <secondaryComponents.PendingCheckoutSessions
              closeWidget={() => setShowPendingSessions(false)}
              setIsRefreshCart={setIsRefreshCart}
            />
          </secondaryComponents.SellModeSideModal>

          {/* End of day widget */}
          <secondaryComponents.SideModal
            isActive={isOpenEndOfDayWidget}
            closeModal={() => setIsOpenEndOfDayWidget(false)}
          >
            <secondaryComponents.SalesEndOfDay
              closeWidget={() => setIsOpenEndOfDayWidget(false)}
            />
          </secondaryComponents.SideModal>

          {/* return widget */}
          <secondaryComponents.SideModal
            isActive={isOpenReturnWidget}
            closeModal={() => setIsOpenReturnWidget(false)}
          >
            <secondaryComponents.ReturnStockWidget
              closeWidget={() => setIsOpenReturnWidget(false)}
            />
          </secondaryComponents.SideModal>
        </div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default ProductsPage;
