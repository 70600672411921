import { useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getUrlQuerysection } from "../../../../helpers";
import {
  useGetExpenses,
  useGetExpensesAnalytics,
} from "../../../../hooks/api/queries/useExpenses";

const useExpenseAnalytics = () => {
  const queries = getUrlQuerysection();
  const [search, setSearch] = useSearchParams();
  const [startDate, setStartDate] = useState(search.get("start_date"));
  const [endDate, setEndDate] = useState(search.get("end_date"));
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const [currentStore, setCurrentStore] = useState(null);
  const [isCreateExpense, setIsCreateExpense] = useState(
    search.get("isCreateExpense") === "true" ? true : false,
  );

  const { isLoading: isFetchingExpenseAnalytics, data: expenseAnalytics } =
    useGetExpensesAnalytics(queries);

  const { refetch: handleFetchExpenses } = useGetExpenses(queries);

  const filterByStoreId = (storeId) => {
    if (storeId === "ALL_STORES") search.delete("store_id");
    else search.set("store_id", storeId);
    setSearch(search, { replace: true });
  };

  const setDateFilter = (data) => {
    setStartDate(data.startDate);
    setEndDate(data.endDate);
    search.set("start_date", data.startDate);
    search.set("end_date", data.endDate);
    setSearch(search, { replace: true });
  };

  return {
    startDate,
    endDate,
    storeList,
    currentStore,
    expenseAnalytics,
    isFetchingExpenseAnalytics,
    setCurrentStore,
    setSearch,
    isCreateExpense,
    setIsCreateExpense,
    filterByStoreId,
    setDateFilter,
    handleFetchExpenses,
  };
};
export default useExpenseAnalytics;
