import { useEffect, useState } from "react";
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "../../../notifications/firebase";
import { useMutation, useQueryClient } from "react-query";
import { createDevice } from "../../../services/notifications";
import messageSound from "../../../assets/sounds/message.mp3";

export const usePushNotifications = () => {
  const queryClient = useQueryClient();
  const [showNotificationToast, setShowNotificationToast] = useState(null);

  const { mutate } = useMutation({
    mutationFn: (data) => createDevice(data),
  });

  const generateToken = async () => {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      const token = await getToken(messaging);
      mutate({ registration_id: token, active: true, type: "web" });
    }
  };

  useEffect(() => {
    generateToken();
    onMessage(messaging, (payload) => {
      if (payload?.data?.type === "store_front") {
        const sound = new Audio(messageSound);
        sound.play();
        queryClient.invalidateQueries(["notifications"]);

        setShowNotificationToast(payload);
      }
    });
  }, []);

  return {
    showNotificationToast,
    setShowNotificationToast,
  };
};
