import styles from "./ToolTipV2.module.scss";
const ToolTipVV = ({ children, tipText = "", position = "top" }) => {
  return (
    <>
      <span className={styles.tooltipCover}>
        <span className="d-block" style={{ position: "relative" }}>
          {tipText !== "" && (
            <span
              className={
                position === "right"
                  ? styles.tooltipTextRight
                  : styles.tooltipTextTop
              }
              dangerouslySetInnerHTML={{ __html: tipText }}
            />
          )}
        </span>
        {children}
      </span>
    </>
  );
};

export default ToolTipVV;
