import styles from "./AddSingleServiceForm.module.scss";
import primaryComponents from "../../primaryComponents";
import LoaderHelper from "../../secondaryComponents/LoaderHelper/LoaderHelper";
import Images from "../../../assets/images";
import { Controller } from "react-hook-form";
import { useAddSingleServiceLogic } from "./AddSingleServiceFormLogic";
import { useAddSingleServiceValidation } from "./AddSingleServiceValidation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatCurrency } from "../../../helpers";
import { useSelector } from "react-redux";

const AddSingleServiceForm = () => {
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  const {
    serviceCategory,
    serviceName,
    sellingPrice,
    costPrice,
    isFetchingCategories,
    profit,
    categoryList,
    fileUploadInputRef,
    serviceImage,
    isUploadingImage,
    isAddingService,
    storeList,
    store,
    setStore,
    setProfit,
    setCostPrice,
    setSellingPrice,
    setServiceCategory,
    setServiceName,
    handleAddCategory,
    handleAddService,
    handleServiceImageUpload,
  } = useAddSingleServiceLogic();
  const {
    control,
    formState: { errors },
    handleSubmit,
    trigger,
  } = useAddSingleServiceValidation({
    serviceCategory,
    serviceName,
    sellingPrice,
    costPrice,
  });

  return (
    <LoaderHelper isLoading={isFetchingCategories} classNames={"mt-3"}>
      <div className={styles["add-single-service"]}>
        <form
          onSubmit={handleSubmit(handleAddService)}
          className={styles["add-single-service-form"]}
        >
          <div className={styles["add-single-service-form__container"]}>
            <div className={styles["add-single-service-form__container-group"]}>
              <div className={styles["service-fields-section"]}>
                <Controller
                  name="serviceName"
                  control={control}
                  defaultValue={serviceName}
                  render={({ field: { onChange } }) => (
                    <primaryComponents.InputField
                      classNames="white"
                      label={"Service Name"}
                      placeholder={"Enter service name"}
                      isRequired
                      onKeyUp={() =>
                        errors.serviceName !== undefined &&
                        trigger("serviceName")
                      }
                      onChange={({ target: { value } }) => {
                        onChange(value);
                        setServiceName(value);
                      }}
                      errorMessage={errors.serviceName}
                    />
                  )}
                />
                <div className="mb-2">
                  <label>
                    <span className="error--text">*</span>Category
                  </label>
                  <Controller
                    name="serviceCategory"
                    control={control}
                    defaultValue={serviceCategory}
                    render={({ field: { onChange } }) => (
                      <primaryComponents.TagsMultiSelect
                        options={categoryList}
                        idKey="id"
                        nameKey="name"
                        placeholder="Select category"
                        onChange={(item) => {
                          setServiceCategory(item);
                          onChange(item?.label);
                        }}
                        onCreateOption={(value) => {
                          handleAddCategory(value);
                          onChange(value);
                        }}
                        isSearchable={true}
                        isMulti={false}
                        value={serviceCategory}
                        giveControl={false}
                        height="50px"
                        fontSize="16px"
                      />
                    )}
                  />
                  {/* error message */}
                  {errors.serviceCategory?.message && (
                    <p className="text-danger">
                      <FontAwesomeIcon
                        icon={["fas", "exclamation-circle"]}
                        className="mr-2"
                        style={{ fontSize: "13px" }}
                      />
                      <span style={{ fontSize: "13px" }}>
                        {errors.serviceCategory?.message}
                      </span>
                    </p>
                  )}
                </div>

                {/* store field */}
                <div className="mb-2">
                  <Controller
                    name="store"
                    control={control}
                    defaultValue={store}
                    render={({ field: { onChange } }) => (
                      <primaryComponents.SelectField
                        options={storeList}
                        isRequired={true}
                        name="storeId"
                        value={store === "" ? "Select store..." : store}
                        errorMessage={errors.store}
                        idKey="id"
                        nameKey="name"
                        label="Store"
                        onChange={(value) => {
                          setStore(value);
                          onChange(value);
                        }}
                      />
                    )}
                  />
                </div>

                <div className={styles["service-fields-section__pricing"]}>
                  <Controller
                    name="costPrice"
                    control={control}
                    defaultValue={costPrice}
                    render={({ field: { onChange } }) => (
                      <primaryComponents.InputField
                        classNames="white"
                        label={"Cost Price"}
                        onKeyUp={() =>
                          errors.costPrice !== undefined && trigger("costPrice")
                        }
                        onChange={({ target: { value } }) => {
                          onChange(value);
                          setCostPrice(value);
                          setProfit(
                            formatCurrency({
                              value: sellingPrice - value,
                              currencyCode: currency_code,
                            }),
                          );
                        }}
                        errorMessage={errors.costPrice}
                      />
                    )}
                  />
                  <Controller
                    name="sellingPrice"
                    control={control}
                    defaultValue={sellingPrice}
                    render={({ field: { onChange } }) => (
                      <primaryComponents.InputField
                        classNames="white"
                        label={"Selling Price"}
                        onKeyUp={() =>
                          errors.sellingPrice !== undefined &&
                          trigger("sellingPrice")
                        }
                        onChange={({ target: { value } }) => {
                          onChange(value);
                          setSellingPrice(value);
                          setProfit(
                            formatCurrency({
                              value: value - costPrice,
                              currencyCode: currency_code,
                            }),
                          );
                        }}
                        errorMessage={errors.sellingPrice}
                      />
                    )}
                  />
                  <primaryComponents.InputField
                    classNames="white"
                    label={"Profit"}
                    value={profit}
                    readOnly
                  />
                </div>
              </div>
              <div className={styles["service-image-section"]}>
                <div className={styles["service-image-section__container"]}>
                  <img
                    src={serviceImage ? serviceImage : Images.product_avatar}
                    alt=""
                  />
                  <input
                    type="file"
                    style={{ display: "none" }}
                    name="service-img"
                    id="service-img"
                    ref={fileUploadInputRef}
                    onChange={handleServiceImageUpload}
                  />
                </div>
                <primaryComponents.Button
                  classNames={"btn btn--outline"}
                  style={{ height: "32px", fontSize: "12px" }}
                  onClick={() => fileUploadInputRef.current.click()}
                  type="button"
                  isLoading={isUploadingImage}
                  loadingText={"uploading..."}
                >
                  Upload Image
                </primaryComponents.Button>
              </div>
            </div>
          </div>
          <div className={styles["add-single-service-form__actions"]}>
            <primaryComponents.Button
              type="button"
              classNames={"btn btn--outline"}
            >
              Cancel
            </primaryComponents.Button>
            <primaryComponents.Button
              type="submit"
              classNames={"btn btn--primary"}
              isLoading={isAddingService}
              loadingText={"Creating..."}
            >
              Create Service
            </primaryComponents.Button>
          </div>
        </form>
      </div>
    </LoaderHelper>
  );
};

export default AddSingleServiceForm;
