import { useTable } from "react-table";
import { useMemo } from "react";

const useLogistics = ({ foodMenus }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Menu",
        accessor: "name",
        Cell: ({ cell: { value } }) => <span>{value}</span>,
      },
      {
        Header: "Store",
        accessor: "store",
        Cell: ({ cell: { value } }) => <span>{value.name}</span>,
      },
      {
        Header: "Delivery Method",
        accessor: "delivery_method",
        Cell: () => (
          <span style={{ color: "#C5C5C5" }}>Delivery is disabled</span>
        ),
      },
    ],
    [],
  );

  const instance = useTable({ columns, data: foodMenus || [] });

  return {
    instance,
  };
};

export default useLogistics;
