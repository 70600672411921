import "./TransferPayment.scss";
import primaryComponents from "../../../primaryComponents";
import TransferPaymentLogic from "./TransferPaymentLogic";

const TransferPayment = ({ closePaymentSession }) => {
  const { loadingExternal, payViaExternal } = TransferPaymentLogic({
    closePaymentSession,
  });

  return (
    <>
      <div className="transfer-payment">
        <p
          className="text-center dark--text mx-auto small-text-size"
          style={{ maxWidth: "75%" }}
        >
          Kindly accept transfer for this order.
        </p>
      </div>
      <div className="card-payment__bottom">
        <primaryComponents.Button
          classNames="btn btn--primary cover mt-2"
          isLoading={loadingExternal}
          loadingText="Confirming..."
          isDisabled={loadingExternal}
          onClick={payViaExternal}
        >
          Confirm
        </primaryComponents.Button>
      </div>
    </>
  );
};
export default TransferPayment;
