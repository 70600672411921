import { useSelector } from "react-redux";

const ProfileLogic = () => {
  const { first_name, last_name } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails,
  );
  const { business_name } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  return { business_name, first_name, last_name };
};
export default ProfileLogic;
