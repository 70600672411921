import secondaryComponents from "../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import styles from "./Expenses.module.scss";
import useExpenses from "./useExpenses";
import primaryComponents from "../../../../components/primaryComponents";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";

const Expenses = () => {
  const {
    isFetchingExpenses,
    expenses,
    metaDetails,
    search,
    isCreateExpense,
    setIsCreateExpense,
    handleFetchExpenses,
    gotoPage,
    searchExpenses,
  } = useExpenses();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Expenses"
      description="View all expenses"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
        >
          <div className={`${styles.expenses} ${globalStyles?.pageColumnGaps}`}>
            <div>
              <h4 className="mb-0">Expenses</h4>
              <p>Track and manage all your expenses in one place</p>
            </div>
            <div className={styles.expenses__header}>
              <p>{metaDetails?.total} expenses</p>
              <secondaryComponents.NavigateWithPermission
                permissionParentKey={"Expense"}
                permissionChildKey="can_create_expense"
              >
                <primaryComponents.Button
                  classNames="btn btn--primary ml-auto"
                  onClick={() => setIsCreateExpense(true)}
                >
                  + Create Expenses
                </primaryComponents.Button>
              </secondaryComponents.NavigateWithPermission>
            </div>
            <div className={styles.expenses__content}>
              <div className={styles.expenses__content__header}>
                <primaryComponents.SearchField
                  classNames={styles.searchField}
                  placeholder="Search for expenses"
                  trigger={(value) => searchExpenses(value)}
                  value={
                    search.get("query") !== null ? search.get("query") : ""
                  }
                  triggerOnInput={true}
                  showsearchBtn={true}
                />
              </div>

              <secondaryComponents.LoaderHelper
                isLoading={isFetchingExpenses}
                classNames="mt-5"
              >
                <secondaryComponents.ExpensesTable
                  metaDetails={metaDetails}
                  expenses={expenses}
                  handleFetchExpenses={handleFetchExpenses}
                  gotoPage={gotoPage}
                />
              </secondaryComponents.LoaderHelper>
            </div>
          </div>

          <secondaryComponents.SideModal
            isActive={isCreateExpense}
            closeModal={() => setIsCreateExpense(false)}
          >
            <secondaryComponents.CreateExpense
              closeWidget={() => setIsCreateExpense(false)}
              handleFetchExpenses={handleFetchExpenses}
            />
          </secondaryComponents.SideModal>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default Expenses;
