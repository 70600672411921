import styles from "./ProductVariantCard.module.scss";
import { findPricingViaIdHelper, formatCurrency } from "../../../../helpers";
import primaryComponents from "../../../primaryComponents";
import useProductVariantCard from "./useProductVariantCard";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import { ProductCardEmptyIcon } from "../../../../assets/svgIcons";
import ItemTrackingSelection from "../ItemTrackingSelection";
import useItemTracking from "../ItemTrackingSelection/useItemTracking";
import secondaryComponents from "../..";

const ProductVariantCard = ({
  product,
  cartItems = undefined,
  handleAddToCart,
  handleReduceCartItem,
  productIndex,
  pricingType,
}) => {
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const { variantOpened, setVariantOpened } = useProductVariantCard();
  const getCartItemsViaVariant = (variantId) => {
    const fountCartItem = cartItems.find(
      (item) => `${item?.id}` === `${product?.id}${variantId}`,
    );
    return fountCartItem;
  };

  const getPricingDetails = (variantPricings) => {
    const pricingDetails = findPricingViaIdHelper(pricingType, variantPricings);
    return pricingDetails;
  };

  const isHasPricingForSelectedPricingType = () => {
    for (let index = 0; index < product?.variants?.length; index++) {
      const pricingDetails = getPricingDetails(
        product?.variants?.[index]?.store_variant_properties?.[0]?.pricings,
      );
      if (pricingDetails) return true;
    }
    return false;
  };
  const { productProperties, setProductProperties, closeModal } =
    useItemTracking();

  return (
    <>
      {isHasPricingForSelectedPricingType() ? (
        <div className={styles.productCard}>
          <div
            className={`${styles.productCard__top}  ${
              variantOpened ? styles.active : ""
            }`}
            onClick={() => setVariantOpened(!variantOpened)}
          >
            <p className={globalStyles.prodouctTypeTag}>Variant</p>
            <div className={`${styles.productCard__top__sub}`}>
              {/* left section */}
              <div className={styles.left}>
                <div className={styles.productImage}>
                  {product?.media_files?.[0]?.url ? (
                    <img src={product?.media_files?.[0]?.url} alt="" />
                  ) : (
                    <ProductCardEmptyIcon />
                  )}
                </div>
                <div style={{ width: "100%" }}>
                  <p className={`dark--text mb-1 ${styles.productName}`}>
                    {product?.name}
                  </p>
                  {/* desktop view */}
                  <div className={styles.middle}>
                    <p className="d-flex align-items-center">
                      <span
                        className="error mr-1"
                        style={{
                          width: "5px",
                          height: "5px",
                          borderRadius: "5px",
                        }}
                      ></span>
                      <span className="font-weight-semibold dark--text">
                        {product?.variants?.length}{" "}
                        {product?.variants?.length > 1 ? "Variants" : "Variant"}
                      </span>
                    </p>
                    <p
                      className={`${styles.stock} ${
                        product?.variants?.reduce(
                          (partialSum, a) =>
                            partialSum +
                            a?.store_variant_properties?.[0]?.stock_quantity,
                          0,
                        ) <= 0
                          ? styles.lowQuantity
                          : ""
                      }`}
                    >
                      <span>
                        {product?.variants?.reduce(
                          (partialSum, a) =>
                            partialSum +
                            a?.store_variant_properties?.[0]?.stock_quantity,
                          0,
                        )}{" "}
                        in stock
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              {/* right section */}
              <div className={styles.right}>
                <primaryComponents.Button
                  classNames={`btn btn--outline ${styles.viewBtn}`}
                  onClick={() => setVariantOpened(true)}
                >
                  View Variants
                </primaryComponents.Button>
              </div>
            </div>
          </div>

          {variantOpened && (
            <motion.div
              initial={{ y: -30 }}
              animate={{ y: 0 }}
              className={`${styles.productCard__bottom} ${styles.active}`}
            >
              <h6 className={styles.variantHeader}>Variants</h6>
              {product?.variants?.map((item, index) => {
                const pricingDetails = getPricingDetails(
                  item?.store_variant_properties?.[0]?.pricings,
                );
                const hasItemTracking =
                  item?.store_variant_properties?.[0]?.has_inventory_items;
                return (
                  <>
                    {pricingDetails ? (
                      <div className={styles.productSubCard} key={index}>
                        <div className={styles.left}>
                          <p>
                            <span>{item?.option}: </span>
                            <span className="dark--text">{item?.value}</span>
                          </p>
                          {/* destop view */}
                          <div style={{ width: "100%" }}>
                            <div className={styles.middle}>
                              <p className="d-flex align-items-center">
                                <span
                                  className="error mr-1"
                                  style={{
                                    width: "5px",
                                    height: "5px",
                                    borderRadius: "5px",
                                  }}
                                ></span>
                                <span className="font-weight-semibold purple--text">
                                  {formatCurrency({
                                    value: (
                                      pricingDetails?.price -
                                      (pricingDetails?.price *
                                        pricingDetails?.discount_rate) /
                                        100
                                    ).toFixed(2),
                                    currencyCode: currency_code,
                                    decimal: 0,
                                  })}
                                </span>
                              </p>
                              <p
                                className={`${styles.stock} ${
                                  (product?.store_variant_properties?.[0]
                                    ?.minimum_stock_quantity || 0) >=
                                    item?.store_variant_properties?.[0]
                                      ?.stock_quantity && styles.lowQuantity
                                }`}
                              >
                                <span>
                                  {
                                    item?.store_variant_properties?.[0]
                                      ?.stock_quantity
                                  }{" "}
                                  in stock
                                </span>
                              </p>

                              <p className="dark--text">
                                <span>Expiry: </span>{" "}
                                <span className="font-weight-semibold">
                                  {item?.store_variant_properties?.[0]
                                    ?.expiry_date || "N/A"}
                                </span>
                              </p>
                            </div>

                            {pricingDetails?.discount_rate > 0 ? (
                              <span
                                className={"error--text"}
                                style={{ fontSize: "10px" }}
                              >
                                <span
                                  style={{ textDecoration: "line-through" }}
                                >
                                  {formatCurrency({
                                    value: pricingDetails?.price,
                                    currencyCode: currency_code,
                                    decimal: 0,
                                  })}
                                </span>{" "}
                                {pricingDetails?.discount_rate}% OFF
                              </span>
                            ) : null}
                          </div>
                        </div>

                        {item?.store_variant_properties?.[0]?.stock_quantity >
                        0 ? (
                          <div className={styles.right}>
                            {getCartItemsViaVariant(item?.id) !== undefined ? (
                              <div className={styles.product__actions}>
                                <button
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    if (!hasItemTracking)
                                      return handleReduceCartItem({
                                        product,
                                        quantity:
                                          Number(
                                            getCartItemsViaVariant(item?.id)
                                              ?.quantity,
                                          ) - 1,
                                        variant: item,
                                      });
                                    setProductProperties({
                                      product,
                                      variant: item,
                                      store_properties:
                                        item?.store_variant_properties?.[0],
                                    });
                                  }}
                                  className={styles.decrement}
                                >
                                  -
                                </button>

                                <primaryComponents.CartInputField
                                  defaultValue={
                                    getCartItemsViaVariant(item?.id)
                                      ?.quantity || 0
                                  }
                                  updateCart={(val) =>
                                    handleAddToCart({
                                      product,
                                      quantity: val,
                                      variant: item,
                                    })
                                  }
                                  disabled={hasItemTracking}
                                  isProductCard={true}
                                />

                                <button
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    if (!hasItemTracking)
                                      return handleAddToCart({
                                        product,
                                        quantity:
                                          Number(
                                            getCartItemsViaVariant(item?.id)
                                              ?.quantity,
                                          ) + 1,
                                        variant: item,
                                      });
                                    setProductProperties({
                                      product,
                                      variant: item,
                                      store_properties:
                                        item?.store_variant_properties?.[0],
                                    });
                                  }}
                                  className={styles.increment}
                                >
                                  +
                                </button>
                              </div>
                            ) : (
                              <primaryComponents.Button
                                classNames={`btn btn--primary ${styles.addToCartBtn}`}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  if (!hasItemTracking)
                                    return handleAddToCart({
                                      product,
                                      quantity:
                                        item?.store_variant_properties?.[0]
                                          ?.stock_quantity > 0 &&
                                        item?.store_variant_properties?.[0]
                                          ?.stock_quantity < 1
                                          ? item?.store_variant_properties?.[0]
                                              ?.stock_quantity
                                          : 1,
                                      variant: item,
                                    });
                                  setProductProperties({
                                    product,
                                    variant: item,
                                    store_properties:
                                      item?.store_variant_properties?.[0],
                                  });
                                }}
                              >
                                Add to cart
                              </primaryComponents.Button>
                            )}
                          </div>
                        ) : (
                          <div className={styles.right}></div>
                        )}
                      </div>
                    ) : null}
                  </>
                );
              })}
            </motion.div>
          )}
        </div>
      ) : null}

      <secondaryComponents.Modal
        isActive={productProperties !== null}
        width={550}
        closeModal={closeModal}
      >
        <ItemTrackingSelection
          closeModal={closeModal}
          productProperties={productProperties}
          cartDetails={getCartItemsViaVariant(productProperties?.variant?.id)}
          handleAddToCart={handleAddToCart}
          handleReduceCartItem={handleReduceCartItem}
        />
      </secondaryComponents.Modal>
    </>
  );
};

export default ProductVariantCard;
