import styles from "./PerformingProductsTable.module.scss";
import { usePerformingProductsLogic } from "./PerformingProductsLogic";
import Images from "../../../assets/images";

const PerformingProductsTable = ({ products }) => {
  const { instance } = usePerformingProductsLogic({
    products,
  });
  const { getTableBodyProps, getTableProps, headerGroups, rows, prepareRow } =
    instance;

  if (products.length <= 0) {
    return (
      <div className="text-center mt-5 mb-5">
        <img src={Images.productsEmptyState} width="220px" alt="" />
        <h4 className="font-weight-semibold text-center mt-4">
          You have not made any sale
        </h4>
        <p className="text-center">When you do, they would appear here.</p>
      </div>
    );
  }

  return (
    <table {...getTableProps()} className={styles["performing-products-table"]}>
      <thead>
        {headerGroups.map((headerGroup, i) => (
          <tr key={i} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, i) => (
              <th key={i} {...column.getHeaderProps()}>
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr key={i} {...row.getRowProps()}>
              {row.cells.map((cell, i) => {
                return (
                  <td key={i} {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default PerformingProductsTable;
