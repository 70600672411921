import primaryComponents from "../../../primaryComponents";
import CashPaymentLogic from "./CashPaymentLogic";
import { formatCurrency } from "../../../../helpers";
import { useSelector } from "react-redux";

const CashPayment = ({ orderDetails, closePaymentSession }) => {
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  const { isConfirmingPayment, amount, payViaCash } = CashPaymentLogic({
    closePaymentSession,
    orderDetails,
  });

  return (
    <>
      <div className="cash-payment mt-5">
        <primaryComponents.InputField
          label="Please confirm that you have received the cash amount below from your customer"
          placeholder={`${currency_code}`}
          isRequired={true}
          isDisabled={true}
          name="amount"
          value={formatCurrency({ value: amount, currencyCode: currency_code })}
          classNames="dark--text font-weight-bold"
        />
      </div>
      <div className="card-payment__bottom">
        <primaryComponents.Button
          classNames="btn btn--primary cover"
          isLoading={isConfirmingPayment}
          loadingText="Confirming..."
          isDisabled={isConfirmingPayment}
          onClick={payViaCash}
        >
          Confirm
        </primaryComponents.Button>
      </div>
    </>
  );
};
export default CashPayment;
