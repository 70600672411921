import { formatCurrency } from "../helpers";

export const useSubscriptionFeatures = ({ tierCurrency }) => {
  const isNigeria = tierCurrency === "NGN" ? true : false;

  return {
    subscriptionFeatures: [
      {
        name: "INVENTORY",
        INVENTORY: [
          {
            name: "Store",
            tiers: {
              free: { value: "1" },
              growth: { value: "1" },
              essential: { value: "3" },
              scale: { value: "10" },
            },
          },
          {
            name: "Orders",
            tiers: {
              free: { value: "5/month" },
              growth: { value: "500/month" },
              essential: { value: "Unlimited" },
              scale: { value: "Unlimited" },
            },
          },
          {
            name: "Users",
            tiers: {
              free: { value: "1" },
              growth: { value: "2" },
              essential: { value: "5" },
              scale: { value: "15" },
            },
          },
          {
            name: "Additional Users",
            tiers: {
              free: { value: "-" },
              growth: {
                value: `${formatCurrency({
                  value: isNigeria ? 5000 : 25,
                  currencyCode: tierCurrency,
                  decimal: 0,
                })} per additional user`,
                checked: true,
              },
              essential: {
                value: `${formatCurrency({
                  value: isNigeria ? 3600 : 25,
                  currencyCode: tierCurrency,
                  decimal: 0,
                })} per additional user`,
                checked: true,
              },
              scale: {
                value: `${formatCurrency({
                  value: isNigeria ? 2000 : 25,
                  currencyCode: tierCurrency,
                  decimal: 0,
                })} per additional user`,
                checked: true,
              },
            },
          },
          {
            name: "Suppliers",
            tiers: {
              free: { value: "1" },
              growth: {
                value: "3",
              },
              essential: {
                value: "Unlimited",
              },
              scale: {
                value: "Unlimited",
              },
            },
          },
          {
            name: "Barcode",
            tiers: {
              free: { value: "", checked: true },
              growth: {
                value: "",
                checked: true,
              },
              essential: {
                value: "",
                checked: true,
              },
              scale: {
                value: "",
                checked: true,
              },
            },
          },
          {
            name: "Reconciliations (Stock Taking) ",
            tiers: {
              free: { value: "1" },
              growth: {
                value: "Unlimited",
              },
              essential: {
                value: "Unlimited",
              },
              scale: {
                value: "Unlimited",
              },
            },
          },
          {
            name: "Restaurant Tables",
            tiers: {
              free: { value: "1" },
              growth: {
                value: "1",
              },
              essential: {
                value: "8",
              },
              scale: {
                value: "Unlimited",
              },
            },
          },
          {
            name: "Digital Menu (Menu Items) ",
            tiers: {
              free: { value: "5" },
              growth: {
                value: "5",
              },
              essential: {
                value: "5",
              },
              scale: {
                value: "Unlimited",
              },
            },
          },
          {
            name: "Waybill Generation",
            tiers: {
              free: { value: "1" },
              growth: {
                value: "10",
              },
              essential: {
                value: "Unlimited",
              },
              scale: {
                value: "Unlimited",
              },
            },
          },
          {
            name: "Bill Generation",
            tiers: {
              free: { value: "1" },
              growth: {
                value: "Unlimited",
              },
              essential: {
                value: "Unlimited",
              },
              scale: {
                value: "Unlimited",
              },
            },
          },
          {
            name: "Credit Tracking",
            tiers: {
              free: { value: "", checked: true },
              growth: {
                value: "",
                checked: true,
              },
              essential: {
                value: "",
                checked: true,
              },
              scale: {
                value: "",
                checked: true,
              },
            },
          },
          {
            name: "Stock Returns",
            tiers: {
              free: { value: "", checked: true },
              growth: {
                value: "",
                checked: true,
              },
              essential: {
                value: "",
                checked: true,
              },
              scale: {
                value: "",
                checked: true,
              },
            },
          },
          {
            name: "Stock Transfer",
            tiers: {
              free: { value: "", checked: true },
              growth: {
                value: "",
                checked: true,
              },
              essential: {
                value: "",
                checked: true,
              },
              scale: {
                value: "",
                checked: true,
              },
            },
          },
          {
            name: "Bulk Restock",
            tiers: {
              free: { value: "", checked: true },
              growth: {
                value: "",
                checked: true,
              },
              essential: {
                value: "",
                checked: true,
              },
              scale: {
                value: "",
                checked: true,
              },
            },
          },
          {
            name: "End Of Day",
            tiers: {
              free: { value: "", checked: true },
              growth: {
                value: "",
                checked: true,
              },
              essential: {
                value: "",
                checked: true,
              },
              scale: {
                value: "",
                checked: true,
              },
            },
          },
          {
            name: "Split Payments",
            tiers: {
              free: { value: "-" },
              growth: {
                value: "",
                checked: true,
              },
              essential: {
                value: "",
                checked: true,
              },
              scale: {
                value: "",
                checked: true,
              },
            },
          },
          {
            name: "Holding Transactions",
            tiers: {
              free: { value: "-" },
              growth: {
                value: "",
                checked: true,
              },
              essential: {
                value: "",
                checked: true,
              },
              scale: {
                value: "",
                checked: true,
              },
            },
          },
          {
            name: "Export Inventory",
            tiers: {
              free: { value: "-" },
              growth: {
                value: "",
                checked: true,
              },
              essential: {
                value: "",
                checked: true,
              },
              scale: {
                value: "",
                checked: true,
              },
            },
          },
          {
            name: "Product Variants",
            tiers: {
              free: { value: "", checked: true },
              growth: {
                value: "",
                checked: true,
              },
              essential: {
                value: "",
                checked: true,
              },
              scale: {
                value: "",
                checked: true,
              },
            },
          },
          {
            name: "Warehouse/Central store",
            tiers: {
              free: { value: "", checked: true },
              growth: {
                value: "",
                checked: true,
              },
              essential: {
                value: "",
                checked: true,
              },
              scale: {
                value: "",
                checked: true,
              },
            },
          },
          {
            name: "Discounts",
            tiers: {
              free: { value: "", checked: true },
              growth: {
                value: "",
                checked: true,
              },
              essential: {
                value: "",
                checked: true,
              },
              scale: {
                value: "",
                checked: true,
              },
            },
          },
          {
            name: "Stock Expiry notification",
            tiers: {
              free: { value: "", checked: true },
              growth: {
                value: "",
                checked: true,
              },
              essential: {
                value: "",
                checked: true,
              },
              scale: {
                value: "",
                checked: true,
              },
            },
          },
          {
            name: "Stock Level notification",
            tiers: {
              free: { value: "", checked: true },
              growth: {
                value: "",
                checked: true,
              },
              essential: {
                value: "",
                checked: true,
              },
              scale: {
                value: "",
                checked: true,
              },
            },
          },
        ],
      },
      {
        name: "TRANSACTION PROCESSING",
        "TRANSACTION PROCESSING": [
          {
            name: "Card Transaction Fee",
            tiers: {
              free: {
                value: `0.6% capped at 
        ${formatCurrency({ value: 150, currencyCode: "NGN", decimal: 0 })}`,
              },
              growth: {
                value: `0.6% capped at 
        ${formatCurrency({ value: 150, currencyCode: "NGN", decimal: 0 })}`,
              },
              essential: {
                value: `0.6% capped at 
        ${formatCurrency({ value: 150, currencyCode: "NGN", decimal: 0 })}`,
              },
              scale: {
                value: `0.6% capped at 
        ${formatCurrency({ value: 150, currencyCode: "NGN", decimal: 0 })}`,
              },
            },
          },
          {
            name: "Transfer Transaction Fee",
            tiers: {
              free: {
                value: `0.6% capped at 
        ${formatCurrency({ value: 150, currencyCode: "NGN", decimal: 0 })}`,
              },
              growth: {
                value: `0.6% capped at 
        ${formatCurrency({ value: 150, currencyCode: "NGN", decimal: 0 })}`,
              },
              essential: {
                value: `0.6% capped at 
        ${formatCurrency({ value: 150, currencyCode: "NGN", decimal: 0 })}`,
              },
              scale: {
                value: `0.6% capped at 
        ${formatCurrency({ value: 150, currencyCode: "NGN", decimal: 0 })}`,
              },
            },
          },
          {
            name: "POS Terminal",
            tiers: {
              free: {
                value: `Outright purchase: Invest in ownership for only ${formatCurrency(
                  { value: 150000, currencyCode: "NGN" },
                )}. Lease Option: Enjoy the product with a lease of just ${formatCurrency(
                  { value: 75000, currencyCode: "NGN", decimal: 0 },
                )}. Choose your repayment period: 3 months at a nominal 3% interest, 6 months with a modest 10% interest rate.`,
              },
              growth: {
                value: `Outright purchase: Invest in ownership for only ${formatCurrency(
                  { value: 150000, currencyCode: "NGN" },
                )}. Lease Option: Enjoy the product with a lease of just ${formatCurrency(
                  { value: 75000, currencyCode: "NGN", decimal: 0 },
                )}. Choose your repayment period: 3 months at a nominal 3% interest, 6 months with a modest 10% interest rate.`,
              },
              essential: {
                value: `Outright purchase: Invest in ownership for only ${formatCurrency(
                  { value: 150000, currencyCode: "NGN" },
                )}. Lease Option: Enjoy the product with a lease of just ${formatCurrency(
                  { value: 75000, currencyCode: "NGN", decimal: 0 },
                )}. Choose your repayment period: 3 months at a nominal 3% interest, 6 months with a modest 10% interest rate.`,
              },
              scale: {
                value: `Outright purchase: Invest in ownership for only ${formatCurrency(
                  { value: 150000, currencyCode: "NGN" },
                )}. Lease Option: Enjoy the product with a lease of just ${formatCurrency(
                  { value: 75000, currencyCode: "NGN", decimal: 0 },
                )}. Choose your repayment period: 3 months at a nominal 3% interest, 6 months with a modest 10% interest rate.`,
              },
            },
          },
          {
            name: "Payment Limit (OUTFLOWS)",
            tiers: {
              free: {
                value: `${formatCurrency({
                  value: 10000000,
                  currencyCode: "NGN",
                  decimal: 0,
                })}/month`,
              },
              growth: {
                value: "Unlimited",
              },
              essential: {
                value: "Unlimited",
              },
              scale: {
                value: "Unlimited",
              },
            },
          },
          {
            name: "Payment (OUTFLOWS)",
            tiers: {
              free: {
                value: `${formatCurrency({
                  value: 25,
                  currencyCode: "NGN",
                  decimal: 0,
                })}/month`,
                checked: true,
              },
              growth: {
                value: `${formatCurrency({
                  value: 25,
                  currencyCode: "NGN",
                  decimal: 0,
                })}/month`,
                checked: true,
              },
              essential: {
                value: `${formatCurrency({
                  value: 25,
                  currencyCode: "NGN",
                  decimal: 0,
                })}/month`,
                checked: true,
              },
              scale: {
                value: `${formatCurrency({
                  value: 25,
                  currencyCode: "NGN",
                  decimal: 0,
                })}/month`,
                checked: true,
              },
            },
          },
          {
            name: "Automated Settlements",
            tiers: {
              free: {
                value: `${formatCurrency({
                  value: 25,
                  currencyCode: "NGN",
                  decimal: 0,
                })}/month`,
                checked: true,
              },
              growth: {
                value: `${formatCurrency({
                  value: 25,
                  currencyCode: "NGN",
                  decimal: 0,
                })}/month`,
                checked: true,
              },
              essential: {
                value: `${formatCurrency({
                  value: 25,
                  currencyCode: "NGN",
                  decimal: 0,
                })}/month`,
                checked: true,
              },
              scale: {
                value: `${formatCurrency({
                  value: 25,
                  currencyCode: "NGN",
                  decimal: 0,
                })}/month`,
                checked: true,
              },
            },
          },
        ],
        dontCheckCountry: false,
      },
      {
        name: "BUSINESS OPTIMIZATION TOOLS",
        "BUSINESS OPTIMIZATION TOOLS": [
          {
            name: "Sub-Accounts",
            tiers: {
              free: { value: "1" },
              growth: { value: "3" },
              essential: { value: "10" },
              scale: { value: "Unlimited" },
            },
          },
        ],
        dontCheckCountry: false,
      },
      {
        name: "STANDARD LOYALTY",
        "STANDARD LOYALTY": [
          {
            name: "Number Of Customers",
            tiers: {
              free: { value: "10" },
              growth: { value: "50" },
              essential: { value: "150" },
              scale: { value: "Unlimited" },
            },
          },
          {
            name: "Number Of Gift Cards",
            tiers: {
              free: { value: "2" },
              growth: { value: "4" },
              essential: { value: "10" },
              scale: { value: "Unlimited" },
            },
          },
        ],
        dontCheckCountry: false,
      },
      {
        name: "LOYALTY API",
        "LOYALTY API": [
          {
            name: "API Calls",
            tiers: {
              free: {
                value: `${formatCurrency({
                  value: 5,
                  currencyCode: "NGN",
                  decimal: 0,
                })}/API calls`,
              },
              growth: {
                value: `${formatCurrency({
                  value: 5,
                  currencyCode: "NGN",
                  decimal: 0,
                })}/API calls`,
              },
              essential: {
                value: `${formatCurrency({
                  value: 5,
                  currencyCode: "NGN",
                  decimal: 0,
                })}/API calls`,
              },
              scale: {
                value: `${formatCurrency({
                  value: 5,
                  currencyCode: "NGN",
                  decimal: 0,
                })}/API calls`,
              },
            },
          },
          {
            name: "API Calls Over Reach",
            tiers: {
              free: { value: "50" },
              growth: { value: "100/month" },
              essential: { value: "10000/month" },
              scale: { value: "10000/month" },
            },
          },
          {
            name: "Number Of Gift Cards",
            tiers: {
              free: { value: "2" },
              growth: { value: "4" },
              essential: { value: "10" },
              scale: { value: "Unlimited" },
            },
          },
        ],
        dontCheckCountry: false,
      },
    ],
  };
};
