import styles from "./TableHelper.module.scss";
const TableHelper = ({
  children = null,
  getTableProps,
  getTableBodyProps,
  headerGroups,
  rows,
  prepareRow,
  useDefaultTableMobile = true,
  autoScrollRef = null,
  handleScroll = false,
  hasHeader = true,
  classNames = "",
}) => {
  return (
    <>
      {rows && rows?.length > 0 ? (
        <div style={{ position: "relative" }}>
          <div
            className={`${classNames} ${styles.tableSection} ${
              handleScroll ? styles.handleScroll : ""
            } ${!useDefaultTableMobile ? styles.dontShowOnMobile : ""}`}
            style={{ paddingTop: hasHeader ? "0px" : "0px" }}
          >
            {/* desktop view */}
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup, i) => (
                  <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, i) => (
                      <th
                        key={i}
                        className="font-weight-semibold"
                        {...column.getHeaderProps()}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr
                      key={i}
                      {...row.getRowProps({ key: i })}
                      ref={autoScrollRef}
                      style={row?.depth === 1 ? { background: "#F3F1FC" } : {}}
                    >
                      {row.cells.map((cell, i) => {
                        return (
                          <td key={i} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* mobile view */}
            {useDefaultTableMobile ? (
              <div className={styles.mobileTable}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <div
                      key={i}
                      className={styles.mobileTable__row}
                      {...row.getRowProps({ key: i })}
                      style={row?.depth === 1 ? { background: "#F3F1FC" } : {}}
                    >
                      {row.cells.map((cell, j) => (
                        <div
                          key={j}
                          className={styles.rowItem}
                          {...cell.getCellProps({
                            key: j,
                          })}
                        >
                          <h6 className={styles.rowItem__title}>
                            {cell.column.Header}
                          </h6>
                          <p className={styles.rowItem__value}>
                            {cell.render("Cell")}
                          </p>
                        </div>
                      ))}
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
};
export default TableHelper;
