import { useQuery } from "react-query";
import { getSubAccounts } from "../../../services/business";

export const useGetSubAccounts = () => {
  return useQuery(
    ["getSubAccounts"],
    () => getSubAccounts().then((res) => res?.data),
    {
      staleTime: Infinity,
    },
  );
};
