import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getViewMenuList } from "../../services/inventory";
import { useState } from "react";

export const useMenuPageLayout = () => {
  const { id } = useParams();
  const { menuCartItems } = useSelector((state) => state.menuCartReducer);

  const [menuSearchText, setMenuSearchText] = useState("");
  const [showCartItems, setShowCartItems] = useState(false);
  const { isLoading: isLoadingMenuObject, data: menuObject } = useQuery(
    ["getViewMenuList", [id]],
    () => getViewMenuList({ id: id }).then((res) => res?.data?.data),
  );

  return {
    isLoadingMenuObject,
    menuCartItems,
    menuSearchText,
    setMenuSearchText,
    showCartItems,
    setShowCartItems,
    menuObject,
  };
};
