import React from "react";
import styles from "./styles.module.scss";
import Icon from "../../../../../../../assets/icons";
import primaryComponents from "../../../../../../../components/primaryComponents";
import secondaryComponents from "../../../../../../../components/secondaryComponents";
import SortCategories from "../SortCategories";
import { useCustomizeStorefront } from "./useCustomizeStorefront";
import { ColorPicker } from "react-color-palette";
import "react-color-palette/css";
import { cleanObject } from "../../../../../../../helpers";

const CustomizeStorefront = ({
  menuProperties,
  isFetchingMenuCategories,
  sortMenuCategories,
  setSortMenuCategories,
}) => {
  const {
    isLoadingSortCategories,
    isLoadingStorefrontColor,
    menuId,
    menu_id,
    fileRef,
    showSettings,
    showBrand,
    showSort,
    color,
    showColorPalette,
    primaryColor,
    secondaryColor,
    selectedLogo,
    logoUrl,
    setShowSettings,
    setShowBrand,
    setShowSort,
    setColor,
    setShowColorPalette,
    setPrimaryColor,
    setSecondaryColor,
    setSelectedLogo,
    mutateActivate,
    mutateActivateDeliveryCheckout,
    mutateSortCategories,
    mutateStorefrontColor,
    mutateUploadLogo,
  } = useCustomizeStorefront({ menuProperties });

  const handleUploadPic = (event) => {
    if (event.target.files) {
      const file = event.target.files[0];
      const reader = new FileReader();

      if (file) {
        const formData = new FormData();
        formData.append("file", file);

        mutateUploadLogo(formData);

        reader.onload = () => {
          setSelectedLogo({
            name: file.name,
            type: file.type,
            preview: reader.result,
          });
        };

        reader.readAsDataURL(file);
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.settings}>
        <div className={styles.settingsHeading}>
          <div className={styles.settingItem}>
            <img src={Icon.storefront_settings} alt="" />
            <div>
              <h5>Settings</h5>
              <p>Apply functionalities that suits your business model</p>
            </div>
          </div>
          <primaryComponents.Button
            classNames="btn btn--primary"
            onClick={() => setShowSettings((prev) => !prev)}
          >
            <span>{showSettings ? "Done" : "Edit"}</span>
          </primaryComponents.Button>
        </div>
        {showSettings && menuProperties && (
          <div
            style={{ width: menuProperties.allow_payment ? "100%" : "50%" }}
            className={styles.settingsContent}
          >
            <div className={styles.settingsItemContainer}>
              <h6>Allow online payment</h6>
              <primaryComponents.Switch
                isChecked={menuProperties.allow_payment}
                name="menuId"
                id={menuId ? menuId : menu_id}
                onChange={() => mutateActivate()}
              />
            </div>
            {menuProperties.allow_payment && (
              <div className={styles.settingsItemContainer}>
                <h6>Allow delivery on checkout</h6>
                <primaryComponents.Checkbox
                  name="deliveryCheckout"
                  id="deliveryCheckout"
                  isChecked={menuProperties?.allow_delivery}
                  onChange={() => mutateActivateDeliveryCheckout()}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div className={styles.brand}>
        <div className={styles.brandHeading}>
          <div className={styles.brandItem}>
            <img src={Icon.brand} alt="" />
            <div>
              <h5>Brand Your Storefront</h5>
              <p>Edit your storefront to fit your brand identity.</p>
            </div>
          </div>
          <primaryComponents.Button
            classNames="btn btn--primary"
            onClick={() => {
              showBrand
                ? mutateStorefrontColor(
                    cleanObject({
                      menu_id: menuId ? menuId : menu_id,
                      primary_colour: primaryColor,
                      secondary_colour: secondaryColor,
                      logo_url: logoUrl,
                    }),
                  )
                : setShowBrand((prev) => !prev);
            }}
            loadingText={"Saving..."}
            isLoading={isLoadingStorefrontColor}
            isDisabled={isLoadingStorefrontColor}
          >
            <span>{showBrand ? "Save" : "Edit"}</span>
          </primaryComponents.Button>
        </div>
        {showBrand && menuProperties && (
          <div className={styles.brandContainer}>
            <div className={styles.brandLogo}>
              <div className={styles.logoHeading}>
                <h6>Upload Brand Logo</h6>
              </div>
              <div
                onClick={() => fileRef.current?.click()}
                className={styles.logoContainer}
              >
                <p>Click to below upload</p>
                <input
                  ref={fileRef}
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleUploadPic}
                  multiple={false}
                />
                <div className={styles.uploadItem}>
                  {logoUrl ? (
                    <img className={styles.logo} src={logoUrl} alt="logo" />
                  ) : selectedLogo ? (
                    <img
                      className={styles.logo}
                      src={selectedLogo?.preview}
                      alt="logo"
                    />
                  ) : (
                    <img src={Icon.uploadLogo} alt="logo" />
                  )}
                </div>
              </div>
            </div>
            <div className={styles.colorPallete}>
              <div className={styles.colorHeading}>
                <h6>Edit Storefront Color Palette</h6>
              </div>
              <div className={styles.colorContainer}>
                <div
                  onClick={() => setShowColorPalette("Primary")}
                  className={styles.colorItem}
                >
                  <div className={styles.textContainer}>
                    <p>Primary Color</p>
                    <span>
                      Colors of Header, Buttons and Prices will be updated
                    </span>
                  </div>
                  <div>
                    <div
                      style={{ background: primaryColor }}
                      className={styles.colorCircle}
                    />
                  </div>
                </div>
                <div
                  onClick={() => setShowColorPalette("Secondary")}
                  className={styles.colorItem}
                >
                  <div className={styles.textContainer}>
                    <p>Secondary Color</p>
                    <span>Color of categorized section will be updated</span>
                  </div>
                  <div>
                    <div
                      style={{ background: secondaryColor }}
                      className={styles.colorCircle}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={styles.sort}>
        <div className={styles.sortHeading}>
          <div className={styles.sortItem}>
            <img src={Icon.sort} alt="" />
            <div>
              <h5>Sort Storefront Categories</h5>
              <p>
                Arrange categories by dragging and dropping them into your
                preferred order.
              </p>
            </div>
          </div>
          <primaryComponents.Button
            classNames="btn btn--primary"
            onClick={() => {
              showSort
                ? mutateSortCategories({
                    menu_category_ids: sortMenuCategories.map(
                      (item) => `${item.id}`,
                    ),
                  })
                : setShowSort((prev) => !prev);
            }}
            loadingText={"Saving..."}
            isLoading={isLoadingSortCategories}
            isDisabled={isLoadingSortCategories}
          >
            <span>{showSort ? "Save" : "Edit"}</span>
          </primaryComponents.Button>
        </div>
        {showSort && (
          <div className={styles.sortContainer}>
            <SortCategories
              isFetchingMenuCategories={isFetchingMenuCategories}
              sortMenuCategories={sortMenuCategories}
              setSortMenuCategories={setSortMenuCategories}
            />
          </div>
        )}
      </div>

      <secondaryComponents.Modal
        width={"686px"}
        isActive={showColorPalette ? true : false}
        closeModal={() => setShowColorPalette(null)}
      >
        <div className={styles.modalContainer}>
          <div className={styles.colorPaletteContainer}>
            <div className={styles.heading}>
              <p>
                {showColorPalette === "Primary"
                  ? "Primary Color"
                  : "Secondary Color"}
              </p>
              <span>
                Select Your{" "}
                {showColorPalette === "Primary" ? "Primary" : "Secondary"} Color
              </span>
            </div>
            <div>
              <ColorPicker color={color} onChange={setColor} />
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <primaryComponents.Button
              classNames="btn btn--outline"
              onClick={() => setShowColorPalette(null)}
            >
              <span>Back</span>
            </primaryComponents.Button>
            <primaryComponents.Button
              classNames="btn btn--primary"
              onClick={() => {
                showColorPalette === "Primary"
                  ? setPrimaryColor(color.hex)
                  : setSecondaryColor(color.hex);

                setShowColorPalette(null);
              }}
            >
              <span>Save</span>
            </primaryComponents.Button>
          </div>
        </div>
      </secondaryComponents.Modal>
    </div>
  );
};

export default CustomizeStorefront;
