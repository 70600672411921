import { useMemo, useState } from "react";
import { useTable } from "react-table";
import { formatCurrency } from "../../../helpers";
import { getOrders } from "../../../services/inventory";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

const useSalesWidget = () => {
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [orderPageDetails, setOrderPageDetails] = useState({});
  const [queryParams, setQueryParams] = useState({});

  const { isLoading: isFetchingOrders, data: orders } = useQuery(
    ["getOrders", queryParams],
    () =>
      getOrders(queryParams).then((res) => {
        setOrderPageDetails(res.data?.meta);
        return res.data?.data;
      }),
  );

  const handleRenderStatusIndicator = (value) => {
    if (value === "COMPLETED") {
      return { color: "#19AE57", backgroundColor: "#E5FFF0" };
    } else if (value === "IN_PROGRESS") {
      return { color: "#F7AD3C", backgroundColor: "#FEF7EB" };
    } else if (value === "PLACED") {
      return { color: "#F7AD3C", backgroundColor: "#FEF7EB" };
    } else {
      return { color: "#E02020", backgroundColor: "#FFEEE9" };
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Order Date/Time",
        accessor: "created_at",
        Cell: ({ cell: { value } }) => (
          <span>
            {value === null || value === undefined
              ? "N/A"
              : new Date(value).toLocaleDateString("en-GB")}
          </span>
        ),
      },
      {
        Header: "Order Number",
        accessor: "id",
        Cell: ({ cell: { value } }) => (
          <span
            onClick={() => {
              setSelectedOrder(value);
            }}
            style={{
              color: "#7647EE",
              height: "28px",
              cursor: "pointer",
            }}
          >
            {value}
          </span>
        ),
      },
      {
        Header: "Customer Name",
        accessor: "customer",
        Cell: ({ cell: { value } }) =>
          value === null ? "N/A" : value?.full_name,
      },
      {
        Header: "Cashier",
        accessor: "cashier",
        Cell: ({ cell: { value } }) => (
          <span>
            {value === null || value === undefined ? "N/A" : value?.first_name}
          </span>
        ),
      },
      {
        Header: "Amount",
        accessor: "total_amount",
        Cell: ({ cell: { value } }) => (
          <span>
            {formatCurrency({ value: value, currencyCode: currency_code })}
          </span>
        ),
      },
      {
        Header: "Payment Type",
        accessor: "payment_method",
        Cell: ({ cell: { value } }) => (
          <span>{value === null ? "N/A" : value}</span>
        ),
      },
      {
        Header: "Store",
        accessor: "store",
        Cell: ({ cell: { value } }) => <span>{value?.name || "N/A"}</span>,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => (
          <span
            style={{
              display: "block",
              ...handleRenderStatusIndicator(value),
              borderRadius: "8px",
              padding: "10px 16px",
              height: "28px",
              width: "100px",
              maxWidth: "100px",
              fontSize: "12px",
              textAlign: "center",
            }}
          >
            {value}
          </span>
        ),
      },
    ],
    [],
  );

  const instance = useTable({ columns, data: orders || [] });

  const handleFilterOrderByStatus = (value) => {
    const newQueryParams = { ...queryParams };
    if (value === "") delete newQueryParams.status;
    else newQueryParams.status = value;
    delete newQueryParams.page;
    setQueryParams(newQueryParams);
  };

  const gotoPage = (pageNumber) => {
    const newQueryParams = { ...queryParams };
    newQueryParams.page = pageNumber;
    setQueryParams(newQueryParams);
  };

  const handleSearchOrders = (value) => {
    const newQueryParams = { ...queryParams };
    if (value.length === 0) delete newQueryParams.query;
    else newQueryParams.query = value;
    delete newQueryParams.page;
    setQueryParams(newQueryParams);
  };

  return {
    instance,
    isFetchingOrders,
    orders,
    orderPageDetails,
    selectedOrder,
    setSelectedOrder,
    gotoPage,
    handleSearchOrders,
    handleFilterOrderByStatus,
  };
};

export default useSalesWidget;
