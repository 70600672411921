import { useContext, useState } from "react";
import {
  deletePaymentGateway,
  getMenus,
  getPaymentGateways,
} from "../../../../../services/inventory";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";
import { getUrlQuerysection } from "../../../../../helpers";
import { ToastContext } from "../../../../../hooks/context";

const useAllFoodMenus = () => {
  const queries = getUrlQuerysection();
  const triggerToast = useContext(ToastContext);
  const queryClient = useQueryClient();

  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpenFoodMenuWidget, setIsOpenFoodMenuWidget] = useState(false);
  const [deletePaymentMethod, setDeletePaymentMethod] = useState(null);

  const {
    data,
    isLoading: isFetchingMenu,
    refetch: handleFetchFoodMenu,
  } = useQuery(
    ["getMenus", queries],
    () => getMenus(queries).then((res) => res.data),
    {
      staleTime: Infinity,
    },
  );

  const { data: dataPaymentMethods } = useQuery(["payment-gateways"], () =>
    getPaymentGateways().then((res) => res.data?.data),
  );

  const { isLoading: isLoadingDelete, mutate } = useMutation({
    mutationFn: (id) => deletePaymentGateway(id),
    onSuccess: (data) => {
      queryClient.refetchQueries(["payment-gateways"]);
      triggerToast(data.data.detail, "success");
      setDeletePaymentMethod(null);
    },
    onError: (error) => {
      triggerToast(`${error.response.data.message}`, "warning");
    },
  });

  const handleSearchMenu = (value) => {
    if (value?.length > 0) searchParams.set("query", value);
    else searchParams.delete("query");
    setSearchParams(searchParams, { replace: true });
  };

  const gotoPage = (pageNumber) => {
    searchParams.set("page", pageNumber);
    setSearchParams(searchParams, { replace: true });
  };

  const handleDeletePaymentMethod = () => {
    mutate(deletePaymentMethod);
  };

  return {
    foodMenus: data?.data || [],
    metaDetails: data?.meta || [],
    gotoPage,
    isOpenFoodMenuWidget,
    isFetchingMenu,
    isLoadingDelete,
    deletePaymentMethod,
    searchParams,
    setIsOpenFoodMenuWidget,
    setDeletePaymentMethod,
    handleFetchFoodMenu,
    handleSearchMenu,
    handleDeletePaymentMethod,
    dataPaymentMethods,
  };
};

export default useAllFoodMenus;
