import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const bulkRestockSchema = yup.object({
  restockProperties: yup.array().of(
    yup.object().shape({
      quantity: yup
        .number()
        .typeError("Enter quantity")
        .required("Enter quantity"),
    }),
  ),
});

export const Validation = ({ restockData }) => {
  return useForm({
    resolver: yupResolver(bulkRestockSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      restockProperties: restockData,
    },
  });
};
