import styles from "../SalesEndOfDay.module.scss";
import primaryComponents from "../../../primaryComponents";
import useEndOfDayDetails from "./useEndOfDayDetails";
import { motion } from "framer-motion";
import { formatCurrency, setToDateMonthYearInWords } from "../../../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";

const EndOfDayDetails = ({ endOfDayDetails, endOfDayFormDetails }) => {
  const { isDownloadSummary, setIsDownloadSummary, downloadEndOfDay } =
    useEndOfDayDetails({ endOfDayFormDetails });
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  return (
    <>
      <motion.div
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        className={styles.endOfDayDetails}
      >
        <h4>End Of Day Details</h4>

        <div className={`${styles.rowCover} mb-4`}>
          <div className={styles.rowItem}>
            <p>Report Date:</p>
            <p className="dark--text">
              {endOfDayDetails?.report_start_date
                ? `${setToDateMonthYearInWords(
                    endOfDayDetails?.report_start_date,
                  )} ${
                    endOfDayDetails?.report_start_time
                  } - ${setToDateMonthYearInWords(
                    endOfDayDetails?.report_end_date,
                  )} ${endOfDayDetails?.report_end_time}`
                : "N/A"}
            </p>
          </div>
          <div className={styles.rowItem}>
            <p>Store(s):</p>
            <p className="dark--text">
              {endOfDayDetails?.store_names || "N/A"}
            </p>
          </div>
          <div className={styles.rowItem}>
            <p>Business:</p>
            <p className="dark--text">
              {endOfDayDetails?.business_name || "N/A"}
            </p>
          </div>
          <div className={styles.rowItem}>
            <p>Initiators:</p>
            <p className="dark--text">
              {endOfDayFormDetails?.initiators?.map(
                (initiator, index) =>
                  `${initiator?.label}${
                    endOfDayFormDetails?.initiators?.length !== index + 1
                      ? ", "
                      : ""
                  }`,
              ) || "N/A"}
            </p>
          </div>
        </div>

        {/* summary section  */}
        {!isDownloadSummary ? (
          <div className={styles.orderBody}>
            {/* succesful */}
            <div className={styles.orderBody__table}>
              <div className={styles.orderBody__table__header}>
                <h5>
                  Order Status{" "}
                  <span className="ml-3 success--squared small-text-size">
                    Successful
                  </span>
                </h5>
              </div>
              <div className={styles.rowCover}>
                {endOfDayDetails?.completed_order_data?.length > 0 ? (
                  <>
                    <div className={styles.rowItem}>
                      <p className={styles.item}>SALES</p>
                      <p className={styles.item}>COUNT</p>
                      <p className={styles.item}>AMOUNT</p>
                    </div>
                    {endOfDayDetails?.completed_order_data?.map(
                      (item, index) => (
                        <div className={styles.rowItem} key={index}>
                          <p className={styles.item}>
                            {item?.sales === "warehouse_checkout"
                              ? "Warehouse"
                              : item?.sales}
                          </p>
                          <p className={styles.item}>{item?.counts}</p>
                          <p className={styles.item}>
                            {formatCurrency({
                              value: item?.amount,
                              currencyCode: currency_code,
                            })}
                          </p>
                        </div>
                      ),
                    )}
                    <div className={styles.rowItem}>
                      <h6 className={styles.item}>
                        {endOfDayDetails?.completed_total_data?.[0]?.sales}
                      </h6>
                      <h6 className={styles.item}>
                        {endOfDayDetails?.completed_total_data?.[0]?.counts}
                      </h6>
                      <h6 className={styles.item}>
                        {formatCurrency({
                          value:
                            endOfDayDetails?.completed_total_data?.[0]?.amount,
                          currencyCode: currency_code,
                        })}
                      </h6>
                    </div>
                  </>
                ) : (
                  <h6 className="text-center mt-2">No data available</h6>
                )}
              </div>
            </div>
            {/* failed */}
            <div className={styles.orderBody__table}>
              <div className={styles.orderBody__table__header}>
                <h5>
                  Order Status{" "}
                  <span className="ml-3 failed--text small-text-size">
                    Cancelled
                  </span>
                </h5>
              </div>
              <div className={styles.rowCover}>
                {endOfDayDetails?.canceled_order_data?.length > 0 ? (
                  <>
                    <div className={styles.rowItem}>
                      <p className={styles.item}>SALES</p>
                      <p className={styles.item}>COUNT</p>
                      <p className={styles.item}>AMOUNT</p>
                    </div>

                    {endOfDayDetails?.canceled_order_data?.map(
                      (item, index) => (
                        <div className={styles.rowItem} key={index}>
                          <h6 className={styles.item}>{item?.sales}</h6>
                          <h6 className={styles.item}>{item?.counts}</h6>
                          <h6 className={styles.item}>
                            {formatCurrency({
                              value: item?.amount,
                              currencyCode: currency_code,
                            })}
                          </h6>
                        </div>
                      ),
                    )}
                  </>
                ) : (
                  <h6 className="text-center mt-2">No data available</h6>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="mt-4">
            <h4>Download Full Summary</h4>
            <p>
              Select preferred file type to view your full End of Day summary
            </p>

            <div
              className={`mt-3 mb-2 ${styles.rowItem} ${styles.rowBorder}`}
              onClick={() => downloadEndOfDay("pdf")}
            >
              <p className="dark--text">PDF</p>
              <FontAwesomeIcon icon="download" className="purple--text" />
            </div>
            <div
              className={`mt-3 ${styles.rowItem} ${styles.rowBorder}`}
              onClick={() => downloadEndOfDay("excel")}
            >
              <p className="dark--text">Excel</p>
              <FontAwesomeIcon icon="download" className="purple--text" />
            </div>
          </div>
        )}
      </motion.div>

      {!isDownloadSummary ? (
        <primaryComponents.Button
          onClick={() => setIsDownloadSummary(true)}
          classNames="btn btn--primary cover mt-auto"
          style={{ minHeight: "48px" }}
        >
          Download Full Summary
        </primaryComponents.Button>
      ) : null}
    </>
  );
};
export default EndOfDayDetails;
