import { useContext, useState } from "react";
import { useMutation, useQueries } from "react-query";
import { useSelector } from "react-redux";
import { getRandomIntInclusive } from "../../../helpers";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import {
  approveOrRejectExpenseWithoutDisbursement,
  disburseExpenseFunds,
  getExpenseCategories,
} from "../../../services/expenses";

const useExpenseReview = ({ closeWidget, expense, handleFetchExpenses }) => {
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const [responseStatus, setResponseStatus] = useState(null);
  const [isDisbursmentForm, setIsDisbursmentForm] = useState(false);
  const [disbursementParameters, setDisbursementParameters] = useState({});
  const [expenseDetails, setExpenseDetails] = useState({
    name: expense?.name,
    store: expense?.store?.id,
    store_label: expense?.store?.name,
    amount: expense?.amount,
    category: expense?.category?.id,
    category_label: expense?.category?.name,
    payment_method: expense?.payment_method,
    payment_method_label: expense?.payment_method,
    description: expense?.description,
    expense_date: expense?.expense_date,
    proof_url: expense?.proof_url,
    decline_reason: null,
  });
  const [expenseCategories] = useQueries([
    {
      queryKey: ["expenseCategories"],
      staleTime: Infinity,
      queryFn: () =>
        getExpenseCategories("").then((res) => {
          return res.data?.data;
        }),
    },
  ]);

  const { mutate: expenseResponse, isLoading: isResponding } = useMutation(
    (data) => approveOrRejectExpenseWithoutDisbursement(data),
  );
  const handleExpenseStatus = (status) => {
    if (status === "decline" && !expenseDetails?.decline_reason)
      return triggerToast(
        "Please enter a reason for declining this expense",
        "warning",
      );

    if (expense?.make_refund && status === "approve") {
      setDisbursementParameters({
        transferTitle: "Review Disbursement",
        transferDesc: "Provide the following details for disbursement",
        amount: expenseDetails.amount,
        bank_code: expense?.bank_code,
        bank_name: expense?.bank_name || expense?.bank_code,
        account_number: expense?.account_number,
        account_name: expense?.account_name,
        useExternalDetails: true,
        isLoading: false,
        callBackFunc: handleDisbursement,
      });
      setIsDisbursmentForm(true);
      return;
    }

    setRequestLoaderProgress(getRandomIntInclusive(5, 40));
    delete expenseDetails.store_label;
    delete expenseDetails.category_label;
    delete expenseDetails.payment_method_label;
    setResponseStatus(status);
    expenseResponse(
      {
        data: expenseDetails,
        id: expense?.id,
        status: status,
      },
      {
        onSuccess: (res) => {
          triggerToast(res?.data?.detail, "success");
          handleFetchExpenses();
          closeWidget();
        },

        onSettled: () => setRequestLoaderProgress(100),
      },
    );
  };

  const { mutate: disburseExpense } = useMutation((data) =>
    disburseExpenseFunds(data),
  );

  const handleDisbursement = (transferDetails) => {
    setDisbursementParameters((prev) => ({ ...prev, isLoading: true }));
    delete expenseDetails.store_label;
    delete expenseDetails.category_label;
    delete expenseDetails.payment_method_label;
    delete expenseDetails.decline_reason;
    disburseExpense(
      {
        data: { ...expenseDetails, ...transferDetails },
        id: expense?.id,
      },
      {
        onSuccess: (res) => {
          triggerToast(res?.data?.detail, "success");
          handleFetchExpenses();
          closeWidget();
        },

        onSettled: () => {
          setRequestLoaderProgress(100);
          setDisbursementParameters((prev) => ({ ...prev, isLoading: false }));
        },
      },
    );
  };
  return {
    isLoadingForm: expenseCategories.isLoading,
    expenseDetails,
    expenseCategories: expenseCategories?.data || [],
    isDisbursmentForm,
    storeList,
    isResponding,
    responseStatus,
    disbursementParameters,
    setExpenseDetails,
    setIsDisbursmentForm,
    handleExpenseStatus,
  };
};
export default useExpenseReview;
