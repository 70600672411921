import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getInvoiceDetails } from "../../../../services/inventory";

export const useInvoiceDetails = () => {
  const params = useParams();
  const invoice_id = params.id;

  const { isLoading: isFetchingInvoiceDetails, data: invoiceDetails } =
    useQuery(["invoiceDetails", invoice_id], () =>
      getInvoiceDetails(invoice_id).then((res) => res.data?.data),
    );

  return { isFetchingInvoiceDetails, invoiceDetails };
};
