/* eslint-disable jsx-a11y/anchor-is-valid */
import primaryComponents from "../../../../components/primaryComponents";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import styles from "./CompositeItems.module.scss";
import secondaryComponents from "../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import Icon from "../../../../assets/icons";
import useCompositeItems from "./useCompositeItems";
import { pathConstants } from "../../../../routes/pathContants";

const CompositeItems = ({
  selectedProducts,
  setSelectedProducts,
  setCurrentStep,
}) => {
  const {
    addSelectedProduct,
    editSelectedProductQuantity,
    removeSelectedProduct,
    gotoNextStep,
  } = useCompositeItems({
    selectedProducts,
    setSelectedProducts,
    setCurrentStep,
  });

  return (
    <motion.div
      initial={{ y: 100 }}
      animate={{ y: 0 }}
      className={globalStyles.pageColumnGaps}
    >
      <div
        className="d-flex justify-content-between align-items-center flex-wrap"
        style={{ gap: "15px" }}
      >
        <div>
          <h4>Composite Items</h4>
          <p>
            Please add the base products that make up your composite product
          </p>
        </div>
        <primaryComponents.Button
          classNames={"btn btn--outline ml-auto"}
          onClick={() => {
            window.open(
              window.location.origin +
                pathConstants.INVENTORY_PRODUCTS_ADD_SINGLE,
            );
          }}
        >
          <img src={Icon.addCriclePlus} className="mr-2" alt="" />
          Create New Base Products
        </primaryComponents.Button>
      </div>

      {/* items section */}
      <div
        className={`mt-4 ${styles.orderSection} ${globalStyles.cardWithBorder}`}
      >
        {/* order header */}
        <div className={`${styles.orderDetails}`}>
          <div>
            <p>ITEM</p>
          </div>
          <div>
            <p>QUANTITY</p>
          </div>
        </div>

        {/* order content */}
        {selectedProducts?.map((item, index) => (
          <div className={styles.orderDetails} key={item?.itemId}>
            <div className="d-flex align-items-center" style={{ gap: "10px" }}>
              <a onClick={() => removeSelectedProduct(index)}>
                <img
                  style={{ cursor: "pointer" }}
                  src={Icon.deleteIcon}
                  className="mr-2"
                  alt=""
                />
              </a>
              <secondaryComponents.ConcatenateWithTooltip
                value={item?.name}
                noOfCharacters={100}
              />
            </div>

            <div className="d-flex align-items-center">
              <primaryComponents.InputField
                floatError={false}
                value={item?.stock_quantity}
                classNames="white"
                style={{ height: "35px" }}
                type="number"
                step={0.01}
                onChange={({ target: { value } }) => {
                  editSelectedProductQuantity(
                    {
                      itemId: item?.itemId,
                      stock_quantity: value,
                    },
                    index,
                  );
                }}
              />
              <p className="ml-2">{item?.unit}</p>
            </div>
          </div>
        ))}

        {/* search section */}
        <secondaryComponents.ProductSearch
          addProduct={addSelectedProduct}
          buttonName="Add Base Products"
          filterByStore={false}
        />
      </div>

      {/* button */}
      <primaryComponents.Button
        classNames={"btn btn--primary ml-auto"}
        style={{ width: "200px" }}
        onClick={gotoNextStep}
      >
        Next
      </primaryComponents.Button>
    </motion.div>
  );
};
export default CompositeItems;
