import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useExpanded, useTable } from "react-table";
import Icon from "../../../assets/icons";
import { cleanObject, formatCurrency, truncateString } from "../../../helpers";
import ProductTableActions from "./ProductTableActions";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "../../secondaryComponents";
import styles from "./AllProductsTable.module.scss";
import { useQuery, useMutation } from "react-query";
import { getProduct, deleteProduct } from "../../../services/inventory";
import { ToastContext } from "../../../hooks/context";
import { useContext } from "react";
import globalStyles from "../../../assets/styles/base/globalPage.module.scss";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import { pathConstants } from "../../../routes/pathContants";

const useAllProductsTable = ({
  productList,
  handleFetchproducts,
  selectedProducts,
  setSelectedProducts,
}) => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [openAddStockWidget, setOpenAddStockWidget] = useState(false);
  const [selectedProductID, setSelectedProductID] = useState(null);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [selectedDeleteProduct, setSelectedDeleteProduct] = useState(null);
  const [selectBarcodeProduct, setSelectBarcodeProduct] = useState(null);
  const triggerToast = useContext(ToastContext);
  const [openedVariants, setOpenedVariants] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  useEffect(() => {
    const result = productList?.map((product) => {
      const productTypeName = product?.is_service
        ? "store_service_properties"
        : "store_product_properties";

      const subResult = {
        ...product,
        cost_price:
          product?.[productTypeName]?.length > 0
            ? product?.[productTypeName]?.[0]?.cost_price
            : null,
        pricings:
          product?.[productTypeName]?.length > 0
            ? product?.[productTypeName]?.[0]?.pricings
            : null,
        stock_quantity:
          product?.[productTypeName]?.length > 0
            ? product?.[productTypeName]?.[0]?.stock_quantity
            : null,
        minimum_stock_quantity:
          product?.[productTypeName]?.length > 0
            ? product?.[productTypeName]?.[0]?.minimum_stock_quantity
            : null,
        discount_selling_price:
          product?.[productTypeName]?.length > 0
            ? (
                product?.[productTypeName]?.[0]?.selling_price -
                (product?.[productTypeName]?.[0]?.discount_rate *
                  product?.[productTypeName]?.[0]?.selling_price) /
                  100
              ).toFixed(2)
            : null,
        subRows:
          product?.variants?.length > 0
            ? product?.variants?.map((variant, index) => ({
                id: product?.id,
                option: variant?.option,
                value: variant?.value,
                name: product?.name,
                cost_price: variant?.store_variant_properties?.[0]?.cost_price,
                pricings: variant?.store_variant_properties?.[0]?.pricings,
                variant_id: product?.variants[index]?.id,
                stock_quantity:
                  variant?.store_variant_properties?.[0]?.stock_quantity,
                discount_selling_price: (
                  variant?.store_variant_properties?.[0]?.selling_price -
                  (variant?.store_variant_properties?.[0]?.discount_rate *
                    variant?.store_variant_properties?.[0]?.selling_price) /
                    100
                ).toFixed(2),
              }))
            : null,
      };
      return subResult;
    });

    setProducts(result);
  }, [productList]);

  const handleSelectAll = () => {
    setSelectAllChecked(!selectAllChecked);

    if (!selectAllChecked) {
      const allProducts = instance.data.flatMap((productMak, index) => {
        const newSelectedProducts = [...selectedProducts];

        // Check if the product has variants
        if (productMak?.variants?.length > 0) {
          return productMak.variants.map((variant) => {
            const selectData = cleanObject({
              itemId: `${productMak?.id}${variant?.id}`,
              product_id: productMak?.is_service ? null : productMak?.id,
              variant_id: variant?.id,
              is_product: productMak?.is_service ? false : true,
              service_id: productMak?.is_service ? productMak?.id : null,
            });

            if (
              newSelectedProducts.some(
                (item) => item?.itemId === selectData?.itemId,
              )
            ) {
              const a = newSelectedProducts.filter(
                (item) => item?.itemId !== selectData?.itemId,
              );
              setSelectedProducts(a);
            } else {
              setSelectedProducts((prev) => [...prev, selectData]);
            }

            return selectData;
          });
        } else {
          // For products without variants
          const selectData = cleanObject({
            itemId: `${productMak?.id}`,
            product_id: productMak?.is_service ? null : productMak?.id,
            variant_id: null,
            is_product: productMak?.is_service ? false : true,
            service_id: productMak?.is_service ? productMak?.id : null,
          });

          if (
            newSelectedProducts.some(
              (item) => item?.itemId === selectData?.itemId,
            )
          ) {
            const a = newSelectedProducts.filter(
              (item) => item?.itemId !== selectData?.itemId,
            );
            setSelectedProducts(a);
          } else {
            setSelectedProducts((prev) => [...prev, selectData]);
          }

          return selectData;
        }
      });

      setSelectedProducts(allProducts);
    } else {
      setSelectedProducts([]);
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: (
          <span style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <primaryComponents.Checkbox
              name="selectAll"
              id="selectAll"
              isChecked={selectAllChecked}
              onChange={handleSelectAll}
            />
            <span>Product Name</span>
          </span>
        ),
        accessor: "variant_id",
        Cell: ({ cell: { value: variant_id }, row }) => {
          return (
            <span
              style={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
              <primaryComponents.Checkbox
                id={`${row?.original?.id}${variant_id || ""}`}
                isChecked={
                  row?.original?.variants?.length > 0 && !variant_id
                    ? selectedProducts?.some(
                        (selectedProduct) =>
                          `${selectedProduct?.product_id}` ===
                          `${row?.original?.id}`,
                      )
                    : selectedProducts?.some(
                        (selectedProduct) =>
                          `${selectedProduct?.itemId}` ===
                          `${row?.original?.id}${variant_id || ""}`,
                      )
                }
                name="checkedProduct"
                onChange={() => {
                  const productList = [...instance?.data];
                  const splitIndex = row?.id?.split(".");
                  const product = productList?.[splitIndex?.[0]];
                  const selectData = cleanObject({
                    itemId: `${product?.id}${variant_id || ""}`,
                    product_id: product?.is_service ? null : product?.id,
                    variant_id: variant_id,
                    is_product: product?.is_service ? false : true,
                    service_id: product?.is_service ? product?.id : null,
                  });

                  const newSelectedProducts = [...selectedProducts];
                  if (row?.original?.variants?.length > 0 && !variant_id) {
                    if (
                      newSelectedProducts?.some(
                        (item) =>
                          `${item?.product_id}` === `${selectData?.product_id}`,
                      )
                    ) {
                      const a = newSelectedProducts.filter(
                        (item) =>
                          `${item?.product_id}` !== `${selectData?.product_id}`,
                      );
                      setSelectedProducts(a);
                    } else {
                      const allSubRow = [];
                      row?.original?.variants?.forEach((item) => {
                        allSubRow.push({
                          ...selectData,
                          variant_id: item?.id,
                          itemId: `${selectData?.itemId}${item?.id}`,
                        });
                      });
                      setSelectedProducts((prev) => [...prev, ...allSubRow]);
                    }
                  } else {
                    if (
                      newSelectedProducts?.some(
                        (item) => item?.itemId === selectData?.itemId,
                      )
                    ) {
                      const a = newSelectedProducts.filter(
                        (item) => item?.itemId !== selectData?.itemId,
                      );
                      setSelectedProducts(a);
                    } else {
                      setSelectedProducts((prev) => [...prev, selectData]);
                    }
                  }
                }}
              />
              {truncateString(row?.original?.name, 300)}
            </span>
          );
        },
      },
      {
        Header: "Product Type",
        accessor: (originalRow) => {
          if (originalRow?.variant_id)
            return `${originalRow?.option}-${originalRow?.value}`;
        },
        Cell: ({ cell: { value }, row }) => {
          return (
            <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
              {row?.depth !== 1 ? (
                <span
                  style={{
                    background: "#EFEFEF",
                    padding: "3px 8px",
                    borderRadius: "8px",
                  }}
                  className="gray--text"
                >
                  {row?.original?.variants?.length > 0
                    ? "Variant"
                    : row?.original?.is_composite
                      ? "Composite"
                      : "Single Product"}
                </span>
              ) : (
                <span>{`${value}` || "N/A"}</span>
              )}
              {row?.canExpand && (
                <span
                  {...row?.getToggleRowExpandedProps({
                    style: {
                      // We can even use the row.depth property
                      // and paddingLeft to indicate the depth
                      // of the row
                      // paddingRight: `${row.depth * 2}rem`,
                    },
                  })}
                >
                  <span
                    style={{
                      width: "14px",
                      height: "14px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background: "#E4DAFC",
                      borderRadius: "2px",
                      border: "1px solid #7647ee",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={row?.isExpanded ? "angle-up" : "angle-down"}
                      color="#7647ee"
                    />
                  </span>
                </span>
              )}
            </div>
          );
        },
      },
      {
        Header: "Selling Price",
        accessor: "pricings",
        Cell: ({ cell: { value: pricings }, row }) => {
          if (pricings !== null)
            return (
              <span>
                <secondaryComponents.ToolTip
                  placeholderContent={formatCurrency({
                    value: (
                      pricings?.[0]?.price -
                      (pricings?.[0]?.price * pricings?.[0]?.discount_rate) /
                        100
                    ).toFixed(2),
                    currencyCode: currency_code,
                    decimal: 0,
                  })}
                  showInfoIcon={true}
                  useContentStyling={true}
                  contentStyles={{
                    width: "100%",
                    maxWidth: "300px",
                    padding: "15px",
                  }}
                >
                  <h6 className="gray--text mb-3">
                    {truncateString(row?.original?.name, 30)}
                  </h6>

                  <span
                    className={`${globalStyles.rowSpaceBetween} ${globalStyles.borderBottom} pb-2`}
                  >
                    <p className="smallest-text-size">Pricing Types</p>
                    <p className="smallest-text-size">Pricing</p>
                  </span>
                  {/* pricings */}
                  <span>
                    {pricings?.map((pricingItem) => (
                      <div
                        className={`${globalStyles.rowSpaceBetween} ${globalStyles.borderBottom} pt-2 pb-2`}
                        key={uuidv4()}
                      >
                        <p className="smallest-text-size">
                          {truncateString(pricingItem?.pricing_type?.label, 20)}
                        </p>
                        <p className="smallest-text-size">
                          {formatCurrency({
                            value: (
                              pricingItem?.price -
                              (pricingItem?.price *
                                pricingItem?.discount_rate) /
                                100
                            ).toFixed(2),
                            currencyCode: currency_code,
                            decimal: 0,
                          })}
                        </p>
                      </div>
                    ))}
                  </span>
                </secondaryComponents.ToolTip>
              </span>
            );

          if (row?.canExpand && !pricings) {
            return <img src={Icon.variantCollapse} alt="" />;
          }
        },
      },
      {
        Header: "Stock Quantity",
        accessor: "stock_quantity",
        Cell: ({ cell: { value, row } }) => {
          if (row?.original?.composite_product_type === "ON_DEMAND")
            return "N/A";
          if (value !== null && value !== undefined)
            return (
              <span
                style={{
                  color:
                    row.original["minimum_stock_quantity"] >= value
                      ? "red"
                      : "#19AE57",
                  backgroundColor:
                    row.original["minimum_stock_quantity"] >= value
                      ? "#FFEEE9"
                      : "#E6FFF0",
                  borderRadius: "4px",
                  padding: "4px",
                  height: "26px",
                  fontSize: "12px",
                }}
              >
                {value}
              </span>
            );

          if (row?.canExpand && !value)
            return <img src={Icon.variantCollapse} alt="" />;

          if (!row?.canExpand && !value) return "N/A";
        },
      },
      {
        Header: "Actions",
        accessor: "action",
        Cell: ({ cell: { row } }) =>
          row?.depth === 1 ? null : (
            <span
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <secondaryComponents.NavigateWithPermission
                permissionParentKey={"Inventory Management"}
                permissionChildKey="can_restock_products_bulk_and_single_restock"
              >
                <primaryComponents.Button
                  onClick={() => {
                    setSelectedProductID(row?.original?.id);
                    setOpenAddStockWidget(true);
                  }}
                  classNames={`btn btn--primary ${styles.stockBtn}`}
                  isDisabled={
                    row?.original?.is_service ||
                    row?.original?.composite_product_type === "ON_DEMAND"
                  }
                >
                  {row?.original?.is_composite ? "Produce" : "Add Stock"}
                </primaryComponents.Button>
              </secondaryComponents.NavigateWithPermission>

              <secondaryComponents.NavigateWithPermission
                permissionParentKey={"Inventory Management"}
                permissionChildKey="can_edit_or_update_product"
              >
                <secondaryComponents.ToolTipV2 tipText="Edit Product">
                  <button
                    onClick={() => {
                      if (row.original?.is_composite)
                        navigate(
                          pathConstants.INVENTORY_PRODUCTS_EDIT_COMPOSITE({
                            id: row.original["composite_product_id"],
                          }),
                        );
                      else if (row.original?.is_service)
                        navigate(
                          pathConstants.INVENTORY_SERVICE_EDIT({
                            id: row.original["id"],
                          }),
                        );
                      else
                        navigate(
                          pathConstants.INVENTORY_PRODUCT_EDIT({
                            id: row.original["id"],
                          }),
                        );
                    }}
                    className={styles.editBtn}
                  >
                    <img
                      src={Icon.editWrite}
                      alt=""
                      width="16px"
                      height="16px"
                    />
                  </button>
                </secondaryComponents.ToolTipV2>
              </secondaryComponents.NavigateWithPermission>
              {row?.original?.is_service ? (
                <secondaryComponents.ToolTipV2 tipText="Edit Product">
                  <button
                    className={styles.editBtn}
                    style={{ border: "none" }}
                  ></button>
                </secondaryComponents.ToolTipV2>
              ) : (
                <secondaryComponents.ToolTipV2 tipText="Scan Barcode">
                  <button
                    onClick={() => {
                      setSelectBarcodeProduct(row?.original);
                    }}
                    className={styles.editBtn}
                  >
                    <img src={Icon.barCodeCreateIcon} alt="" />
                  </button>
                </secondaryComponents.ToolTipV2>
              )}
              <secondaryComponents.ToolTipV2 tipText="Options">
                <ProductTableActions
                  product={row?.original}
                  setIsOpenDeleteDialog={setIsOpenDeleteDialog}
                  setSelectedProduct={setSelectedDeleteProduct}
                />
              </secondaryComponents.ToolTipV2>
            </span>
          ),
      },
    ],
    [selectedProducts],
  );

  const instance = useTable(
    {
      columns,
      data: products,
    },
    useExpanded,
  );

  const {
    isLoading: isFetchingProductDetails,
    data: selectedProduct,
    refetch: refetchSelectedProduct,
  } = useQuery(
    ["getProductDetails", selectedProductID],
    () => getProduct(selectedProductID).then((res) => res?.data?.data),
    {
      enabled: selectedProductID !== null,
      staleTime: Infinity,
    },
  );

  const { mutate: deleteProductFromStore, isLoading: isDeletingProduct } =
    useMutation((data) => deleteProduct(data));
  const handleDeleteProduct = () => {
    deleteProductFromStore(
      {
        id: selectedDeleteProduct?.id,
        isService: selectedDeleteProduct?.is_service,
      },
      {
        onSuccess: () => {
          handleFetchproducts();
          setIsOpenDeleteDialog(false);
          triggerToast("Product deleted successfully", "success");
        },
      },
    );
  };

  const toggleVariantDisplay = (productID) => {
    const newOpenedVariants = [...openedVariants];
    if (newOpenedVariants.includes(productID)) {
      const index = newOpenedVariants.indexOf(productID);
      newOpenedVariants.splice(index, 1);
    } else {
      newOpenedVariants.push(productID);
    }
    setOpenedVariants(newOpenedVariants);
  };

  return {
    instance,
    openAddStockWidget,
    selectedProduct,
    isFetchingProductDetails,
    isOpenDeleteDialog,
    selectedDeleteProduct,
    isDeletingProduct,
    products,
    openedVariants,
    selectBarcodeProduct,
    selectedProducts,
    setSelectedProducts,
    setSelectBarcodeProduct,
    refetchSelectedProduct,
    setOpenedVariants,
    setSelectedDeleteProduct,
    setIsOpenDeleteDialog,
    setOpenAddStockWidget,
    handleDeleteProduct,
    setSelectedProductID,
    toggleVariantDisplay,
  };
};
export default useAllProductsTable;
