import { useContext, useState } from "react";
import { printOrderReceipt } from "../../../../services/sales-point";
import { useGenerateInvoice } from "../../../../hooks/api/mutations/useInventory";
import { ToastContext } from "../../../../hooks/context";
import { useCancelOrderMutation } from "../../../../hooks/api/mutations/useOrder";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";

const useTransactionDetails = ({ orderId }) => {
  const { business_phone_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const [isLoading, setIsloading] = useState(false);
  const [isPrintModal, setIsPrintModal] = useState(false);
  const [isEditDate, setIsEditDate] = useState(false);
  const [isOrderCustomer, setIsOrderCustomer] = useState(false);
  const triggerToast = useContext(ToastContext);
  const queryClient = useQueryClient();
  const [customerOrderDetails, setCustomerOrderDetails] = useState({
    customerPhoneCode: business_phone_code,
  });

  const printReceipt = (billWasUpdated = false) => {
    setIsloading(true);
    printOrderReceipt(orderId, billWasUpdated)
      .then((response) => {
        const receiptLink =
          response?.data?.data?.url || response?.data?.data?.bill_url;
        window.open(receiptLink, "_blank");
        setIsloading(false);
      })
      .catch(() => {
        setIsloading(false);
      });
  };

  const { handlegenerateInvoice, isLoading: isGeneratingInvoice } =
    useGenerateInvoice();

  const { mutate: cancelOrder, isLoading: isCancellingOrder } =
    useCancelOrderMutation();
  const cancelCurrentOrder = () => {
    cancelOrder(
      { orderId: orderId },
      {
        onSuccess: () => {
          triggerToast("Order Cancelled Successfully", "warning");
          queryClient.invalidateQueries(["orderDetails"]);
          queryClient.invalidateQueries(["getOrders"]);
        },
      },
    );
  };

  return {
    isLoading,
    isGeneratingInvoice,
    isCancellingOrder,
    isPrintModal,
    isEditDate,
    isOrderCustomer,
    customerOrderDetails,
    setCustomerOrderDetails,
    setIsOrderCustomer,
    setIsEditDate,
    setIsPrintModal,
    cancelCurrentOrder,
    printReceipt,
    handlegenerateInvoice,
  };
};
export default useTransactionDetails;
