import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { getUrlQuerysection } from "../../../../../helpers";
import { useGetCreditLog } from "../../../../../hooks/api/queries/useInventory";

export const useCreditLog = () => {
  const [search, setSearch] = useSearchParams();
  const queries = getUrlQuerysection();

  useEffect(() => {
    if (!search.get("credit_only")) {
      search.set("credit_only", "true");
      setSearch(search, { replace: true });
    }
  }, []);

  const {
    isLoading: isFetchingCredits,
    data: creditLog,
    refetch: handleFetchCredits,
  } = useGetCreditLog(queries);

  const gotoPage = (pageNumber) => {
    search.set("page", pageNumber);
    setSearch(search, { replace: true });
  };

  const searchCredits = (value) => {
    if (value.length === 0) search.delete("query");
    else search.set("query", value);
    search.delete("page");
    setSearch(search, { replace: true });
  };

  return {
    isFetchingCredits,
    creditLog: creditLog?.data || [],
    metaDetails: creditLog?.meta || {},
    search,
    handleFetchCredits,
    gotoPage,
    searchCredits,
  };
};
