import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { pay } from "../../../services/credit";
import { ToastContext } from "../../../hooks/context";

const UpdateCreditWidgetLogic = ({ closeWidget, customer, onSuccess }) => {
  const [amount, setAmount] = useState(customer.outstanding_balance);
  const [store, setStore] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const triggerToast = useContext(ToastContext);

  const makeRepayment = () => {
    const data = {
      amount,
      payment_method: paymentMethod,
      store_id: store.id,
    };
    setIsSubmitting(true);

    pay(customer.id, data)
      .then(() => {
        triggerToast("Payment successful", "success");
        onSuccess();
        closeWidget();
      })
      .catch((error) => {})
      .finally(() => setIsSubmitting(false));
  };

  return {
    amount,
    setAmount,
    store,
    setStore,
    storeList,
    isSubmitting,
    setIsSubmitting,
    paymentMethod,
    setPaymentMethod,
    makeRepayment,
  };
};

export default UpdateCreditWidgetLogic;
