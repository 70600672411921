import styles from "./AllFoodMenus.module.scss";
import globalStyles from "../../../../../assets/styles/base/globalPage.module.scss";
import Images from "../../../../../assets/images";
import primaryComponents from "../../../../../components/primaryComponents";
import secondaryComponents from "../../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { getPlatform } from "../../../../../helpers";
import { platformHandler } from "../../../../../hooks/context";
import useAllFoodMenus from "./useFoodMenu";
import PaymentMethod from "./components/PaymentMethod";
import LogisticsSettings from "./components/Logistics";

const AllFoodMenus = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { platform } = useContext(platformHandler);
  const {
    foodMenus,
    isFetchingMenu,
    isLoadingDelete,
    searchParams,
    metaDetails,
    deletePaymentMethod,
    setDeletePaymentMethod,
    gotoPage,
    handleFetchFoodMenu,
    handleSearchMenu,
    handleDeletePaymentMethod,
    dataPaymentMethods,
  } = useAllFoodMenus();
  const tab = searchParams.get("tab");

  const menusTab = [
    "Storefront Details",
    "Customize Storefront",
    "Payment Method Settings",
    // "Logistics Settings",
  ];

  const [selectedMenuTab, setSelectedMenuTab] = useState(
    tab === "customize"
      ? "Customize Storefront"
      : tab === "payment"
        ? "Payment Method Settings"
        : "Storefront Details",
  );

  useEffect(() => {
    if (location.state?.menuTab === "Storefront Details") {
      setSelectedMenuTab("Storefront Details");
    }
    if (location.state?.menuTab === "Customize Storefront") {
      setSelectedMenuTab("Customize Storefront");
    }
    if (location.state?.menuTab === "Payment Method Settings") {
      setSelectedMenuTab("Payment Method Settings");
    }
    // if (location.state?.menuTab === "Logistics Settings") {
    //   setSelectedMenuTab("Logistics Settings");
    // }
  }, [location]);

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Inventory Menu"
      description={"View and Manage Menu for e.g Restaurant"}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.3 } }}
        className={`${styles.allFoodMenu}`}
      >
        <div className={styles.menuHeading}>
          <h4 className="mb-0">Online & In-Store Self Checkout</h4>
          <p>
            Let customers shop online or in-store, scan a QR code, and checkout
            effortlessly.
          </p>
        </div>

        {foodMenus?.length > 0 && (
          <div className={styles.menuItemScrollContainer}>
            {menusTab?.map((item, index) => (
              <div
                key={index}
                className={`
                      ${styles.menuItemScroll}
                      ${
                        item === selectedMenuTab
                          ? styles.active
                          : styles.inactive
                      }
                    `}
                onClick={() => {
                  setSelectedMenuTab(item);
                  navigate(".", {
                    state: { menuTab: item },
                  });
                }}
              >
                {item.split(" ").map((item, index) => (
                  <span key={index}>{item}&nbsp;</span>
                ))}
              </div>
            ))}
          </div>
        )}

        <div
          className="d-flex align-items-center justify-content-between flex-wrap"
          style={{ rowGap: "10px", columnGap: "30px" }}
        >
          {selectedMenuTab === "Storefront Details" ||
          selectedMenuTab === "Customize Storefront" ? (
            <p>{metaDetails?.total} Storefront</p>
          ) : selectedMenuTab === "Payment Method Settings" ? (
            <p>{dataPaymentMethods?.length} Payment Methods</p>
          ) : (
            <></>
          )}

          {selectedMenuTab === "Storefront Details" && (
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_view_edit_and_create_menu"
            >
              <primaryComponents.Button
                classNames="btn btn--primary ml-auto"
                onClick={() =>
                  navigate(
                    getPlatform(platform) + "/inventory/storefront/create",
                  )
                }
              >
                + Create Storefront
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>
          )}
          {selectedMenuTab === "Payment Method Settings" && (
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Payments"}
              permissionChildKey="can_create_payment_gateway"
            >
              <primaryComponents.Button
                classNames="btn btn--primary ml-auto"
                onClick={() =>
                  navigate(
                    getPlatform(platform) +
                      "/inventory/storefront/payment-method",
                  )
                }
              >
                + Add Payment Method
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>
          )}
        </div>

        <div className={styles.allFoodMenu__content}>
          {selectedMenuTab !== "Payment Method Settings" && (
            <div className={`${globalStyles.tableHeader}`}>
              <primaryComponents.SearchField
                value={
                  searchParams.get("query") ? searchParams.get("query") : ""
                }
                placeholder={"Search for a storefront"}
                triggerOnInput={true}
                showsearchBtn={true}
                trigger={(value) => handleSearchMenu(value)}
                classNames={`${globalStyles.searchField}`}
              />
            </div>
          )}

          <secondaryComponents.LoaderHelper
            isLoading={isFetchingMenu}
            classNames={"mt-4"}
          >
            {foodMenus?.length ? (
              <>
                {selectedMenuTab === "Payment Method Settings" ? (
                  <PaymentMethod
                    setDeletePaymentMethod={setDeletePaymentMethod}
                  />
                ) : selectedMenuTab === "Logistics Settings" ? (
                  <LogisticsSettings foodMenus={foodMenus} />
                ) : (
                  <secondaryComponents.AllFoodMenusList
                    foodMenus={foodMenus}
                    metaDetails={metaDetails}
                    gotoPage={gotoPage}
                    handleFetchFoodMenu={handleFetchFoodMenu}
                    selectedMenuTab={selectedMenuTab}
                  />
                )}
              </>
            ) : (
              <div className="text-center mt-5 mb-5">
                <img src={Images.foodMenuEmptyState} width="250px" alt="" />
                <h4 className="font-weight-semibold text-center">
                  You have no Storefront
                </h4>
                <p
                  style={{ maxWidth: "400px" }}
                  className="text-center mx-auto"
                >
                  Use the “Create Storefront“ button above to add your
                  storefront. When you do, they would appear here.
                </p>
              </div>
            )}
          </secondaryComponents.LoaderHelper>
        </div>
      </motion.div>

      <secondaryComponents.ConfirmDialog
        title="Delete Payment Method"
        isOpen={deletePaymentMethod !== null && deletePaymentMethod !== false}
        setIsOpen={setDeletePaymentMethod}
        isLoading={isLoadingDelete}
        handler={() => handleDeletePaymentMethod()}
      >
        <p className="text-center">
          Are you sure you want to delete this payment method?
        </p>
        <p className="text-center">This action can not be undone</p>
      </secondaryComponents.ConfirmDialog>
    </secondaryComponents.MetaSetter>
  );
};

export default AllFoodMenus;
