import { useQuery } from "react-query";
import { getOrderDetails } from "../../../services/inventory";

export const useGetOrderDetails = ({ id, isEnabled = true }) => {
  return useQuery(
    ["orderDetails", id],
    () => getOrderDetails(id).then((res) => res?.data?.data),
    { enabled: isEnabled && id ? true : false, staleTime: Infinity },
  );
};
