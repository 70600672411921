import { useMemo, useState } from "react";
import { useTable } from "react-table";
import { formatCurrency } from "../../../helpers";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { pathConstants } from "../../../routes/pathContants";

const useSalesTransactionsTable = ({ salesTransactions, showpricing }) => {
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const [search] = useSearchParams();
  const [selectedOrder, setSelectedOrder] = useState(
    search.get("orderId") || null,
  );

  const columns = useMemo(
    () => [
      {
        Header: "Order Number",
        accessor: "id",
        Cell: ({ cell: { value } }) => (
          <span
            style={{
              color: "#7647EE",
              height: "28px",
              cursor: "pointer",
            }}
            onClick={() => {
              setSelectedOrder(value);
            }}
          >
            {value}
          </span>
        ),
      },
      {
        Header: "Order Date",
        accessor: "created_at",
        Cell: ({ cell: { value }, row }) => (
          <span>
            {row?.original?.order_dates?.length > 0
              ? new Date(
                  row?.original?.order_dates?.[
                    row?.original?.order_dates?.length - 1
                  ],
                ).toLocaleString("en-us")
              : new Date(value).toLocaleString("en-us")}
          </span>
        ),
      },
      {
        Header: "Payment Type",
        accessor: "payment_method",
        Cell: ({ cell: { value } }) => (
          <span>{value === null ? "N/A" : value}</span>
        ),
      },
      {
        Header: "Amount",
        accessor: "total_amount",
        Cell: ({ cell: { value } }) => (
          <span>
            {showpricing
              ? formatCurrency({ value: value, currencyCode: currency_code })
              : "*****"}
          </span>
        ),
      },
      {
        Header: "Customer",
        accessor: "customer",
        Cell: ({ cell: { value }, row }) =>
          value ? (
            <Link
              className="purple--text"
              to={pathConstants.CUSTOMER_DETAILS({
                customerId: row?.original?.customer?.id,
              })}
              style={{ fontSize: "12px", textDecoration: "underline" }}
            >
              {" "}
              {value?.full_name}
            </Link>
          ) : (
            "N/A"
          ),
      },
      {
        Header: "Table",
        accessor: "table",
        Cell: ({ cell: { value } }) => <span>{value?.name || "N/A"}</span>,
      },
      {
        Header: "Cashier",
        accessor: "cashier",
        Cell: ({ cell: { value } }) => (
          <span>
            {value === null || value === undefined ? "N/A" : value?.first_name}
          </span>
        ),
      },
      {
        Header: "Store",
        accessor: "store",
        Cell: ({ cell: { value } }) => <span>{value?.name || "N/A"}</span>,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => (
          <span
            style={{
              display: "block",
              ...handleRenderStatusIndicator(value),
              borderRadius: "8px",
              padding: "10px 16px",
              height: "28px",
              width: "100px",
              maxWidth: "100px",
              fontSize: "12px",
              textAlign: "center",
            }}
          >
            {value}
          </span>
        ),
      },
    ],
    [],
  );

  const handleRenderStatusIndicator = (value) => {
    if (value === "COMPLETED") {
      return { color: "#19AE57", backgroundColor: "#E5FFF0" };
    } else if (value === "IN_PROGRESS") {
      return { color: "#F7AD3C", backgroundColor: "#FEF7EB" };
    } else if (value === "PLACED") {
      return { color: "#F7AD3C", backgroundColor: "#FEF7EB" };
    } else {
      return { color: "#E02020", backgroundColor: "#FFEEE9" };
    }
  };

  const instance = useTable({ columns, data: salesTransactions });

  return {
    instance,
    selectedOrder,
    setSelectedOrder,
  };
};

export default useSalesTransactionsTable;
