import MenuPage from "./MenuPage";
import MenuOrderStatus from "./MenuPage/components/OrderStatusModal";
import SubscriptionView from "./SubscriptionView";

const publicPages = {
  MenuPage,
  MenuOrderStatus,
  SubscriptionView,
};

export default publicPages;
