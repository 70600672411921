import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const StoreCustomerValidationSchema = yup.object().shape(
  {
    customerName: yup.string().required("Customer name is required"),
    customerPhoneNumber: yup
      .string()
      .notRequired()
      .when("customerPhoneNumber", {
        is: (val) => val?.length,
        then: yup
          .string()
          .matches(/^[0-9]+$/, "Phone number must contain only digits")
          .test(
            "num",
            "Invalid phone number format",
            (val) => !val.startsWith("0"),
          )
          .min(10, "Invalid phone number format")
          .max(10, "Invalid phone number format"),
      }),
    customerEmail: yup
      .string()
      .notRequired()
      .nullable("Email is required")
      .email("Invalid email address")
      .max(40, "Maximum of 30 characters is allowed"),
  },
  ["customerPhoneNumber", "customerPhoneNumber"],
);

const Validation = ({ customerDetails }) => {
  return useForm({
    resolver: yupResolver(StoreCustomerValidationSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      customerName: customerDetails?.customerPhoneNumber,
      customerPhoneNumber: customerDetails?.customerPhoneNumber,
      customerEmail: customerDetails?.customerEmail,
    },
  });
};

export default Validation;
