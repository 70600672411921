import { useTable } from "react-table";
import { useMemo, useState, useEffect, useContext } from "react";
import { getCustomerTransactions } from "../../../services/customer";
import { RequestLoader } from "../../../hooks/context";
import { formatCurrency, handleRenderStatusIndicator } from "../../../helpers";
import { useSelector } from "react-redux";
import { platformHandler } from "../../../hooks/context";
import { getPlatform } from "../../../helpers";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

const useCustomerTransactionsTable = (customerId) => {
  const [isFetchingTransactions, setIsFetchingTransactions] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [search, setSearch] = useSearchParams();
  const { platform } = useContext(platformHandler);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  const gotoPage = (pageNumber) => {
    search.set("page", pageNumber);
    setSearch(search, { replace: true });
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  const fetchTransactions = () => {
    setIsFetchingTransactions(true);
    setRequestLoaderProgress(40);
    getCustomerTransactions(customerId)
      .then((response) => {
        setTransactions(response.data?.data);
        setRequestLoaderProgress(100);
        setIsFetchingTransactions(false);
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsFetchingTransactions(false);
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Order Number",
        accessor: "id",
        Cell: ({ cell: { value } }) => (
          <Link
            className="purple--text"
            to={
              getPlatform(platform) +
              `/inventory/sales-transactions?orderId=${value}`
            }
            style={{ fontSize: "12px", textDecoration: "underline" }}
          >
            {" "}
            {value}{" "}
          </Link>
        ),
      },
      {
        Header: "Order Date/Time",
        accessor: "created_at",
        Cell: ({ cell: { value } }) => new Date(value).toLocaleString("en-us"),
      },
      {
        Header: "Count Items",
        accessor: "total_item",
        Cell: ({ cell: { value } }) => value,
      },
      {
        Header: "Payment method",
        accessor: "payment_method",
        Cell: ({ cell: { value } }) => value,
      },
      {
        Header: "Cashier",
        accessor: "cashier",
        Cell: ({ cell: { value } }) => (
          <span>
            {" "}
            {value?.first_name} <br /> {value?.last_name}
          </span>
        ),
      },
      {
        Header: "Amount",
        accessor: "total_item_amount",
        Cell: ({ cell: { value } }) =>
          formatCurrency({ value: value, currencyCode: currency_code }),
      },
      {
        Header: "Store",
        accessor: "store.name",
        Cell: ({ cell: { value } }) => <span>{value}</span>,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => (
          <span
            style={{
              display: "block",
              ...handleRenderStatusIndicator(value),
              borderRadius: "8px",
              padding: "10px 16px",
              height: "28px",
              width: "100px",
              maxWidth: "100px",
              fontSize: "12px",
              textAlign: "center",
            }}
          >
            {value}
          </span>
        ),
      },
    ],
    [],
  );

  const instance = useTable({ columns, data: transactions });

  return {
    transactions,
    isFetchingTransactions,
    instance,
    gotoPage,
  };
};

export default useCustomerTransactionsTable;
