import styles from "./StockHistory.module.scss";
import primaryComponents from "../../../../components/primaryComponents";
import secondaryComponents from "../../../../components/secondaryComponents";
import { StockHistoryLogic } from "./StockHistoryLogic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import { motion } from "framer-motion";

const StockHistory = () => {
  const {
    search,
    isFetchingStockHistory,
    storeList,
    currentStore,
    metaDetails,
    isDownLoadWidget,
    stockHistory,
    initiator,
    storeType,
    userList,
    isLoadingUsers,
    setInitiator,
    setStoreType,
    filterByInitiator,
    filterByStoreType,
    handleFetchStockHistory,
    setIsDownLoadWidget,
    filterHistoryByStore,
    setCurrentStore,
    gotoPage,
    searchStockHistory,
  } = StockHistoryLogic();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Inventory - Stock History"
      description="View all stock history"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          className={`${styles.stockHistory} ${globalStyles.pageColumnGaps}`}
        >
          <div>
            <h4 className="mb-0">Stock History</h4>
            <p>
              Monitor and review the changes in your inventory levels over time.
            </p>
          </div>
          <div className={styles.stockHistory__header}>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_export_stock_history"
            >
              <primaryComponents.Button
                classNames="btn btn--outline"
                onClick={() => setIsDownLoadWidget(true)}
              >
                <FontAwesomeIcon icon="download" className="mr-2" />
                Export stock receipt
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>
          </div>

          <div className={styles.stockHistory__content}>
            <div className={globalStyles.tableHeader}>
              <primaryComponents.SearchField
                classNames={globalStyles.searchField}
                placeholder="Search for stock history by (Product Name, Product ID)"
                trigger={(value) => searchStockHistory(value)}
                value={search.get("query") !== null ? search.get("query") : ""}
                triggerOnInput={true}
                showsearchBtn={true}
              />
              <div style={{ width: "160px" }}>
                <primaryComponents.SelectField
                  options={[
                    { name: "All stores", id: "ALL_STORES" },
                    ...storeList,
                  ]}
                  idKey="id"
                  nameKey="name"
                  onChange={(value) => {
                    setCurrentStore(value);
                    filterHistoryByStore(value);
                  }}
                  value={
                    storeList?.find(
                      (item) => `${item?.id}` === `${currentStore}`,
                    )?.name || "Select store"
                  }
                  floatError={false}
                  classNames="store-select"
                />
              </div>
              <div style={{ width: "160px" }}>
                <secondaryComponents.LoaderHelper isLoading={isLoadingUsers}>
                  <primaryComponents.SelectField
                    options={[
                      { first_name: "All Initiators", id: "ALL_INITIATORS" },
                      ...userList,
                    ]}
                    idKey="id"
                    nameKey="first_name"
                    onChange={(value) => {
                      setInitiator(value);
                      filterByInitiator(value);
                    }}
                    value={
                      userList?.find((item) => item?.id === initiator)
                        ?.first_name || "Select Initiator"
                    }
                    floatError={false}
                    classNames="store-select"
                  />
                </secondaryComponents.LoaderHelper>
              </div>
              <div style={{ width: "160px" }}>
                <primaryComponents.SelectField
                  options={[
                    { name: "All stores", id: "ALL_STORES" },
                    { name: "Instore", id: "INTERNAL" },
                    { name: "Storefront", id: "MENU" },
                  ]}
                  idKey="id"
                  nameKey="name"
                  onChange={(value) => {
                    setStoreType(value);
                    filterByStoreType(value);
                  }}
                  value={storeType || "Filter store"}
                  floatError={false}
                  classNames="store-select"
                />
              </div>
            </div>
            <secondaryComponents.LoaderHelper
              isLoading={isFetchingStockHistory}
              classNames="pt-5"
            >
              <secondaryComponents.StockHistoryTable
                metaDetails={metaDetails}
                stockHistory={stockHistory}
                handleFetchStockHistory={handleFetchStockHistory}
                gotoPage={gotoPage}
              />
            </secondaryComponents.LoaderHelper>
          </div>

          <secondaryComponents.SideModal
            isActive={isDownLoadWidget}
            closeModal={() => setIsDownLoadWidget(false)}
          >
            <secondaryComponents.StockHistoryStatement
              closeWidget={() => setIsDownLoadWidget(false)}
            />
          </secondaryComponents.SideModal>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default StockHistory;
