import primaryComponents from "../../primaryComponents";
import styles from "./ReturnStockWidget.module.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller } from "react-hook-form";
import { useReturnStock } from "./ReturnStockLogic";
import { useReturnStockValidation } from "./validation";

const ReturnStockWidget = ({ closeWidget }) => {
  const {
    formData,
    stores,
    isReturningStock,
    isOrderItemsSelected,
    setFormData,
    handleReturnStock,
  } = useReturnStock({ closeWidget });
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useReturnStockValidation(formData);

  return (
    <div className={styles.returnStockWidget}>
      <div className={styles.returnStockWidgetHeader}>
        <button onClick={closeWidget} className={styles.backButton}>
          <FontAwesomeIcon
            icon="angle-left"
            className="purple--text"
            style={{ fontSize: "20px" }}
          />
        </button>
        <h3>Returns</h3>
      </div>
      <form
        onSubmit={handleSubmit(handleReturnStock)}
        className={styles.returnStockWidgetForm}
      >
        <div className={styles.returnStockWidgetFormSection}>
          <div>
            <Controller
              control={control}
              name="store_id"
              defaultValue={formData.store_id}
              render={({ field: { onChange } }) => (
                <primaryComponents.SelectField
                  options={stores}
                  label="Select The Store Your Customer Purchased The Product(s) From"
                  nameKey={"name"}
                  idKey={"id"}
                  onChange={(value) => {
                    onChange(value);
                    setFormData((prev) => ({
                      ...prev,
                      store_id: value,
                    }));
                  }}
                  errorMessage={errors.store_id}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="order_id"
              defaultValue={formData.order_id}
              render={({ field: { onChange } }) => (
                <primaryComponents.OrderComboBox
                  label="Enter and Search by Order ID"
                  onChange={(selectedOrder) => {
                    const { id, order_items } = selectedOrder;

                    if (selectedOrder) {
                      const orderItems = order_items
                        .filter((order) => order?.product !== null)
                        .map((order) => ({
                          item_id: order?.id,
                          store_id: formData.store_id,
                          quantity: order?.quantity,
                          selling_price: order?.product?.selling_price,
                          cost_price: order?.product?.cost_price,
                          isReturning: false,
                          cart_item_name: order?.cart_item_name,
                        }));

                      onChange(id);
                      setFormData((prev) => ({
                        ...prev,
                        items: orderItems,
                        order_id: id,
                      }));
                    }
                  }}
                  error={errors?.order_id?.message}
                />
              )}
            />
          </div>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p className="dark--text" style={{ fontSize: "14px" }}>
                Select Items Your Customer is Returning
              </p>
              <p className="dark--text" style={{ fontSize: "14px" }}>
                Quantity
              </p>
            </div>
            <div className={styles.returnStockWidgetItems}>
              {formData.items.map((item, index) => (
                <div
                  key={item.item_id + index + item.cart_item_name}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <primaryComponents.Checkbox
                      name="item"
                      id={item.item_id}
                      isChecked={item?.isReturning === true}
                      onChange={(value) => {
                        let orderItems = [...formData.items];
                        orderItems[index].isReturning = value;
                        setFormData((prev) => ({ ...prev, items: orderItems }));
                      }}
                    />
                    <p className="ml-1">{item.cart_item_name}</p>
                  </div>
                  <div style={{ width: "72px" }}>
                    <Controller
                      name={`items[${formData.items[index]}].quantity`}
                      control={control}
                      defaultValue={formData.items[index].quantity}
                      render={({ field: { onChange } }) => {
                        return (
                          <primaryComponents.InputField
                            classNames="white"
                            value={item.quantity}
                            isDisabled={!item?.isReturning}
                            name={`items[${formData.items[index]}].quantity`}
                            onChange={({ target: { value } }) => {
                              onChange(value);
                              let _orderItems = [...formData.items];
                              _orderItems[index].quantity = Number(value);
                              setFormData((prev) => ({
                                ...prev,
                                items: _orderItems,
                              }));
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div>
            <Controller
              control={control}
              name="remarks"
              defaultValue={formData.remarks}
              render={({ field: { onChange } }) => (
                <primaryComponents.TextArea
                  label="Remarks"
                  placeholder="Customer returned this because good was damaged internally"
                  value={formData.remarks}
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setFormData((prev) => ({ ...prev, remarks: value }));
                  }}
                  errorMessage={errors.remarks}
                />
              )}
            />
          </div>
        </div>
        <div>
          <primaryComponents.Button
            classNames={"btn btn--primary mt-auto w-100"}
            isDisabled={isReturningStock || !isOrderItemsSelected}
            isLoading={isReturningStock}
            loadingText={"Processing..."}
          >
            Confirm
          </primaryComponents.Button>
        </div>
      </form>
    </div>
  );
};

export default ReturnStockWidget;
