import styles from "./BulkRestockComboBox.module.scss";
import primaryComponents from "../../primaryComponents";
import { useBulkRestockComboBox } from "./useBulkRestockComboBox";
import secondaryComponents from "..";
import { motion } from "framer-motion";
import { formatCurrency } from "../../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";

const BulkRestockComboBox = ({ handleAppendRestockData, setStoreError }) => {
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  const {
    isLoading,
    searchRef,
    products,
    isDropDown,
    searchValue,
    isVariantOpenId,
    setSearchValue,
    searchProduct,
    addProductForRestock,
    setIsVariantOpenId,
  } = useBulkRestockComboBox({ handleAppendRestockData, setStoreError });

  return (
    <div className={styles.bulkRestockComboBox} ref={searchRef}>
      <primaryComponents.SearchField
        placeholder="Search by Barcode Scaning or Product Name, ID & SKU)"
        trigger={(value) => searchProduct(value)}
        value={searchValue}
        useControlledValue={true}
        setValue={(val) => setSearchValue(val)}
        triggerOnInput={true}
        showsearchBtn={true}
        isScan={true}
      />

      {/* dropdown section */}
      {isDropDown ? (
        <motion.div
          initial={{ y: 50 }}
          animate={{
            y: 0,
          }}
          className={styles.resultSection}
        >
          <secondaryComponents.LoaderHelper
            isLoading={isLoading}
            classNames="mt-5 mt-5"
          >
            {products?.length > 0 ? (
              <>
                <div className={styles.nameContainer}>
                  {products?.map((item) => {
                    if (!item?.is_service) {
                      return (
                        <div key={item?.id} style={{ borderBottom: "#ddd" }}>
                          <div
                            className={`${styles.listItemParent} ${
                              item?.variants?.length > 0 ? styles.isVariant : ""
                            } ${
                              item?.id === isVariantOpenId ? styles.active : ""
                            }`}
                            onClick={() => {
                              if (item?.variants?.length > 0) {
                                if (isVariantOpenId === item?.id)
                                  setIsVariantOpenId(null);
                                else setIsVariantOpenId(item?.id);
                              } else {
                                addProductForRestock(item);
                              }
                            }}
                          >
                            <p className="mb-0">{item?.name}</p>
                            <p className="mb-0">
                              {item?.variants?.length > 0 ? (
                                <span
                                  className="d-flex align-items-center"
                                  style={{ width: "fit-content" }}
                                >
                                  <span>
                                    {item?.variants?.length}{" "}
                                    {item?.variants?.length > 1
                                      ? "variants"
                                      : "variant"}
                                  </span>
                                  <FontAwesomeIcon
                                    className="ml-2"
                                    icon={`${
                                      isVariantOpenId === item?.id
                                        ? "angle-up"
                                        : "angle-down"
                                    }`}
                                  />
                                </span>
                              ) : (
                                formatCurrency({
                                  value:
                                    item?.store_product_properties?.[0]
                                      ?.pricings?.[0]?.price,
                                  currencyCode: currency_code,
                                })
                              )}
                            </p>
                          </div>

                          {isVariantOpenId === item?.id &&
                            item?.variants?.map((variantItem) => (
                              <motion.div
                                initial={{ y: 20 }}
                                animate={{
                                  y: 0,
                                }}
                                key={variantItem?.id}
                                className={`${styles.listItemParent} ${styles.isSubVariant}`}
                                onClick={() => {
                                  addProductForRestock(item, variantItem);
                                }}
                              >
                                <p className="mb-0">
                                  {variantItem?.option} - {variantItem?.value}
                                </p>
                                <p className="mb-0">
                                  {formatCurrency({
                                    value:
                                      variantItem?.store_variant_properties?.[0]
                                        ?.pricings?.[0]?.price,
                                    currencyCode: currency_code,
                                  })}
                                </p>
                              </motion.div>
                            ))}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </>
            ) : (
              <h5 className="mt-5 mb-5 text-center">No Data Available</h5>
            )}
          </secondaryComponents.LoaderHelper>
        </motion.div>
      ) : null}
    </div>
  );
};

export default BulkRestockComboBox;
