import { ToastContext } from "../../../hooks/context";
import { useContext } from "react";
import primaryComponents from "../../primaryComponents";
import { useDeleteCustomerMutation } from "../../../hooks/api/mutations/useCustomer";

const CustomerDeletion = ({
  customerDetails,
  closeWidget,
  handleFetchCustomers,
}) => {
  const deleteCustomer = useDeleteCustomerMutation();
  const triggerToast = useContext(ToastContext);

  const handleDeleteCustomerById = () => {
    deleteCustomer.mutate(
      { id: customerDetails?.id },
      {
        onSuccess: () => {
          handleFetchCustomers();
          triggerToast("Successfully deleted this customer", "success");
          closeWidget();
        },
      },
    );
  };

  return (
    <div className="py-3 px-3">
      <h4 className="text-center font-weight-semibold">
        Are you sure you want to delete this customer
      </h4>
      <div
        style={{
          backgroundColor: "#F3F1FC",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          borderRadius: "8px",
          margin: "20px 0",
        }}
      >
        <p
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span className="dark--text font-weight-semibold mb-1">
            Customer Name
          </span>
          <span>{customerDetails?.full_name || "N/A"}</span>
        </p>
      </div>

      <div
        className="d-flex justify-content-between mt-2"
        style={{ gap: "20px" }}
      >
        <primaryComponents.Button
          onClick={closeWidget}
          classNames={"btn btn--outline cover"}
        >
          Cancel
        </primaryComponents.Button>
        <primaryComponents.Button
          onClick={handleDeleteCustomerById}
          classNames={"btn btn--danger cover"}
          isLoading={deleteCustomer.isLoading}
          isDisabled={deleteCustomer.isLoading}
          loadingText="Deleting..."
        >
          Continue
        </primaryComponents.Button>
      </div>
    </div>
  );
};
export default CustomerDeletion;
