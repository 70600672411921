import Images from "../../../assets/images";
import styles from "./AllProductsTable.module.scss";
import useAllProductsTable from "./useAllProductsTable";
import secondaryComponents from "..";
import ProductTableActions from "./ProductTableActions";
import primaryComponents from "../../primaryComponents";
import Icon from "../../../assets/icons";
import { useNavigate } from "react-router-dom";
import { formatCurrency, truncateString } from "../../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { pathConstants } from "../../../routes/pathContants";

const AllProductsTable = ({
  products: productList,
  metaDetails,
  gotoPage,
  handleFetchproducts,
  selectedProducts,
  setSelectedProducts,
}) => {
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  const {
    instance,
    openAddStockWidget,
    selectedProduct,
    isFetchingProductDetails,
    isOpenDeleteDialog,
    isDeletingProduct,
    selectedDeleteProduct,
    products,
    openedVariants,
    selectBarcodeProduct,
    setSelectBarcodeProduct,
    refetchSelectedProduct,
    toggleVariantDisplay,
    setSelectedDeleteProduct,
    setSelectedProductID,
    setIsOpenDeleteDialog,
    handleDeleteProduct,
    setOpenAddStockWidget,
  } = useAllProductsTable({
    productList,
    handleFetchproducts,
    selectedProducts,
    setSelectedProducts,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;
  const navigate = useNavigate();

  return (
    <div className={styles.allProductsTable}>
      <div className={styles.tableSection}>
        {productList?.length > 0 ? (
          <>
            <secondaryComponents.TableHelper
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              headerGroups={headerGroups}
              rows={rows}
              prepareRow={prepareRow}
              useDefaultTableMobile={false}
            />

            {/* mobile view */}
            <div className={styles.mobileViewTable}>
              {products?.map((product) => {
                const productTypeName = product?.is_service
                  ? "store_service_properties"
                  : "store_product_properties";
                return (
                  <div className={styles.rowItem} key={product?.id}>
                    <div className={styles.rowItem__header}>
                      <h6 className="mb-0">
                        {truncateString(product?.name, 300)}
                      </h6>
                      <ProductTableActions
                        setIsOpenDeleteDialog={setIsOpenDeleteDialog}
                        setSelectedProduct={setSelectedDeleteProduct}
                        product={product}
                        isMobile={true}
                      />
                    </div>
                    {/* row body */}
                    <div className={styles.rowItem__body}>
                      <div className={styles.item}>
                        <p>Selling Price</p>
                        {product?.[productTypeName]?.[0]?.pricings?.[0]
                          ?.price ? (
                          <h6 className="mb-0 d-flex align-items-center">
                            <span
                              style={{
                                width: "5px",
                                height: "5px",
                                borderRadius: "5px",
                              }}
                              className="error mr-1"
                            ></span>
                            <span>
                              {formatCurrency({
                                value: (
                                  product?.[productTypeName]?.[0]?.pricings?.[0]
                                    ?.price -
                                  (product?.[productTypeName]?.[0]
                                    ?.pricings?.[0]?.price *
                                    product?.[productTypeName]?.[0]
                                      ?.pricings?.[0]?.discount_rate) /
                                    100
                                ).toFixed(2),

                                currencyCode: currency_code,
                              })}

                              <span className="ml-2 purple--text smallest-text-size">
                                (
                                {
                                  product?.[productTypeName]?.[0]?.pricings?.[0]
                                    ?.pricing_type?.label
                                }
                                )
                              </span>
                            </span>
                          </h6>
                        ) : (
                          <p>N/A</p>
                        )}
                      </div>
                      <div className={styles.item}>
                        <p>Stock Left</p>

                        {product?.variants?.length > 0 ? (
                          <p>N/A</p>
                        ) : product?.[productTypeName]?.[0]?.stock_quantity ? (
                          <span
                            style={{
                              color: "white",
                              backgroundColor:
                                product?.[productTypeName]?.[0]
                                  ?.stock_quantity >
                                product?.[productTypeName]?.[0]
                                  ?.minimum_stock_quantity
                                  ? "#19ae57"
                                  : "red",
                              borderRadius: "20px",
                              padding: "2px 10px 0px 10px",
                              lineHeight: 2,
                              fontSize: "10px",
                            }}
                          >
                            {product?.[productTypeName]?.[0]?.stock_quantity} in
                            stock
                          </span>
                        ) : (
                          <p>N/A</p>
                        )}
                      </div>
                      <div className={styles.item}>
                        <p>Variants</p>
                        {product?.variants?.length > 0 ? (
                          <p
                            className="dark--text"
                            onClick={() => toggleVariantDisplay(product?.id)}
                          >
                            Yes{" "}
                            <FontAwesomeIcon
                              className="purple--text ml-1"
                              icon={
                                openedVariants.includes(product?.id)
                                  ? "angle-up"
                                  : "angle-down"
                              }
                            />
                          </p>
                        ) : (
                          <p className="dark--text">No</p>
                        )}
                      </div>
                    </div>
                    {/* sub row item */}
                    {openedVariants?.includes(product?.id) ? (
                      <>
                        {product?.variants?.map((variantItem) => (
                          <div
                            className={styles.rowItem__subBody}
                            key={variantItem?.id}
                          >
                            <div
                              className={`${styles.item} ${styles.itemHeader}`}
                            >
                              <h6>
                                {variantItem?.option} - {variantItem?.value}
                              </h6>
                            </div>
                            <div className={styles.item}>
                              <p>Selling Price</p>
                              {variantItem?.store_variant_properties?.[0]
                                ?.pricings ? (
                                <h6 className="mb-0 d-flex align-items-center">
                                  <span
                                    style={{
                                      width: "5px",
                                      height: "5px",
                                      borderRadius: "5px",
                                    }}
                                    className="error mr-1"
                                  ></span>
                                  <span>
                                    {formatCurrency({
                                      value: (
                                        variantItem
                                          ?.store_variant_properties?.[0]
                                          ?.pricings?.[0]?.price -
                                        (variantItem
                                          ?.store_variant_properties?.[0]
                                          ?.pricings?.[0]?.price *
                                          variantItem
                                            ?.store_variant_properties?.[0]
                                            ?.pricings?.[0]?.discount_rate) /
                                          100
                                      ).toFixed(2),

                                      currencyCode: currency_code,
                                    })}

                                    <span className="ml-2 purple--text smallest-text-size">
                                      (
                                      {
                                        variantItem
                                          ?.store_variant_properties?.[0]
                                          ?.pricings?.[0]?.pricing_type?.label
                                      }
                                      )
                                    </span>
                                  </span>
                                </h6>
                              ) : (
                                <p>N/A</p>
                              )}
                            </div>
                            <div className={styles.item}>
                              <p>Stock Left</p>

                              <span
                                style={{
                                  color: "white",
                                  backgroundColor:
                                    variantItem?.store_variant_properties?.[0]
                                      ?.stock_quantity >
                                    variantItem?.store_variant_properties?.[0]
                                      ?.minimum_stock_quantity
                                      ? "#19ae57"
                                      : "red",
                                  borderRadius: "20px",
                                  padding: "2px 10px 0px 10px",
                                  lineHeight: 2,
                                  fontSize: "10px",
                                }}
                              >
                                {
                                  variantItem?.store_variant_properties?.[0]
                                    ?.stock_quantity
                                }{" "}
                                in stock
                              </span>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : null}

                    <div className={styles.rowItem__bottom}>
                      <secondaryComponents.NavigateWithPermission
                        permissionParentKey={"Inventory Management"}
                        permissionChildKey="can_restock_products_bulk_and_single_restock"
                      >
                        <primaryComponents.Button
                          classNames={`btn btn--primary ${styles.btn}`}
                          isDisabled={product?.is_service}
                          onClick={() => {
                            setSelectedProductID(product?.id);
                            setOpenAddStockWidget(true);
                          }}
                        >
                          Add Stock
                        </primaryComponents.Button>
                      </secondaryComponents.NavigateWithPermission>
                      <div className="d-flex" style={{ gap: "5px" }}>
                        <secondaryComponents.NavigateWithPermission
                          permissionParentKey={"Inventory Management"}
                          permissionChildKey="can_edit_or_update_product"
                        >
                          <primaryComponents.Button
                            className={styles.editBtn}
                            onClick={() => {
                              if (product?.is_composite)
                                navigate(
                                  pathConstants.INVENTORY_PRODUCTS_EDIT_COMPOSITE(
                                    {
                                      id: product["composite_product_id"],
                                    },
                                  ),
                                );
                              else if (product?.is_service)
                                navigate(
                                  pathConstants.INVENTORY_SERVICE_EDIT({
                                    id: product["id"],
                                  }),
                                );
                              else
                                navigate(
                                  pathConstants.INVENTORY_PRODUCT_EDIT({
                                    id: product["id"],
                                  }),
                                );
                            }}
                          >
                            <img
                              src={Icon.editWrite}
                              alt=""
                              width="16px"
                              height="16px"
                            />
                          </primaryComponents.Button>
                        </secondaryComponents.NavigateWithPermission>
                        {!product.is_service ? (
                          <button
                            onClick={() => setSelectBarcodeProduct(product)}
                            className={styles.editBtn}
                          >
                            <img src={Icon.barCodeCreateIcon} alt="" />
                          </button>
                        ) : (
                          <button
                            className={styles.editBtn}
                            style={{ border: "none" }}
                          ></button>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <secondaryComponents.Pagination
              totalPageNumber={metaDetails?.last_page}
              handlePageClick={(value) => gotoPage(value?.selected + 1)}
              currentPage={metaDetails?.current_page}
            />
          </>
        ) : (
          <div className="text-center mt-5 mb-5">
            <img src={Images.productsEmptyState} width="250px" alt="" />
            <h4 className="font-weight-semibold text-center mt-4">
              You have no products
            </h4>
            <p className="text-center">
              Use the button above to add your products. When you do, they would
              appear here.
            </p>
          </div>
        )}
      </div>

      {/* add stock modal */}
      <secondaryComponents.SideModal
        width={1200}
        isActive={openAddStockWidget}
        closeModal={() => setOpenAddStockWidget(false)}
      >
        <secondaryComponents.LoaderHelper
          classNames="mt-5"
          isLoading={isFetchingProductDetails}
        >
          <secondaryComponents.AddProductStock
            closeWidget={() => setOpenAddStockWidget(false)}
            selectedProduct={selectedProduct}
            handleFetchproducts={handleFetchproducts}
            refetchSelectedProduct={refetchSelectedProduct}
          />
        </secondaryComponents.LoaderHelper>
      </secondaryComponents.SideModal>

      {/* barcode */}
      <secondaryComponents.SideModal
        isActive={selectBarcodeProduct !== null}
        closeModal={() => setSelectBarcodeProduct(null)}
      >
        <secondaryComponents.ProductBarcode
          closeWidget={() => setSelectBarcodeProduct(null)}
          selectedProduct={selectBarcodeProduct}
          handleFetchproducts={handleFetchproducts}
        />
      </secondaryComponents.SideModal>

      {/* delete confirmation modal */}
      {isOpenDeleteDialog && (
        <secondaryComponents.ConfirmDialog
          title="Are you sure you want to delete this product"
          isOpen={isOpenDeleteDialog}
          setIsOpen={setIsOpenDeleteDialog}
          isLoading={isDeletingProduct}
          handler={handleDeleteProduct}
        >
          <div
            style={{
              backgroundColor: "#F3F1FC",
              padding: "16px",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              borderRadius: "8px",
            }}
          >
            <p
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span className="dark--text font-weight-semibold mb-1">
                Product Name
              </span>
              <span>{selectedDeleteProduct?.name}</span>
            </p>
          </div>
        </secondaryComponents.ConfirmDialog>
      )}
    </div>
  );
};
export default AllProductsTable;
