import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const CreditLimitSchema = yup.object({
  creditLimit: yup
    .number()
    .required("Credit Limit is required")
    .typeError("Amount must be a number")
    .positive("Invalid amount"),
});

const Validation = (creditLimit) => {
  return useForm({
    resolver: yupResolver(CreditLimitSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      creditLimit,
    },
  });
};

export default Validation;
