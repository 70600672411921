import secondaryComponents from "..";
import primaryComponents from "../../primaryComponents";
import "./ImageUploadModal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageUploadModalLogic from "./ImageUploadModalLogic";

const ImageUploadModal = ({ showImageModal, setShowImageModal, setImage }) => {
  const {
    uploadProgress,
    setImageSelected,
    hiddenFileInput,
    isLoading,
    imageList,
  } = ImageUploadModalLogic({ setImage, setShowImageModal });

  return (
    <secondaryComponents.Modal
      width="800px"
      isActive={showImageModal}
      closeModal={() => {
        setShowImageModal(false);
      }}
    >
      <div className="image-upload">
        {/* progress loader */}
        <div className={`upload-progress ${uploadProgress !== 0 && "active"}`}>
          <div
            className="upload-progress__loader"
            style={{ width: `${uploadProgress}%` }}
          ></div>
        </div>

        {/* upload section */}
        <div className="image-upload__container">
          <h4>Upload Image</h4>

          <div className="mt-3 d-flex align-items-center justify-content-between">
            <input
              style={{ display: "none" }}
              type="file"
              accept="image/x-png,image/jpeg"
              multiple="multiple"
              ref={hiddenFileInput}
              onChange={setImageSelected}
            />
            <p>Select image from your gallery</p>
            <primaryComponents.Button
              classNames="btn btn--primary"
              onClick={() => {
                hiddenFileInput.current.click();
              }}
            >
              Click to upload
              <FontAwesomeIcon
                className="ml-2"
                icon="upload"
                style={{ fontSize: "15px" }}
              />
            </primaryComponents.Button>
          </div>

          {!isLoading && (
            <div className="image-upload__container__items">
              {imageList.map((imageItem) => (
                <div
                  key={imageItem.id}
                  className="image-item"
                  onClick={() => {
                    setImage(imageItem.url);
                    setShowImageModal(false);
                  }}
                >
                  <img src={imageItem.url} alt="library" />
                </div>
              ))}
            </div>
          )}

          {isLoading && (
            <div className="text-center">
              <FontAwesomeIcon
                icon={["fas", "spinner"]}
                className="fa-spin purple--text mt-5"
                style={{ fontSize: "25px" }}
              />
            </div>
          )}
        </div>
      </div>
    </secondaryComponents.Modal>
  );
};

export default ImageUploadModal;
