import { useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useMarkDocketHasPrinted } from "../../../hooks/api/mutations/useSection";
import { useReactToPrint } from "react-to-print";
import { useGetSectionItemDetails } from "../../../hooks/api/queries/useSection";
import { usePDF } from "@react-pdf/renderer";
import MobileDoc from "./MobileDoc";

const useSectionView = ({
  section_id,
  allowPrint = false,
  setAllowPrint,
  isDocket = false,
}) => {
  // Detect if the device is mobile
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const sectionPrintComponent = useRef();
  const queryClient = useQueryClient();
  const { business_name, address } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const { data: sectionDetails } = useGetSectionItemDetails({
    id: section_id,
    enabled: allowPrint,
  });
  const { handleMarkDocketHasPrinted } = useMarkDocketHasPrinted({
    id: section_id,
    order_id: sectionDetails?.order?.id,
  });

  const handlePrint = useReactToPrint({
    content: () => sectionPrintComponent.current,
    onAfterPrint: () => clearAfterPrint(),
    removeAfterPrint: true,
  });

  const handlePrintOnMobile = ({ url }) => {
    window.open(url);
    clearAfterPrint();
  };

  const [instance, updateInstance] = usePDF({
    document: (
      <MobileDoc
        address={address}
        business_name={business_name}
        sectionDetails={sectionDetails}
      />
    ),
  });

  const [currentUrl, setCurrentUrl] = useState({
    count: 0,
    url: instance?.url,
  });

  useEffect(() => {
    if (instance?.url) {
      if (currentUrl?.count === 1) handlePrintOnMobile({ url: instance?.url });
      setCurrentUrl({ count: currentUrl?.count + 1, url: instance?.url });
    }
  }, [instance?.url]);

  useEffect(() => {
    if (allowPrint && sectionDetails) {
      if (!isMobile) handlePrint();
      else {
        if (currentUrl?.count >= 2)
          handlePrintOnMobile({ url: currentUrl?.url });
        else
          updateInstance(
            <MobileDoc
              address={address}
              business_name={business_name}
              sectionDetails={sectionDetails}
            />,
          );
      }
    }
  }, [allowPrint, sectionDetails]);

  const clearAfterPrint = () => {
    if (isDocket && !sectionDetails?.is_printed) {
      queryClient.invalidateQueries(["getOrders"]);
      handleMarkDocketHasPrinted();
    }
    setAllowPrint(false);
  };

  return {
    business_name,
    address,
    sectionDetails,
    sectionPrintComponent,
    allowPrint,
    clearAfterPrint,
  };
};

export default useSectionView;
