import styles from "./FoodMenuWidget.module.scss";
import primaryComponents from "../../primaryComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useFoodMenuWidget from "./useFoodMenuWidget";
import secondaryComponents from "..";
import QRCode from "react-qr-code";
import CopyToClipboard from "react-copy-to-clipboard";
import Icon from "../../../assets/icons";
import { useReactToPrint } from "react-to-print";
import {
  QRScanHandPhoneIcon,
  QRScanHandPhoneTouchIcon,
  ScanCameraIcon,
  ScanTextIcon,
  ShareIcon,
  WebIcon,
} from "../../../assets/svgIcons";
import React, { useRef, useEffect } from "react";

const FoodMenuWidget = React.forwardRef(({ closeWidget, menuId }, ref) => {
  const {
    isFetchingMenuDetails,
    menuDetails,
    setSize,
    printSize,
    setPrintSize,
    setIsSize,
    onCopy,
    handleShare,
    getQRCodeContainerSize,
  } = useFoodMenuWidget({
    menuId,
  });

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      if (componentRef.current) {
        const printContainer = componentRef.current.querySelector(
          `.${styles.qrCodeContainer}`,
        );
        if (printContainer) {
          printContainer.style.maxWidth = getQRCodeContainerSize(printSize);
        }
      }
    },
  });

  useEffect(() => {
    setPrintSize(setSize);
  }, [setSize]);

  return (
    <div className={styles.foodMenuWidget}>
      <div>
        <primaryComponents.Button
          classNames={"btn btn--outline smallBtn"}
          onClick={closeWidget}
        >
          <FontAwesomeIcon
            icon="angle-left"
            className="purple--text"
            style={{ fontSize: "20px" }}
          />
        </primaryComponents.Button>
        <h4 className="mt-2">Continental</h4>
      </div>
      <div>
        <primaryComponents.SelectField
          options={[
            "4x4 inches",
            "4x5 inches",
            "11.69x8.26 Inches (A4 Paper size)",
          ]}
          value={setSize || "Select qr code size"}
          label="QR Code Size"
          classNames="white"
          isRequired={true}
          onChange={(value) => {
            setIsSize(value);
          }}
          floatError={false}
        />
      </div>

      <secondaryComponents.LoaderHelper
        isLoading={isFetchingMenuDetails}
        classNames="mt-5"
      >
        <div
          className={`${styles.printSection} ${styles.noSizeSelected}`}
          ref={componentRef}
        >
          {setSize ? (
            <div
              className={styles.qrCodeContainer}
              style={{
                maxWidth: getQRCodeContainerSize(setSize),
              }}
            >
              <div className="d-flex justify-content-between mb-3">
                <img
                  height={35}
                  width={100}
                  src={menuDetails?.logo_url}
                  alt="Merchant Logo"
                />
                <div>
                  <p className="smallest-text-size">Powered by </p>
                  <p className="smallest-text-size font-weight-semibold purple--text">
                    LUMI BUSINESS{" "}
                  </p>
                </div>
              </div>
              <div className={styles.qrCodeSection}>
                <div className={styles.qrSection}>
                  <div className="p-2" style={{ maxWidth: "50%" }}>
                    <ScanTextIcon
                      width={
                        setSize === "4x4 inches"
                          ? "150px"
                          : setSize === "4x5 inches"
                            ? "150px"
                            : "250px"
                      }
                      height={setSize === "4x4 inches" ? "167px" : "220px"}
                    />
                  </div>

                  <div className={styles.verticalLine}></div>
                  <QRCode
                    size={396}
                    style={{
                      height: "auto",
                      maxWidth: "400px",
                      width: "100%",
                      paddingTop: "25px",
                      paddingBottom: "25px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                    viewBox={"0 0 256 256"}
                    value={`${window.location.origin}/storefront/${
                      menuDetails?.slug_url?.split("/menu/")[1]
                    }`}
                  />
                </div>
                <hr className={styles.horizontalLine} />
                <div className="d-flex p-2 flex-wrap justify-content-center">
                  <WebIcon />
                  <p className="smallest-text-size purple--text ml-2">
                    {`${window.location.origin}/storefront/${
                      menuDetails?.slug_url?.split("/menu/")[1]
                    }`}
                  </p>
                </div>
                <hr className={styles.horizontalLine} />
                <div className="d-flex items-center p-2 flex-wrap">
                  <div className={styles.circle}></div>
                  <ScanCameraIcon />
                  <p className="smallest-text-size dark--text ml-2">
                    open your camera
                  </p>
                </div>
                <hr className={styles.horizontalLine} />
                <div className="d-flex items-center p-2 flex-wrap">
                  <div className={styles.circle}></div>
                  <QRScanHandPhoneIcon />
                  <p className="smallest-text-size dark--text ml-2">
                    Hover the Camera over the QR Code
                  </p>
                </div>
                <hr className={styles.horizontalLine} />
                <div className="d-flex pb-2 items-center p-2 flex-wrap">
                  <div className={styles.circle}></div>
                  <QRScanHandPhoneTouchIcon />
                  <p className="smallest-text-size dark--text ml-2">
                    Tap on the notification to open the link that appears
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <h3>Select QR Code Size above</h3>
          )}
        </div>
        <div className={styles.foodMenuWidget__bottom}>
          <CopyToClipboard
            text={`${window.location.origin}/storefront/${
              menuDetails?.slug_url?.split("/menu/")[1]
            }`}
            onCopy={() => onCopy()}
          >
            <primaryComponents.Button classNames={"btn btn--outline cover"}>
              <img className="mr-2" src={Icon.copy} alt="" />
              <span>Copy Link</span>
            </primaryComponents.Button>
          </CopyToClipboard>
          <primaryComponents.Button
            classNames={"btn btn--primary cover"}
            onClick={handlePrint}
          >
            <img
              className="mr-2"
              height={18}
              width={18}
              src={Icon.download}
              alt=""
            />
            <span>Download</span>
          </primaryComponents.Button>
          <primaryComponents.Button
            classNames={"btn btn--outline cover"}
            onClick={() =>
              handleShare(
                `${window.location.origin}/storefront/${
                  menuDetails?.slug_url?.split("/menu/")[1]
                }`,
              )
            }
          >
            <ShareIcon width="20" />
            <span className="ml-2">Share</span>
          </primaryComponents.Button>
        </div>
      </secondaryComponents.LoaderHelper>
    </div>
  );
});

FoodMenuWidget.displayName = "FoodMenuWidget";

export default FoodMenuWidget;
