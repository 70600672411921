import "./SearchField.scss";
import Icon from "../../../assets/icons";
import primaryComponents from "..";
import { useState } from "react";

const SearchField = ({
  name,
  placeholder,
  trigger,
  value,
  triggerOnInput = false,
  showsearchBtn = false,
  classNames = "",
  useControlledValue = false,
  setValue = () => {},
  isScan = false,
  borderColor = "#7647ee",
  scanIcon,
  showClearBtn = false,
}) => {
  const [searchValue, setSearchValue] = useState(value);
  return (
    <div style={{ borderColor }} className={`search__field ${classNames}`}>
      {scanIcon ? (
        scanIcon
      ) : (
        <img
          className="mr-2"
          src={isScan ? Icon.barCodeIcon : Icon.search}
          alt="search transactions"
        />
      )}

      <input
        type="text"
        placeholder={placeholder}
        name={name}
        value={useControlledValue ? value : searchValue}
        onKeyUp={(event) => {
          if (event.key === "Enter") trigger(event.target.value);
        }}
        onInput={(event) => {
          if (triggerOnInput) trigger(event.target.value);
        }}
        onChange={(event) => {
          if (useControlledValue) setValue(event.target.value);
          else setSearchValue(event.target.value);
          if (event.target.value === "") trigger(event.target.value);
        }}
      />
      {!triggerOnInput || showsearchBtn ? (
        <primaryComponents.Button
          classNames={"btn btn--primary search-btn"}
          onClick={() => trigger(searchValue)}
          style={{ background: borderColor }}
        >
          Search
        </primaryComponents.Button>
      ) : null}
      {showClearBtn && !showsearchBtn ? (
        <primaryComponents.Button
          classNames={"btn btn--primary search-btn"}
          onClick={() => {
            trigger("");
            setSearchValue("");
          }}
          style={{ background: borderColor }}
        >
          Clear
        </primaryComponents.Button>
      ) : null}
    </div>
  );
};

export default SearchField;
