import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  getViewMenuList,
  menuConfirmCustomer,
  menuOrder,
  ordersTables,
} from "../../../../../services/inventory";
import { ToastContext } from "../../../../../hooks/context";
import { useParams } from "react-router-dom";
import { cleanObject } from "../../../../../helpers";
import { useSelector } from "react-redux";
import countriesList from "../../../../../localdata/worldCountries.json";

const useCheckoutForm = ({
  setShowCheckoutModal,
  setShowOrderSummaryModal,
}) => {
  const triggerToast = useContext(ToastContext);
  const { id } = useParams();
  const { menuCartItems } = useSelector((state) => state.menuCartReducer);

  const countryCode = countriesList
    .filter((item) => item.countryCode !== "undefined")
    .find(
      (item) => item.currencyCode === menuCartItems[0].store.currency_code,
    )?.countryCode;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [tableNumber, setTableNumber] = useState("");
  const [numberOfDiners, setNumberOfDiners] = useState("");
  const [orderNotes, setOrderNotes] = useState("");
  const [orderType, setOrderType] = useState("DINE_IN");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [apartment, setApartment] = useState("");

  const { data: menuObject } = useQuery(["getViewMenuList", [id]], () =>
    getViewMenuList({ id: id }).then((res) => res?.data?.data),
  );

  useEffect(() => {
    if (menuObject?.allow_delivery && !menuObject?.allow_payment) {
      setOrderType("DELIVERY");
    }
  }, [menuObject]);

  const { isLoading: isLoadingConfirmCustomer, mutate: mutateConfirmCustomer } =
    useMutation({
      mutationFn: (data) => menuConfirmCustomer({ data }),
      onSuccess: () => {
        setShowCheckoutModal(false);
        setShowOrderSummaryModal(true);
      },
      onError: (error) => {
        triggerToast(`${error.response.data.message}`, "warning");
      },
    });

  const { isLoading: isLoadingOrder, mutate: mutateOrder } = useMutation({
    mutationFn: (data) => menuOrder({ data }),
    onSuccess: (data) => {
      const guest_token = localStorage.getItem("guest_token");

      triggerToast(data.data.detail, "success");
      localStorage.setItem("order_id", data.data.data.id);

      if (menuObject) {
        mutateConfirmCustomer(
          cleanObject({
            menu_id: menuObject.id,
            guest_token,
            order_id: data.data.data.id,
            customer_name: name,
            customer_phone_code: phoneCode || countryCode,
            customer_phone_number: phoneNumber,
            customer_email: email,
            order_table_id: tableNumber,
            diners: numberOfDiners,
            order_notes: orderNotes,
            order_type: orderType,
            zipcode: postalCode,
            customer_address:
              streetAddress || apartment
                ? streetAddress + `, ${apartment}`
                : "",
            state: state,
            country: country,
          }),
        );
      }
    },
    onError: (error) => {
      triggerToast(`${error.response.data.message}`, "warning");
    },
  });

  const { data: dataTables } = useQuery(
    ["orders-tables", menuObject],
    () => ordersTables(menuObject?.id).then((res) => res?.data?.data),
    { enabled: menuObject ? true : false },
  );

  const submitDetails = () => {
    const guest_token = localStorage.getItem("guest_token");

    if (menuObject) {
      mutateOrder({
        menu_id: menuObject.id,
        guest_token,
      });
    }
  };

  return {
    countriesList,
    name,
    email,
    phoneNumber,
    phoneCode,
    numberOfDiners,
    tableNumber,
    orderNotes,
    orderType,
    isLoadingOrder,
    isLoadingConfirmCustomer,
    countryCode,
    dataTables,
    menuObject,
    country,
    state,
    postalCode,
    streetAddress,
    apartment,
    setName,
    setEmail,
    setPhoneNumber,
    setPhoneCode,
    setNumberOfDiners,
    setTableNumber,
    setOrderNotes,
    setOrderType,
    setCountry,
    setState,
    setPostalCode,
    setStreetAddress,
    setApartment,
    submitDetails,
  };
};

export default useCheckoutForm;
