import { useContext, useMemo } from "react";
import { useTable } from "react-table";
import { formatCurrency, getPlatform, truncateString } from "../../../helpers";
import { Link } from "react-router-dom";
import { platformHandler } from "../../../hooks/context";
import { useSelector } from "react-redux";

const RecentTransactionTableLogic = ({ mostRecentTransaction }) => {
  const { platform } = useContext(platformHandler);
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "created_at",
        Cell: ({ cell: { value } }) => new Date(value).toLocaleString("en-us"),
      },
      {
        Header: "",
        accessor: "payer",
        Cell: ({ cell: { value, row } }) => (
          <Link
            to={`${getPlatform(platform)}/transactions/${row.original["id"]}`}
            className="purple--text"
          >
            {truncateString(value || "", 12)}
          </Link>
        ),
      },
      {
        Header: "",
        accessor: "amount",
        Cell: ({ cell: { value } }) =>
          formatCurrency({ value: value, currencyCode: currency_code }),
      },
    ],
    [],
  );

  const instance = useTable({ columns, data: mostRecentTransaction });

  return {
    data: mostRecentTransaction,
    instance,
  };
};

export default RecentTransactionTableLogic;
