import httpClient from "../axiosBase/axiosHandler";

export const createSubAccount = (payload) => {
  const response = httpClient.post("/business/", payload);
  return response;
};

export const getSubAccounts = () => {
  const response = httpClient.get("/business/");
  return response;
};

export const switchBusinessAccount = (payload) => {
  const response = httpClient.post("/business/switch-business/", payload);
  return response;
};

export const updateSubAccount = (payload) => {
  const response = httpClient.post("/business/update-sub-account/", payload);
  return response;
};
