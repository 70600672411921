import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  getReconciliationsProducts,
  rejectReconciliation,
  approveReconciliation,
  getReconciliationDetails,
} from "../../../../../services/inventory";
import { useInView } from "react-intersection-observer";
import { useContext, useEffect, useState } from "react";
import { platformHandler, ToastContext } from "../../../../../hooks/context";
import { getPlatform } from "../../../../../helpers";

const useReconciliationReview = () => {
  const { id } = useParams();
  const { ref: autoScrollRef, inView } = useInView();
  const [reconciliedData, setReconciliedData] = useState({});
  const navigate = useNavigate();
  const triggerToast = useContext(ToastContext);
  const [isReconciliationPrompt, setIsReconciliationPrompt] = useState(false);
  const [reconciliationType, setReconciliationType] = useState(null);
  const { platform } = useContext(platformHandler);
  const [isReview] = useState(true);

  const {
    isLoading: isFetchingReconciliationDetails,
    data: reconciliationDetails,
  } = useQuery(["getReconciliationDetails", id], () =>
    getReconciliationDetails(id).then((res) => res?.data?.data),
  );

  const { isLoading, data, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useInfiniteQuery(
      ["getReconciliationsProducts", [id, isReview]],
      ({ pageParam = 1 }) =>
        getReconciliationsProducts({
          id: id,
          page: pageParam,
          review: isReview,
        }).then((res) => res?.data),
      {
        cacheTime: 0,
        getNextPageParam: ({ meta: { next_page } }) =>
          next_page === null ? undefined : next_page,
      },
    );

  const products = new Map();
  // eslint-disable-next-line array-callback-return
  data?.pages?.map((page) => {
    products.set(`${page.meta.current_page}`, page.data);
  });
  const productsList = [].concat(...products.values());

  useEffect(() => {
    if (inView && hasNextPage) fetchNextPage();
  }, [inView, fetchNextPage, hasNextPage]);

  const { mutate: approve, isLoading: isApproving } = useMutation((data) =>
    approveReconciliation(data),
  );
  const approveReconciledData = () => {
    approve(
      { id: id },
      {
        onSuccess: (res) => {
          triggerToast(res?.data?.detail, "success");
          navigate(getPlatform(platform) + "/inventory/reconciliations");
        },
      },
    );
  };

  const { mutate: reject, isLoading: isRejecting } = useMutation((data) =>
    rejectReconciliation(data),
  );
  const rejectReconciledData = () => {
    reject(
      { id: id },
      {
        onSuccess: (res) => {
          triggerToast(res?.data?.detail, "success");
          navigate(getPlatform(platform) + "/inventory/reconciliations");
        },
      },
    );
  };

  const proceedReconciliation = () => {
    if (reconciliationType === "approve") approveReconciledData();
    else rejectReconciledData();
  };

  return {
    isFetchingReconciliationDetails,
    reconciliationDetails,
    isRejecting,
    isApproving,
    products: productsList || [],
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    inView,
    reconciliedData,
    reconciliationType,
    isReconciliationPrompt,
    autoScrollRef,
    fetchNextPage,
    approveReconciledData,
    rejectReconciledData,
    setReconciliedData,
    setIsReconciliationPrompt,
    proceedReconciliation,
    setReconciliationType,
  };
};
export default useReconciliationReview;
