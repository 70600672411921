import styles from "./MultipleSelectField.module.scss";
import Select from "react-select";
import { useSelectField } from "./MultipleSelectFieldLogic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MultipleSelectField = ({
  value = null,
  onChange = () => {},
  options = [],
  idKey,
  nameKey,
  placeholder,
  isRequired = false,
  label = null,
  isDisabled = false,
  errorMessage = undefined,
  isSearchable = true,
  isClearable = true,
  minHeight = "50px",
  fontSize = "16px",
  floatError = true,
}) => {
  const { items, selectedItem } = useSelectField({
    options,
    valueKey: idKey,
    labelKey: nameKey,
    value,
  });

  const customStyles = (bodyStyles) => {
    return {
      control: (provided, state) => ({
        ...provided,
        border:
          state.isFocused || errorMessage !== undefined
            ? `1px solid ${
                errorMessage !== undefined ? "#dc3545" : "#7647ee"
              } !important`
            : "1px solid #cccccc",
        boxShadow: "none",
        "&:hover": {
          border: "1px solid #7647ee !important",
          boxShadow: "none",
        },
        ...bodyStyles,
      }),

      option: (styles, { isFocused }) => ({
        ...styles,
        backgroundColor: isFocused ? "#7647ee" : null,
        color: isFocused ? "#fff" : null,
        zIndex: 1,
      }),
      menu: (base) => ({
        ...base,
        zIndex: 10,
      }),
    };
  };

  return (
    <div className={styles["custom-dropdown"]}>
      {label !== null && (
        <label htmlFor="" data-testid="label">
          {isRequired && <span className="text-danger">*</span>}
          <span>{label}</span>
        </label>
      )}
      <Select
        className={styles["custom-dropdown__select"]}
        classNamePrefix={styles["custom-dropdown__select"]}
        options={items}
        onChange={(answer, others) => {
          onChange(answer, others);
        }}
        defaultValue={value === null ? null : selectedItem}
        placeholder={placeholder}
        isDisabled={isDisabled}
        label={label}
        styles={customStyles({ minHeight: minHeight, fontSize: fontSize })}
        isMulti
        isSearchable={isSearchable}
        isClearable={isClearable}
      />
      {/* error message */}
      {floatError || errorMessage !== undefined ? (
        <p
          className={`${styles["error-message"]} text-danger ${
            errorMessage !== undefined ? styles["show"] : ""
          }`}
          data-testid="error-message"
        >
          <FontAwesomeIcon
            icon={["fas", "exclamation-circle"]}
            className="mr-2"
            style={{ fontSize: "13px" }}
          />
          {errorMessage !== undefined && <span>{errorMessage.message}</span>}
        </p>
      ) : null}
    </div>
  );
};

export default MultipleSelectField;
