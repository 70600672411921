/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import { DeleteIcon, StorefrontAddIcon } from "../../../../assets/svgIcons";
import secondaryComponents from "../..";
import primaryComponents from "../../../primaryComponents";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import useAllCampaigns from "../../../../pages/appPages/Loyalty/Campaign/AllCampaigns/useAllCampaigns";
import CampaignEmpty from "../../StoreCart/CampaignEmpty";
import Modal from "../../Modal/Modal";
import UpdateCreditLogModal from "../../UpdateCreditLogModal";

const AddCustomerSection = ({
  customerDetails,
  setCustomerDetails,
  toggleAddCustomer,
  customer,
  title = "",
}) => {
  const [isAddCustomer, setIsAddCustomer] = useState(false);
  const { campaigns } = useAllCampaigns();
  const [isCampaigns, setIsCampaigns] = useState(false);
  const [isOutStanding, setIsOutStanding] = useState(false);
  const [isLoyaltyEnabled, setIsLoyaltyEnabled] = useState(false);

  useEffect(() => {
    if (campaigns && campaigns.length > 0) {
      setIsLoyaltyEnabled(true);
    } else {
      setIsLoyaltyEnabled(false);
    }
  }, [campaigns]);

  useEffect(() => {
    if (customerDetails?.customerName) {
      setIsOutStanding(true);
    }
  }, [customerDetails?.customerName, customer?.outstanding_balance]);

  const addCustomer = ({
    customer_id,
    customer_phone_number,
    customer_full_name,
    customer_email,
    customer_phone_code,
  }) => {
    setCustomerDetails({
      customerId: customer_id,
      customerPhoneNumber: customer_phone_number,
      customerEmail: customer_email,
      customerName: customer_full_name,
      customerPhoneCode: customer_phone_code,
    });
  };

  const handleAddCustomerClick = () => {
    if (title !== "") {
      toggleAddCustomer();
    } else {
      setIsAddCustomer(true);
    }
  };

  const handleSwitchChange = () => {
    if (!campaigns || campaigns.length === 0) {
      setIsCampaigns(true);
    } else {
      setIsLoyaltyEnabled((prev) => !prev);
    }
  };

  return (
    <>
      <div className={globalStyles.cardWithBorder}>
        <div className="d-flex justify-content-between mb-3">
          <h5 className="mb-0">Select Existing Users Here</h5>
          <a
            className="d-flex align-items-center cursor-pointer"
            onClick={handleAddCustomerClick}
            style={{ gap: "5px" }}
          >
            <StorefrontAddIcon width="18" height="18" />
            <p className="purple--text smallest-text-size">Add new customer</p>
          </a>
        </div>
        <secondaryComponents.CustomersSearchField
          setCustomerDetails={addCustomer}
          phone={"customerPhoneNumber"}
          email
          title={"updateCustomerOrderDetails"}
        />
        {customerDetails?.customerName ? (
          <>
            <hr />

            <div
              className="d-flex flex-wrap justify-content-between"
              style={{ gap: "15px" }}
            >
              <h6 className="mb-0">{customerDetails?.customerName}</h6>
              <div
                className="d-flex align-items-center"
                style={{ gap: "10px" }}
              >
                <div className="text-right">
                  <h6 className="mb-0 smallest-text-szie">
                    {customerDetails?.customerPhoneNumber !== ""
                      ? customerDetails?.customerPhoneCode
                      : ""}{" "}
                    {customerDetails?.customerPhoneNumber}
                  </h6>
                  <p className="smallest-text-szie mb-0">
                    {customerDetails?.customerEmail}
                  </p>
                </div>
                <span
                  onClick={() =>
                    setCustomerDetails((prev) => ({
                      ...prev,
                      customerPhoneNumber: "",
                      customerEmail: "",
                      customerName: "",
                    }))
                  }
                  style={{ cursor: "pointer" }}
                >
                  <DeleteIcon />
                </span>
              </div>
            </div>
          </>
        ) : null}

        {customerDetails?.customerName ? (
          <div className={`${globalStyles.cardWithBorder} mt-4`}>
            <div className="d-flex justify-content-between items-center">
              <div>
                <p className="mb-0 small-text-size">
                  Enable or Disable Loyalty for this customer
                </p>
              </div>
              <primaryComponents.Switch
                name={"loyalty"}
                id={customerDetails}
                isChecked={isLoyaltyEnabled}
                onChange={handleSwitchChange}
              />
            </div>
          </div>
        ) : null}
      </div>

      <secondaryComponents.Modal
        closeModal={() => setIsAddCustomer(false)}
        isActive={isAddCustomer}
        width={500}
      >
        <secondaryComponents.AddCustomer
          closeModal={() => setIsAddCustomer(false)}
          addCustomer={setCustomerDetails}
        />
      </secondaryComponents.Modal>

      <secondaryComponents.Modal
        closeModal={() => setIsCampaigns(false)}
        isActive={isCampaigns}
      >
        <CampaignEmpty />
      </secondaryComponents.Modal>

      <Modal
        isActive={isOutStanding && customer?.outstanding_balance >= 1}
        closeModal={() => {
          setIsOutStanding(false);
        }}
        width={500}
      >
        <UpdateCreditLogModal
          customer={customer}
          closeModal={() => {
            setIsOutStanding(false);
          }}
          type="outstanding"
        />
      </Modal>
    </>
  );
};
export default AddCustomerSection;
