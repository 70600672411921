import "../ProductCategoryModal/ProductCategoryModal.scss";
import primaryComponents from "../../primaryComponents";
import useUpdateCreditLogLogic from "./useUpdateCreditLogLogic";
import Validation from "./useValidation";
import { CreditLimitIcon } from "../../../assets/svgIcons";
import { useSelector } from "react-redux";
import { convertToTilteCase, formatCurrency } from "../../../helpers";

const UpdateCreditLogModal = ({ customer, closeModal, type = "credit" }) => {
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  const { updateCreditLimitById, creditLimit, setCreditLimit, isUpdating } =
    useUpdateCreditLogLogic({ customer, closeModal });
  const {
    register,
    formState: { errors },
    trigger,
    control,
    handleSubmit,
  } = Validation();
  return (
    <div className="product-category-modal-wrapper">
      {type === "credit" ? (
        <h5 className="font-weight-semibold text-center">
          Edit Credit limit for this customer
        </h5>
      ) : type === "outstanding" ? (
        <div className="">
          <CreditLimitIcon />
          <h4>
            {convertToTilteCase(customer?.full_name)} has an outstanding debt
            balance with your business
          </h4>
        </div>
      ) : (
        <div className="">
          <CreditLimitIcon />
          <p className="orange--text default-text-size items-center">
            {convertToTilteCase(customer?.full_name)} has exceeded their credit
            limit
          </p>
          <h6 className="mt-1">
            Current Credit Limit:{" "}
            <span className="gray--text">
              {formatCurrency({
                value: customer?.credit_limit,
                currencyCode: currency_code,
              })}
            </span>
          </h6>
        </div>
      )}
      <form onSubmit={handleSubmit(updateCreditLimitById)} className="mt-4">
        {/* Edit Credit limit for this customer */}
        {type === "outstanding" ? (
          <div>
            <h5 className="gray--text">Outstanding balance:</h5>
            <h4 className="orange--text">
              {formatCurrency({
                value: customer?.outstanding_balance,
                currencyCode: currency_code,
              })}

              <div className="pt-5 items-center text-center">
                <p className="small-text-size">
                  Do you want to proceed with the transaction?
                </p>
              </div>
            </h4>
          </div>
        ) : (
          <primaryComponents.AmountFieldV2
            control={control}
            classNames="white"
            name="creditLimit"
            label={
              type === "credit"
                ? "Set Credit Limit"
                : "Update Customer's Credit Limit to Proceed"
            }
            isRequired={true}
            value={creditLimit}
            placeholder={"Edit Credit limit for this customer"}
            onChange={({ target: { value } }) => {
              setCreditLimit(value);
            }}
            errorMessage={errors.creditLimit}
            onKeyUp={() =>
              errors.creditLimit !== undefined && trigger("creditLimit")
            }
            register={register}
          />
        )}
        <div className="modal-buttons">
          <primaryComponents.Button
            classNames={"btn btn--outline"}
            onClick={() => {
              closeModal();
            }}
          >
            Cancel
          </primaryComponents.Button>

          {type === "outstanding" ? (
            <primaryComponents.Button
              classNames={"btn btn--primary"}
              onClick={() => {
                closeModal();
              }}
            >
              Proceed
            </primaryComponents.Button>
          ) : (
            <primaryComponents.Button
              classNames={"btn btn--primary "}
              isLoading={isUpdating}
              isDisabled={isUpdating}
              loadingText="Updating..."
              type="submit"
            >
              Update
            </primaryComponents.Button>
          )}
        </div>
      </form>
    </div>
  );
};
export default UpdateCreditLogModal;
