import { useContext, useEffect, useState } from "react";
import { checkoutWithSplit } from "../../../../services/sales-point";
import { ToastContext } from "../../../../hooks/context";
import { useSelector } from "react-redux";
import { useQueryClient } from "react-query";

const SplitPaymentLogic = ({ closePaymentSession, orderDetails }) => {
  const { currency_code, business_phone_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  const [cashAmount, setCashAmount] = useState("");
  const [transferAmount, setTransferAmount] = useState("");
  const [cardAmount, setCardAmount] = useState("");
  const [creditAmount, setCreditAmount] = useState("");
  const [customerDetails, setCustomerDetails] = useState({
    customerPhoneCode: business_phone_code,
  });
  const [isConfirmingPayment, setIsConfirmingPayment] = useState(false);
  const [repaymentDate, setRepaymentDate] = useState(null);
  const triggerToast = useContext(ToastContext);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (orderDetails?.customer)
      setCustomerDetails({
        customerPhoneCode: orderDetails?.customer?.phone_code,
        customerName: orderDetails?.customer?.full_name,
        customerPhoneNumber: orderDetails?.customer?.phone,
        customerEmail: orderDetails?.customer?.email,
      });
  }, []);

  const payViaSplit = () => {
    if (
      Number(cardAmount) +
        Number(transferAmount) +
        Number(creditAmount) +
        Number(cashAmount) -
        Number(orderDetails?.total_amount) !==
      0
    )
      return triggerToast(
        `Please ensure that your estimated balance is ${currency_code} 0`,
        "warning",
      );

    setIsConfirmingPayment(true);
    const data = {
      order_id: orderDetails?.id,
      cash_amount: Number(cashAmount),
      transfer_amount: Number(transferAmount),
      card_amount: Number(cardAmount),
      credit_amount: Number(creditAmount),
      repayment_date: repaymentDate,
    };
    if (creditAmount > 0) {
      data.customer_name = customerDetails?.customerName;
      data.customer_phone_number = customerDetails?.customerPhoneNumber;
      data.customer_phone_code =
        customerDetails?.customerPhoneCode || business_phone_code;
      data.customer_email = customerDetails?.customerEmail;
    }

    checkoutWithSplit(data)
      .then(() => {
        triggerToast("Payment successful", "success");
        queryClient.invalidateQueries(["orderDetails", orderDetails?.id]);
        closePaymentSession();
      })
      .catch(() => {})
      .finally(() => setIsConfirmingPayment(false));
  };

  return {
    isConfirmingPayment,
    cashAmount,
    transferAmount,
    cardAmount,
    creditAmount,
    repaymentDate,
    customerDetails,
    setCustomerDetails,
    setTransferAmount,
    setCashAmount,
    setCardAmount,
    setCreditAmount,
    setRepaymentDate,
    payViaSplit,
  };
};

export default SplitPaymentLogic;
