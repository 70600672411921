import { useContext, useState } from "react";
import { useMutation, useQueries, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { getRandomIntInclusive } from "../../../helpers";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import {
  createExpense,
  getExpenseCategories,
} from "../../../services/expenses";
import { uploadImage } from "../../../services/media";
import {
  fetchBankList,
  resolveAccountNumber,
} from "../../../services/transfers";

const useCreateExpense = ({ closeWidget, handleFetchExpenses }) => {
  const queryClient = useQueryClient();
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const [isDisbursmentForm, setIsDisbursmentForm] = useState(false);
  const [expenseCategories, bankList] = useQueries([
    {
      queryKey: ["expenseCategories"],
      staleTime: Infinity,
      queryFn: () =>
        getExpenseCategories("").then((res) => {
          return res.data?.data;
        }),
    },

    {
      queryKey: ["bankList"],
      staleTime: Infinity,
      enabled: isDisbursmentForm,
      queryFn: () =>
        fetchBankList("").then((res) => {
          return res?.data?.data;
        }),
    },
  ]);

  const [expenseDetails, setExpenseDetails] = useState({
    name: "",
    store: "",
    amount: "",
    category: "",
    payment_method: "",
    description: "",
    expense_date: "",
    proof_url: null,
    make_refund: false,
    bank_code: "",
    account_number: "",
    account_name: null,
  });

  const handleProofUpload = (document) => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));
    const formData = new FormData();
    formData.append("file", document);
    uploadImage(formData)
      .then((res) => {
        setExpenseDetails((prevState) => ({
          ...prevState,
          proof_url: res?.data?.data?.url,
        }));
        triggerToast("Document successfully uploaded", "success");
      })
      .finally(() => setRequestLoaderProgress(100));
  };

  const { mutate: resolveAccount } = useMutation((data) =>
    resolveAccountNumber(data),
  );
  const handleResolveAccountNumber = (bankCode, accountNumber) => {
    resolveAccount(
      { bank_code: bankCode, account_number: accountNumber },
      {
        onSuccess: (res) => {
          setExpenseDetails((prevState) => ({
            ...prevState,
            account_name: res?.data?.data?.account_name,
          }));
        },
        onError: () => {
          setExpenseDetails((prevState) => ({
            ...prevState,
            account_name: null,
          }));
        },
      },
    );
  };

  const { mutate: addExpense, isLoading: isCreatingExpense } = useMutation(
    (data) => createExpense(data),
  );
  const submitExpense = () => {
    if (expenseDetails.make_refund && !isDisbursmentForm) {
      setIsDisbursmentForm(true);
      window.setExpenseFormValue("isDisbursmentForm", true);
      return;
    }
    delete expenseDetails.store_label;
    delete expenseDetails.category_label;
    delete expenseDetails.payment_method_label;
    delete expenseDetails.bank_code_label;
    if (!expenseDetails?.make_refund) {
      delete expenseDetails.bank_code;
      delete expenseDetails.account_number;
      delete expenseDetails.account_name;
    }

    setRequestLoaderProgress(getRandomIntInclusive(5, 40));
    addExpense(expenseDetails, {
      onSuccess: () => {
        triggerToast("Expense creation successful", "success");
        handleFetchExpenses();
        closeWidget();
      },

      onSettled: () => setRequestLoaderProgress(100),
    });
  };

  const handleFetchCategories = () => {
    queryClient.refetchQueries(["getExpenseCategories", "expenseCategories"]);
    expenseCategories.refetch();
  };

  return {
    isLoading: bankList.isLoading || expenseCategories.isLoading,
    bankList: bankList.data || [],
    storeList,
    expenseCategories: expenseCategories?.data || [],
    expenseDetails,
    isDisbursmentForm,
    isCreatingExpense,
    setExpenseDetails,
    setIsDisbursmentForm,
    submitExpense,
    handleProofUpload,
    handleResolveAccountNumber,
    handleFetchCategories,
  };
};
export default useCreateExpense;
