import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getSuppliersDetails } from "../../../../services/suppliers";
import { getSupplierInvoices } from "../../../../services/invoices";

const useSupplierDetails = () => {
  const params = useParams();

  const { isLoading: isFetchingSupplierDetails, data: supplierDetails } =
    useQuery(["getSuppliersDetails", params.id], () =>
      getSuppliersDetails(params.id).then((res) => res?.data?.data),
    );

  const {
    isLoading: isFetchingInvoice,
    data: invoices,
    refetch: refetchSupplierInvoices,
  } = useQuery(["getSupplierInvoices", params.id], () =>
    getSupplierInvoices(params.id).then((res) => res?.data?.data),
  );

  return {
    isFetchingSupplierDetails,
    isFetchingInvoice,
    supplierDetails,
    invoices,
    refetchSupplierInvoices,
  };
};
export default useSupplierDetails;
