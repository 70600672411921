import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import {
  fetchPaymentMethods,
  getViewMenuList,
  menuProcessPayment,
} from "../../../../../services/inventory";
import { useParams } from "react-router-dom";
import { ToastContext } from "../../../../../hooks/context";

const usePayForOrder = () => {
  const { id } = useParams();
  const triggerToast = useContext(ToastContext);
  const { menuCartItems } = useSelector((state) => state.menuCartReducer);
  const [paymentMethod, setPaymentMethod] = useState(null);

  const { data: menuObject } = useQuery(["getViewMenuList", [id]], () =>
    getViewMenuList({ id: id }).then((res) => res?.data?.data),
  );

  const { isLoading: isLoadingProcessPayment, mutate: mutateProcessPayment } =
    useMutation({
      mutationFn: (data) => menuProcessPayment(data),
      onSuccess: (data) => {
        window.location.href = data.data.data.init_data.url;
      },
      onError: (error) => {
        triggerToast(`${error.response.data.message}`, "warning");
      },
    });

  const {
    isLoading: isLoadingPaymentGateways,
    mutate: mutatePaymentGateway,
    data: dataPaymentGateways,
  } = useMutation({
    mutationFn: (data) => fetchPaymentMethods(data),
    onError: (error) => {
      triggerToast(`${error.response.data.message}`, "warning");
    },
  });

  const handleProcessPayment = () => {
    if (!paymentMethod) {
      return triggerToast(
        "Choose your preferred payment method to make payment",
        "warning",
      );
    }

    if (id && menuObject) {
      const guest_token = localStorage.getItem("guest_token");
      const order_id = localStorage.getItem("order_id");

      mutateProcessPayment({
        guest_token,
        order_id,
        menu_id: menuObject.id,
        callback_url: `${window.location.origin}/storefront/${id}?orderId=${order_id}`,
        merchant_token: paymentMethod.merchant_token,
        payment_method: paymentMethod.type,
      });
    }
  };

  useEffect(() => {
    if (dataPaymentGateways && dataPaymentGateways.data.data.length === 1) {
      setPaymentMethod(dataPaymentGateways.data.data[0]);
    }
  }, [dataPaymentGateways]);

  useEffect(() => {
    if (id && menuObject) {
      mutatePaymentGateway({ menu_id: menuObject.id });
    }
  }, [id, menuObject]);

  return {
    isLoadingProcessPayment,
    isLoadingPaymentGateways,
    dataPaymentGateways,
    menuCartItems,
    paymentMethod,
    store: menuObject?.store,
    setPaymentMethod,
    handleProcessPayment,
    menuObject,
  };
};

export default usePayForOrder;
