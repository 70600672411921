import httpClient from "../axiosBase/axiosHandler";

export const getMetacareAnalytics = () => {
  return httpClient.get("/metabase-analytics/");
};

export const getMetacareAnalyticsDetails = (id) => {
  return httpClient.get(`/metabase-analytics/${id}`);
};

export const getProfitAndLostData = (queries) => {
  return httpClient.get(`/metrics/profit-lost/${queries}`);
};
