import httpClient from "../axiosBase/axiosHandler";

export const uploadImage = (payload) => {
  const response = httpClient.post("/media/", payload);
  return response;
};

export const getUploadedImage = () => {
  const response = httpClient.get("/media/");
  return response;
};
