import { useQuery } from "react-query";
import { useParams } from "react-router";
import { getViewMenuList } from "../../../../../services/inventory";

export const useOrderSummary = () => {
  const { id } = useParams();

  const { data: menuObject } = useQuery(["getViewMenuList", [id]], () =>
    getViewMenuList({ id: id }).then((res) => res?.data?.data),
  );

  return {
    store: menuObject?.store,
    menuObject,
  };
};
